import React from 'react';
import './upper-cards.css';
import './progress.css';
import { Col, Progress, Row } from 'antd';
import ProgressBar from './ProgressBar';

const UpperCard2 = (props) => {
  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={8}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            <ProgressBar
              strokeColor={'#1890ff'}
              strokeWidth={12}
              percent={82}
              showInfo={false}
              status="active"
            />
          </div>
          <div>
            <h1 className="title">{props.number1}</h1>
            <p className="body mb-0" style={{ fontSize: "18px" }}>{props.title1}</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            <ProgressBar
              strokeColor={'#1890ff'}
              strokeWidth={12}
              percent={35}
              showInfo={false}
              status="active"
            />
          </div>
          <div>
            <h1 className="title">{props.number2}</h1>
            <p className="body mb-0" style={{ fontSize: "18px" }}>{props.title3}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpperCard2;
