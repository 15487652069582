import React from "react";
import { Pagination } from "antd";

const CustomPagination = ({ onPageChange, current, total }) => (
  <Pagination
    onChange={onPageChange}
    current={current}
    defaultCurrent={1}
    pageSize={20}
    total={total}
    showSizeChanger={false}
    showTotal={(total) => `${total} records come`}
  />
);

export default CustomPagination;
