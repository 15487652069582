import React from "react";
import { Row, Col, Button, Typography } from "antd";
const styles = {
  addVideoButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },

  titleContainer: {
    padding: "10px 20px",
    color: "#fb6222",
  },
};
export default function ContantTitle({ heading, openForm, buttonText }) {
  return (
    <Row
      type="flex"
      justify={"space-between"}
      gutter={[16, 16]}
      align={"middle"}
    >
      <Col span={8}>
        <Typography.Title level={3} style={styles.titleContainer}>
          {heading}
        </Typography.Title>
      </Col>
      <Col span={4}>
        {buttonText && (
          <div style={styles.addVideoButtonContainer}>
            <Button
              type={"primary"}
              onClick={openForm}
              size="large"
              style={styles.addVideoButton}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
}
