import { Col, Image, Rate, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { MAIN_BACKGROUND_COLOR } from '../../styleConstants';
import ModalUtil from '../../utils/Modal/Modal';
import RowStatus from './RowStatus';

const styles = {
  modal: {
    backgroundColor: '#f5f5f5',
  },
  submitButton: {
    width: '100%',
  },
  submitButtonCancel: {
    width: '100%',
  },
  submitButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

const AddUser = (props) => {
  const {
    isOpen,
    closeModal,
    inputHandler,
    submitForm,
    isFormLoading,
    selectedUser,
  } = props;
  console.log(selectedUser, 'selectedUser');
  return (
    <ModalUtil
      timerModalVisible={isOpen}
      timerModalLoading={isFormLoading}
      handleTimerModalOnOk={submitForm}
      handleTimerModalOnCancle={closeModal}
      width={'800px'}
    >
      <Row
        style={{
          background: MAIN_BACKGROUND_COLOR,
          padding: '10px',
        }}
      >
        <Col span={24}>
          <Typography.Title level={3} style={{ color: 'white' }}>
            Profile Hisory
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[16, 24]} justify='center' align='middle'>
        <Col span={12}>
          <Row gutter={[16, 24]} justify='center' align='middle'>
            <Col push={2} span={10}>
              <Avatar
                size={100}
                src={
                  selectedUser?.profileImage ? (
                    <Image src={selectedUser?.profileImage} alt='profile' />
                  ) : (
                    'https://via.placeholder.com/100'
                  )
                }
              />
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'column' }} span={12}>
              <Typography.Title
                style={{ textTransform: 'capitalize' }}
                level={4}
              >
                {selectedUser?.name}
              </Typography.Title>
              {selectedUser?.city && (
                <Typography.Title level={4}>
                  {selectedUser?.city}
                </Typography.Title>
              )}
              <Rate tooltips={desc} value={selectedUser?.rating} />
              {selectedUser?.rating ? (
                <span className='ant-rate-text'>
                  Profile strength {desc[selectedUser?.rating - 1]}
                </span>
              ) : (
                ''
              )}
              <Typography.Text>
                {' '}
                <a
                  style={{ color: 'blue' }}
                  target='_blank'
                  href={`mailto:${selectedUser?.email}`}
                >
                  {selectedUser?.email}
                </a>
              </Typography.Text>
              <Typography.Text>
                {' '}
                <a
                  style={{ color: 'blue' }}
                  href={`tel:${selectedUser?.phoneNumber}`}
                >
                  {selectedUser?.phoneNumber}{' '}
                </a>
              </Typography.Text>
              <Typography.Text>Status {selectedUser?.status}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <RowStatus field={'Total Post'} value={23} />
            <RowStatus
              field={'Date of Creation'}
              value={new Date(selectedUser?.createdAt).toLocaleDateString()}
            />
            {selectedUser?.lastSignIn && (
              <RowStatus
                field={'Last Login'}
                value={new Date(selectedUser?.lastSignIn).toLocaleDateString()}
              />
            )}

            <RowStatus
              field={'Login History'}
              value={selectedUser?.signInCount ? selectedUser?.signInCount : 1}
            />
            <RowStatus
              field={'Edit Hitory'}
              value={
                selectedUser?.profileUpdateCount
                  ? selectedUser?.profileUpdateCount
                  : 1
              }
            />
            <RowStatus field={'Got Reported'} value={5} />

            <RowStatus field={'Made Report'} value={5} />
            <RowStatus
              field={'Social Engagements'}
              value={'like 43, dislike 43, comment 23,'}
            />

            <RowStatus field={'Total Business'} value={'23423 PRR'} />
          </Row>
        </Col>
      </Row>
    </ModalUtil>
  );
};

export default AddUser;
