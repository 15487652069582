import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHANGE_MOBILE_VIEW } from '../Redux/actionTypes';

export default function MobileComponent() {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const { isMobileView } = useSelector((state) => state.Setting);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  useEffect(() => {
    handleSideNavToggle();
  }, [width]);

  const handleSideNavToggle = () => {
    if (!isMobileView && width < 700) {
      dispatch({
        type: CHANGE_MOBILE_VIEW,
        payload: true,
      });
    } else if (isMobileView && width > 700) {
      dispatch({
        type: CHANGE_MOBILE_VIEW,
        payload: false,
      });
    }
  };
  return <div></div>;
}
