import { Button, Card, Col, DatePicker, Image, Row, Select, Typography } from "antd";
import { default as React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
// import { dateFormat, poultryCommodities } from '../../../constant';
import { MAIN_BACKGROUND_COLOR } from "../../../styleConstants";
import { dateFormat, poultryCommodities } from "../../../shared";
import MurghiMandiLogo from "../../../assets/Dashboard Icons/MurghiMandi.png";
import { getMurgiStatApi } from "../../../RestServices/postingService";
const { RangePicker } = DatePicker;

const { Option } = Select;

export default function OrderDashboardStat({ userId }) {
  const [selectedCommodities, setSelectedCommodities] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [stat, setStat] = useState(null);
  const { token } = useSelector((state) => state.Authentication.user);

  const { allcities: ecommerceCity } = useSelector((state) => state.Cities);

  const [dateBetween, setDateBetween] = useState({
    startDate: moment(new Date()).set("days", -5),
    endDate: moment(new Date()),
  });

  useEffect(() => {
    (async () => {
      try {
        const statItem = await getMurgiStatApi(
          {
            startDate: dateBetween.startDate.toDate(),
            endDate: dateBetween.endDate.toDate(),
            subType: selectedCommodities,
            city: selectedCity,
            crmUserId: userId,
          },
          token,
        );
        setStat(statItem?.posts);
      } catch (err) { }
    })();
  }, [token, dateBetween, selectedCity, selectedCommodities]);

  const kFormatter = (num) => {
    if (isNaN(num)) {
      return "0";
    }
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(3) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisiblePost, setIsModalVisiblePost] = useState(false);

  const showModalPost = () => {
    setIsModalVisiblePost(true);
  };

  const handleOkPost = () => {
    setIsModalVisiblePost(false);
  };

  const handleCancelPost = () => {
    setIsModalVisiblePost(false);
  };
  const { Title } = Typography;
  const [selectedModal, setSelectedModal] = useState(undefined);

  return (
    <div style={{ marginTop: "20px", padding: "10px" }}>
      {/* <UserDetails
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        data={selectedModal}
      />

      <PostDetails
        isModalVisible={isModalVisiblePost}
        handleOk={handleOkPost}
        handleCancel={handleCancelPost}
        data={selectedModal}
      /> */}

      <Row gutter={[12, 12]} style={{ paddingBottom: "12px", borderBottom: "2px solid #603813", alignItems: "center" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Image src={MurghiMandiLogo} alt="Murghi Mandi Logo" style={{ width: "100%", height: "120px", marginLeft: "40px" }} />
        </Col>
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <Select
            showSearch
            style={{ width: "100%" }}
            value={selectedCity}
            placeholder="Select a city"
            optionFilterProp="children"
            onChange={(e) => {
              setSelectedCity(e);
            }}
            filterOption={(input, option) =>
              option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
          >
            <Option value={""}>Over All</Option>;
            {ecommerceCity?.map((city) => {
              return (
                <Option key={city.value} value={city.value}>
                  {city.value}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <Select
            showSearch
            style={{ width: "100%" }}
            value={selectedCommodities}
            placeholder="Select a Commodity"
            optionFilterProp="children"
            onChange={(e) => {
              setSelectedCommodities(e);
            }}
            filterOption={(input, option) =>
              option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
          >
            <Option value={""}>Over All</Option>;
            {poultryCommodities.map((commodities) => {
              return (
                <Option key={commodities} value={commodities}>
                  {commodities}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <RangePicker
            style={{ width: "100%" }}
            onChange={(e) =>
              setDateBetween({
                startDate: e[0],
                endDate: e[1],
              })
            }
            defaultValue={[
              moment(dateBetween.startDate, dateFormat),
              moment(dateBetween.endDate, dateFormat),
            ]}
            value={[
              moment(dateBetween.startDate, dateFormat),
              moment(dateBetween.endDate, dateFormat),
            ]}
            renderExtraFooter={() => (
              <div>
                <Button
                  onClick={(e) =>
                    setDateBetween({
                      startDate: moment().startOf("week"),
                      endDate: moment().endOf("week"),
                    })
                  }
                  type="primary"
                  className={"datePicker"}
                >
                  This week
                </Button>
                <Button
                  onClick={(e) =>
                    setDateBetween({
                      startDate: moment().startOf("month"),
                      endDate: moment().endOf("month"),
                    })
                  }
                  type="primary"
                  className={"datePicker"}
                >
                  This month
                </Button>
                <Button
                  onClick={(e) =>
                    setDateBetween({
                      startDate: moment().startOf("year"),
                      endDate: moment().endOf("year"),
                    })
                  }
                  type="primary"
                  className={"datePicker"}
                >
                  This year
                </Button>
                <Button
                  onClick={(e) =>
                    setDateBetween({
                      startDate: moment().startOf("week").set("days", -7),
                      endDate: moment().startOf("week"),
                    })
                  }
                  type="primary"
                  className={"datePicker"}
                >
                  Previous Week
                </Button>
                <Button
                  onClick={(e) =>
                    setDateBetween({
                      startDate: moment().startOf("month").set("days", -30),
                      endDate: moment().startOf("month"),
                    })
                  }
                  type="primary"
                  className={"datePicker"}
                >
                  Previous Month
                </Button>
              </div>
            )}
          />
        </Col>
      </Row>

      <Row gutter={[12, 12]} style={{ paddingBottom: "12px", paddingTop: "12px", }}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor" onClick={() => {
            setSelectedModal({
              stat: stat?.sellerStat,
              payment: stat?.sellingPaymentDetail,
            });
            showModal();
          }}>
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>Top Seller</Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>{stat?.sellerStat?.length}</Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor" onClick={() => {
            setSelectedModal({
              stat: stat?.buyerStat,
              payment: stat?.buyingPaymentDetail,
            });
            showModal();
          }}
          >
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>Top buyer</Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>{stat?.buyerStat?.length}</Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor"
            style={{ backgroundColor: MAIN_BACKGROUND_COLOR }} onClick={() => {
              setSelectedModal({
                stat: stat?.postStat[0].postIds,
              });
              showModalPost();
            }}>
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ color: "white", marginBottom: "3px", }}>Views(Selling)</Title>
              <Title level={2} className="primaryColor" style={{ color: "white", marginBottom: 0, marginTop: 0, }}>
                {!stat?.postStat.length
                  ? stat?.postStat.length
                  : stat?.postStat[0]?.views}
              </Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor"
            style={{ backgroundColor: MAIN_BACKGROUND_COLOR }} onClick={() => {
              setSelectedModal({
                stat: stat?.buyingPost[0].postIds,
              });
              showModalPost();
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ color: "white", marginBottom: "3px", }}>View(Buying)</Title>
              <Title level={2} className="primaryColor" style={{ color: "white", marginBottom: 0, marginTop: 0, }}>
                {!stat?.buyingPost.length
                  ? stat?.buyingPost.length
                  : stat?.buyingPost[0]?.views}
              </Title>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor">
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>
                Unit sold(Selling)
              </Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>
                {!stat?.postStat.length
                  ? stat?.postStat.length
                  : stat?.postStat[0]?.numberOfPatties}
              </Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor">
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>
                Unit sold(buying)
              </Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>
                {!stat?.buyingPost.length
                  ? stat?.buyingPost.length
                  : stat?.buyingPost[0]?.numberOfPatties}
              </Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor">
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>
                Total Commission
              </Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>
                {stat?.postStat.length
                  ? kFormatter(stat?.paymentDetail[0]?.total)
                  : 0}
              </Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor"
            style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}
          >
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ color: "white", marginBottom: "3px", }}>
                Total Posts(Selling)
              </Title>
              <Title level={2} className="primaryColor" style={{ color: "white", marginBottom: 0, marginTop: 0, }}>
                {!stat?.postStat.length
                  ? stat?.postStat.length
                  : stat?.postStat[0]?.count}
              </Title>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor"
            style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}>
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ color: "white", marginBottom: "3px", }}>
                Total Posts(Buying)
              </Title>
              <Title level={2} className="primaryColor" style={{ color: "white", marginBottom: 0, marginTop: 0, }}>
                {!stat?.buyingPost.length
                  ? stat?.buyingPost.length
                  : stat?.buyingPost[0]?.count}
              </Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor">
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>
                Total offer
              </Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>
                {!stat?.dealStat.length
                  ? stat?.dealStat.length
                  : stat?.dealStat[0].count}
              </Title>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card className="blockOrderDashboardStat primaryColor">
            <div style={{ textAlign: "center" }}>
              <Title level={4} className="primaryColor" style={{ marginBottom: "3px", }}>
                Total deals
              </Title>
              <Title level={2} className="primaryColor" style={{ marginBottom: 0, marginTop: 0, }}>
                {!stat?.dealStat.length
                  ? stat?.dealStat.length
                  : stat?.dealStat[0].numberOfDeals}
              </Title>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
