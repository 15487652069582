import { Select } from "antd";
import React from "react";
import "./dropdown.css";
const { Option } = Select;

const CategoryDropDown = ({ itemProducts, selectedProduct, setProduct }) => {
  return (
    <>
      <div>
        <Select
          value={selectedProduct}
          onChange={(value)=>setProduct(value)}
          style={{ width: "100%" }}
        >
          {itemProducts.map((val) => {
            return (
              <Option key={val} value={val}>
                {val.split("_").join(" ")?.toLowerCase()}
              </Option>
            );
          })}
        </Select>
      </div>
    </>
  );
};

export default CategoryDropDown;
