import { AntDesignOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Upload,
} from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { customPayment } from '../../RestServices/postingService';
import { uploadThumnail } from '../../RestServices/videosService';

export default function PaySlip({
  visible,
  handleCancel,
  handleOk,
  paySlipBid,
}) {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const { token } = useSelector((state) => state.Authentication.user);

  const uploadThumbnail = async (thumbnail) => {
    // this.setState({ isFormLoading: true });
    // const { thumbnail } = this.state;
    const formData = new FormData();
    formData.append('thumbnail', thumbnail);
    try {
      const response = await uploadThumnail(formData, token);
      setUrl(response.thumnailUrl);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  const [thumbnails, setThumbnails] = useState([]);
  const thumbnailProps = {
    onRemove: () => {
      // setArticleImage(null);
      setThumbnails(null);
    },
    beforeUpload: (image) => {
      uploadThumbnail(image);
      setThumbnails([thumbnails, ...image]);
      return false;
    },
    fileList: thumbnails,
    accept: 'image/*',
  };

  const addReceipt = async () => {
    try {
      setLoading(true);
      const response = await customPayment(
        {
          bidId: paySlipBid?.bid?._id,
          receiptUrl: url,
          amount,
          customer: paySlipBid?.user,
        },
        token,
      );
      console.log(response);
      handleOk();
      setUrl('');
      setAmount('');
    } catch (err) {
      message.error(new Error(err).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title="Payment slip"
        visible={visible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <Form onSubmit={() => alert('asdfj')} layout={'vertical'}>
          <Form.Item labelAlign required label="Slip Image">
            <Col span={4} style={{ textAlign: 'right' }}>
              <Avatar
                size={{
                  xs: 50,
                  sm: 60,
                  md: 70,
                  lg: 80,
                  xl: 90,
                  xxl: 100,
                }}
                icon={!url ? <AntDesignOutlined /> : <Image src={url} />}
              />

              <Upload {...thumbnailProps}>
                <Button
                  block
                  size="large"
                  icon={<UploadOutlined />}
                  style={{ marginTop: '18px' }}
                >
                  image Upload
                </Button>
              </Upload>
            </Col>
          </Form.Item>
          <Form.Item labelAlign required requiredMark label="Amount">
            <Input
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item labelAlign required requiredMark label="bid Id">
            <Input name="bid" value={paySlipBid?.bid?._id} required disabled />
          </Form.Item>
          <Form.Item labelAlign required requiredMark label="User Contact Info">
            <Input
              disabled
              name="User"
              value={paySlipBid?.user?.phoneNumber}
              required
            />
          </Form.Item>
          <Form.Item labelAlign required requiredMark label="Slip url">
            <Input disabled name="url" value={url} required />
          </Form.Item>
          <Button onClick={addReceipt} loading={loading} type="primary">
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
