import { Select } from "antd";
import React from "react";
import "./dropdown.css";
const { Option } = Select;
const CategoryDropDown = ({ categoriesOptions, setCategory, category }) => {
  return (
    <>
      <Select
        value={category}
        style={{ width: "100%" }}
        onChange={setCategory}
        placeholder="Select Category"
      >
        {categoriesOptions.map((val, index) => {
          return (
            <Option key={index} value={val}>
              {val.split("_").join(" ")}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export default CategoryDropDown;
