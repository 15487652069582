import {
  CITIES_LOADING,
  CITIES_SUCCESSFULL,
  CITIES_FAILED,
  CITIES_CHANGED,
} from "../../actionTypes";

const Cities = (
  state = {
    cities: null,
    isLoading: false,
    errMess: null,
    provinces: null,
    allcities: null,
  },
  action
) => {
  switch (action.type) {
    case CITIES_LOADING:
      return { ...state, isLoading: true, errMess: null };
    case CITIES_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.errMess,
      };
    case CITIES_SUCCESSFULL:
      return {
        ...state,
        cities: action.cities.cities,
        provinces: action.cities.provices,
        allcities: action.cities.allcities,
        isLoading: false,
        errMess: null,
      };
    case CITIES_CHANGED:
      return {
        ...state,
        cities: action.cities.cities,
        isLoading: false,
        errMess: null,
      };
    default:
      return state;
  }
};
export default Cities;
