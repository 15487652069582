import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Row,
  Select,
  Skeleton,
  Table,
  Typography,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbacks } from "../../Redux/ActionCreaters/FeedBackRequest";
import { feedBackEdit } from "../../RestServices/authService";
import ContantTitle from "../../utils/contantTitle/ContantTitle";
import Pagination from "../Pagination";

const { Option } = Select;
export default function FeedBack() {
  const [visible, setVisible] = useState(false);
  const [details, setdetails] = useState({});
  const columns = [
    {
      title: "Sr #",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "name",
      dataIndex: "userId",
      key: "userId",
      width: "15%",
      align: "left",
      render: (userId) => (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Row>
              <Col span={4}>
                <Avatar size={30}>
                  <Image src={userId && userId.profileImage} />
                </Avatar>
              </Col>
              <Col
                style={{
                  paddingLeft: "20px",
                  textDecoration: "underline",
                }}
                span={20}
              >
                {userId && userId.name}
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "userId",
      key: "userId",
      width: "15%",
      align: "left",
      render: (userId) => (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Row align="middle" justify="start">
              <Typography.Text type={"warning"}>
                {" "}
                {userId && userId.phoneNumber}
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Subject",
      key: "subject",
      dataIndex: "subject",
      align: "left",
      render: (subject) => <span>{subject}</span>,
    },
    {
      title: "Detail",
      key: "message",
      dataIndex: "message",
      align: "left",
      width: "10%",
      render: (detail) => (
        <div style={{ width: "100px" }}>
          {" "}
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              tooltip: <span> {detail}</span>,
            }}
            style={{ fontSize: "12px" }}
          >
            {detail}
          </Typography.Paragraph>
        </div>
      ),
    },

    {
      title: "First seen by",
      key: "adminSeenBy",
      dataIndex: "adminSeenBy",
      align: "left",
    },
    {
      title: "Status",
      key: "feedbackResponsed",
      dataIndex: "feedbackResponsed",
      width: "15%",
      align: "center",
      render: (feedbackResponsed) => {
        return (
          <span>
            {feedbackResponsed ? (
              <span style={{ color: "green" }}> Resolved </span>
            ) : (
              <span>Not Resolved</span>
            )}
          </span>
        );
      },
    },
    {
      title: "Date / Time",
      key: "createdAt",
      dataIndex: "createdAt",
      width: "15%",
      align: "center",
      render: (createdAt) => (
        <span>
          {new Date(createdAt).toLocaleDateString()} /
          {new Date(createdAt).toLocaleTimeString()}
        </span>
      ),
    },
    {
      title: "Show Datail",
      key: "_id",
      dataIndex: "_id",
      width: "15%",
      align: "center",
      render: (_id, detail) => (
        <span>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
              setdetails(detail);
            }}
          >
            {" "}
            Show Detail
          </Button>
        </span>
      ),
    },
  ];

  const [feedbackResponsed, setFeedbackResponsed] = useState(false);

  const { isLoading, feedBack } = useSelector((state) => state.FeedBack);
  const { token } = useSelector((state) => state.Authentication.user);

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    getData(page);
  }, [page, feedbackResponsed]);

  const getData = (page) => {
    dispatch(getFeedbacks(page, feedbackResponsed, token));
  };
  const [onEditLoading, setonEditLoading] = useState(false);
  const onEditFeedBack = async (feedbackId) => {
    try {
      setonEditLoading(true);
      const response = await feedBackEdit(
        {
          feedbackId,
          attributes: {
            feedbackResponsed: true,
          },
        },
        token,
      );
      console.log(response);
      const { feedback } = response;
      dispatch(getFeedbacks(page, feedbackResponsed, token));
      setdetails({ ...details, feedbackResponsed: feedback.feedbackResponsed });
    } catch (err) {
    } finally {
      setonEditLoading(false);
    }
  };
  const { Title } = Typography;
  return (
    <div>
      <Modal
        title="Details Feedback"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={700}
      >
        <Row gutter={[16, 16]}>
          <Col span={6}>First Seen By:</Col>
          <Col span={18}>{details?.adminSeenBy}</Col>

          <Col span={6}>Subject:</Col>

          <Col span={18}>{details?.subject}</Col>

          <Col span={6}>Detail Message:</Col>

          <Col span={18}>{details?.message}</Col>

          <Col span={6}>Status</Col>

          <Col span={18}>
            {details?.feedbackResponsed ? "Resolved" : "Not Resolved"}
          </Col>

          <Col span={6}>Feed Back From: </Col>
          <Col span={18}>
            <div>
              name: {details?.userId?.name} <br /> phoneNumber:{" "}
              {details?.userId?.phoneNumber}
            </div>
          </Col>
          <Col span={6}></Col>
          <Col>
            {!details?.feedbackResponsed ? (
              <Button
                loading={onEditLoading}
                onClick={() => onEditFeedBack(details._id)}
                type="primary"
              >
                {" "}
                Resolved Feed Back
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal>{" "}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <div className="flex-between">
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div className="flex-col">
                        <Title level={3} className="mb-0 text-primary" style={{ height: "50px", lineHeight: "50px" }}>{feedBack ? `All List (${feedBack.totalDocs})` : "All List"}</Title>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Category</Title>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Category"
                          optionFilterProp="children"
                          onChange={setFeedbackResponsed}
                          filterOption={(input, option) =>
                            option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                          }
                        >
                          <Option value={true}>Resolved</Option>

                          <Option value={false}>Not Resolved</Option>
                        </Select>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Button
                    loading={isLoading}
                    type={"primary"}
                    onClick={() => getData(1)}
                  >
                    Refersh
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            {isLoading ? (
              <Skeleton active paragraph={{ rows: 10 }} />
            ) : (
              <Table
                pagination={true}
                columns={columns}
                dataSource={feedBack && feedBack.docs}
                style={{ overflow: "auto" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

