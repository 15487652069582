import React from "react";
import "./upper-cards.css";
import "./progress.css";
import ProgressBar from "./ProgressBar";
import { calCulatePercentage } from "../../../../../shared";
// import { Progress } from "antd";

const UpperCard1 = ({ setSelected, ...props }) => {
  return (
    <>
      <div
        onClick={() => setSelected("Dashboard/User", "All")}
      > 
        <div style={{ position: "relative", padding: "15px" }}>

          <div>
            <ProgressBar
              strokeColor={"#fcb539"}
              strokeWidth={12}
              percent={calCulatePercentage(props.number, props.preNumber)}
              showInfo={false}
              status="active"
            />
          </div>

          <div>
            <h1 className="title">{props.number}</h1>
            <p className="body mb-0">{props.title}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpperCard1;
