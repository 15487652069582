import React from "react";
import ReactPlayer from "react-player";
import { Modal } from "antd";
class VideoPlayer extends React.Component {
  render() {
    const { closePlayer, url, isPlayerOpen, videoTitle } = this.props;
    return (
      <Modal
        maskClosable={false}
        title={videoTitle}
        onCancel={closePlayer}
        visible={isPlayerOpen}
        footer={null}
      >
        <div>
          <ReactPlayer width="100%" controls playing={isPlayerOpen} url={url} />
        </div>
      </Modal>
    );
  }
}
export default VideoPlayer;
