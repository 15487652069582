import {
  ORDER_LOADING,
  ORDER_SUCCESSFULL,
  ORDER_FAILED,
  ORDER_CHANGED,
  ORDER_COUNT_SUCCESSFULL,
  BIDDINGS_SUCCESSFULL,
} from '../actionTypes';

export const ordersLoading = () => ({
  type: ORDER_LOADING,
});

export const ordersSuccessFull = (orders) => ({
  type: ORDER_SUCCESSFULL,
  orders,
});

export const biddingSuccess = (payload) => ({
  type: BIDDINGS_SUCCESSFULL,
  payload,
});

export const ordersCount = (ordersCount) => ({
  type: ORDER_COUNT_SUCCESSFULL,
  ordersCount,
});

export const changeOrder = (order) => ({
  type: ORDER_CHANGED,
  order,
});

export const ordersFailed = (errMess) => ({
  type: ORDER_FAILED,
  errMess,
});
