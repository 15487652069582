import { Button, Form, Input, Row, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import { deleteUserByUserId } from "../../RestServices/authService";
import { PermissionEnum } from "../../shared";
import ModalUtil from "../../utils/Modal/Modal";

class DeleteUser extends React.Component {
  state = {
    isDeleteLoading: false,
    isDeleted: false,
    actionModal: false,
    passwordForAction: null,
  };
  deleteUser = async () => {
    const { permissions: userPermission } = this.props.Auth.user.profileDetails;

    if (this.state.passwordForAction !== "1345") {
      return message.error("Pass key is wrong. Rocorded");
    }
    try {
      if (
        userPermission.includes(PermissionEnum["AllResources:*"]) ||
        userPermission.includes(PermissionEnum["Users:*"]) ||
        userPermission.includes(PermissionEnum["Users:delete"])
      ) {
        this.setState({ isDeleteLoading: true });

        await deleteUserByUserId(
          { userId: this.props.customer._id },
          this.props.Auth.user.token,
        );
        message.success("User " + this.props.customer.name + " Deleted!");
        this.setState({
          isDeleteLoading: false,
          isDeleted: true,
          actionModal: false,
          passwordForAction: null,
        });
      } else {
        this.setState({ actionModal: false });
        return message.error("User has not Permission");
      }
    } catch (err) {
      this.setState({ isDeleteLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      message.error(err.message);
    }
  };
  onCloseActionModal = () => {
    this.setState({
      actionModal: false,
      passwordForAction: null,
    });
  };
  render() {
    const { isDeleted, isDeleteLoading } = this.state;
    if (isDeleted) return "User Deleted!";
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ModalUtil
          timerModalVisible={this.state.actionModal}
          timerModalLoading={isDeleteLoading}
          title={"set Rate"}
          showTimerModal={this.setActionModal}
          handleTimerModalOnOk={this.deleteUser}
          handleTimerModalOnCancle={() => this.onCloseActionModal()}
        >
          <Row style={{ margin: "20px" }}>
            <Form
              style={{ padding: "20px" }}
              colon={false}
              layout="vertical"
              onSubmit={this.deleteUser}
            >
              <h3>Enter the pass key to perform action</h3>
              <Form.Item label="Pass key" required>
                <Input
                  size="large"
                  type="password"
                  name="passwordForAction"
                  onChange={(e) =>
                    this.setState({ passwordForAction: e.target.value })
                  }
                  value={this.state.passwordForAction}
                  placeholder="Enter Password for action"
                />
              </Form.Item>
            </Form>
          </Row>
        </ModalUtil>

        <Button
          type={"primary"}
          loading={isDeleteLoading}
          disabled={isDeleteLoading}
          onClick={() => this.setState({ actionModal: true })}
        >
          Delete
        </Button>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(DeleteUser);
