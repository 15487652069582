import { LeftSquareTwoTone } from '@ant-design/icons';
import { Breadcrumb, Col, Image, message, Row, Table } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { createAdminOrSubAdmin } from '../../RestServices/authService';
import { getCommodityTopFan } from '../../RestServices/postingService';
import { PermissionEnum } from '../../shared';
import { fontColor, MAIN_BACKGROUND_COLOR } from '../../styleConstants';
import Calender from '../../utils/CalenderDropDown/Calender';
import ContantTitle from '../../utils/contantTitle/ContantTitle';
import '../Dashboard/styles.css';
import Pagination from '../Pagination';
//

// import Table from "../Table";

const styles = {
  layout: {
    minHeight: '100vh',
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: 'bold',
  },
  content: {
    color: fontColor,
    backgroundColor: 'white',
    margin: '20px',
  },
  logo: {
    height: '200px',
    width: '190px',
  },
  menuToggler: {
    color: fontColor,
    fontSize: '30px',
    marginLeft: '5px',
    marginTop: '15px',
  },

  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  addUserButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addUserButton: {
    marginRight: '20px',
    backgroundColor: '#1f910e',
    color: 'white',
  },
};

class CommodityTrend extends React.Component {
  state = {
    collapsed: false,
    input: null,
    isFormLoading: false,
    customers: [],
    totalPages: 1,
    selectedRowKeys: [],
    currentPage: 1,
    metaData: {},
    isModalOpen: false,
    name: '',
    email: '',
    password: '',
    permissions: [],
    type: 'ADMIN',
    city: '',
    selectedUser: {},
    rawCustomer: [],
    phoneNumber: '',
    dropdrownshow: false,
    dateSelection: {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    },

    datesBetween: {
      startDate: undefined,
      endDate: undefined,
    },
  };

  onChangePermission = (permissions) => {
    this.setState({ permissions });
  };
  toggleSider = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  inputEvent = (e) => {
    this.setState({ input: e.target.value });
  };
  inputHandler = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };
  openUserShowDetailModal = () => this.setState({ isModalOpen: true });
  closeUserShowDetailModal = () => this.setState({ isModalOpen: false });

  resetForm = () =>
    this.setState({
      name: '',
      email: '',
      password: '',
      city: '',
      phoneNumber: '',
      permissions: [],
    });
  submitForm = async (evt) => {
    evt.preventDefault();
    const { name, email, password, phoneNumber, city, type, permissions } =
      this.state;
    if (!name || !email || !password || !phoneNumber || !city || !type) {
      return message.error('Please fill all value ');
    }
    if (!permissions.length) {
      return message.error('Please check the some permission');
    }

    this.setState({ isFormLoading: true });
    try {
      const data = await createAdminOrSubAdmin(
        { ...this.state, permissions },
        this.props.Auth.user.token,
      );

      const customer = {
        name: data.customer.name,
        email: data.customer.email,
        key: data.customer._id,
        updatedAt: new Date().toISOString(),
        type: data.customer.profileDetails.type,
        status: data.customer.status,
        phoneNumber: data.customer.phoneNumber,
      };
      this.setState({
        isFormLoading: false,
        isModalOpen: false,
        customers: [customer, ...this.state.customers],
      });
      message.success('User ' + data.customer.name + ' Created!');
      this.resetForm();
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  getUserApiFunc = async () => {
    let payload = {
      page: this.state.currentPage,
    };
    if (this.state.datesBetween.startDate)
      payload = {
        ...payload,
        startDate: moment(this.state.datesBetween.startDate)
          .add(1, 'days')
          .toDate(),
        endDate: moment(this.state.datesBetween.endDate)
          .add(1, 'days')
          .toDate(),
      };
    try {
      let data = await getCommodityTopFan(payload, this.props.Auth.user.token);
      console.log(data, 'data');

      const customers = data.stat.docs.map(
        ({ count, product, city, customerProfile: customer }) => ({
          count: count,
          Commodity: product,
          name: customer.name,
          city: city,
          //   type: customer.profileDetails.type,
          status: customer.status,
          key: customer.id + product,

          phoneNumber: customer.phoneNumber,
          profileImage: customer.profileImage,
        }),
      );
      this.setState({
        customers: [...customers],
        currentPage: data.stat.page,
        totalPages: data.stat.totalDocs,
        rawCustomer: data.stat.docs,
      });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  componentDidMount() {
    this.getUserApiFunc();
  }

  tableColumns = [
    {
      title: 'Image',
      width: 20,
      dataIndex: 'profileImage',
      fixed: 'left',
      align: 'center',
      render: (text, record, index) => (
        <Avatar src={<Image style={{ width: '80px' }} src={text} />} />
      ),
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },

    {
      key: 'phoneNumber',
      title: 'Phone',
      dataIndex: 'phoneNumber',
      render: (text, record, index) => (
        <a style={{ color: 'blue' }} href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      key: 'count',
      title: 'Level',
      dataIndex: 'count',
    },

    {
      key: 'Commodity',
      title: 'Commodity  Name',
      dataIndex: 'Commodity',
    },

    {
      key: 'city',
      title: 'City',
      dataIndex: 'city',
    },

    // {
    //   key: "type",
    //   title: "Rule",
    //   dataIndex: "type",
    // },

    {
      key: 'status',
      title: 'Status',
      // title: "Category",
      dataIndex: 'status',
    },
    // {
    //   key: "createdAt",
    //   title: "date of Creation",
    //   dataIndex: "createdAt",
    // },

    // {
    //   key: "updatedAt",
    //   title: "Last Seen",
    //   dataIndex: "updatedAt",
    // },
  ];

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getUserApiFunc();
    });
  };

  handleSelect = (ranges) => {
    if (ranges.hasOwnProperty('range1')) {
      this.setState({ dateSelection: { ...ranges.range1 } });
    } else {
      this.setState({ dateSelection: { ...ranges.selection } });
    }
  };
  PickDate = () => {
    this.setState(
      {
        dropdrownshow: false,
        datesBetween: {
          startDate: this.state.dateSelection.startDate,
          endDate: this.state.dateSelection.endDate,
        },
      },
      () => this.getUserApiFunc(),
    );
  };

  rowSelection = {
    onChange: (selectedRowKeys) => {
      const [id] = selectedRowKeys;
      const selectedUser = this.state.rawCustomer.find(
        ({ customerProfile }) => customerProfile._id === id,
      );
      this.setState({
        selectedRowKeys,
        selectedUser: selectedUser,
      });
      this.openUserShowDetailModal();
    },
  };

  render() {
    const { customers, totalPages, currentPage } = this.state;

    const { permissions: userPermission } = this.props.Auth.user.profileDetails;

    return (
      <div style={styles.content}>
        <Row>
          {' '}
          <Col span={24}>
            <div>
              <LeftSquareTwoTone
                onClick={() => {
                  if (this.props.slectedSubkey.startsWith('Crm')) {
                    this.props.setSelected('Crm_System');
                  } else {
                    this.props.setSelected('Dashboard');
                  }
                }}
                style={{
                  fontSize: '24px',
                  color: 'red',
                  margin: '20px',
                  cursor: 'pointer',
                }}
              />
            </div>
          </Col>
          <Col span={16}>
            <Breadcrumb style={{ margin: '16px 16px', cursor: 'pointer' }}>
              <Breadcrumb.Item>Admin Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.slectedSubkey}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={8}>
            <Calender
              handleSelect={this.handleSelect}
              PickDate={this.PickDate}
              dateSelection={this.state.dateSelection}
              datesBetween={this.state.datesBetween}
              dropdrownshow={this.state.dropdrownshow}
              setdropdrownshow={() => this.setState({ dropdrownshow: true })}
              setdateSelection={(pros) =>
                this.setState({ ...pros, dropdrownshow: false })
              }
            />
          </Col>
        </Row>
        {(userPermission.includes(PermissionEnum['AllResources:*']) ||
          userPermission.includes(PermissionEnum['Users:*']) ||
          userPermission.includes(PermissionEnum['Users:add'])) && (
          <ContantTitle
            heading={this.props.slectedSubkey + ' Users '}
            // buttonText={"Add User"}
            openForm={console.log}
          />
        )}
        {/* <div style={styles.tableContainer}> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={currentPage}
            total={totalPages}
            onPageChange={this.onPageChange}
          />
        </div>
        <Table
          dataSource={this.state.customers}
          pagination={false}
          columns={this.tableColumns}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(CommodityTrend);
