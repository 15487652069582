import { Checkbox, Form, Input, Menu } from "antd";
import React from "react";
import { UserPermisstion } from "../../shared";
import ModalUtil from "../../utils/Modal/Modal";
const styles = {
  modal: {
    backgroundColor: "#f5f5f5",
  },
  submitButton: {
    width: "100%",
  },
  submitButtonCancel: {
    width: "100%",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

const AddUser = (props) => {
  const {
    isOpen,
    closeModal,
    inputHandler,
    submitForm,
    isFormLoading,
    name,
    email,
    editUserId,
    password,
    type,
    onChangePermission,
    permissions,
    phoneNumber,
    city,
  } = props;
  const menu = (
    <Menu
      onClick={({ key }) =>
        inputHandler({ target: { name: "type", value: key } })
      }
    >
      <Menu.Item key="ADMIN">Admin</Menu.Item>
      <Menu.Item key="SUBADMIN">Sub Admin</Menu.Item>
    </Menu>
  );
  return (
    <ModalUtil
      timerModalVisible={isOpen}
      timerModalLoading={isFormLoading}
      handleTimerModalOnOk={submitForm}
      handleTimerModalOnCancle={closeModal}
    >
      {editUserId ? (
        <h1 style={{ paddingLeft: "20px", paddingTop: "20px" }}>Edit User</h1>
      ) : (
        <img src="/image/addUser.png" style={{ width: "100%" }} />
      )}

      <Form
        layout="vertical"
        style={{ padding: "20px" }}
        colon={false}
        onSubmit={submitForm}
      >
        <Form.Item label="Full name" required>
          <Input
            size="large"
            type="text"
            name="name"
            value={name}
            onChange={inputHandler}
            placeholder="Enter Full Name"
          />
        </Form.Item>
        <Form.Item label="Email" required>
          <Input
            size="large"
            type="email"
            name="email"
            value={email}
            onChange={inputHandler}
            // prefix={<Icon type="mail" className="input-icon" />}
            placeholder="Enter Eamil Address"
          />
        </Form.Item>
        <Form.Item label="Mobile Number" required>
          <Input
            size="large"
            type="tel"
            name="phoneNumber"
            value={phoneNumber}
            onChange={inputHandler}
            // prefix={<Icon type="phone" className="input-icon" />}
            placeholder="Enter Phone Number"
          />
        </Form.Item>
        <Form.Item label="City" required>
          <Input
            size="large"
            type="text"
            name="city"
            value={city}
            onChange={inputHandler}
            // prefix={<Icon type="home" className="input-icon" />}
            placeholder="Enter City Name"
          />
        </Form.Item>
        {editUserId ? (
          <></>
        ) : (
          <Form.Item label="Password" required>
            <Input
              size="large"
              value={password}
              onChange={inputHandler}
              type="password"
              name="password"
              // prefix={<Icon type="lock" className="input-icon" />}
              placeholder="Enter your password"
            />
          </Form.Item>
        )}
        {/* <Form.Item label="Role" required>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button style={{ width: "100%", textAlign: "left" }}>
              {type !== "" ? type : "User Role"}
            </Button>
          </Dropdown>
        </Form.Item> */}
        {/* {type === "SUBADMIN" && ( */}
        <Form.Item label="Permission" wrapperCol={{ span: 24 }} required>
          <Checkbox.Group
            options={UserPermisstion}
            onChange={onChangePermission}
            value={permissions}
            // disabled
          />
        </Form.Item>
        {/* )} */}
      </Form>
    </ModalUtil>
  );
};

export default AddUser;
