import {
  COMMODITY_LOADING,
  COMMODITY_SUCCESSFULL,
  COMMODITY_FAILED,
  COMMODITY_ADD_SUCCESSFULL,
  CREATE_TIME_STRING,
  GET_TIME_STRING,
  TIMER_LOADING,
  COMMODITY_COUNT_LOADING_FALSE,
  COMMODITY_COUNT_GET,
} from "../../actionTypes";

const CommodityRates = (
  state = {
    commodityRate: {},
    products: [],
    dateItems: {},
    isLoading: false,
    errMess: null,
    commodityStatColumns: [],
    commodityCountLoading: false,
    commodityCount: [],
    timerLoading: false,
    provinces: null,
  },
  action,
) => {
  switch (action.type) {
    case COMMODITY_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
      };
    case COMMODITY_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.errMess,
      };
    case COMMODITY_ADD_SUCCESSFULL:
      return {
        ...state,
        // commodityRate: {},
        isLoading: false,
        errMess: action.errMess,
      };

    case COMMODITY_SUCCESSFULL:
      return {
        ...state,
        commodityRate: action.commodities,
        isLoading: false,
        errMess: null,
      };
    case COMMODITY_COUNT_GET:
      return {
        ...state,
        commodityStatColumns: action.payload.columns,
        commodityCount: action.payload.item,
        products: action.payload.products,
        commodityCountLoading: false,
      };
    case CREATE_TIME_STRING:
      return {
        ...state,
        dateItems: action.payload,
        isLoading: false,
        errMess: null,
      };

    case COMMODITY_COUNT_LOADING_FALSE:
      return {
        ...state,
        commodityCountLoading: false,
      };

    case COMMODITY_COUNT_LOADING_FALSE:
      return {
        ...state,
        commodityCountLoading: true,
      };

    case COMMODITY_COUNT_LOADING_FALSE:
      return {
        ...state,
        commodityCountLoading: true,
      };

    case GET_TIME_STRING:
      return {
        ...state,
        dateItems: action.payload,
        isLoading: false,
        errMess: null,
      };
    case TIMER_LOADING:
      return {
        ...state,
        timerLoading: action.payload,
        isLoading: false,
        errMess: null,
      };
    default:
      return state;
  }
};
export default CommodityRates;
