import { videosService } from "../shared";
import axios from "axios";

export const getVideos = async (
  { limit, pageNo, customerId, isActive, categoryName },
  token,
) => {
  const uri = "/api/v1/admin/videos/getVideos";
  const resp = await axios.get(videosService + uri, {
    params: { limit, pageNo, customerId, isActive, categoryName },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getArticles = async (
  { articleType, pageNo, searchQuery },
  token,
) => {
  const uri = "/api/v1/admin/articles/getArticles";
  const resp = await axios.get(videosService + uri, {
    params: { articleType, page: pageNo, searchQuery },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const uploadThumnail = async (formData, token, progressCb) => {
  const uri = "/api/v1/general/uploadThumbnail";
  const resp = await axios.post(videosService + uri, formData, {
    onUploadProgress: (progress) => {
      const { total, loaded } = progress;
      const totalSizeInMB = total / 1000000;
      const loadedSizeInMB = loaded / 1000000;
      const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
      progressCb(uploadPercentage.toFixed(2));
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const saveVideo = async (formData, token, progressCb) => {
  const uri = "/api/v1/admin/videos/saveVideo";
  const resp = await axios.post(videosService + uri, formData, {
    onUploadProgress: (progress) => {
      const { total, loaded } = progress;
      const totalSizeInMB = total / 1000000;
      const loadedSizeInMB = loaded / 1000000;
      const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
      progressCb(uploadPercentage.toFixed(2));
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const createArticle = async (data, token, progressCb) => {
  const uri = "/api/v1/admin/articles/createArticle";
  const resp = await axios.post(videosService + uri, data, {
    onUploadProgress: (progress) => {
      const { total, loaded } = progress;
      const totalSizeInMB = total / 1000000;
      const loadedSizeInMB = loaded / 1000000;
      const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
      progressCb(uploadPercentage.toFixed(2));
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const updateArticle = async (data, token, progressCb) => {
  const uri = "/api/v1/admin/articles/updateArticle";
  const resp = await axios.post(videosService + uri, data, {
    onUploadProgress: (progress) => {
      const { total, loaded } = progress;
      const totalSizeInMB = total / 1000000;
      const loadedSizeInMB = loaded / 1000000;
      const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
      progressCb(uploadPercentage.toFixed(2));
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const editVideo = async (data, token, progressCb) => {
  const uri = `/api/v1/admin/videos/editRelatedInformation/${data._id}`;
  const resp = await axios.patch(videosService + uri, data, {
    onUploadProgress: (progress) => {
      const { total, loaded } = progress;
      const totalSizeInMB = total / 1000000;
      const loadedSizeInMB = loaded / 1000000;
      const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
      progressCb(uploadPercentage.toFixed(2));
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const deleteVideoById = async (
  { videoId, videoUri, thumbnailUri },
  token,
) => {
  const uri = "/api/v1/admin/videos/deleteVideoByVideoId";
  const resp = await axios.delete(videosService + uri, {
    params: {
      videoId,
      videoUri,
      thumbnailUri,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const deleteArticle = async (data, token) => {
  const uri = "/api/v1/admin/articles/deleteArticle";
  const resp = await axios.post(videosService + uri, data, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getVideosCount = async (
  { startDate, endDate, type, city },
  token,
) => {
  const uri = "/api/v1/admin/videos/getVideosCount";
  const resp = await axios.get(videosService + uri, {
    params: { startDate, endDate, type, city },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getArticleCount = async (
  { startDate, endDate, type, city },
  token,
) => {
  const uri = "/api/v1/admin/articles/getArticlesCount";
  const resp = await axios.get(videosService + uri, {
    params: { startDate, endDate, type, city },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const videoThumbnailUrl = (videoId) =>
  `${videosService}/api/v1/customer/getVideoThumbnail?videoId=${videoId}`;
