import { Col, Row, Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { findCustomerWithPhoneNumbers } from "../../RestServices/authService";

export default function PhoneNumberFilter({
  phoneNumberFilter: isModalVisible,
  handleCancel,
  handleOk,
}) {
  const { token } = useSelector((state) => state.Authentication.user);
  const [screenSwitch, setScreenSwitch] = useState(false);
  const [phoneNumberText, setPhoneNumberText] = useState("");
  const [getPhoneListLoading, setGetPhoneListLoading] = useState(false);
  const [listUser, setListUser] = useState([]);

  const onChange = ({ target: { value } }) => {
    setPhoneNumberText(value);
  };

  const fetchUserData = async () => {
    try {
      let payload = phoneNumberText.split("\n");

      payload = payload.filter(function (el) {
        return el != "";
      });

      setGetPhoneListLoading(true);
      const response = await findCustomerWithPhoneNumbers(token, {
        phoneNumbers: payload,
      });
      setListUser(response.customerList);
      setScreenSwitch(true);
    } catch (err) {
      console.log(err);
    } finally {
      setGetPhoneListLoading(false);
    }
  };
  return (
    <Modal
      title="Filter Phone Number"
      visible={isModalVisible}
      onOk={handleOk}
      width="70%"
      height="80vh"
      onCancel={handleCancel}
    >
      <Row justify="center" align="middle">
        {!screenSwitch ? (
          <Col span={12}>
            <p>Enter phone numbers</p>
            <TextArea
              style={{ width: "100%" }}
              value={phoneNumberText}
              onChange={onChange}
              placeholder="Controlled autosize"
              autoSize={{ minRows: 3, maxRows: 15 }}
            />
            <Button
              loading={getPhoneListLoading}
              onClick={fetchUserData}
              style={{ marginTop: "20px" }}
              type={"primary"}
            >
              Get Result
            </Button>
          </Col>
        ) : (
          <Col span={16}>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <p>Users Number</p>
                <TextArea
                  style={{ width: "100%" }}
                  value={listUser?.customerList
                    ?.map((value) => value.phoneNumber)
                    ?.join("\n")}
                  // onChange={onChange}
                  placeholder="User"
                  autoSize={{ minRows: 3, maxRows: 15 }}
                />
              </Col>
              <Col span={12}>
                <p>Non Users Number</p>
                <TextArea
                  style={{ width: "100%" }}
                  value={listUser?.notPresents?.join("\n")}
                  // onChange={onChange}
                  placeholder="Non User"
                  autoSize={{ minRows: 3, maxRows: 15 }}
                />
              </Col>
            </Row>
            <Button
              loading={getPhoneListLoading}
              onClick={() => setScreenSwitch(!screenSwitch)}
              style={{ marginTop: "20px" }}
              type={"primary"}
            >
              Find Result
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
}
