import { Badge, Layout, Menu, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import { getAllCities } from "../../Redux/ActionCreaters/citiesRequets";
import {
  RemoveColonFromPermission,
  TopPermission,
  UserPermisstion,
} from "../../shared";
import { MAIN_BACKGROUND_COLOR, fontColor } from "../../styleConstants";
import Ads from "../Ads/Body";
import AppUser from "../AppUsers/index.js";
import CommodityRates from "../CommodityRates/CommodityRatesBody/Body";
import CrmComponent from "../Crm/CrmComponent";
import FarmersWeek from "../Farmers_of_the_Week/Body";
import FeedBack from "../FeedBack/FeedBack";

import AssignTargetUsers from "../AssignTargetCrm/assignTarget";
import FormComponent from "../Form";
import MandiGurus from "../MandiGurus/Body";
import MandiRates from "../MandiRates/Body";
import News from "../News/Body";
import NewsArticle from "../NewsAndArtical/Body";
import PhoneDirectory from "../PhoneDirectory/Body";
import PostShower from "../Posts/PostShower";
import Tags from "../Tags";
import Tips from "../Tips/Body";
import Users from "../Users";
import DoctorCorner from "../Videos/Body";
import CommisionRates from "../commissionRates/Body";
import SchedulerNotification from "../scheduleNotification/Body";
import Body from "./DashboardBody/Body";
import HeaderContent from "./HeaderContent";
import "./styles.css";

const styles = {
  layout: {
    minHeight: "100vh",
    overflow: "hidden",
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: "bold",
  },
  content: {
    color: fontColor,
    margin: "10px",
  },
  logo: {
    height: "200px",
    width: "190px",
  },
  menuToggler: {
    color: fontColor,
    fontSize: "30px",
    marginLeft: "5px",
    marginTop: "15px",
  },
};
class Dashboard extends React.Component {
  state = {
    collapsed: false,
    input: null,
    selectedKey: "Dashboard",
    slectedSubkey: "",
    noficicationCount: 0,
    data: [],
  };

  toggleSider = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  inputEvent = (e) => {
    this.setState({ input: e.target.value });
    console.log(e.target.value);
  };
  handleSideNavToggle = () => {
    if (window.innerWidth < 992) {
      this.setState({ collapsed: true });
    } else if (window.innerWidth > 992) {
      this.setState({ collapsed: false });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize");
  }
  // Authentication.user.profileDetails.permissions
  componentDidMount() {
    this.handleSideNavToggle();
    window.addEventListener("resize", this.handleSideNavToggle);
    if (
      this.props.Auth.user.profileDetails &&
      this.props.Auth.user.profileDetails.permissions &&
      this.props.Auth.user.profileDetails.permissions[0] !==
        UserPermisstion[0].value
    ) {
      let permissions = this.props.Auth.user.profileDetails.permissions;
      let firstPermission = permissions[0];
      if (
        permissions.includes(TopPermission[0].value) ||
        permissions.includes(TopPermission[1].value)
      ) {
        firstPermission = TopPermission[1].value;
      }

      this.setState({
        selectedKey: firstPermission.split(":")[0],
        allPermission: this.props.Auth.user.profileDetails.permissions,
      });
    } else if (
      this.props.Auth.user.profileDetails &&
      this.props.Auth.user.profileDetails.permissions &&
      this.props.Auth.user.profileDetails.permissions[0] ===
        UserPermisstion[0].value
    ) {
      this.setState({
        allPermission: this.props.Auth.user.profileDetails.permissions,
      });
    }
    if (!this.props.cites) {
      this.props.getCities(this.props.Auth.user.token);
    }
    this.profRouteRenderer();
  }
  profRouteRenderer = () => {
    const { app } = this.state;
    const { selectedKey, slectedSubkey } = this.state;

    if (selectedKey?.toLowerCase() === "Dashboard"?.toLowerCase())
      return (
        <Body
          setSelected={(selectedKey, slectedSubkey, data) => {
            this.setState({ selectedKey, slectedSubkey, data });
          }}
        />
      );
    if (selectedKey?.toLowerCase() === "Dashboard/User"?.toLowerCase())
      return (
        <AppUser
          setSelected={(selectedKey) => this.setState({ selectedKey })}
          slectedSubkey={slectedSubkey}
          selectedKey={selectedKey}
          data={this.state.data}
        />
      );

    if (selectedKey === "Users") return <Users />;
    if (selectedKey === "CommodityRates") return <CommodityRates />;
    if (selectedKey === "MandiRates") return <MandiRates />;
    if (selectedKey === "MandiGurus") return <MandiGurus />;
    if (selectedKey === "Videos") return <DoctorCorner />;
    if (selectedKey === "News_Articles") return <NewsArticle />;
    if (selectedKey === "Crm_System")
      return (
        <CrmComponent
          setSelected={(selectedKey, slectedSubkey, data) => {
            this.setState({ selectedKey, slectedSubkey, data });
          }}
        />
      );
    if (selectedKey === "AssignTarget") return <AssignTargetUsers />;
    if (selectedKey === "Posts") return <PostShower />;
    if (selectedKey === "Schedule-Notification")
      return <SchedulerNotification />;

    if (selectedKey === "Tips") return <Tips />;
    if (selectedKey === "News") return <News />;
    if (selectedKey === "FarmersWeek") return <FarmersWeek />;
    if (selectedKey === "Ads") return <Ads />;
    if (selectedKey === "CommissionRates") return <CommisionRates />;

    if (selectedKey === "PhoneDirectory") return <PhoneDirectory />;
    // if (selectedKey === "Reports") return <Reports />;
    if (selectedKey === "FeedBack") return <FeedBack />;
    if (selectedKey === "Notification") return <FormComponent />;
    if (selectedKey === "Tags") return <Tags />;
  };
  changeProfRoute = (key) => {
    console.log(key);
    this.setState({ selectedKey: key });
  };

  render() {
    const { Sider, Header, Content } = Layout;
    const { permissions } = this.props.Auth.user.profileDetails;
    if (!permissions) {
      localStorage.clear();
      window.location.href = "/signin";
      message.error("You have not any permission");
    }
    const PermissionsRomovingColor = RemoveColonFromPermission(permissions);

    return (
      <Layout style={{ overflow: "hidden" }}>
        <Sider
          trigger={null}
          style={{
            ...styles.sider,

            display: this.state.collapsed ? "none" : "block",
          }}
        >
          <div style={styles.logo}>
            <img src="/image/logo.png" style={styles.logo} alt="Poultry Baba" />
          </div>
          <Menu
            style={styles.menu}
            mode="inline"
            onSelect={({ key }) => this.changeProfRoute(key)}
            selectedKeys={[this.state.selectedKey.split("/")[0]]}
            // defaultSelectedKeys={[this.state.selectedKey]}
          >
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Dashboard")
                  ? false
                  : true
              }
              key="Dashboard"
            >
              <span> Dashboard</span>
            </Menu.Item>

            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("AssignTarget")
                  ? false
                  : true
              }
              key="AssignTarget"
            >
              <span>Assign Target CRM</span>
            </Menu.Item>

            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("CommissionRates")
                  ? false
                  : true
              }
              key="CommissionRates"
            >
              <span>Commission Rates</span>
            </Menu.Item>

            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes("Crm_System") ? false : true
              }
              key="Crm_System"
            >
              <span> CRM System</span>
            </Menu.Item>

            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Users")
                  ? false
                  : true
              }
              key="Users"
            >
              <span>Users</span>
            </Menu.Item>
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("CommodityRates")
                  ? false
                  : true
              }
              key="CommodityRates"
            >
              <span>Commodity Rates</span>
            </Menu.Item>
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("MandiRates")
                  ? false
                  : true
              }
              key="MandiRates"
            >
              <span>Mandi Rates</span>
            </Menu.Item>
            {/* <Menu.Item key="MandiGurus">
              <span>Mandi Gurus</span>
            </Menu.Item> */}
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Videos")
                  ? false
                  : true
              }
              key="Videos"
            >
              <span> Videos</span>
            </Menu.Item>

            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Posts")
                  ? false
                  : true
              }
              key="Posts"
            >
              <span> Posts</span>
            </Menu.Item>

            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("News_Articles")
                  ? false
                  : true
              }
              key="News_Articles"
            >
              <span> News {"&"} Articles</span>
            </Menu.Item>
            {/* <Menu.Item key="Tips">
              <span>Tips</span>
            </Menu.Item>
            <Menu.Item key="News">
              <span>News</span>
            </Menu.Item> */}
            {/* <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Reports")
                  ? false
                  : true
              }
              key="Reports"
            >
              <span>Reports</span>
            </Menu.Item> */}
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Ads")
                  ? false
                  : true
              }
              key="Ads"
            >
              <span>Ads</span>
            </Menu.Item>
            {/* <Menu.Item key="PhoneDirectory">
              <span>Phone Directory</span>
            </Menu.Item> */}
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("FeedBack")
                  ? false
                  : true
              }
              key="FeedBack"
            >
              <span>Feedback {"&"} suggestion</span>
              <Badge count={this.state.noficicationCount}> </Badge>
            </Menu.Item>
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Notification")
                  ? false
                  : true
              }
              key="Notification"
            >
              <span>Notification</span>
              {/* <Badge count={this.state.noficicationCount}> </Badge> */}
            </Menu.Item>

            {/* <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("News_Articles")
                  ? false
                  : true
              }
              key="Tags"
            >
              <span>Tags</span>
            </Menu.Item> */}
            <Menu.Item
              disabled={
                PermissionsRomovingColor.includes(
                  UserPermisstion[0].value.split(":")[0],
                ) || PermissionsRomovingColor.includes("Notification")
                  ? false
                  : true
              }
              key="Schedule-Notification"
            >
              <span>Schedule Notification</span>
              {/* <Badge count={this.state.noficicationCount}> </Badge> */}
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={styles.header}>
            <HeaderContent
              toggleSider={this.toggleSider}
              collapsed={this.state.collapsed}
              name={this.state.input}
              onChange={this.inputEvent}
              onNotification={(noficicationCount) =>
                this.setState({ noficicationCount })
              }
            />
          </Header>
          {/* <Content
            style={{
              background: "#fff",
              margin: 0,
              minHeight: 280,
            }}
          > */}
          <div style={styles.content}>{this.profRouteRenderer()}</div>
          {/* </Content> */}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
  cities: store.Cities.cities,
  isMobileView: store.Setting.isMobileView,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCities: (token) => {
      dispatch(getAllCities(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
