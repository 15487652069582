import { Col, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { useSelector } from 'react-redux'
import { getPosts } from '../../../RestServices/postingService'
import moment from 'moment'
import { formateString } from '../../../shared'

const SearchUserModal = ({ handleOk, isModalOpen, handleCancel }) => {
  const [searchingId, setSearchingPostId] = useState('')
  const { token } = useSelector((state) => state.Authentication.user)

  const [post, setPost] = useState(undefined)

  useEffect(() => {
    (async () => {
      if (!searchingId) return
      try {
        const { posts } = await getPosts(
          {
            postIdAutoNumber: searchingId,
          },
          token,
        )
        if (posts?.docs.length) {
          setPost(posts?.docs[0])
        }
      } catch (err) {
        console.log(err)
      }
    })()
  }, [searchingId])

  return (
    <>
      <Modal
        title={'Search Post'}
        visible={isModalOpen}
        width="80%"
        hight="80%"
        onOk={() => handleOk()}
        onCancel={() => handleCancel(false)}
      >
        <Row>
          <Col span={24}>
            <Input
              prefix={<SearchIcon />}
              style={{ width: '200px'}}
              type="number"
              value={searchingId}
              onChange={(e) => setSearchingPostId(e.target.value)}
              placeholder="Search Post ID"
            />
          </Col>
          <Col span={24}>
            {post ? (
              <Row justify="center" align="middle" style={{ width: '100%' }}>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    marginBottom: '20px',
                    background: 'yellow',
                  }}
                  span={24}
                >
                  <div
                    style={{
                      width: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                      height: '100px',
                      flexDirection: 'column',
                      cursor: 'pointer',
                    }}
                    className=" primaryColor"
                  >
                    <h4 className="primaryColor">
                      {post?.postDetails?.subType}({post?.postDetails?.status})
                    </h4>
                  </div>

                  {post ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '10px',
                      }}
                      className="primaryColor"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <p
                          style={{
                            width: '150px',
                          }}
                        >
                          Id
                        </p>
                        <p style={{ textDecorationStyle: 'capitalize' }}>
                          {post?.autoId}
                        </p>
                      </div>
                      {Object.keys(post?.postDetails).map((key) => {
                        if (
                          key !== '_id' &&
                          key !== 'subType' &&
                          key !== 'type' &&
                          key !== 'status'
                        )
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                textDecoration: 'capitalize',
                              }}
                              key={key}
                            >
                              <p
                                style={{
                                  textTransform: 'capitalize',
                                  width: '150px',
                                }}
                              >
                                {key
                                  .replace('subType', 'category')
                                  .replace('status', 'Post Type')}
                              </p>
                              <p
                                style={{
                                  textTransform: 'capitalize',
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {post.postDetails[key]}
                              </p>
                            </div>
                          )
                      })}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          textDecoration: 'capitalize',
                        }}
                      >
                        <p
                          style={{
                            textTransform: 'capitalize',
                            width: '150px',
                          }}
                        >
                          created At
                        </p>
                        <p
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {moment(post.createdAt).format(formateString)}
                        </p>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          textDecoration: 'capitalize',
                        }}
                      >
                        <p
                          style={{
                            textTransform: 'capitalize',
                            width: '150px',
                          }}
                        >
                          City
                        </p>
                        <p
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {post.city}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default SearchUserModal
