import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
// import DatePicker from "react-date-picker";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { getCommoditystatCounts } from "../../../Redux/ActionCreaters/CommodityRequets";
import { MAIN_BACKGROUND_COLOR } from "../../../styleConstants";
import "./calendar.css";
import CategoryChart from "./categoryChart";
import ChartComponent from "./chart";
import MurghiMundiState from "./MurghiMundiStat";
import "./table.css";

const TableComponent = ({ date }) => {
  const dispatch = useDispatch();

  const [dateSelection, setdateSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [datesBetween, setDateBetween] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [Category, setCategory] = useState();
  const { Option } = Select;

  const [open, setOpen] = useState(true);
  const { token } = useSelector((state) => state.Authentication.user);

  const { commodityStatColumns, commodityCount, products } = useSelector(
    (state) => state.CommodityRates,
  );

  const [visible, setVisible] = useState(false);
  const [visibleCategoryModel, setVisibleCategoryModel] = useState(false);
  const [modelData, setModelData] = useState({});
  const handleModel = (status, record) => {
    setVisible(status);
    setModelData(record);
  };
  const startUpColums = [
    {
      title: "Total Click",
      width: 10,
      dataIndex: "rowCount",
      fixed: "left",
      align: "center",
      sorter: (a, b) => a.rowCount - b.rowCount,
      ellipsis: true,
    },
    {
      title: "Image",
      width: 20,
      dataIndex: "imageUrl",
      fixed: "left",
      align: "center",
      render: (text, record, index) => (
        <Image style={{ width: "80px" }} preview={false} src={text} />
      ),
    },
    {
      title: "Category Name",
      width: 60,
      dataIndex: "categoryName",
      fixed: "left",
      align: "center",
    },
  ];
  const [columns, setcolumns] = useState(startUpColums);

  useEffect(() => {
    const startDate = moment(datesBetween.startDate).toDate();
    const enddate = moment(datesBetween.endDate).toDate();

    dispatch(getCommoditystatCounts(startDate, enddate, token));
  }, [datesBetween.startDate]);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (dataSource && !selectedRowKeys.length) {
      const totalview = dataSource
        .map((value) => value.rowCount)
        .reduce((a, b) => a + b, 0);
      SetTotalView(totalview);
    } else if (selectedRowKeys.length) {
      const totalview = dataSource
        .map((value, index) => {
          if (selectedRowKeys.indexOf(index) !== -1) {
            return value.rowCount;
          } else {
            return 0;
          }
        })
        .reduce((a, b) => a + b, 0);
      SetTotalView(totalview);
    } else if (!dataSource.length) {
      SetTotalView(" ");
    }
  }, [selectedRowKeys, dataSource]);

  const [totalview, SetTotalView] = useState(" ");

  const [selectCity, setselectCity] = useState("");

  function onChangeCity(value) {
    console.log(`selected ${value}`);
    setselectCity(value);
    setVisible(true);
  }
  useEffect(() => {
    if (commodityStatColumns) {
      setDataSource(commodityCount);
      const colums =
        commodityStatColumns &&
        commodityStatColumns.map((value, index) => ({
          title: value,
          dataIndex: value,
          width: 50,
          key: index,
          align: "center",
          render: (text, record, index) => (
            <div style={{ color: text === 0 ? "red" : "black" }}>
              {text === 0 ? " " : text}
            </div>
          ),
        }));
      setcolumns([...startUpColums, ...colums]);
    }
  }, [commodityStatColumns]);

  console.log("modelData", modelData);

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const children = [];
  // for (let i = 10; i < dataSource; i++) {
  //   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  // }
  products &&
    products.map((el) => {
      return children.push(<Option key={el}>{el}</Option>);
    });
  function handleChange(value) {
    console.log(`selected ${value}`);

    const datasour = commodityCount.filter(
      (item) => value.indexOf(item.categoryName) > -1,
    );
    if (datasour.length) {
      setDataSource(datasour);
    } else {
      setDataSource(commodityCount);
    }
  }

  function onChange(value) {
    console.log(`selected ${value}`);
    setVisibleCategoryModel(true);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleSelect = (ranges) => {
    if (ranges.hasOwnProperty("range1")) {
      setdateSelection(ranges.range1);
    } else {
      setdateSelection(ranges.selection);
    }
  };
  const PickDate = () => {
    setdropdrownshow(false);
    setDateBetween({
      startDate: dateSelection.startDate,
      endDate: dateSelection.endDate,
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <DateRangePicker
          rangeColors={[MAIN_BACKGROUND_COLOR]}
          ranges={[dateSelection]}
          onChange={handleSelect}
        />
      </Menu.Item>
      <Menu.Item>
        <Row>
          <Col push={14} span={5}>
            <Button
              onClick={() => PickDate()}
              style={{ width: "100%" }}
              type={"primary"}
            >
              Select
            </Button>
          </Col>
          <Col span={5}>
            <Button
              onClick={() => {
                setdropdrownshow(false);
                setdateSelection({ ...dateSelection, ...datesBetween });
              }}
              style={{ width: "100%" }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );
  const { Title } = Typography;
  const [dropdrownshow, setdropdrownshow] = useState(false);

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <div className="flex-between">
              <div style={{ width: "100%" }}>
                <Row gutter={[12, 12]} style={{ marginRight: "10px" }}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div>
                      <Title level={4} className="mb-0 text-primary" style={{ height: "30px", lineHeight: "30px" }}> Commodities Rates (Total Views: {totalview})</Title>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <div>
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Select Category"
                        onChange={handleChange}
                        tokenSeparators={[","]}
                      >
                        {children}
                      </Select>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <div>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select City"
                        optionFilterProp="children"
                        onChange={onChangeCity}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                      >
                        {commodityStatColumns &&
                          commodityStatColumns.map((el) => {
                            return <Option value={el}>{el}</Option>;
                          })}
                      </Select>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div>
                      <Dropdown
                        onVisibleChange={setdropdrownshow}
                        visible={dropdrownshow}
                        overlay={menu}
                      >
                        <Row justify="space-around">
                          <Col span={11}>
                            <Input
                              type="primary"
                              value={new Date(dateSelection.startDate).toDateString()}
                              placeholder="start Date"
                            />
                          </Col>
                          <Col span={11}>
                            <Input
                              type="primary"
                              value={new Date(dateSelection.endDate).toDateString()}
                              placeholder="End Date"
                            />
                          </Col>
                        </Row>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Button className="buttonStyle" size="small">
                  {open ? (
                    <DownOutlined onClick={() => setOpen(!open)} />
                  ) : (
                    <UpOutlined onClick={() => setOpen(!open)} />
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      {/* </Col> */}
      {open ? (
        <Card >
          <div className="custom-ant-table">
            <Table
              columns={columns}
              pagination={false}
              dataSource={dataSource}
              scroll={{ x: true }}
              rowSelection={rowSelection}
              onHeaderRow={(columns, index) => {
                return {
                  onClick: () => {
                    console.log(index, columns);
                  }, // click header row
                };
              }}
              onChange={(column) => {
                return {
                  onClick: () => console.log("columns", column), // click header row
                };
              }}
              style={{ overflow: "auto" }}
            // onHeaderRow={(columns, index) => console.log("columns", columns, "index", index)}
            />
          </div>
          <MurghiMundiState />
        </Card>
      ) : null}
      <Modal
        title={
          <Row justify="start">
            <Col span={12}>{selectCity} Commodity Rates</Col>

            <Col span={5}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {products &&
                  products.map((el) => {
                    return <Option value={el}>{el}</Option>;
                  })}
              </Select>
            </Col>
          </Row>
        }
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={950}
      >
        <ChartComponent
          lebel={products}
          value={commodityCount.map((value) => value[selectCity])}
        />
      </Modal>
      <Modal
        title={
          <Row justify="start">
            <Col span={17}>Top 10 Cities Commodity</Col>
            <Col span={5}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {commodityStatColumns &&
                  commodityStatColumns.map((el) => {
                    return <Option value={el}>{el}</Option>;
                  })}
              </Select>
            </Col>
          </Row>
        }
        centered
        visible={visibleCategoryModel}
        onOk={() => setVisibleCategoryModel(false)}
        onCancel={() => setVisibleCategoryModel(false)}
        width={920}
      >
        <CategoryChart />
      </Modal>
    </>
  );
};

export default TableComponent;
