import React from "react";
import { Table, Row, Col, Switch, Card, Button, message, Popconfirm } from "antd";
import "./body.css";
import moment from "moment";
import { editPoster, deletePoster } from "../../RestServices/postingService";
import { useSelector } from "react-redux";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";

export default function TableComponent({
  poster,
  setEditModal,
  edit,
  setEdit,
}) {
  const { token } = useSelector((state) => state.Authentication.user);

  const text = 'Are you sure to delete this task?';
  const description = 'Delete the task';

  const confirm = async (row) => {
    const posterId = row?._id;
    try {
      const data = await deletePoster(posterId, token);
      console.log(data);
      message.success('Ad deleted successfully!.');
    } catch (error) {
      message.error(error);
    }
  };

  const onChange = async (checked, data) => {
    const {
      category,
      _id: posterId,
      displayType,
      bannerLinks,
      title,
      colorCode,
      description,
    } = data;
    try {
      await editPoster(
        {
          category,
          posterId,
          displayType,
          isActive: checked,
          bannerLinks,
          colorCode,
          description,
          title,
        },
        token
      );
      setEdit(!edit);
    } catch (err) {
      console.log(err);
    }
  };
  const columns = [
    {
      title: (
        <span
          style={{
            textAlign: "left",
            alignSelf: "start",
          }}
        >
          <b>Sr #</b>
        </span>
      ),
      dataIndex: "sr",
      key: "sr",
      align: "center",
    },
    {
      title: (
        <span>
          <b>
            Ads ID <br /> <span style={{ color: "black" }}> Date/Time</span>{" "}
          </b>
        </span>
      ),
      dataIndex: "_id",
      key: "_id",
      align: "left",
      render: (id, row, index) => {
        return (
          <Row align="middle" justify="start">
            <Col span={24}>
              <Row align="top" justify="start">
                <b> {row?._id}</b>
              </Row>
              <Row align="middle" justify="start">
                <Col span={24}>
                  <b>{moment(row?.createdAt).format("MMMM Do YYYY")}</b>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
    },
    {
      title: (
        <span>
          <b>Image</b>
        </span>
      ),
      dataIndex: "bannerLinks",
      key: "bannerLinks",
      align: "left",
      render: (image) => {
        return (
          <img height={100} width={120} src={image[0]} alt="bannerLinks" />
        );
      },
    },
    {
      title: (
        <span>
          <b>Title</b>
        </span>
      ),
      dataIndex: "title",
      key: "title",
      align: "left",
    },

    {
      title: (
        <span>
          <b>Category</b>
        </span>
      ),
      dataIndex: "category",
      key: "category",
      align: "left",
    },
    {
      title: (
        <span>
          <b>Display for</b>
        </span>
      ),
      dataIndex: "displayType",
      key: "displayType",
      align: "left",
    },

    {
      title: (
        <span>
          <b>Status</b>
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (id, row, index) => {
        return (
          <Row align="middle" justify="center">
            <Col span={24}>
              <Row align="middle" justify="space-around">
                <Col span={8} onClick={() => setEditModal(true, row)}>
                  <EditOutlined />
                </Col>
                <Col span={8} style={{ cursor: "pointer" }}>
                  <Popconfirm
                    placement="topLeft"
                    title={text}
                    description={description}
                    onConfirm={() => confirm(row)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutline />
                  </Popconfirm>
                </Col>
                <Col span={8}>
                  <Switch
                    defaultChecked={id}
                    onChange={(e) => onChange(e, row)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Table columns={columns} dataSource={poster} style={{ overflow: "auto" }} />
        </Card>
      </Col>
    </Row>
  );
}
