export const styles = {
  timerStyle: {
    height: "100px",
    display: "flex",

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    height: "40px",
    borderBottom: "1px solid gray",
  },
  headerStyle: {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  typeStyle: { paddingLeft: "5px" },
};
