import { Card, Col, Divider, Image, Row, Skeleton, Typography } from 'antd';
import React from 'react';
import './lower-card.css';
// import DummyPic from "./Images/dummyPic.jpg";

const LowerCard = ({ data, ...props }) => {
  const dataOne = data
    ? data?.find((value) => value.title === props.Heading)
    : null;

  const { Title } = Typography;
  return (
    <div>
      <div className="flex-center">
        <Image src={props.Img} style={{ width: "100%", height: "100px" }} alt='pic' />
      </div>
      <Title level={3} className="mb-0 text-primary" style={{ textAlign: "center", marginTop: "5px" }}>
        {props.Heading.split('_')
          .join(' ')
          .replace('Poultry Market', 'Poultry Plaza')}
      </Title>
      <Divider />
      <div>
        {!props?.loading ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={[12, 12]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalShare}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{"N/A"}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalPost}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne ? dataOne.count : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalView}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne?.views ? dataOne.views : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalVisit}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne && dataOne.visits ? dataOne.visits : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalLikes}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne && dataOne?.totalLikes
                  ? dataOne?.totalLikes
                  : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>TotalDislike</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne && dataOne?.totalDislikes
                  ? dataOne?.totalDislikes
                  : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.AvgViewTime}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{'N/A'}</Title>
              </Col>
            </Row>
          </Col>
        ) : <Skeleton line={10} active />}
      </div>
    </div>
  );
};

export default LowerCard;
