import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Spin, Modal, Table, Card, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createNotification,
  getAdminAlerts,
} from "../../RestServices/authService";
import CustomPagination from "../Pagination";

const FormComponent = () => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [version, setVersion] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [appLink, setAppLink] = useState("");
  const { token } = useSelector((state) => state.Authentication.user);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAdminAlertFunc({ page: 1 });
  }, [loading]);

  const [alerts, setAlerts] = useState(null);

  const getAdminAlertFunc = async ({ page }) => {
    try {
      const response = await getAdminAlerts({ page }, token);
      console.log(response);
      setAlerts(response.alerts);
    } catch (err) {
      console.log(err);
    }
  };

  const onPageChange = (page) => {
    getAdminAlertFunc({ page });
  };
  const onChangeVersion = (value) => {
    console.log(`selected ${value}`);
    setVersion(value);
  };

  const handleSubmit = () => {
    let data = {
      version,
      message,
      title,
      appLink,
    };
    createNotification(data, token, setLoading);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
  );

  const tableColumns = [
    {
      key: "senderName",
      title: "sended By",
      dataIndex: "senderName",
    },

    {
      key: "message",
      title: "Message Type",
      dataIndex: "message",
    },

    {
      key: "title",
      title: "Title ",
      dataIndex: "title",
    },

    {
      key: "notificationType",
      title: "Notification Type",
      dataIndex: "notificationType",
    },
    {
      key: "applink",
      title: "App Link",
      dataIndex: "applink",
    },
    {
      title: "Assign Task",
      key: "_id",
      dataIndex: "_id",
      render: (text, record) => {
        return (
          <Button type={"primary"} onClick={() => onResendPrepare(record)}>
            Resend
          </Button>
        );
      },
    },
  ];

  const onResendPrepare = (record) => {
    setMessage(record.message);
    setTitle(record.title);
    setAppLink(record.applink);
    setVersion(record.notificationType);
    setIsModalVisible(false);
  };
  const { Title } = Typography;
  return (
    <div>
      <Modal
        width="100%"
        title="Sended Notification"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            current={alerts?.page}
            total={alerts?.totalPages}
            onPageChange={onPageChange}
          />
        </div>
        <Table
          dataSource={alerts?.docs}
          pagination={false}
          columns={tableColumns}
          style={{ overflow: "auto" }}
        />
      </Modal>
      <Row style={{marginTop: "20px",marginBottom: "20px"}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div style={{ padding: "5px" }}>
              <div className="flex-between">
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Notifications</Title>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Button type="primary" onClick={showModal}>
                    List Notifications
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col 
        xs={{
          span: 24,
          offset: 0,
        }}
        sm={{
          span: 24,
          offset: 0,
        }}
        md={{
          span: 10,
          offset: 6,
        }}
        lg={{
          span: 10,
          offset: 6,
        }}
        xl={{
          span: 10,
          offset: 6,
        }}>
          <div>
            <Card>
              <div style={{ padding: "10px" }}>
                <div className="flex-col" style={{ marginBottom: "10px" }}>
                  <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Type</Title>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="New Version Released"
                    optionFilterProp="children"
                    onChange={onChangeVersion}
                    value={version}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    <Option value="NEW_VERSION_RELEASED">
                      NEW_VERSION_RELEASED
                    </Option>
                    <Option value="GENERAL_ANNOUNCEMENT">
                      GENERAL_ANNOUNCEMENT
                    </Option>
                  </Select>
                </div>
                <div className="flex-col" style={{ marginBottom: "10px" }}>
                  <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Message</Title>
                  <TextArea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="flex-col" style={{ marginBottom: "10px" }}>
                  <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Title</Title>
                  <Input
                    value={title}
                    style={{ width: "100%" }}
                    placeholder="Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                {version === "NEW_VERSION_RELEASED" && (
                  <div className="flex-col" style={{ marginBottom: "10px" }}>
                    <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>App Link</Title>
                    <Input
                      value={appLink}
                      style={{ width: "100%" }}
                      placeholder="App Link"
                      onChange={(e) => setAppLink(e.target.value)}
                    />
                  </div>
                )}

                <Button type="primary" style={{ width: "100%", marginTop: "10px" }} onClick={() => handleSubmit()}>
                  {loading ? <Spin indicator={antIcon} /> : "Submit"}
                </Button>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FormComponent;
