import React from "react";
import { Bar } from "react-chartjs-2";

const ChartComponent = ({ lebel, value }) => {
  // const data = [{
  //   type: 'white Egg',
  //   sales: 45,
  // },
  // {
  //   type: 'Brwon Egg',
  //   sales: 52,
  // },
  // {
  //   type: 'Layer Chick',
  //   sales: 70,
  // },
  // {
  //   type: 'c',
  //   sales: 69,
  // },
  // {
  //   type: 'Broiler',
  //   sales: 53,
  // },
  // {
  //   type: 'layer',
  //   sales: 38,
  // },
  // {
  //   type: 'brown Layer',
  //   sales: 38,
  // },
  // {
  //   type: 'corn',
  //   sales: 38,
  // },
  // ];

  const data = {
    labels: lebel.map((item) => item.split("_").join(" ").toLocaleLowerCase()),
    datasets: [
      {
        label: "Commodity",
        data: value,
        backgroundColor: [
          "rgba(255, 206, 86, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],

        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        width: 10,
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    borderWidth: "10px",
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <>
      <Bar
        data={data}
        options={options}
        width={20}
        // height={10}
        options={{ maintainAspectRatio: false }}
      />
    </>
  );
};

export default ChartComponent;
