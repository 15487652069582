import { getBiddings } from '../../RestServices/postingService';

import { biddingSuccess, ordersFailed, ordersLoading } from '../Actions/order';

export const getAllBiddings =
  (selectedCommodity, page, token, crmUserId, obj) => async (dispatch) => {
    dispatch(ordersLoading());
    try {
      let { biddings } = await getBiddings(
        { subType: selectedCommodity, page, limit: 10, crmUserId, ...obj },
        token,
      );

      dispatch(biddingSuccess(biddings));
    } catch (err) {
      dispatch(ordersFailed(err.message));
      throw err;
    }
  };
