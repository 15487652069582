import { Button, Col, Progress, Row, Card } from "antd";
import React from "react";

export default function ProductList({ productDetail }) {
  return (
    <Row justify="center">
      <Col span={22}>
        <Card
          title={
            <h1
              className="headingColor"
              style={{
                fontSize: "17px",
                textAlign: "left",
                fontWeight: "bolder",
              }}
            >
              {productDetail?.productName}
            </h1>
          }
          style={{
            marginBottom: "30px",
            width: "100%",
            textAlign: "center",
            fontWeight: "bolder",
            marginTop: "30px",
            borderTop: "12px solid #de923b",
          }}
        >
          <Row justify="start" align="top">
            {/* <Col span={2}></Col> */}
            <Col span={10}>
              {productDetail?.selectedThemnail ? (
                <div style={{ position: "relative" }}>
                  {productDetail?.isFormLoading && (
                    <Progress
                      width={40}
                      style={{
                        position: "absolute",

                        right: "10px",
                        top: "10px",
                      }}
                      type="circle"
                      percent={productDetail?.uploadedPercentage}
                    />
                  )}
                  <img
                    style={{ width: "250px", padding: "3px" }}
                    src={URL.createObjectURL(productDetail?.selectedThemnail)}
                    alt="Thumbnail"
                  />
                </div>
              ) : productDetail?.articleImage ? (
                <div style={{ position: "relative" }}>
                  {productDetail?.isFormLoading && (
                    <Progress
                      width={40}
                      style={{
                        position: "absolute",

                        right: "10px",
                        top: "10px",
                      }}
                      type="circle"
                      percent={productDetail?.uploadedPercentage}
                    />
                  )}
                  <img
                    style={{ width: "250px", padding: "3px" }}
                    src={productDetail?.articleImage}
                    alt="Thumbnail"
                  />
                </div>
              ) : (
                <></>
              )}
              {/* <Image width={200} src={img} alt="" /> */}
            </Col>
            <Col span={14} style={{ textAlign: "start", paddingTop: "1px" }}>
              <h1 style={{ fontWeight: "bolder", fontSize: "24px" }}>
                {productDetail?.subHeading}
              </h1>
              <p style={{ fontSize: "16px", fontWeight: "400" }}>
                {productDetail?.description}
              </p>
              <Button
                className="headingColor"
                // onClick={() => window.onclick(buttonLink, "_blank")}
                style={{
                  backgroundColor: "#de923b",
                  width: "80%",
                  fontSize: "20px",
                  height: "50px",
                  marginBottom: "25px",
                  borderRadius: "0px",
                }}
              >
                {productDetail?.buttonText ? productDetail?.buttonText : " "}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
