import { Button, Card, Col, DatePicker, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import Calendar from '../../../../utils/CalenderDropDown/Calender';
import CityDropDown from '../Cards/LowerCards/CityDropDown';
import './top-feature.css';
const TopFeature = ({
  text,
  TopButtonText,
  showcalender,
  handleSelect,
  top5Commodity,
  PickDate,
  dateSelection,
  showCity,
  datesBetween,
  data,
  onChangeCity,
  city,
  date,
  showDatePicker,
  onToggleCommodity,
  dropdrownshow,
  setdropdrownshow,
  onChangeDate,
  setdateSelection,
}) => {
  const { Title } = Typography;
  return (
    <div style={{ width: "100%" }}>
      <Row gutter={[12, 12]} style={{ marginRight: "10px" }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div>
            <Title level={4} className="mb-0 text-primary">
              {text}

              {(() => {
                if (text !== 'Top Features') {
                  return;
                }
                let totalVisits = 0;
                let totalViews = 0;

                if (data) {
                  for (let product of data) {
                    totalVisits += product?.visits || 0;
                    totalViews += product?.views || 0;
                  }
                }

                return (
                  <Title level={4} className="mb-0 text-primary">
                    (Total Visit = {totalVisits} Total Views = {totalViews})
                  </Title>
                );
              })()}
            </Title>
          </div>
        </Col>
        {top5Commodity && (
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div>
              {' '}
              <Button onClick={onToggleCommodity && onToggleCommodity}>
                {TopButtonText}
              </Button>
            </div>
          </Col>
        )}
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div>
            {showCity && <CityDropDown city={city} onChangeCity={onChangeCity} />}
          </div>
        </Col>
        {showcalender && (
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className='calendar'>
              <Calendar
                handleSelect={handleSelect}
                PickDate={PickDate}
                dateSelection={dateSelection}
                datesBetween={datesBetween}
                dropdrownshow={dropdrownshow}
                setdropdrownshow={setdropdrownshow}
                setdateSelection={setdateSelection}
              />
            </div>
          </Col>
        )}
        {showDatePicker && (
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {' '}
            <DatePicker value={moment(date)} onChange={onChangeDate} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TopFeature;
