import { Typography, Col } from "antd";
import React from "react";

export default function RowStatus({ field, value }) {
  return (
    <Col style={{ margin: "5px" }} className="gutter-row" span={22}>
      <div
        style={{
          background: "lightgray",
          padding: "3px",
          borderRadius: "15px",
          display: "flex",
          justifyContent: "flex-start",
          // alignItems: "flex-start",
          paddingTop: "10px",
          paddingLeft: "10px",
          flexDirection: "row",
        }}
      >
        <Typography.Title style={{ width: "50%" }} level={4}>
          {field}
        </Typography.Title>
        <Typography.Text style={{ width: "50%" }}>{value}</Typography.Text>
      </div>
    </Col>
  );
}
