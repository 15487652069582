export const citiesForEgg = [
  "samundri",
  "kamalia",
  "pirmahal",
  "faisalabad",
  "gojra",
  "bura weharri",
  "sahiwal",
  "cheechawatni",
  "arifwalabahawalnagar",
  "lodhra",
  "multan",
  "rahimyaarkhan",
  "sadiqabad",
  "lahore",
  "gujranwala",
  "mandi bahauldin",
  "sargodha",
  "chakwal",
  "bhera",
  "rawalpindi",
  "peshawar",
];

export const citesForBroilerOrChick = [
  "lahore",
  "rawalpindi",
  "chakwal",
  "kahuta",
  "faisalabad",
  "jhang",
  "chiniot",
  "sargodha",
  "gujranwala",
  "sialkot",
  "wazirabad",
  "sheikhupura",
  "hafizabad",
  "gujrat",
  "sahiwal",
  "chichawatni",
  "arifwala",
  "pak pattan Sharif",
  "burewala",
  "Vehari",
  "khanewl",
  "multan",
  "rahim yar khan",
  "rajinpur",
];
