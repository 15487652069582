import { UploadOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@material-ui/icons";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { uploadThumnail } from "../../../RestServices/videosService";
import { MAIN_BACKGROUND_COLOR } from "../../../styleConstants";
const styles = {
  modal: {
    backgroundColor: "white",
  },
  submitButton: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    width: "100%",
    border: "none",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

const ProductInput = ({
  setproductDetail,
  productDetail,
  productDetails,
  index,
}) => {
  const [productName, setProductName] = useState(
    productDetail?.productName ? productDetail?.productName : ""
  );
  const [subHeading, setSubHeading] = useState(
    productDetail?.subHeading ? productDetail?.subHeading : ""
  );
  const [description, setDescription] = useState(
    productDetail?.description ? productDetail?.description : ""
  );
  const [buttonText, setButtonText] = useState(
    productDetail?.buttonText ? productDetail?.buttonText : ""
  );
  const [buttonLink, setButtonLink] = useState(
    productDetail?.buttonLink ? productDetail?.buttonLink : ""
  );
  const [tagName, setTagName] = useState(
    productDetail?.tagName ? productDetail?.tagName : ""
  );
  const [selectedThemnail, setselectedThemnail] = useState();
  const [articleImage, setArticleImage] = useState(
    productDetail?.articleImage ? productDetail?.articleImage : ""
  );

  const [isFormLoading, setFromLoading] = useState(false);
  const [uploadedPercentage, setuploadedPercentage] = useState(0);

  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.productName = productName;
    setproductDetail([...productDetails]);
  }, [productName]);

  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.buttonLink = buttonLink;
    setproductDetail([...productDetails]);
  }, [buttonLink]);
  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.description = description;
    setproductDetail([...productDetails]);
  }, [description]);
  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.subHeading = subHeading;
    setproductDetail([...productDetails]);
  }, [subHeading]);

  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.buttonText = buttonText;
    setproductDetail([...productDetails]);
  }, [buttonText]);
  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.selectedThemnail = selectedThemnail;
    setproductDetail([...productDetails]);
  }, [selectedThemnail]);

  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.tagName = tagName;
    setproductDetail([...productDetails]);
  }, [tagName]);
  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.articleImage = articleImage;
    setproductDetail([...productDetails]);
  }, [articleImage]);

  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.uploadedPercentage = uploadedPercentage;
    setproductDetail([...productDetails]);
  }, [uploadedPercentage]);

  useEffect(() => {
    const oneProductDetail = productDetails[index];
    oneProductDetail.isFormLoading = isFormLoading;
    setproductDetail([...productDetails]);
  }, [isFormLoading]);

  const OnInsertTable = () => {
    setproductDetail([
      ...productDetails,
      {
        productName: "",
        subHeading: "",
        description: "",
        buttonText: "",
        buttonLink: "",
        tagName: "",
        isFormLoading: false,
        uploadedPercentage: 0,
        selectedThemnail: "",
        articleImage: "",
      },
    ]);
  };
  // useEffect(() => {
  //   (() => {
  //     const oneProductDetail = productDetail;
  //     debugger;
  //     if (oneProductDetail && oneProductDetail.productName) {
  //       setProductName(productDetails?.productName);
  //       setSubHeading(productDetails?.subHeading);
  //       setDescription(productDetails?.description);
  //       setButtonText(productDetails?.buttonText);
  //       setButtonLink(productDetails?.buttonLink);
  //       setTagName(productDetails?.tagName);
  //       setArticleImage(productDetails?.articleImage);
  //     }
  //   })();
  // }, []);

  const { TextArea } = Input;

  const { token } = useSelector((state) => state.Authentication.user);

  const uploadThumbnail = async (thumbnail) => {
    // this.setState({ isFormLoading: true });
    // const { thumbnail } = this.state;
    const formData = new FormData();
    setuploadedPercentage(0);
    formData.append("thumbnail", thumbnail);
    setFromLoading(true);
    try {
      const response = await uploadThumnail(formData, token, (percentage) =>
        setuploadedPercentage(percentage)
      );
      setArticleImage(response.thumnailUrl);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFromLoading(false);
    }
  };

  const thumbnailProps = {
    onRemove: () => {
      setArticleImage(null);
      setselectedThemnail(null);
    },
    beforeUpload: (image) => {
      uploadThumbnail(image);
      setselectedThemnail(image);
      return false;
    },
    fileList: selectedThemnail ? [selectedThemnail] : [],
    accept: "image/*",
  };

  const deleteItem = (index) => {
    let productDetailsClone = [...productDetails];
    productDetailsClone.splice(index, 1);
    setproductDetail(productDetailsClone);
  };

  return (
    <div style={{ paddingTop: "15px" }}>
      <Typography.Text>Product Box {index + 1}</Typography.Text>
      {productDetails.length !== 1 && (
        <CloseOutlined
          onClick={() => deleteItem(index)}
          style={{ position: "relative", left: "85%", top: "30px" }}
        />
      )}
      <Row gutter={[16, 16]} justify="center">
        <Col span={11}>
          <Input
            size="large"
            type="text"
            name="title"
            // disabled={isFormLoading}
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name"
            style={{ marginTop: "20px" }}
          />
          <Input
            size="large"
            type="number"
            name="title"
            // disabled={isFormLoading}
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Product Price"
            style={{ marginTop: "20px" }}
          />
          <TextArea
            rows={4}
            placeholder="Description"
            style={{ marginTop: "20px" }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Col>
        <Col span={11}>
          <Input
            size="large"
            type="text"
            name="title"
            // disabled={isFormLoading}
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            placeholder="Tag Name"
            style={{ marginTop: "20px" }}
          />
          <Input
            size="large"
            type="text"
            name="title"
            // disabled={isFormLoading}
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
            placeholder="Button Text"
            style={{ marginTop: "20px" }}
          />
          <Input
            size="large"
            type="text"
            name="title"
            // disabled={isFormLoading}
            value={buttonLink}
            onChange={(e) => setButtonLink(e.target.value)}
            placeholder="Button Link"
            style={{ marginTop: "20px" }}
          />

          <Upload {...thumbnailProps} style={{ width: "100%" }}>
            <Button
              block
              size="large"
              icon={<UploadOutlined />}
              style={{ marginTop: "18px" }}
            >
              Product image Upload
            </Button>
          </Upload>
        </Col>
      </Row>
    </div>
  );
};

export default ProductInput;
