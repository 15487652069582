import { LeftSquareTwoTone } from "@ant-design/icons";
import { Breadcrumb, Col, Row, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { PermissionEnum } from "../../shared";
import { fontColor, MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import ContantTitle from "../../utils/contantTitle/ContantTitle";
import "../Dashboard/styles.css";

//
// import Table from "../Table";

const styles = {
  layout: {
    minHeight: "100vh",
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: "bold",
  },
  content: {
    color: fontColor,
    backgroundColor: "white",
    margin: "20px",
  },
  logo: {
    height: "200px",
    width: "190px",
  },
  menuToggler: {
    color: fontColor,
    fontSize: "30px",
    marginLeft: "5px",
    marginTop: "15px",
  },

  tableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  addUserButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addUserButton: {
    marginRight: "20px",
    backgroundColor: "#1f910e",
    color: "white",
  },
};
class NonAppUser extends React.Component {
  state = {
    collapsed: false,
    input: null,
    isFormLoading: false,
    customers: this.props.data,
    totalPages: 1,
    selectedRowKeys: [],
    currentPage: 1,
    metaData: {},
    isModalOpen: false,
    name: "",
    email: "",
    password: "",
    permissions: [],
    type: "ADMIN",
    city: "",
    selectedUser: {},
    rawCustomer: [],
    phoneNumber: "",
    dropdrownshow: false,
    dateSelection: {
      startDate: undefined,
      endDate: undefined,
      key: "selection",
    },

    datesBetween: {
      startDate: undefined,
      endDate: undefined,
    },
  };

  tableColumns = [
    {
      title: "Sr.",
      width: 20,
      dataIndex: "index",
      fixed: "left",
      align: "center",
      render: (text, record, index) => index + 1,
    },

    {
      key: "phoneNumber",
      title: "Phone",
      dataIndex: "phoneNumber",
      render: (text, record, index) => (
        <a style={{ color: "blue" }} href={`tel:${text}`}>
          {text}
        </a>
      ),
    },

    {
      key: "blockedReason",
      title: "Blocked Reason",
      dataIndex: "blockedReason",
    },

    {
      key: "status",
      title: "Status",
      // title: "Category",
      dataIndex: "status",
    },
  ];

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getUserApiFunc();
    });
  };

  render() {
    const { customers, totalPages, currentPage, isFormLoading } = this.state;

    const { permissions: userPermission } = this.props.Auth.user.profileDetails;

    return (
      <div style={styles.content}>
        <Row>
          {" "}
          <Col span={24}>
            <div>
              <LeftSquareTwoTone
                onClick={() => this.props.setSelected("Dashboard")}
                style={{
                  fontSize: "24px",
                  color: "red",
                  margin: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          </Col>
          <Col span={16}>
            <Breadcrumb style={{ margin: "16px 16px", cursor: "pointer" }}>
              <Breadcrumb.Item>Admin Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.slectedSubkey}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {(userPermission.includes(PermissionEnum["AllResources:*"]) ||
          userPermission.includes(PermissionEnum["Users:*"]) ||
          userPermission.includes(PermissionEnum["Users:add"])) && (
          <ContantTitle
            heading={this.props.slectedSubkey}
            // buttonText={"Add User"}
            openForm={console.log}
          />
        )}

        <Table
          dataSource={customers}
          pagination={true}
          columns={this.tableColumns}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(NonAppUser);
