import axios from 'axios';
import { authService } from '../shared';
export const getDailyStat = async (date, token) => {
  const uri = `/admin/api/v1/stat/userDailyStat?date=${date}`;
  const resp = await axios.get(authService + uri, {
    headers: {
      Authorization: 'bearer ' + token,
      'api-key': 'poultrybaba@1234Sumandri',
    },
  });
  return resp.data.userDailyStat;
};

export const getTopFan = async ({ startDate, endDate, page, limit }, token) => {
  const uri = '/admin/api/v1/topFans/getTopFansToday';
  const resp = await axios.get(authService + uri, {
    params: {
      startDate,
      endDate,
      page,
      limit,
    },

    headers: {
      Authorization: 'bearer ' + token,
      'api-key': 'poultrybaba@1234Sumandri',
    },
  });
  return resp.data;
};

export const getDailyTaskContactsReport = async (payload, token) => {
  const uri = '/admin/api/v1/subAdminTasks/geUserPost';
  const resp = await axios.get(authService + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: 'bearer ' + token,
      'api-key': 'poultrybaba@1234Sumandri',
    },
  });
  return resp.data;
};

export const getTrafficGraphData = async (
  { startDate, endDate, city },
  token,
) => {
  const uri = '/admin/api/v1/topFans/getTrafficGraphData';
  const resp = await axios.get(authService + uri, {
    params: {
      endDate,
      startDate,
      city,
      limit: 500,
    },

    headers: {
      Authorization: 'bearer ' + token,
      'api-key': 'poultrybaba@1234Sumandri',
    },
  });
  return resp.data;
};
