import {
  LOGIN_FAILED,
  LOGIN_SUCCESSFULL,
  LOGIN_LOADING,
  LOGOUT,
} from "../actionTypes";

const Signin = (
  state = {
    user: null,
    user1: {
      city: "Faisalabad",
      email: "ceo@poultrybaba.com",
      name: "zaheer",
      phoneNumber: "+923167004343",
      profileDetails: {
        permissions: [
          "Users:get",
          "MandiRates:*",
          "News_Articles:*",
          "AllResources:*",
        ],
        type: "ADMIN",
      },
      referalCode: "lntvOAYfx",
      refreshToken:
        "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwODg2ZmE5OGU0ZWFhNmY0Y2NmZTkzNyIsImVtYWlsIjoiY2VvQHBvdWx0cnliYWJhLmNvbSIsInJvbGUiOiJBRE1JTiIsImlhdCI6MTYyMjY4MzkyNiwiZXhwIjoxNjM4MjM1OTI2fQ.KkTHseosbdewzXHWCDiJDgNDmSfXT3p9o_mUjWJ5N4ZsiCLxY1qjnQV9vn62K0jeNT3xh7rX37tr46xu300rEvjSAs6qMv4NrmTe52pGz4WmSVltPTHFd4X_mzkgIo6fo408IE_1S_NchFoTdtfSPWCPSK5RwxcpeXlPOFJTo6U",
      status: "LOGGED_IN",
      token:
        "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwODg2ZmE5OGU0ZWFhNmY0Y2NmZTkzNyIsImVtYWlsIjoiY2VvQHBvdWx0cnliYWJhLmNvbSIsInJvbGUiOiJBRE1JTiIsImlhdCI6MTYyMzczMDI5NywiZXhwIjoxNjIzODE2Njk3fQ.iYQ0IVJ18QSl0Ka6q_KmLoP5ZI3By1wmMMSdJAAax_CMoQxfARWl-1maG99_WB3ej7O1F5OIW2nIN2Vm7FZ2_w",
      _id: "60886fa98e4eaa6f4ccfe937",
    },
    isLoading: false,
    errMess: null,
  },
  action
) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return { user: null, isLoading: true, errMess: null };
    case LOGIN_FAILED:
      return { user: null, isLoading: false, errMess: action.errMess };
    case LOGIN_SUCCESSFULL:
      return { user: action.user, isLoading: false, errMess: null };
    case LOGOUT:
      localStorage.clear("token");
      return { user: null, isLoading: false, errMess: null };
    default:
      return state;
  }
};
export default Signin;
