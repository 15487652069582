import { Button, Col, Image, message, Row, Typography, Input, Card } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  createArticle,
  getArticles,
  updateArticle,
  uploadThumnail,
} from "../../RestServices/videosService";
import Pagination from "../Pagination";
import Table from "../Table";

import { ThumbDown, ThumbUp } from "@material-ui/icons";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { NewsEnum } from "../../shared";
import { MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import ContantTitle from "../../utils/contantTitle/ContantTitle";
import Calendar from "../../utils/dropDown/Calendar";
import CategoryDropDown from "../../utils/dropDown/CategoryDropDown";
import DeleteVideo from "../UiElements/DeleteVideo";
import AddArticlesScreen from "./AddArticlesScreen";
import moment from "moment";

const styles = {
  tableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  addVideoButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addVideoButton: {
    marginRight: "20px",
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: "white",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "5px",
  },
  titleContainer: {
    padding: "10px 20px",
    color: "#fb6222",
  },
};

// LIKE", "CLAP", "ANGRY"
const columns = [
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>Sr.</p>
        <p style={{ margin: 0, padding: 0, color: "black" }}>Date/Time</p>
      </div>
    ),
    accessor: "_id",
  },
  {
    Header: "Images",
    accessor: "image",
  },
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>title</p>
        <p style={{ margin: 0, padding: 0, color: "black" }}>discription</p>
      </div>
    ),
    accessor: "title",
  },

  {
    Header: "Engagements",
    accessor: "reactions",
  },
  {
    Header: "Views",
    accessor: "views",
  },
  {
    Header: "articleType",
    accessor: "articleType",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

class Body extends React.Component {
  constructor() {
    super();
    this.state = {
      isFormLoading: false,
      isEditFormLoading: false,
      uploadedPercentage: 0,
      videos: [],
      isModalEdit: false,
      isModalOpen: false,
      videoId: "",
      searchQuery: "",
      title: "",
      editData: {},
      typingTimeout: 0,
      shortDescription: "",
      description: "",
      video: null,
      descriptionWithImage: [{ paragraph: "", imageUrl: "", image: "" }],
      thumbnail: null,
      totalPages: 0,
      tags: [],
      currentPage: 1,
      thumnailUrl: "",
      playingVideo: null,
      isVideoPlayerOpen: false,
      selectedCategory: "",
      getArticlesFilterCategory: "",
      province: "پنجاب",
      text: "",
      main: true,
    };
  }

  onAddCategoryChange = (selectedCategory) =>
    this.setState({ selectedCategory });

  openVideoPlayer = () => this.setState({ isVideoPlayerOpen: true });
  closeVideoPlayer = () => this.setState({ isVideoPlayerOpen: false });
  openVideoForm = () => {
    this.setState({
      text: "Add",
      editData: {},
      main: !this.state.main,
    });
    this.getArticles(this.state.currentPage);
  };
  closeVideoForm = () => this.setState({ isModalOpen: false });
  closeVideoEditFrom = () =>
    this.setState({ isModalEdit: false, title: "", description: "", tags: [] });
  inputChangeHandler = (evt, textNmae) => {
    const { name, value } = evt.target;
    if (name) {
      this.setState({ [name]: value });
    } else {
      this.setState({ [textNmae]: value });
    }
  };

  inputChangeDiscription = (description, index) => {
    const { descriptionWithImage } = this.state;
    descriptionWithImage[index].paragraph = description;
    this.setState({ descriptionWithImage });
  };

  selectVideo = (video) => this.setState({ video });
  selectThumbnail = (thumbnail) => this.setState({ thumbnail });

  onVideoCategoryChange = (getArticlesFilterCategory) =>
    this.setState({ getArticlesFilterCategory }, this.getArticles);

  submitVideo = async () => {
    const {
      title,
      description,
      shortDescription,
      thumnailUrl,
      selectedCategory,
      descriptionWithImage,
    } = this.state;
    if (
      !title ||
      !shortDescription ||
      !thumnailUrl ||
      !selectedCategory ||
      !descriptionWithImage
    ) {
      return message.warn("Please fill all the required Fields");
    }
    this.setState({ isFormLoading: true });

    try {
      await createArticle(
        {
          title,
          shortDescription,
          thumbnail: thumnailUrl,
          articleType: selectedCategory,
          descriptionWithImage: descriptionWithImage,
        },
        this.props.Auth.user.token,
        (percentage) => this.setState({ uploadedPercentage: percentage }),
      );
      this.resetForm();
      message.success("Article Successfully Uploaded");
      this.getArticles(1);
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  uploadThumbnail = async (thumbnail) => {
    this.setState({ isFormLoading: true });
    // const { thumbnail } = this.state;
    const formData = new FormData();

    formData.append("thumbnail", thumbnail);

    try {
      const response = await uploadThumnail(
        formData,
        this.props.Auth.user.token,
        (percentage) => this.setState({ uploadedPercentage: percentage }),
      );
      this.setState({
        isFormLoading: false,
        thumnailUrl: response.thumnailUrl,
      });
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  uploadThumbnailofParagraph = async (thumbnail, index) => {
    this.setState({ isFormLoading: true });
    // const { thumbnail } = this.state;
    const formData = new FormData();

    formData.append("thumbnail", thumbnail);

    try {
      const response = await uploadThumnail(
        formData,
        this.props.Auth.user.token,
        (percentage) => this.setState({ uploadedPercentage: percentage }),
      );
      const { descriptionWithImage } = this.state;
      descriptionWithImage[index].imageUrl = response.thumnailUrl;
      descriptionWithImage[index].image = response.thumbnail;

      this.setState({ descriptionWithImage, isFormLoading: false });
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  removeThumbnailofParagraph = (index) => {
    const { descriptionWithImage } = this.state;
    descriptionWithImage[index].imageUrl = "";
    this.setState({ descriptionWithImage });
  };

  videoEditSubmit = async () => {
    this.setState({ isEditFormLoading: true });
    const {
      title,
      descriptionWithImage,
      tags,
      videoId,
      thumnailUrl,
      selectedCategory,
      shortDescription,
    } = this.state;

    try {
      await updateArticle(
        {
          title,
          descriptionWithImage,
          articleType: selectedCategory,
          thumbnail: thumnailUrl,
          shortDescription,
          articleId: videoId,
        },
        this.props.Auth.user.token,
        (percentage) => this.setState({ uploadedPercentage: percentage }),
      );
      this.resetForm();
      message.success("Article Successfully Uploaded");
      this.getArticles(this.state.currentPage + 1 ?? 1);
    } catch (err) {
      this.getArticles(this.state.currentPage + 1 ?? 1);

      this.setState({ isEditFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  resetForm = () =>
    this.setState({
      title: "",
      description: "",
      video: null,
      isFormLoading: false,
      isModalOpen: false,
      isEditFormLoading: false,
      descriptionWithImage: [{ paragraph: "", imageUrl: "", image: "" }],

      uploadedPercentage: 0,
      isModalEdit: false,
    });

  getArticles = async (pageNo) => {
    try {
      const data = await getArticles(
        {
          articleType: this.state.getArticlesFilterCategory,
          pageNo,
          searchQuery: this.state.searchQuery,
        },
        this.props.Auth.user.token,
      );
      const videos = data.articles.docs.map((video, index) => ({
        // serialNumber: `${video?.sr}`,
        _id: (
          <div>
            <p>{video?.sr}</p>
            <p>{moment(new Date(video.publishedAt)).format("MMM DD YY")} </p>
          </div>
        ),
        title: (
          <div style={{ width: "300px" }}>
            <Typography.Paragraph
              ellipsis={{
                rows: 3,
                tooltip: <span> {video.title}</span>,
              }}
              type="warning"
              style={{ textDecoration: "underline", fontSize: "14px" }}
            >
              {video.title}
            </Typography.Paragraph>

            <Typography.Paragraph
              ellipsis={{
                rows: 4,
                tooltip: <span> {video.shortDescription}</span>,
              }}
              style={{ textDecoration: "underline", fontSize: "14px" }}
            >
              {video.shortDescription}
            </Typography.Paragraph>
          </div>
        ),

        articleType: (
          <div>
            <Typography.Text style={{ fontSize: "14px", color: "black" }}>
              {video.pageCategory ?? "Not assign yet"} / {video.articleType}
            </Typography.Text>
          </div>
        ),

        views: video.totalViews,
        reactions: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <ThumbUp style={{ color: "#de923b" }} /> {video.totalLikes}
            </div>
            <div>
              <ThumbDown /> {video.totalDislikes}
            </div>
          </div>
        ),
        image: (
          <Image
            style={{ width: "80px", height: "40px" }}
            src={video?.articleImage[0]?.url}
            alt={video.title}
          />
        ),
        action: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {" "}
            <Button
              onClick={(e) => this.onEditClick(e, video)}
              style={{ marginRight: "10px" }}
              type="primary"
            >
              Edit
            </Button>{" "}
            <br />
            <DeleteVideo articles={true} video={video} />
          </div>
        ),
      }));
      this.setState({
        videos,
        totalPages: data.articles.totalDocs,
        currentPage: data.articles.page,
      });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  onEditClick = (e, data) => {
    e.stopPropagation();

    this.setState({
      editData: data,
      text: "Edit",
      main: !this.state.main,
    });
  };
  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getArticles(this.state.currentPage);
    });
  };

  componentDidMount() {
    this.getArticles(1);
  }
  rowClickHandler = (video) => {
    this.setState({ playingVideo: video, isVideoPlayerOpen: true });
  };
  changeProvince = (province) => {
    this.setState({ province });
  };
  handleChangeTags = (tags) => {
    this.setState({ tags });
  };
  handleAddNewParagraph = () => {
    const { descriptionWithImage } = this.state;
    descriptionWithImage.push({ imageUrl: "", paragraph: "" });
    this.setState({ descriptionWithImage });
  };
  handleRemoveParagraph = (index) => {
    const { descriptionWithImage } = this.state;
    try {
      if (descriptionWithImage.length < 2) return;
      descriptionWithImage.splice(index, 1);
      this.setState({ descriptionWithImage });
    } catch (err) { }
  };

  handleImagesParagraph = (image, index) => {
    const { descriptionWithImage } = this.state;
    try {
      descriptionWithImage[index].imageUrl = image;
      this.setState({ descriptionWithImage });
    } catch (err) { }
  };
  render() {
    const {
      videos,
      totalPages,
      currentPage,
      isVideoPlayerOpen,
      video,
      isEditFormLoading,
      playingVideo,
      title,
      description,
      isModalOpen,
      isFormLoading,
      thumbnail,
      removeThumbnailofParagraph,
      thumnailUrl,
      selectedCategory,
      shortDescription,
      descriptionWithImage,
      tags,
    } = this.state;

    const { Title } = Typography;

    return (
      <div>
        {!this.state.main ? (
          <AddArticlesScreen
            text={this.state.text}
            editData={this.state.editData}
            open={() => this.openVideoForm()}
          />
        ) : (
          <>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ padding: "10px" }}>
                  <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <div className="flex-between">
                      <div style={{ width: "100%" }}>
                        <Row gutter={[12, 12]}>
                          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                            <div className="flex-col">
                              <Title level={2} className="mb-0 text-primary" style={{ height: "50px", lineHeight: "50px" }}>All News and Article</Title>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                            <div className="flex-col">
                              <Title level={4} className="mb-0 text-primary">Search Text</Title>
                              <Input
                                placeholder="Search..."
                                value={this.state.searchQuery}
                                onChange={(e) => {
                                  const searchQuery = e.target.value;
                                  if (this.state.typingTimeout) {
                                    clearTimeout(this.state.typingTimeout);
                                  }

                                  this.setState({
                                    searchQuery: searchQuery,
                                    typingTimeout: setTimeout(() => {
                                      this.getArticles(this.state.currentPage);
                                    }, 1000),
                                  });
                                }}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                            <div className="flex-col">
                              <Title level={4} className="mb-0 text-primary">Select Category</Title>
                              <CategoryDropDown
                                category={this.state.getArticlesFilterCategory}
                                categoriesOptions={NewsEnum}
                                setCategory={this.onVideoCategoryChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Button type="primary" onClick={this.openVideoForm}>
                          Upload News or Article
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ padding: "10px" }}>
                  <Table
                    onRowClick={this.rowClickHandler}
                    data={videos}
                    columns={columns}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
                  <Pagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={this.onPageChange}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        {/* <VideoPlayer
          videoTitle={playingVideo && playingVideo.title}
          closePlayer={this.closeVideoPlayer}
          isPlayerOpen={isVideoPlayerOpen}
          url={
            playingVideo &&
            `${videoUrl}?videoId=${playingVideo._id}&customerId=${this.props.Auth.user._id}`
          }
        /> */}
        {/* <AddVideo
          title={title}
          category={selectedCategory}
          setCategory={this.onAddCategoryChange}
          categoriesOptions={NewsEnum}
          closeModal={this.closeVideoForm}
          description={descriptionWithImage}
          inputHandler={this.inputChangeHandler}
          inputChangeDiscription={this.inputChangeDiscription}
          selectVideo={this.selectVideo}
          video={video}
          isOpen={isModalOpen}
          tags={tags}
          removeThumbnailofParagraph={removeThumbnailofParagraph}
          uploadThumbnail={this.uploadThumbnail}
          shortDescription={shortDescription}
          isNotShow={false}
          handleChangeTags={this.handleChangeTags}
          isFormLoading={isFormLoading}
          submitVideo={this.submitVideo}
          selectThumbnail={this.selectThumbnail}
          thumbnail={thumbnail}
          uploadedPercentage={this.state.uploadedPercentage}
          handleImagesParagraph={this.handleImagesParagraph}
          handleAddNewParagraph={this.handleAddNewParagraph}
          handleRemoveParagraph={this.handleRemoveParagraph}
          uploadThumbnailofParagraph={this.uploadThumbnailofParagraph}
        /> */}
        {
          // !this.state.isModalOpen ?
          //   <AddVideo
          //     title={title}
          //     isNotShow={false}
          //     category={selectedCategory}
          //     thumnailUrl={thumnailUrl}
          //     handleImagesParagraph={this.handleImagesParagraph}
          //     thumbnail={thumbnail}
          //     setCategory={this.onAddCategoryChange}
          //     categoriesOptions={NewsEnum}
          //     closeModal={this.closeVideoEditFrom}
          //     uploadThumbnail={this.uploadThumbnail}
          //     description={descriptionWithImage}
          //     inputChangeDiscription={this.inputChangeDiscription}
          //     shortDescription={shortDescription}
          //     inputHandler={this.inputChangeHandler}
          //     selectVideo={this.selectVideo}
          //     removeThumbnailofParagraph={removeThumbnailofParagraph}
          //     video={video}
          //     isOpen={this.state.isModalEdit}
          //     handleChangeTags={this.handleChangeTags}
          //     isFormLoading={isEditFormLoading}
          //     submitVideo={this.videoEditSubmit}
          //     selectThumbnail={this.selectThumbnail}
          //     thumbnail={thumbnail}
          //     uploadedPercentage={this.state.uploadedPercentage}
          //     handleAddNewParagraph={this.handleAddNewParagraph}
          //     handleRemoveParagraph={this.handleRemoveParagraph}
          //     uploadThumbnailofParagraph={this.uploadThumbnailofParagraph}
          //   />
          //   :
        }
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(Body);
