import {
  commodityAddOneSuccessfull,
  commodityLoading,
  commoditySuccessfull,
  commodityFailed,
  getTimeString,
  timerLoading,
  createTimeString,
  getCommoditiesCountLoadingFalse,
  getCommoditiesCountLoading,
  getCommoditiesCount,
} from "../Actions/commodityAction";
import { message, Row } from "antd";
import {
  createCommodityRate,
  getCommodityRate,
  updateCommodityRate,
  createRateTimeString,
  getRateTimeString,
  updateRateTimeString,
  getCommodityStatCount,
  deleteCommodityRate,
} from "../../RestServices/postingService";

export const createCommuditiy =
  (data, token, loadCallBack, handleTimerModalOnCancle, commodityRate) =>
  async (dipsatch) => {
    dipsatch(commodityLoading());
    try {
      loadCallBack(true);
      const response = await createCommodityRate(data, token);
      //citites fillter
      handleTimerModalOnCancle();
      const { rate } = response.data;
      console.log(response.data);
      if (!commodityRate.hasOwnProperty(rate.cityId)) {
        commodityRate[rate.cityId] = {
          ...rate,
        };
      } else {
        commodityRate[rate.cityId] = {
          ...rate,
        };
      }
      dipsatch(commoditySuccessfull(commodityRate));
    } catch (err) {
      dipsatch(commodityFailed(err.message));
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    } finally {
      loadCallBack(false);
    }
  };

export const updateCommodity =
  (id, data, token, loadCallBack, handleTimerModalOnCancle, commodityRate) =>
  async (dipsatch) => {
    dipsatch(commodityLoading());
    try {
      loadCallBack(true);
      const response = await updateCommodityRate(id, data, token);
      //citites fillter
      handleTimerModalOnCancle();
      const { rate } = response.data;

      console.log(response.data);
      if (!commodityRate.hasOwnProperty(rate.cityId)) {
        commodityRate[rate.cityId] = {
          ...rate,
        };
      } else {
        commodityRate[rate.cityId] = {
          ...rate,
        };
      }
      dipsatch(commoditySuccessfull(commodityRate));
    } catch (err) {
      dipsatch(commodityFailed(err.message));
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    } finally {
      loadCallBack(false);
    }
  };

export const deleteItemRate =
  (id, token, commodityRate) => async (dipsatch) => {
    dipsatch(commodityLoading());
    try {
      const response = await deleteCommodityRate(id, token);
      //citites fillter
      const { rate } = response.data;
      delete commodityRate[rate.cityId];
      dipsatch(commoditySuccessfull(commodityRate));
    } catch (err) {
      dipsatch(commodityFailed(err.message));
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    }
  };

export const getCommodity = (product, province, token) => async (dipsatch) => {
  dipsatch(commodityLoading());
  try {
    const response = await getCommodityRate(product, province, token);
    //citites fillter

    let { rates } = response.data;

    let commodityrates = {};
    if (rates) {
      rates.map((item) => {
        if (!commodityrates.hasOwnProperty(item.cityId)) {
          commodityrates[item.cityId] = {
            ...item,
          };
        }
      });
    }
    dipsatch(commoditySuccessfull(commodityrates));
  } catch (err) {
    dipsatch(commodityFailed(err.message));
    if (err.response) {
      message.error(err.response.data.message);
    } else {
      message.error(err.message);
    }
  }
};

export const getCommoditystatCounts =
  (startDate, endDate, token) => async (dipsatch) => {
    dipsatch(getCommoditiesCountLoading());
    try {
      const response = await getCommodityStatCount(startDate, endDate, token);
      //citites fillter
      let { stat } = response.data;
      let payload = { columns: [], item: [] };
      if (stat.length) {
        let columns = stat.map((item) => item.city);
        columns = Array.from(new Set(columns));
        payload.columns = columns;

        let finalList = { [columns[0]]: 0 };

        columns.reduce((obj, item) => {
          finalList[item] = 0;
        });

        let products = stat.map((item) => item.product);

        products = Array.from(new Set(products));

        let Rows = [];

        for (let item of stat) {
          const foundItem = Rows.find((i) => i.categoryName === item.product);
          if (foundItem) {
            foundItem[item.city] = item.count;
            foundItem.rowCount += item.count;
          } else {
            Rows.push({
              ...finalList,
              imageUrl: `/image/${item.product}.png`,
              categoryName: item.product,
              [item.city]: item.count,
              key: Rows.length,
              rowCount: item.count,
            });
          }
        }
        payload.item = Rows;
        payload.products = products;
      }
      dipsatch(getCommoditiesCount(payload));
    } catch (err) {
      dipsatch(getCommoditiesCountLoadingFalse(err.message));
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    }
  };

export const createCommodityRateTimeString =
  (data, token, loadCallBack, setTimerModalVisible, dateItems) =>
  async (dipsatch) => {
    try {
      loadCallBack(true);
      dipsatch(timerLoading(true));
      const response = await createRateTimeString(data, token);
      //citites fillter
      setTimerModalVisible(false);
      const { time } = response.data;
      console.log(response.data);
      console.log(time, dateItems);
      if (!dateItems.hasOwnProperty(time.division)) {
        dateItems[time.division] = {
          ...time,
        };
      } else {
        dateItems[time.division] = {
          ...time,
        };
      }
      dipsatch(createTimeString(dateItems));
    } catch (err) {
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    } finally {
      loadCallBack(false);
      dipsatch(timerLoading(false));
    }
  };

export const updateCommodityRateTimeString =
  (id, data, token, loadCallBack, setTimerModalVisible, dateItems) =>
  async (dipsatch) => {
    try {
      loadCallBack(true);
      dipsatch(timerLoading(true));

      const response = await updateRateTimeString(id, data, token);
      //citites fillter
      setTimerModalVisible(false);
      const { time } = response.data;
      console.log(response.data);
      console.log(time, dateItems);
      if (!dateItems.hasOwnProperty(time.division)) {
        dateItems[time.division] = {
          ...time,
        };
      } else {
        dateItems[time.division] = {
          ...time,
        };
      }
      dipsatch(createTimeString(dateItems));
    } catch (err) {
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    } finally {
      loadCallBack(false);
      dipsatch(timerLoading(false));
    }
  };

export const getDateTimeString = (data, token) => async (dipsatch) => {
  try {
    dipsatch(timerLoading(true));
    const response = await getRateTimeString(data, token);
    //citites fillter
    const { times } = response.data;
    let dateItems = {};
    times.map((time) => {
      if (!dateItems.hasOwnProperty(time.division)) {
        dateItems[time.division] = {
          ...time,
        };
      } else {
        dateItems[time.division] = {
          ...time,
        };
      }
    });

    dipsatch(getTimeString(dateItems));
  } catch (err) {
    if (err.response) {
      message.error(err.response.data.message);
    } else {
      message.error(err.message);
    }
  } finally {
    dipsatch(timerLoading(false));
  }
};
