import { CHANGE_MOBILE_VIEW } from '../../actionTypes';

const Setting = (
  state = {
    isMobileView: false,
  },
  action
) => {
  switch (action.type) {
    case CHANGE_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.payload,
      };
    default:
      return state;
  }
};
export default Setting;
