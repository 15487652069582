import { Button, Modal, Row, Col } from "antd";
import React from "react";
import { ModalStyle } from "./ModalStyle";

export default function ModalUtil({
  timerModalVisible,
  timerModalLoading,
  showTimerModal,
  handleTimerModalOnOk,
  handleTimerModalOnCancle,
  title,
  width,
  ...otherProps
}) {
  return (
    <div className="modalC-timer">
      {" "}
      <Modal
        className="rateModalTimer"
        visible={timerModalVisible}
        title={title}
        onOk={handleTimerModalOnOk}
        onCancel={handleTimerModalOnCancle}
        footer={null}
        width={width}
      >
        {otherProps.children}
        <Row>
          <Col style={ModalStyle.okCol} span={12}>
            <Button
              className="antPerimeryButton"
              onClick={handleTimerModalOnOk}
              loading={timerModalLoading}
              style={ModalStyle.okButton}
            >
              OK
            </Button>
          </Col>
          <Col style={ModalStyle.cancelCol} span={12}>
            <Button
              onClick={handleTimerModalOnCancle}
              style={{ height: "40px", background: "#fff", width: "80%", borderRadius: "8px", border: "1px solid #d4d4d4", cursor: "pointer" }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
