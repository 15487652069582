import {
  LOGIN_LOADING,
  LOGIN_SUCCESSFULL,
  LOGIN_FAILED,
  LOGOUT
} from "../actionTypes";

export const loginLoading = () => ({
  type: LOGIN_LOADING
});

export const loginSuccessfull = user => ({
  type: LOGIN_SUCCESSFULL,
  user
});
export const loginFailed = errMess => ({
  type: LOGIN_FAILED,
  errMess
});


export const logout = () => ({
  type: LOGOUT
});
