import { FileAddOutlined, LeftSquareTwoTone } from "@ant-design/icons";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Upload,
  DatePicker,
  Typography,
} from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import icon3 from "../../assets/Blogs Icons/Group 3999.png";
import icon2 from "../../assets/Blogs Icons/Group 4027.png";

import icon1 from "../../assets/Blogs Icons/Group 4026.png";
import {
  createArticle,
  updateArticle,
  uploadThumnail,
} from "../../RestServices/videosService";
import { NewsEnum } from "../../shared";
import ProductBox from "./productBox/productTable";
import ProductTable from "./ProductTable/productTable";
import { BsArrowLeftCircle } from "react-icons/bs";

import ProsCons from "./prosAndCons/ModalProseCons";
import "./style.css";
const AddArticlesScreen = ({ text, open, editData }) => {
  useEffect(() => {
    if (editData && editData.hasOwnProperty("_id")) {
      setproductDetail(editData?.productDetails);
      setTitle(editData?.title);
      setTags(editData?.tags ?? []);
      setproductTable(editData?.productTable ? editData.productTable : []);
      setProsCons(editData?.prosCons);
      setDescription(editData?.shortDescription);
      setParagraph(editData?.paragraph);
      setPageCategory(editData?.pageCategory ?? []);
      setArticleType(editData?.articleType);
      setTag(editData?.tag);
      setArticleImage(editData?.articleImage);
      setpermalink(editData?.permalink);

      document.querySelector("#editor").innerHTML = editData?.description;
    }
  }, [editData]);

  const [prosCons, setProsCons] = useState([{ pros: [], cons: [] }]);

  const [images, setimages] = useState([]);

  const [productDetails, setproductDetail] = useState([]);

  const [productTable, setproductTable] = useState([]);

  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [shortDescription, setDescription] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [pageCategory, setPageCategory] = useState("Blogs");
  const [articleType, setArticleType] = useState("");
  const [articleImage, setArticleImage] = useState([]);
  const [permalink, setpermalink] = useState();
  const [tag, setTag] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [tableModelOpen, setTableModelOpen] = useState(false);
  const [ProductTableModelOpen, setProductTableModelOpen] = useState(false);

  const [publishedAt, setPublishedAt] = useState(
    editData.publishedAt ? new Date(editData?.publishedAt) : new Date(),
  );

  const { TabPane } = Tabs;
  const { Dragger } = Upload;
  const { Option } = Select;

  const resetState = () => {
    setProsCons([{ pros: [], cons: [] }]);
    setimages([]);
    setproductDetail([]);
    setTitle("");
    setDescription("");
    setParagraph("");
    setPublishedAt("");
    setArticleType("");
    setArticleImage([]);
    setpermalink();
    setTag("");
    setTags([]);
    setproductTable([]);
    setIsModelOpen(false);
    setTableModelOpen(false);
    document.getElementById("editor").innerHTML = "";
    open(true);
  };
  const { token } = useSelector((state) => state.Authentication.user);

  function onChange(value) {
    setArticleType(value);
    console.log(`selected ${value}`);
  }

  const callback = (key) => {
    console.log(key);
  };

  const [isFormLoading, setFromLoading] = useState(false);
  const [uploadedPercentage, setuploadedPercentage] = useState(0);
  const uploadThumbnail = async (thumbnail) => {
    // this.setState({ isFormLoading: true });
    // const { thumbnail } = this.state;
    const formData = new FormData();
    setuploadedPercentage(0);
    formData.append("thumbnail", thumbnail);
    setFromLoading(true);
    try {
      const response = await uploadThumnail(formData, token, (percentage) =>
        setuploadedPercentage(percentage),
      );
      setArticleImage([
        ...articleImage,
        {
          url: response.thumnailUrl,
          name: response.thumnailUrl.split("thumbnail")[1].split("-")[1],
        },
      ]);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFromLoading(false);
    }
  };

  const thumbnailProps = {
    onRemove: (image) => {
      let article = articleImage.filter((value) => value.url !== image.url);
      setArticleImage(article);
    },
    beforeUpload: (image) => {
      uploadThumbnail(image);
      setimages([...images, image]);
      return false;
    },
    fileList: articleImage ? articleImage : [],
    accept: "*",
  };

  const sumbitArticle = async () => {
    const paragraph = document.getElementById("editor").innerHTML;
    console.log(paragraph);

    console.log({
      title,
      shortDescription,
      articleType,
      publishedAt,
      permalink,
    });
    if (
      // !prosCons ||
      // !productDetails ||
      !title ||
      !shortDescription ||
      !articleType ||
      !publishedAt ||
      !permalink
    ) {
      return message.warn("Please fill all the required Fields");
    }
    setFromLoading(true);

    try {
      if (editData.hasOwnProperty("_id")) {
        await updateArticle(
          {
            prosCons,
            articleId: editData?._id,
            productDetails,
            title,
            shortDescription,
            articleType,
            productTable,
            description: paragraph,
            permalink: permalink.split(" ").join("-"),
            tag,
            tags,
            pageCategory,
            publishedAt,
            articleImage,
          },
          token,
          (percentage) => setuploadedPercentage(percentage),
        );
      } else {
        await createArticle(
          {
            prosCons,
            productDetails,
            title,
            productTable,
            shortDescription,
            articleType,
            permalink: permalink.split(" ").join("-"),
            tag,
            tags,
            pageCategory,
            description: paragraph,
            articleImage,
          },
          token,
          (percentage) => setuploadedPercentage(percentage),
        );
      }
      setFromLoading(false);
      resetState();
      // this.resetForm();
      message.success("Article Successfully Uploaded");
    } catch (err) {
      setFromLoading(false);
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  useEffect(() => {
    DecoupledEditor.create(document.querySelector("#editor"))
      .then((editor) => {
        // The toolbar needs to be explicitly appended.
        document
          .querySelector("#toolbar-container")
          .appendChild(editor.ui.view.toolbar.element);
        window.editor = editor;
      })
      .catch((error) => {
        console.error("There was a problem initializing the editor.", error);
      });
  }, []);

  function onChangeDate(date, dateString) {
    setPublishedAt(dateString);
  }

  console.log(tags, "asdf");
  const { Title } = Typography;
  return (
    <div style={{ padding: "20px", color: "#242424" }}>
      <BsArrowLeftCircle
        color="#de923b"
        size={26}
        style={{cursor: "pointer"}}
        onClick={() => open(true)}
      />
      <div style={{ padding: "20px" }}>
        <Title level={2} className="mb-0 text-primary">{text} News</Title>
      </div>

      <div style={{ padding: "20px" }}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Title</Title>
                  <Input
                    size="large"
                    type="text"
                    name="title"
                    disabled={isFormLoading}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter Article's Title"
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Type</Title>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    size="large"
                    value={articleType}
                    placeholder="Select a acticle's type"
                    optionFilterProp="children"
                    onChange={onChange}
                  >
                    {NewsEnum.map((value) => {
                      return (
                        <Option key={value} value={value}>
                          {value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Meta Tags</Title>
                  <Input.TextArea
                    size="large"
                    type="text"
                    name="tags"
                    disabled={isFormLoading}
                    value={tag}
                    rows={5}
                    onChange={(e) => setTag(e.target.value)}
                    placeholder={`<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        `}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Permalink</Title>
                  <Input
                    size="large"
                    type="text"
                    name="title"
                    disabled={isFormLoading}
                    value={permalink}
                    onChange={(e) => setpermalink(e.target.value.trim())}
                    placeholder="Add perma link without space"
                  />
                </div>
                <div className="flex-col" style={{ marginTop: "30px" }}>
                  <Title level={4} className="mb-0">Pages category</Title>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={pageCategory}
                    onChange={setPageCategory}
                    options={[
                      { value: "home" },
                      { value: "poultry bazar" },
                      { value: "Poultry plaza" },
                      { value: "murghi mandi" },
                      { value: "blogs" },
                      { value: "video" },
                      { value: "rates" },
                      { value: "pets point" },
                    ]}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Will Publish At</Title>
                  <DatePicker
                    defaultValue={moment(new Date(publishedAt), "DD/MM/YYYY")}
                    onChange={onChangeDate}
                    style={{ width: "100%", height: "40px" }}
                    showTime
                  />
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Tags</Title>
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Tags Mode"
                    onChange={setTags}
                    value={tags}
                  // options={tags}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="flex-col">
                  <Title level={4} className="mb-0">Description</Title>
                  <Input
                    size="large"
                    type="text"
                    name="title"
                    disabled={isFormLoading}
                    value={shortDescription}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Business Man, Food, Vendor, Financial Services"
                  />
                </div>
              </Col>

              <Col span={24} style={{ marginTop: "20px", textTransform: "none" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    padding: 0,
                  }}
                  justify="end"
                  align="top"
                >
                  <Col span={11} id="toolbar-container"></Col>

                  <Col
                    span={4}
                    style={{ textAlign: "right", marginTop: "5px" }}
                    onClick={() => setTableModelOpen(!tableModelOpen)}
                  >
                    {productDetails.length !== 0 ? (
                      `${productDetails.length} Product`
                    ) : (
                      <></>
                    )}{" "}
                    <img alt="" height="22px" src={icon1} />
                  </Col>
                  <Col
                    onClick={() => setProductTableModelOpen(!tableModelOpen)}
                    span={5}
                    style={{ textAlign: "right", marginTop: "5px" }}
                  >
                    {productTable?.length ? `Has Product Table` : <></>}{" "}
                    <img alt="" height="22px" src={icon2} />
                  </Col>
                  <Col
                    span={4}
                    style={{ textAlign: "right", marginTop: "5px" }}
                    onClick={() => setIsModelOpen(!isModelOpen)}
                  >
                    {prosCons[0]?.cons?.length !== 0 ||
                      prosCons[0]?.pros?.length !== 0 ? (
                      `Has Pros`
                    ) : (
                      <></>
                    )}
                    <img alt="" height="22px" src={icon3} />
                  </Col>
                </Row>

                <div
                  id="editor"
                  style={{
                    border: "1px solid #de923b",
                    borderRadius: "6px",
                    height: "460px",
                    textTransform: "none",
                    overflow: "auto",
                    paddingBottom: "40px",
                  }}
                ></div>
              </Col>
              <Col span={22}>
                <Button
                  style={{
                    backgroundColor: "#de923b",
                    width: "250px",
                    height: "42px",
                    fontWight: 600,
                    fontSize: "20px",
                    marginTop: "20px",
                    borderRadius: "8px",
                  }}
                  loading={isFormLoading}
                  onClick={sumbitArticle}
                >
                  {editData.hasOwnProperty("_id") ? "Edit Publish" : "Publish"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Row gutter={[0, 8]}>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "black",
                }}
              // className="label-c"
              >
                Categories
              </span>
              <Col span={22} style={{ border: "1px solid #de923b" }}>
                <Tabs
                  defaultActiveKey="1"
                  onChange={callback}
                  style={{ paddingLeft: "20px" }}
                >
                  <TabPane tab="All Category" key="1">
                    <div>
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      White Egg
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      Brown Eggs
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      White Layer Chick
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      Brown Layer Chick
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      White Hen
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      Brown Hen
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "12px", marginRight: "10px" }}
                      />{" "}
                      Poultry Feed
                      <br />
                      <Checkbox
                        defaultChecked={false}
                        style={{ marginBottom: "30px", marginRight: "10px" }}
                      />{" "}
                      Manure
                    </div>
                  </TabPane>
                  <TabPane tab="Add Tags" key="2">
                    Content of Tab Pane 2
                  </TabPane>
                </Tabs>
              </Col>
              <Col span={22}>
                <span
                  className="label-c"
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    marginBottom: "20px",
                  }}
                >
                  Image
                </span>

                <Upload {...thumbnailProps} style={{ marginTop: "20px" }}>
                  <p className="ant-upload-drag-icon">
                    <FileAddOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Upload Image/Video/GIF .JPG/.PNG/MP3/MP4 /web.p
                  </p>
                  <Button
                    style={{
                      backgroundColor: "#de923b",
                      width: "100%",
                      height: "42px",
                      fontWight: 600,
                      fontSize: "20px",
                      marginTop: "20px",
                      borderRadius: "8px",
                    }}
                  >
                    Upload
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ProsCons
        prosCons={prosCons}
        setProsCons={setProsCons}
        isModelOpen={isModelOpen}
        editData={editData}
        setIsModelOpen={setIsModelOpen}
      />
      <ProductBox
        tableModelOpen={tableModelOpen}
        editData={editData}
        productDetails={productDetails}
        setTableModelOpen={setTableModelOpen}
        setproductDetail={setproductDetail}
      />
      <ProductTable
        tableModelOpen={ProductTableModelOpen}
        editData={editData}
        productDetails={productTable}
        setTableModelOpen={setProductTableModelOpen}
        setproductDetail={setproductTable}
      />
    </div>
  );
};

export default AddArticlesScreen;
