import React from "react";
import { Bar } from "react-chartjs-2";

export default function CommodityGraph({ data }) {
  return (
    <Bar
      data={{
        labels: data.data.labels,
        datasets: data.data.datasets,
      }}
      options={data.options}
    />
  );
}
