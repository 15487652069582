import { message } from 'antd'
import {
  activeCityForCityRequest,
  deActiveCityForCityRequest,
  getAllAvailableCities,
} from '../../RestServices/authService'
import {
  changeCity,
  citiesFailed,
  citiesLoading,
  citiesSuccessfull,
} from '../Actions/citiesActions'

export const getAllCities = (token) => async (dipsatch) => {
  dipsatch(citiesLoading())
  try {
    const response = await getAllAvailableCities(token)
    //citites fillter
    const { cities: RatesTable } = response
    let finalList = {}

    RatesTable.reduce((obj, item) => {
      if (!finalList.hasOwnProperty(item.division)) {
        finalList[item.division] = {
          division: item.division,
          province: item.province,
          cities: [item],
        }
      } else {
        finalList[item.division].cities = [
          ...finalList[item.division].cities,
          item,
        ]
      }
    })
    let provices = []
    for (let item of RatesTable) {
      if (provices.indexOf(item.province) == -1) {
        provices.push(item.province)
      }
    }
    let ArrayList = []
    for (let i in finalList) {
      ArrayList.push(finalList[i])
    }
    dipsatch(
      citiesSuccessfull({ cities: ArrayList, provices, allcities: RatesTable }),
    )
  } catch (err) {
    dipsatch(citiesFailed(err.message))
    if (err.response) {
      message.error(err.response.data.message)
    } else {
      message.error(err.message)
    }
  }
}

export const updateCityToactive =
  (id, data, token, cities, division, getCities) => async (dipsatch) => {
    dipsatch(citiesLoading())
    try {
      const response = await activeCityForCityRequest(id, data, token)
      //citites fillter
      const { city } = response.data
      const itemCity = cities.find((item) => item.division === city.division)
      let changedCity = itemCity.cities.find((item) => item._id === city._id)
      // let finalList = {};
      changedCity.activeForProduct = city.activeForProduct
      dipsatch(changeCity({ cities }))
      message.success('cities is added successfully')
      getCities && getCities()
    } catch (err) {
      dipsatch(citiesFailed(err.message))
      if (err.response) {
        message.error(err.response.data.message)
      } else {
        message.error(err.message)
      }
    }
  }

export const updateCityToInactive =
  (id, data, token, cities, division) => async (dipsatch) => {
    dipsatch(citiesLoading())
    try {
      const response = await deActiveCityForCityRequest(id, data, token)
      //citites fillter
      const { city } = response.data
      const itemCity = cities.find((item) => item.division === city.division)
      let changedCity = itemCity.cities.find((item) => item._id === city._id)
      // let finalList = {};
      changedCity.activeForProduct = city.activeForProduct
      dipsatch(changeCity({ cities }))
    } catch (err) {
      dipsatch(citiesFailed(err.message))
      if (err.response) {
        message.error(err.response.data.message)
      } else {
        message.error(err.message)
      }
    }
  }
