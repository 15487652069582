import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React from "react";
import { MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import CategoryDropDown from "../../utils/dropDown/CategoryDropDown";

const { Option } = Select;
const styles = {
  modal: {
    backgroundColor: "white",
  },
  submitButton: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    width: "100%",
    border: "none",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

const AddVideo = (props) => {
  const {
    isOpen,
    closeModal,
    inputHandler,
    submitForm,
    isFormLoading,
    title,
    videoUri,
    description,
    selectVideo,
    video,
    submitVideo,
    selectThumbnail,
    thumbnail,
    uploadedPercentage,
    selectedCategory,
    isNotShow,
    category,
    categoriesOptions,
    setCategory,
    tags,
    handleChangeTags,
  } = props;

  const uploadProps = {
    onRemove: () => {
      selectVideo(null);
      selectThumbnail(null);
    },
    beforeUpload: (vid) => {
      selectVideo(vid);
      return false;
    },
    fileList: video ? [video] : [],
    accept: "video/mp4",
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const thumbnailProps = {
    onRemove: () => selectThumbnail(null),
    beforeUpload: (image) => {
      selectThumbnail(image);
      return false;
    },
    fileList: thumbnail ? [thumbnail] : [],
    accept: "image/*",
  };
  return (
    <Modal
      bodyStyle={styles.modal}
      title={
        !isNotShow ? <h3>Upload Video</h3> : <h3>Edit Video information</h3>
      }
      visible={isOpen}
      closable={!isFormLoading}
      confirmLoading={true}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
    >
      <Form colon={false} layout="vertical" onSubmit={submitForm}>
        <Row type={"flex"} justify="space-around" align="middle">
          <Col
            style={{ padding: "10px", paddingLeft: "0px" }}
            span={!isNotShow ? 12 : 24}
          >
            {/* <Form.Item label="Video Title">
              <Input
                size="large"
                type="text"
                name="title"
                disabled={isFormLoading}
                value={title}
                onChange={inputHandler}
                placeholder="Enter Video Title"
              />
            </Form.Item> */}
          </Col>
          {!isNotShow && (
            <Col className="addvedio" style={{ paddings: "10px" }} span={24}>
              {" "}
              <Form.Item label="select category">
                <CategoryDropDown
                  category={category}
                  setCategory={setCategory}
                  categoriesOptions={categoriesOptions}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {/* <Form.Item label="Video description">
          <Input.TextArea
            size="large"
            disabled={isFormLoading}
            name="description"
            rows={4}
            value={description}
            onChange={(e) => inputHandler(e, "description")}
            placeholder="Some Video Description..."
          />
        </Form.Item> */}
        {/* <Form.Item label="Add Tags">
          <Select
            mode="tags"
            value={tags}
            style={{ width: "100%" }}
            placeholder="vedio Tags"
            onChange={handleChangeTags}
          ></Select>
        </Form.Item> */}

        <Form.Item label="Video Youtube Link">
          <Input
            size="large"
            disabled={isFormLoading}
            name="videoUri"
            value={videoUri}
            onChange={inputHandler}
            placeholder=" Video Iink..."
          />
        </Form.Item>
        {/* {!isNotShow && (
          <Form.Item label="upload video" required>
            <Upload {...uploadProps}>
              <Button disabled={isFormLoading}>
                <UploadOutlined /> Select Video
              </Button>
            </Upload>
          </Form.Item>
        )}
        {!isNotShow && (
          <Form.Item label="upload Thumbnail" required>
            <Upload {...thumbnailProps}>
              <Button disabled={isFormLoading}>
                <UploadOutlined /> Add Thumbnail
              </Button>
              {thumbnail && (
                <div>
                  <img
                    style={{ width: "150px", padding: "3px" }}
                    src={URL.createObjectURL(thumbnail)}
                    alt="Thumbnail"
                  />
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
        {isFormLoading && (
          <Form.Item style={{ justifyContent: "center", display: "flex" }}>
            <Progress type="circle" percent={uploadedPercentage} />
          </Form.Item>
        )} */}
        <Form.Item wrapperCol={{ span: 24 }}>
          <div style={styles.submitButtonContainer}>
            <Button
              size="large"
              loading={isFormLoading}
              style={styles.submitButton}
              htmlType="submit"
              type="primary"
              onClick={submitVideo}
            >
              Upload
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddVideo;
