import { Button, Col, Dropdown, Input, Menu, Row } from 'antd';
import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { MAIN_BACKGROUND_COLOR } from '../../styleConstants';

export default function Calender(props) {
  const {
    handleSelect,
    PickDate,
    dateSelection,
    datesBetween,
    dropdrownshow,
    setdropdrownshow,
    setdateSelection,
  } = props;
  // console.log(props, 'handleSelectdf');
  const menu = (
    <Menu>
      <Menu.Item key={new Date().getTime()}>
        <DateRangePicker
          rangeColors={[MAIN_BACKGROUND_COLOR]}
          ranges={[dateSelection]}
          onChange={handleSelect}
        />
      </Menu.Item>
      <Menu.Item key={new Date().getTime() + '13324'}>
        <Row>
          <Col push={14} span={5}>
            <Button
              onClick={() => {
                PickDate();
              }}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Select
            </Button>
          </Col>
          <Col span={5}>
            <Button
              onClick={() => {
                setdateSelection({ ...dateSelection, ...datesBetween });
              }}
              style={{ width: '100%' }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row style={{ zIndex: 23232323232332 }}>
      <Col>
        <Dropdown
          onVisibleChange={setdropdrownshow}
          visible={dropdrownshow}
          overlay={menu}
        >
          <Row justify='space-around'>
            <Col span={11}>
              <Input
                type='primary'
                value={new Date(
                  dateSelection?.startDate
                    ? dateSelection?.startDate
                    : new Date()
                ).toDateString()}
                placeholder='start Date'
              />
            </Col>
            <Col span={11}>
              <Input
                type='primary'
                value={new Date(
                  dateSelection?.endDate ? dateSelection?.endDate : new Date()
                ).toDateString()}
                placeholder='End Date'
              />
            </Col>
          </Row>
        </Dropdown>
      </Col>
    </Row>
  );
}
