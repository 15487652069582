import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Spin, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createNotification,
  getAdminAlerts,
} from "../../RestServices/authService";
import CustomPagination from "../Pagination";

const Tags = () => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [page, setPage] = useState("");
  const [tags, setTags] = useState("");
  const [title, setTitle] = useState("");
  const [appLink, setAppLink] = useState("");
  const { token } = useSelector((state) => state.Authentication.user);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAdminAlertFunc({ page: 1 });
  }, [loading]);

  const [alerts, setAlerts] = useState(null);

  const getAdminAlertFunc = async ({ page }) => {
    try {
      const response = await getAdminAlerts({ page }, token);
      console.log(response);
      setAlerts(response.alerts);
    } catch (err) {
      console.log(err);
    }
  };

  const onPageChange = (page) => {
    getAdminAlertFunc({ page });
  };
  const onChangeVersion = (value) => {
    console.log(`selected ${value}`);
    setPage(value);
  };

  const handleSubmit = () => {
    let data = {
      page,
      tags,
    };
    createNotification(data, token, setLoading);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
  );

  const tableColumns = [
    {
      key: "senderName",
      title: "sended By",
      dataIndex: "senderName",
    },

    {
      key: "tags",
      title: "tags Type",
      dataIndex: "tags",
    },

    {
      key: "title",
      title: "Title ",
      dataIndex: "title",
    },

    {
      key: "notificationType",
      title: "Notification Type",
      dataIndex: "notificationType",
    },
    {
      key: "applink",
      title: "App Link",
      dataIndex: "applink",
    },
    {
      title: "Assign Task",
      key: "_id",
      dataIndex: "_id",
      render: (text, record) => {
        return (
          <Button type={"primary"} onClick={() => onResendPrepare(record)}>
            Resend
          </Button>
        );
      },
    },
  ];

  const onResendPrepare = (record) => {
    setTags(record.tags);
    setTitle(record.title);
  };
  return (
    <Row justify="center">
      {/* // 2 dropdowns --> new page, release
            1 title field
            tags    
    */}
      <Modal
        width="100%"
        title="Sended Notification"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            current={alerts?.page}
            total={alerts?.totalPages}
            onPageChange={onPageChange}
          />
        </div>
        <Table
          dataSource={alerts?.docs}
          pagination={false}
          columns={tableColumns}
        />
      </Modal>

      <Col span={12}>
        <Row justify="center">
          <Col push={20} span={4}>
            <Button type="primary" onClick={showModal}>
              List Tags
            </Button>
          </Col>
          <Col span={24} style={{ color: "black", textAlign: "left" }}>
            <Row style={{ marginTop: "10px" }}>
              <Col span={4}>
                <span>Select Page</span>
              </Col>
              {/* // new page, release */}
              <Col span={20}>
                <Select
                  showSearch
                  style={{ width: "90%" }}
                  placeholder="Select Page"
                  optionFilterProp="children"
                  onChange={onChangeVersion}
                  value={page}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  <Option value="NEW_VERSION_RELEASED">
                    NEW_VERSION_RELEASED
                  </Option>
                  <Option value="GENERAL_ANNOUNCEMENT">
                    GENERAL_ANNOUNCEMENT
                  </Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ color: "black", textAlign: "left" }}>
            <Row style={{ marginTop: "10px" }}>
              <Col span={4}>
                <span>Tags</span>
              </Col>
              <Col span={20}>
                <TextArea
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  rows={4}
                  placeholder={`<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        `}
                  style={{ width: "90%" }}
                />
              </Col>
            </Row>
          </Col>

          <Col span={20} style={{ color: "black", textAlign: "left" }}>
            <Row justify="end" style={{ marginTop: "10px" }}>
              <Col span={4}>
                <Button type="primary" onClick={() => handleSubmit()}>
                  {loading ? <Spin indicator={antIcon} /> : "Submit"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Tags;
