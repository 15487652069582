import { Button, Card, Col, Form, Row, Select, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPosters } from "../../RestServices/postingService";
import { posterCategories, posterFor } from "../../shared";
import AddAds from "./addAds";
import "./body.css";
import TableComponent from "./TableComponent";

const { Option } = Select;

const Body = ({ item }) => {
  const [displayType, setDisplayType] = useState();
  const [category, setCategory] = useState("");

  const [poster, setPoster] = useState([]);

  const [EditItem, setEditItem] = useState(null);

  const [edit, setEdit] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [editModal, setEditModal] = useState(false);

  const { token } = useSelector((state) => state.Authentication.user);

  useEffect(() => {
    (async () => {
      const response = await getPosters({ displayType, category }, token);
      if (response.posters) {
        const poster = response.posters.map((item, index) => ({
          ...item,
          key: item._id,
          sr: index,
        }));
        setPoster(poster);
      }
    })();
  }, [isModalVisible, category, edit, editModal, displayType]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { Title } = Typography;
  return (
    <>
      <AddAds
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleOk={handleOk}
        item={null}
        handleCancel={handleCancel}
      />

      <AddAds
        isModalVisible={editModal}
        showModal={() => setEditModal(true)}
        handleOk={handleOk}
        item={EditItem}
        handleCancel={() => {
          setEditModal(false);
          setEditItem(null);
        }}
      />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <div className="flex-between">
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={6} lg={5} xl={5}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Select Category</Title>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Category"
                          optionFilterProp="children"
                          onChange={setCategory}
                          filterOption={(input, option) =>
                            option.children
                              ?.toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          }
                        >
                          {posterCategories.map((value) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={5} xl={5}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Display For</Title>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Display for"
                          optionFilterProp="children"
                          onChange={setDisplayType}
                          filterOption={(input, option) =>
                            option.children
                              ?.toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          }
                        >
                          {posterFor.map((value) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Button type="primary" onClick={() => showModal()}>
                    Add Ads
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
          <TableComponent
                setEditModal={(cond, item) => {
                  setEditModal(cond);
                  setEditItem(item);
                }}
                edit={edit}
                setEdit={setEdit}
                poster={poster}
              />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Body;
