import React from "react";
import { List, Avatar, Button, Tag, message } from "antd";
import { updateMandiGuruRequestStatus } from "../../RestServices/authService";
class RenderMandiGuru extends React.Component {
  state = {
    processed: false,
    approvedLoading: false,
    declinedLoading: false,
  };
  updateMandiGuruStatus = async (evt) => {
    const { name } = evt.target;
    const { item, token } = this.props;
    try {
      this.setState({ [name?.toLowerCase() + "Loading"]: true });
      await updateMandiGuruRequestStatus(
        {
          customerId: item.customerId._id,
          requestStatus: name,
        },
        token,
      );
      this.setState({
        [name?.toLowerCase() + "Loading"]: false,
        processed: true,
      });
      message.success(`${item.customerId.name} ${name} as Mandi Guru!`);
    } catch (err) {
      this.setState({
        [name?.toLowerCase() + "Loading"]: false,
      });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  render() {
    const { processed, approvedLoading, declinedLoading } = this.state;
    const { item } = this.props;
    if (processed) {
      return null;
    }
    return (
      <List.Item key={item._id}>
        <List.Item.Meta
          avatar={<Avatar src={item.customerId.profileImage} />}
          title={item.customerId.name}
          description={
            <div>
              <div>{item.customerId.email}</div>
              <Tag>{item.registeredAs}</Tag>
              {item.customerId.mandiGuruCity && (
                <Tag>{item.customerId.mandiGuruCity}</Tag>
              )}
              <Tag>{item.customerId.phoneNumber}</Tag>
            </div>
          }
        />
        <div></div>
        <div>
          <Button
            loading={approvedLoading}
            disabled={declinedLoading}
            onClick={this.updateMandiGuruStatus}
            name="APPROVED"
            shape="round"
            type="primary"
          >
            APPROVE
          </Button>{" "}
          <Button
            loading={declinedLoading}
            disabled={approvedLoading}
            onClick={this.updateMandiGuruStatus}
            name="DECLINED"
            shape="round"
            type="danger"
          >
            DECLINE
          </Button>
        </div>
      </List.Item>
    );
  }
}

export default RenderMandiGuru;
