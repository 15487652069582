import { Button, Col, DatePicker, Row } from 'antd';
import React from 'react';
import { phoneStatuses } from '../../../shared';
import { MAIN_BACKGROUND_COLOR } from '../../../styleConstants';

export default function Download({ phoneList, onUpdateContactStatus }) {
  return (
    <Col
      lg={8}
      md={16}
      sm={20}
      xs={20}
      style={{
        border: '1px black solid',
        overflowY: 'auto',
        overflowX: 'hidden',

        margin: '4px',
        borderRadius: '5px',
      }}
    >
      <Row
        style={{
          borderBottom: '1px black solid',
          borderRadius: '5px',
        }}
      >
        <Col style={{ padding: '10px', backgroundColor: 'brown' }} span={8}>
          <DatePicker />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {phoneList?.length}
          </div>
        </Col>
        <Col span={10}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h3>Download</h3>
          </div>
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          span={6}
        >
          Total: {phoneList?.length}
        </Col>
      </Row>

      <Row
        gutter={[12, 12]}
        justify='center'
        align='top'
        style={{ height: '100vh', color: 'black', padding: '20px' }}
      >
        <Col span={24}>
          {phoneList?.map((value, index) => {
            return (
              <Row
                key={value.phoneNumber}
                gutter={[12, 12]}
                justify='center'
                align='middle'
                style={{ color: 'black', marginTop: '10px' }}
              >
                <Col span={2}>{index + 1}</Col>
                <Col span={9}>{value.phoneNumber}</Col>
                <Col span={7}>
                  {value.createdAt && new Date(value.createdAt).toDateString()}
                </Col>
                <Col span={10}>
                  {!value?.blockedReason ? (
                    <Button
                      onClick={() =>
                        onUpdateContactStatus({
                          _id: value._id,
                          phoneNumber: value.phoneNumber,
                          taskId: value.taskId,
                          status: phoneStatuses.CALLED_NUMBER,
                        })
                      }
                      type={'primary'}
                      style={{ borderRadius: '50px' }}
                    >
                      Ok
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        onUpdateContactStatus({
                          _id: value._id,
                          taskId: value.taskId,
                          phoneNumber: value.blockedReason,
                          status: phoneStatuses.CALLED_NUMBER,
                        })
                      }
                      type={'primary'}
                      style={{ borderRadius: '50px' }}
                    >
                      {value.blockedReason}
                    </Button>
                  )}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
}
