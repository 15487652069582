import React, { useState } from "react";
import "./drop-down.css";
import { Select } from "antd";
import { useSelector } from "react-redux";
const { Option } = Select;

const CityDropDown = ({ city, onChangeCity }) => {
  const { allcities } = useSelector((state) => state.Cities);
  return (
    <Select
      showSearch
      style={{width: "100%"}}
      placeholder="Select city"
      optionFilterProp="children"
      onChange={onChangeCity}
      value={city ? city : "select city"}
    >
      {allcities &&
        [{ name: "" }, ...allcities].map((el) => {
          return (
            <Option value={el.name}>
              {el.name ? el.name : "All Cities"}
            </Option>
          );
        })}
    </Select>
  );
};

export default CityDropDown;

//   <select value={City} onChange={selectCity} className="dropDown">
// {city.map((val) => {
//   return (
//     <option key={val.id} value={val.Value} className="dropDown-list">
//       {val.Value}
//     </option>
//   );
// })}
//   </select>
