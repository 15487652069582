import React from "react";
import "./body.css";

const Body = () =>{
    return(
        <>
        <h1 className="heading">News Component</h1>
        </>
    );
};

export default Body;