import {
  Button,
  Col,
  Form,
  Input,
  DatePicker,
  message,
  Row,
  Table,
  Card,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  createAdminOrSubAdmin,
  createTask,
  getTaskUserIdAndMonth,
  getAdminOrSubAdmins,
} from "../../RestServices/authService";
import { PermissionEnum } from "../../shared";
import { fontColor, MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import {
  getCommisionList,
  getCommissionForAllAdmin,
} from "../../RestServices/postingService";
const styles = {
  layout: {
    minHeight: "100vh",
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: "bold",
  },
  content: {
    color: fontColor,
    backgroundColor: "white",
    margin: "20px",
    overflow: "scroll",
  },
  logo: {
    height: "200px",
    width: "190px",
  },
  menuToggler: {
    color: fontColor,
    fontSize: "30px",
    marginLeft: "5px",
    marginTop: "15px",
  },

  tableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  addUserButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addUserButton: {
    marginRight: "20px",
    backgroundColor: "#1f910e",
    color: "white",
  },
};
const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Role",
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
class AssignTargetUsers extends React.Component {
  state = {
    collapsed: false,
    input: null,
    isFormLoading: false,
    customers: [],
    targetId: null,
    name: "",
    userId: "",
    downloads: 0,
    reviews: 0,
    posts: 0,
    email: "",
    createdTask: [],
    password: "",
    loading: false,
    visible: false,
    historyModal: false,
    historyDetails: [],

    permissions: [],
    type: "ADMIN",
    city: "",
    phoneNumber: "",
    selectedRowKeys: [],
    selectedUser: {},
    creatTaskLoading: false,
    selectedDate: moment().format("MMMM YYYY"),
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  onChangeDatePicker = (date) => {
    this.setState({ selectedDate: date.format("MMMM YYYY") }, () =>
      this.getAdminOrSubAdminsInCom(),
    );
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onChangePermission = (permissions) => {
    this.setState({ permissions });
  };
  toggleSider = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  inputEvent = (e) => {
    this.setState({ input: e.target.value });
    console.log(e.target.value);
  };
  inputHandler = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };
  openAddUserModal = (id) => {
    this.setState({ visible: true, userId: id });
  };

  resetForm = () =>
    this.setState({
      name: "",
      email: "",
      password: "",
      city: "",
      phoneNumber: "",
      permissions: [],
      userId: "",
      createdTask: [],
      downloads: 0,
      reviews: 0,
      posts: 0,
    });
  submitForm = async (evt) => {
    evt.preventDefault();
    const { name, email, password, phoneNumber, city, type, permissions } =
      this.state;
    if (!name || !email || !password || !phoneNumber || !city || !type) {
      return message.error("Please fill all value ");
    }
    // let permissions = [];
    // if (type === "ADMIN") {
    if (!permissions.length) {
      return message.error("Please check the some permission");
    }
    // else {
    // permissions = [...permisstions];
    // }
    // } else {
    // permissions = ["AllResources:*"];
    // }
    this.setState({ isFormLoading: true });
    try {
      const data = await createAdminOrSubAdmin(
        { ...this.state, permissions },
        this.props.Auth.user.token,
      );

      const customer = {
        name: data.customer.name,
        _id: data.customer._id,
        key: data.customer.key,

        email: data.customer.email,
        updatedAt: new Date().toISOString(),
        type: data.customer.profileDetails.type,
        phoneNumber: data.customer.phoneNumber,
      };
      this.setState({
        isFormLoading: false,
        customers: [customer, ...this.state.customers],
      });
      message.success("User " + data.customer.name + " Created!");
      this.resetForm();
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  getAdminOrSubAdminsInCom = async () => {
    try {
      let data = await getAdminOrSubAdmins(this.props.Auth.user.token);
      // console.log(data, "data");
      let customers = [];

      try {
        const response = await getTaskUserIdAndMonth(
          {
            month: this.state.selectedDate,
          },
          this.props.Auth.user.token,
        );

        const dataCommission = await getCommissionForAllAdmin(
          {
            month: this.state.selectedDate,
          },
          this.props.Auth.user.token,
        );

        const { tasks } = response;
        data.customers.forEach((customer) => {
          if (
            customer.profileDetails.permissions.includes(
              PermissionEnum["Crm_System:*"],
            )
          ) {
            console.log(customer, "asdf");
            const commissionOfUser = dataCommission.commission.find(
              (com) => com._id === customer._id,
            );

            const found = tasks?.find((task) => task.userId === customer._id);
            customers.push({
              name: customer.name,
              email: customer.email,
              key: customer._id,
              _id: customer._id,
              commissionOfUser: commissionOfUser
                ? commissionOfUser.sumQuantity.toFixed(2)
                : 0,
              type: customer.profileDetails.type,
              updatedAt: customer.updatedAt,
              phoneNumber: customer.phoneNumber,
              taskFound: found ? true : false,
              downloads:
                found && commissionOfUser
                  ? `${commissionOfUser?.downloadCount} / ${found.downloads}`
                  : "N/A",
              reviews:
                found && commissionOfUser
                  ? `${commissionOfUser?.reviewCount} / ${found.reviews}`
                  : "N/A",
              posts:
                found && commissionOfUser
                  ? `${commissionOfUser.allCount -
                  commissionOfUser.reviewCount -
                  commissionOfUser.downloadCount
                  } / ${found.posts}`
                  : "N/A",
            });
          }
        });
      } catch (err) {
        console.log(err);
      }

      this.setState({ customers });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  componentDidMount() {
    this.getAdminOrSubAdminsInCom();
  }

  tableColumns = [
    {
      key: "name",
      title: "User Name",
      // dataIndex: 'name',
      render: (record) => (
        <div>
          <p>{record.name}</p>
          <Button
            type={"primary"}
            onClick={() => {
              this.setState({
                historyModal: true,
              });
              this.getDataOfCommission(record._id);
            }}
          >
            See task Details
          </Button>
        </div>
      ),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "phoneNumber",
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      key: "downloads",
      title: "Downloads",
      dataIndex: "downloads",
    },
    {
      key: "reviews",
      title: "Reviews",
      dataIndex: "reviews",
    },
    {
      key: "posts",
      title: "Posts",
      dataIndex: "posts",
    },
    {
      key: "commissionOfUser",
      title: "Month Commission",
      dataIndex: "commissionOfUser",
    },

    {
      title: "Assign Task",
      key: "_id",
      dataIndex: "_id",
      render: (text, record) =>
        !record.taskFound ? (
          <Button type={"primary"} onClick={() => this.openAddUserModal(text)}>
            Assign Tasks
          </Button>
        ) : (
          <span>Task Assigned</span>
        ),
    },

    {
      key: "updatedAt",
      title: "Last Seen",
      dataIndex: "updatedAt",
      render: (text) => (
        <span type={"primary"}>{moment(text).format("lll")}</span>
      ),
    },
  ];

  commissionColumns = [
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
    },
    {
      key: "amountGain",
      title: "Commission Amount",
      dataIndex: "amountGain",
    },
    {
      key: "createdBy",
      title: "User Target",
      dataIndex: "createdBy",
    },
    {
      key: "createdAt",
      title: "Creation Date",
      dataIndex: "createdAt",
      render: (text) => (
        <span type={"primary"}>{moment(text).format("lll")}</span>
      ),
    },
  ];

  rowSelection = {
    onChange: (selectedRowKeys) => {
      const [id] = selectedRowKeys;
      const selectedUser = this.state.customers.find((user) => user._id === id);
      this.setState({
        selectedRowKeys,
        selectedUser: selectedUser,
      });
      // this.openUserShowDetailModal();
    },
  };

  onCreateTarget = async () => {
    try {
      const { downloads, posts, reviews } = this.state;

      this.setState({ creatTaskLoading: true });

      const response = await createTask(
        {
          userId: this.state.userId,
          month: moment().format("MMMM YYYY"),
          downloads,
          reviews,
          posts,
        },
        this.props.Auth.user.token,
      );

      this.getAdminOrSubAdminsInCom();
      this.handleCancel();
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ creatTaskLoading: false });
    }
  };

  getDataOfCommission = async (id) => {
    try {
      const { commission } = await getCommisionList(
        {
          month: this.state.selectedDate,
          referenceOf: id,
        },
        this.props.Auth.user.token,
      );
      this.setState({
        historyDetails: commission,
      });
    } catch (err) { }
  };

  render() {
    const { customers } = this.state;

    return (
      <div style={styles.content}>
        <Modal
          visible={this.state.visible}
          title="Assign Targets"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form layout={"vertical"} name="control-hooks">
            <Form.Item
              required
              label="Assign Download"
              rules={[{ required: true }]}
            >
              <Input
                type="number"
                onChange={this.inputHandler}
                name={"downloads"}
              />
            </Form.Item>
            <Form.Item
              required
              label="Assign Posts"
              rules={[{ required: true }]}
            >
              <Input type="number" onChange={this.inputHandler} name="posts" />
            </Form.Item>
            <Form.Item
              required
              label="Assign Reviews"
              rules={[{ required: true }]}
            >
              <Input
                type="number"
                onChange={this.inputHandler}
                name={"reviews"}
              />
            </Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  loading={this.state.creatTaskLoading}
                  onClick={this.onCreateTarget}
                  type={"primary"}
                >
                  Assign Targets
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          width={"90%"}
          visible={this.state.historyModal}
          title="User History"
          onOk={() =>
            this.setState({ historyModal: false, historyDetails: [] })
          }
          onCancel={() =>
            this.setState({ historyModal: false, historyDetails: [] })
          }
          footer={null}
        >
          <Table
            dataSource={this.state.historyDetails}
            pagination={false}
            columns={this.commissionColumns}
            rowKey={(record) => record?.key}
          />
        </Modal>
        <Card>
          <div style={{ marginBottom: "10px" }}>
            <Row justify="end" gutter={[12, 12]}>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <h3 style={{ color: "black" }}>Month Filter </h3>
                <DatePicker
                  value={moment(this.state.selectedDate)}
                  onChange={this.onChangeDatePicker}
                  picker="month"
                />
              </Col>
            </Row>
          </div>

          <Table
            dataSource={customers}
            pagination={false}
            columns={this.tableColumns}
            rowKey={(record) => record?.key}
          />
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(AssignTargetUsers);
