import { Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./dropdown.css";
const { Option } = Select;

const ProvinceDropDown = ({ selectProvince, selectedProvince }) => {
  const { provinces } = useSelector((state) => state.Cities);
  // const provinces = ["punjab", "sind"];
  // const selectProvince = (event) => {
  //   setProvince(event.target.value);
  //   console.log(event.target.value);
  // };
  return (
    <>
      {/* <div className="province"> */}

      <Select
        value={selectedProvince ? selectedProvince : ""}
        onChange={(e) => selectProvince(e)}
        // className="drop-down"
        style={{width: "100%"}}
      >
        {provinces &&
          provinces.reverse().map((val, index) => {
            return (
              <Option key={index} value={val}>
                {val.split("_").join(" ")?.toLowerCase()}
              </Option>
            );
          })}
      </Select>

      {/* </div> */}
    </>
  );
};

export default ProvinceDropDown;
