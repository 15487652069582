/* eslint-disable react-hooks/exhaustive-deps */
import { PayCircleFilled } from '@ant-design/icons';
import { Avatar, Button, Col, Image, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCustomers } from '../../../RestServices/authService';
import { getBiddings } from '../../../RestServices/postingService';
import AddUser from '../../AppUsers/AddUser';
import Message from '../../message/Message';
import PaySlip from '../../payslip/Payslip';

export default function PostBidDetailsModal({
  isModalVisible,
  handleOk,
  selectedBit,
  handleCancel,
}) {
  const [paySlip, setPaySlip] = useState(false);
  const [paySlipBid, setPaySlipBid] = useState(false);
  const { token } = useSelector((state) => state.Authentication.user);

  const [biddingGet, setBiddingGet] = useState([]);

  const [userDetailModal, setUserDetailModal] = useState(false);
  const [userList, setUserList] = useState([null, null]);
  useEffect(() => {
    (async () => {
      try {
        const userList = [null, null];
        const { customers } = await getCustomers(token, {
          userId: selectedBit.customerId,
        });
        if (customers) {
          userList[0] = customers.docs[0];
        }
        const { customers: ownerCustomer } = await getCustomers(token, {
          userId: selectedBit.postOwnerId,
        });
        if (ownerCustomer) {
          userList[1] = ownerCustomer.docs[0];
        }
        setUserList(userList);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [isModalVisible, paySlip, token]);

  useEffect(() => {
    (async () => {
      if (selectedBit) {
        try {
          const response = await getBiddings(
            {
              postOwnerId: selectedBit.postOwnerId,
              customerId: selectedBit.customerId,
              postId: selectedBit.postId,
            },
            token,
          );
          setBiddingGet(response.biddings.docs);
        } catch (err) {
          setBiddingGet([]);
        }
      }
    })();
  }, [selectedBit, token]);
  const [userToShowIndex, setUserToShowIndex] = useState(0);
  return (
    <Modal
      title="Details and Cards"
      visible={isModalVisible}
      onOk={handleOk}
      width="80%"
      userDetailModal
      onCancel={handleCancel}
    >
      <PaySlip
        paySlipBid={paySlipBid}
        visible={paySlip}
        handleOk={() => {
          setPaySlip(!paySlip);
          handleCancel();
        }}
        handleCancel={() => {
          setPaySlip(!paySlip);
        }}
      />
      <AddUser
        isOpen={userDetailModal}
        closeModal={() => setUserDetailModal(false)}
        submitForm={() => setUserDetailModal(false)}
        isFormLoading={false}
        selectedUser={userList[userToShowIndex]}
      />
      <Row justify="center" align="middle">
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: '20px',
            background: 'yellow',
          }}
          span={16}
        >
          <div
            style={{
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              height: '100px',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
            className=" primaryColor"
          >
            <h4 className="primaryColor">Post Detail</h4>
          </div>

          {selectedBit ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '10px',
              }}
              className="primaryColor"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    width: '150px',
                  }}
                >
                  Id
                </p>
                <p style={{ textDecorationStyle: 'capitalize' }}>
                  {selectedBit?.postDetails?.autoId}
                </p>
              </div>
              {Object.keys(selectedBit?.postDetails?.postDetails).map((key) => {
                if (key !== '_id')
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        textDecoration: 'capitalize',
                      }}
                      key={key}
                    >
                      <p
                        style={{
                          textTransform: 'capitalize',
                          width: '150px',
                        }}
                      >
                        {key.replace('status', 'Post Type')}
                      </p>
                      <p
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {selectedBit.postDetails.postDetails[key]}
                      </p>
                    </div>
                  );
              })}
            </div>
          ) : (
            <></>
          )}
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: '20px',
            background: 'yellow',
          }}
          span={16}
        >
          <div
            style={{
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              height: '100px',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
            onClick={() => {
              setUserDetailModal(true);
              setUserToShowIndex(1);
            }}
            className=" primaryColor"
          >
            <Avatar
              src={
                userList[1]?.profileImage ? (
                  <Image src={userList[1]?.profileImage} alt="profile" />
                ) : (
                  'https://via.placeholder.com/100'
                )
              }
              shape="circle"
            />
            <h4 className="primaryColor">{userList[1]?.name}</h4>
            <p>(Seller)</p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
            }}
            className="primaryColor"
          >
            <h2> Order Number</h2>
            <h2> {selectedBit?._id}</h2>

            <h2> Order Detail</h2>
            <h1>PKR {selectedBit?.offeredPrice}/-</h1>
          </div>

          <div
            style={{
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              height: '100px',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
            onClick={() => {
              setUserDetailModal(true);
              setUserToShowIndex(0);
            }}
            className=" primaryColor"
          >
            <Avatar
              src={
                userList[0]?.profileImage ? (
                  <Image src={userList[0]?.profileImage} alt="profile" />
                ) : (
                  'https://via.placeholder.com/100'
                )
              }
              shape="circle"
            />
            <h4 className="primaryColor">{userList[0]?.name}</h4>
            <p>(Buyer)</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            <Col
              span={24}
              style={{
                border: '1px solid black',
                padding: '10px',
                background: 'white',
                height: '170px',
              }}
            >
              <div
                style={{ background: 'white', cursor: 'pointer' }}
                onClick={() => {
                  setUserDetailModal(true);
                  setUserToShowIndex(1);
                }}
                className="blockOrderDashboardStat primaryColor"
              >
                <Avatar
                  src={
                    userList[1]?.profileImage ? (
                      <Image src={userList[1]?.profileImage} alt="profile" />
                    ) : (
                      'https://via.placeholder.com/100'
                    )
                  }
                  shape="circle"
                />
                <h4 className="primaryColor">{userList[1]?.name}</h4>
                <p>(Seller)</p>
              </div>
              {selectedBit?.paymentStatusOwner === 'PAID' ? (
                <Button>
                  Paid
                  <PayCircleFilled style={{ marginLeft: '10px' }} />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setPaySlip(!paySlip);
                    setPaySlipBid({ user: userList[0], bid: selectedBit });
                  }}
                  type="primary"
                >
                  Custom Pay slip
                  <PayCircleFilled style={{ marginLeft: '10px' }} />
                </Button>
              )}
            </Col>
            <Col
              span={24}
              style={{
                border: '1px solid black',
                padding: '10px',
                background: 'white',
              }}
            >
              <div
                style={{
                  background: 'white',
                  height: '300px',
                  overflow: 'auto',
                }}
                className=" primaryColor"
              >
                <h4 className="primaryColor">Message from seller</h4>
                <Message userId={userList[1]?._id} biddingGet={biddingGet} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col
              span={24}
              style={{
                border: '1px solid black',
                padding: '10px',
                background: 'white',
                height: '170px',
              }}
            >
              <div
                style={{ background: 'white', cursor: 'pointer' }}
                className="blockOrderDashboardStat primaryColor"
                onClick={() => {
                  setUserDetailModal(true);
                  setUserToShowIndex(0);
                }}
              >
                <Avatar
                  src={
                    userList[0]?.profileImage ? (
                      <Image src={userList[0]?.profileImage} alt="profile" />
                    ) : (
                      'https://via.placeholder.com/100'
                    )
                  }
                  shape="circle"
                />
                <h4 className="primaryColor">{userList[0]?.name}</h4>
                <p>(Buyer)</p>
              </div>
              {selectedBit?.paymentStatusClient === 'PAID' ? (
                <Button>
                  Paid
                  <PayCircleFilled style={{ marginLeft: '10px' }} />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setPaySlip(!paySlip);
                    setPaySlipBid({ user: userList[1], bid: selectedBit });
                  }}
                  type="primary"
                >
                  Custom Pay slip
                  <PayCircleFilled style={{ marginLeft: '10px' }} />
                </Button>
              )}
            </Col>

            <Col
              span={24}
              style={{
                border: '1px solid black',
                padding: '10px',
                background: 'white',
              }}
            >
              <div
                style={{ background: 'white', height: '300px' }}
                className=" primaryColor"
              >
                <h4 className="primaryColor">Message from buyer</h4>
                <Message userId={userList[0]?._id} biddingGet={biddingGet} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
