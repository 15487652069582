import { MAIN_BACKGROUND_COLOR } from "../../styleConstants";


export const ModalStyle = {
  okCol: {
    height: "60px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  cancelCol: {
    height: "60px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  okButton: {
    width: "80%",
    height: "40px",
    background: MAIN_BACKGROUND_COLOR,
  },
  cancelButton: {
    width: "80%",
    background: "white",

    height: "40px",
  },
};
