import React from "react";
import { Button, DatePicker, AutoComplete, message, Card } from "antd";
import Table from "../../Table";
import EggRageModal from "./EggRatesModal";
import Pagination from "../../Pagination";
import { citiesForEgg } from "../../../cites";
import { getRates } from "../../../RestServices/postingService";
import { connect } from "react-redux";
const styles = {
  addRateButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addRateButton: {
    marginRight: "20px",
    backgroundColor: "#1f910e",
    color: "white",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "5px",
  },
  filterContainer: {
    display: "flex",
    padding: "0px 20px",
  },
  filterDatePicker: {
    width: "150px",
    marginRight: "5px",
  },
  filterCity: {
    width: "150px",
  },
};
class EggRates extends React.Component {
  state = {
    isModalOpen: false,
    isLoading: false,
    currentPage: 1,
    totalPages: 0,
    selectedDate: null,
    selectedCity: null,
    rates: [],
  };
  columns = [
    { Header: "City", accessor: "city" },
    { Header: "Date", accessor: "date" },
    { Header: "Cage Rate", accessor: "cageRate" },
    { Header: "Floor Rate", accessor: "floorRate" },
  ];
  filterOptions = (inputValue, option) => {
    return (
      option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !==
      -1
    );
  };
  onCityChange = (value) => {
    if (value === "") {
      this.setState({ selectedCity: null }, () =>
        this.getRates({
          city: this.state.selectedCity,
          date: this.state.selectedDate,
        })
      );
    }
  };
  selecteCity = (city) => {
    this.setState({ selectedCity: city }, () =>
      this.getRates({
        city: this.state.selectedCity,
        date: this.state.selectedDate,
      })
    );
  };
  onDateChange = (moment) =>
    this.setState({ selectedDate: moment ? moment.toISOString() : null }, () =>
      this.getRates({
        city: this.state.selectedCity,
        date: this.state.selectedDate,
      })
    );

  onPageChange = (pageNo) =>
    this.setState({ currentPage: pageNo }, () =>
      this.getRates({
        city: this.state.selectedCity,
        date: this.state.selectedDate,
        pageNo: pageNo,
      })
    );
  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  getRates = async ({ city, date, pageNo }) => {
    try {
      this.setState({ rates: [] });
      const data = await getRates(
        { type: "EGG", date, city, pageNo },
        this.props.Auth.user.token
      );
      const rates = data.rates.docs.map((rate) => ({
        city: rate.city,
        date: new Date(rate.createdAt).toLocaleDateString(),
        floorRate: rate.rateDetails.floorRate,
        cageRate: rate.rateDetails.cageRate,
      }));
      this.setState({
        rates,
        totalPages: data.rates.totalDocs,
        currentPage: data.rates.page,
      });
    } catch (err) {
      this.setState({ rates: [] });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  componentDidMount() {
    this.getRates({});
  }

  render() {
    const { isModalOpen, totalPages, currentPage, rates } = this.state;
    return (
      <div>
        <Card>
          <div className="flex-between">
            <div style={styles.filterContainer}>
              <DatePicker
                onChange={this.onDateChange}
                style={styles.filterDatePicker}
                placeholder="Filter By Date"
              />
              <AutoComplete
                style={styles.filterCity}
                onSelect={this.selecteCity}
                dataSource={citiesForEgg}
                placeholder="Filter By City"
                onChange={this.onCityChange}
                filterOption={this.filterOptions}
              />
            </div>
            <div style={styles.addRateButtonContainer}>
              <Button
                size="large"
                onClick={this.openModal}
                style={styles.addRateButton}
              >
                Add Egg Rate
              </Button>
            </div>
          </div>
        </Card>
        <div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Card>
              <Table data={rates} columns={this.columns} />
            </Card>
          </div>
        </div>
        <div style={styles.paginationContainer}>
          <Pagination
            current={currentPage}
            total={totalPages}
            onPageChange={this.onPageChange}
          />
        </div>
        <EggRageModal isModalOpen={isModalOpen} closeModal={this.closeModal} />
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(EggRates);
