import React from "react";
import { useTable } from "react-table";
const styles = {
  tableContainer: {
    overflow: "auto",
    width: "100%",
    padding: "20px",
  },
  table: {
    // border: "1px solid black",
    width: "100%",
    fontWeight: "bold",
  },
  th: {
    padding: "20px",
    background: "white",
    // borderLeft: "1px solid black",
    textAlign: "center",
    // borderBottom: "1px solid black",
    color: "#de923b",
  },
  td: {
    color: "black",
    padding: "10px",
    fontWeight: 500,
    // border: "1px solid black",
  },
};
function Table(props) {
  const { data, columns, onRowClick, onActionClick } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  console.log(rows.values);
  return (
    <div style={styles.tableContainer}>
      <table {...getTableProps()} style={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th style={styles.th} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                className={
                  index % 2 === 0
                    ? onRowClick && "tableRow gray"
                    : onRowClick && "tableRow white"
                }
                onClick={
                  onRowClick
                    ? (evt) => onRowClick({ ...row.values }, evt, index)
                    : undefined
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  if (cell.column.id === "action") {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={onActionClick && "tableRow"}
                        onClick={
                          onActionClick
                            ? (evt) => onActionClick({ ...row.values }, evt)
                            : undefined
                        }
                        style={styles.td}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()} style={styles.td}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
