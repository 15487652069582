import InboxOutlined from "@material-ui/icons/InboxOutlined";
import { Col, Row, Input, message, Form, Modal, Select, Button } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createPoster, editPoster } from "../../RestServices/postingService";
import { uploadThumnail } from "../../RestServices/videosService";
import { posterCategories, posterFor } from "../../shared";
import { SketchPicker } from "react-color";

const { Option } = Select;
export default function AddAds({
  isModalVisible,
  handleOk,
  handleCancel,
  item,
}) {
  const { token } = useSelector((state) => state.Authentication.user);
  const [isFormLoading, setFormLoading] = useState(false);
  const [displayType, setDisplayType] = useState("");
  const [category, setCategory] = useState("");

  const [images, setImages] = useState([]);
  const [bannerLinks, setBannerLinks] = useState([]);
  const [title, setTitle] = useState("");
  const [colorCode, setColorCode] = useState("#de923b");

  const [description, setDescription] = useState("");

  const [uploadedPercentage, setUploadedPercentage] = useState(0);

  useEffect(() => {
    if (item) {
      setDisplayType(item?.displayType);
      setCategory(item?.category);
      setDescription(item?.description ? item?.description : "");
      setBannerLinks(item?.bannerLinks);
      setColorCode(item?.colorCode ? item?.colorCode : "");
      setTitle(item?.title);
    }
  }, [item]);

  const uploadThumbnail = async (thumbnail) => {
    setFormLoading(true);

    setUploadedPercentage(0);
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    try {
      const response = await uploadThumnail(formData, token, (percentage) =>
        setUploadedPercentage(percentage),
      );
      setBannerLinks([...bannerLinks, response.thumnailUrl]);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  const thumbnailProps = {
    onRemove: (image) => {
      let imag = images.filter((value) => value.name !== image.name);
      setImages(imag);
    },

    beforeUpload: (image) => {
      uploadThumbnail(image);
      setImages([...images, image]);
      return false;
    },
    fileList: images ? images : [],
    accept: "image/*",
  };

  const submitAds = async () => {
    try {
      if (
        !displayType ||
        !colorCode ||
        !description ||
        !category ||
        !bannerLinks ||
        !title
      ) {
        return message.warn("Please enter the all fields");
      }
      setFormLoading(true);
      if (!item) {
        await createPoster(
          {
            displayType,
            category,
            bannerLinks,
            description,
            title,
            colorCode,
            isActive: true,
          },
          token,
        );
      } else {
        await editPoster(
          {
            category,
            posterId: item?._id,
            displayType,
            isActive: item?.isActive,
            bannerLinks,
            colorCode,
            description,
            title,
          },
          token,
        );
      }
      setDisplayType("");
      setCategory("");
      setDescription("");
      setBannerLinks([]);
      setImages([]);
      setColorCode("");
      setTitle("");
      handleOk();
      handleCancel();
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title="Add Ads"
        visible={isModalVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item label="Title" required>
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  disabled={isFormLoading}
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter the Title"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Description" required>
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  disabled={isFormLoading}
                  name="title"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter the Description"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Select Category" required>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Category"
                  optionFilterProp="children"
                  onChange={setCategory}
                  value={category}
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {posterCategories.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Display For" required>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Display for"
                  optionFilterProp="children"
                  onChange={setDisplayType}
                  value={displayType}
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {posterFor.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              {/* <Input style={{ marginTop: '10px' }} placeholder="PKR 500" /> */}
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <Dragger {...thumbnailProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint">Upload Image/Video/GIF</p>
                  <p className="ant-upload-hint">.JPG/.PNG/MP3/MP4</p>
                </Dragger>
              </div>
            </Col>
            <Col>
              <SketchPicker
                color={colorCode}
                onChangeComplete={(color) => setColorCode(color.hex)}
              />
            </Col>
            <Col span={24}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  size="large"
                  loading={isFormLoading}
                  style={{ width: "100%", marginTop: "80px" }}
                  htmlType="submit"
                  type="primary"
                  onClick={submitAds}
                >
                  {item ? "Update" : "Upload"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
