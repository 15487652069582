import React, { Component } from "react";
import "./logout.css";
import { connect } from "react-redux";
import { RemoveColonFromPermission, UserPermisstion } from "../../shared";
import { deleteToken } from "../../utils/token";
import { FiShoppingBag } from "react-icons/fi";
import { BiLogOutCircle } from "react-icons/bi";
import {
  UserOutlined,
} from "@ant-design/icons";
import { IoIosArrowDown } from "react-icons/io";
import {
  Avatar,
} from "antd";
import { truncateName } from "../../utils/truncateName";

class LogOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOutsideClick = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };
  logout = () => {
    deleteToken();
    window.location.href = "/signin";
  };
  render() {
    const { isOpen } = this.state;
    const { permissions } = this.props.Auth.user.profileDetails;
    const PermissionsRomovingColor = RemoveColonFromPermission(permissions);
    return (
      <div ref={this.dropdownRef}>
        <Avatar icon={<UserOutlined />} style={{ marginLeft: "10px" }} />
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
        <span className="title" style={{
            maxWidth: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>{this.props.Auth.user?.name}</span>
          <button onClick={this.toggleDropdown} className="arrow-icon">
            <IoIosArrowDown style={{ fontSize: "20px" }} />
          </button>
        </div>

        {isOpen && (
          <div className="dropdown-content">
            <ul>
              {PermissionsRomovingColor.includes(
                UserPermisstion[0].value.split(":")[0]
              ) || PermissionsRomovingColor.includes("e-commerce") ? (
                <li><a href={`https://e-commence-admin-penal-live.web.app/?${this.props.Auth.user.token}`}><FiShoppingBag />E-Commerce</a></li>
              ) : (
                <></>
              )}

              <li><button style={{ color: "red" }} onClick={this.logout}><BiLogOutCircle />Sign Out</button></li>
            </ul>
          </div >
        )
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(LogOut);
