import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCities,
  updateCityToactive,
} from "../../../Redux/ActionCreaters/citiesRequets";
import {
  getCommodity,
  getDateTimeString,
} from "../../../Redux/ActionCreaters/CommodityRequets";
import { AddCity } from "../../../RestServices/authService";
import { itemProducts } from "../../../shared";
import ProvinceDropDown from "../../../utils/dropDown/ProvinceDropDown";
import ModalUtil from "../../../utils/Modal/Modal";
import Calendar from "../../Dashboard/DashboardBody/Calendar";
import "./body.css";
import CategoryDropDown from "./CategoryDropDown";
import CommodityRatesDivs from "./CommodityRatesDivs";

const Body = () => {
  const { cities, provinces, allcities } = useSelector((state) => state.Cities);
  const [product, setProduct] = useState(itemProducts[0]);
  const [province, setProvince] = useState("");
  const [getRate, setGetRate] = useState(0);

  const [addCityModalVisible, setAddCityModalVisible] = useState(false);

  const [addCityModalLoading, SetaddCityModalLoading] = useState(false);

  // const [getRate, setGetRate] = useState(0);

  const title = "add city";

  useEffect(() => {
    if (!province) {
      setProvince(provinces ? provinces[0] : "");
    }
  }, [provinces]);
  const { token } = useSelector((state) => state.Authentication.user);

  const dispatch = useDispatch();
  const [allDivistion, setallDivistion] = useState([]);
  const [allCities, setAllCities] = useState([]);

  useEffect(() => {
    if (province) {
      dispatch(getCommodity(product, province, token));
      setallDivistion(cities.map((val) => val.division));
      setAllCities(allcities.map((val) => val.value));
    }
  }, [getRate, province]);
  useEffect(() => {
    if (province) {
      dispatch(getDateTimeString({ province, product }, token));
    }
  }, [province, product]);
  const changeProduct = (product) => {
    dispatch(getCommodity(product, province, token));
    setProduct(product);
  };
  const changeProvince = (province) => {
    dispatch(getCommodity(product, province, token));
    // dispatch(getDateTimeString({ province, product }, token));

    setProvince(province);
  };
  const addCityButtonFuction = async (e, data) => {
    e.stopPropagation();
    let newCity = false;
    if (provinces && provinces.indexOf(addProvince?.toLowerCase()) === -1) {
      newCity = true;
    }
    if (
      allDivistion &&
      allDivistion.indexOf(addDivision?.toLowerCase()) === -1
    ) {
      newCity = true;
    }
    if (allCities && allCities.indexOf(addDistrict?.toLowerCase()) === -1) {
      newCity = true;
    }
    console.log(newCity, "add new cityes");
    if (newCity === true) {
      const payload = {
        cities: [
          {
            isActive: true,
            name: addDistrict?.toLowerCase(),
            value: addDistrict?.toLowerCase(),
            division: addDivision?.toLowerCase(),
            district: addDistrict?.toLowerCase(),
            province: addProvince?.toLowerCase(),
            activeForProduct: [product],
          },
        ],
      };
      SetaddCityModalLoading(true);

      try {
        const responce = await AddCity(payload, token);
        handleAddcityModalOnCancle();
        dispatch(getAllCities(token));
        message.success(
          `city is added into the ${addProvince} province in ${addDivision} for ${product} commodity`,
        );
      } catch (err) {
        console.log(err);
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      } finally {
        SetaddCityModalLoading(false);
      }
    } else {
      let cityToBeUpdate = allcities.find(
        (valu) => valu.value == addDistrict?.toLowerCase(),
      );
      dispatch(
        updateCityToactive(
          cityToBeUpdate._id,
          { product },
          token,
          cities,
          addDivision,
          getCities,
        ),
      );
      handleAddcityModalOnCancle();
    }
  };
  const getCities = () => {
    dispatch(getAllCities(token));
  };
  const handleAddcityModalOnCancle = () => {
    // this.setState({ city: "", addCityModalVisible: false });
    setAddCityModalVisible(false);
    setaddNewCityFlag(false);
    setaddDivision("");
    setaddDistrict("");
    setaddProvince("");
    SetaddCityModalLoading(false);
  };
  const [addProvince, setaddProvince] = useState("");
  const [addNewCityFlag, setaddNewCityFlag] = useState(false);

  const whenProvinceChange = (value) => {
    setaddProvince(value);
  };
  const whenChnageDivision = (value) => {
    setaddDivision(value);
    if (allDivistion.indexOf(addDivision?.toLowerCase()) === -1) {
      const divistionFind =
        cities && cities.find((val) => val.division === value);
      setAllCities(
        divistionFind &&
        divistionFind.cities &&
        divistionFind.cities.map((item) => item.value),
      );
    }
  };
  const whenChnageDistrict = (value) => {
    setaddDistrict(value);
  };
  const [addDivision, setaddDivision] = useState("");
  const [addDistrict, setaddDistrict] = useState("");

  const { Title } = Typography;
  return (
    <div>
      <ModalUtil
        timerModalVisible={addCityModalVisible}
        timerModalLoading={addCityModalLoading}
        showTimerModal={addCityModalVisible}
        handleTimerModalOnOk={addCityButtonFuction}
        handleTimerModalOnCancle={handleAddcityModalOnCancle}
        title="add city"
      >
        <Row style={{ margin: "20px" }}>
          <Form
            style={{ padding: "20px", width: "100%" }}
            colon={false}
            layout="vertical"
            onSubmit={addCityButtonFuction}
          >
            <Typography.Text>
              {/* Please enter the city to add in {division} division. */}
            </Typography.Text>
            <Form.Item label="provice" required>
              <AutoComplete
                // style={{ width: 200 }}
                style={{ background: "white", color: "black" }}
                dataSource={provinces}
                value={addProvince}
                onChange={(e) => whenProvinceChange(e)}
                placeholder="province"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item label="Division" required>
              <AutoComplete
                // style={{ width: 200 }}
                style={{ background: "white", color: "black" }}
                dataSource={allDivistion}
                value={addDivision}
                onChange={(e) => whenChnageDivision(e)}
                placeholder="Division"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
              {/* <Input
                size="large"
                type="text"
                name="district"
                onChange={(e) => SetaddDistrict(e.target.value)}
                value={addDistrict}
                placeholder="district Name"
              /> */}
            </Form.Item>
            <Form.Item label="Distict" required>
              <AutoComplete
                // style={{ width: 200 }}
                style={{ background: "white", color: "black" }}
                dataSource={allCities}
                value={addDistrict}
                onChange={(e) => whenChnageDistrict(e)}
                placeholder="ditrict"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Form>
        </Row>
      </ModalUtil>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <div className="flex-between">
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Select Province</Title>
                        <ProvinceDropDown
                          selectProvince={changeProvince}
                          selectedProvince={province}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Select Category</Title>
                        <CategoryDropDown
                          itemProducts={itemProducts}
                          selectedProduct={product}
                          setProduct={changeProduct}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div className="flex-col">
                        <div className="flex-row">
                          <DateRangeOutlinedIcon />
                          <Title level={4} className="mb-0 text-primary">From</Title>
                        </div>
                        <Calendar />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div className="flex-col">
                        <div className="flex-row">
                          <DateRangeOutlinedIcon />
                          <Title level={4} className="mb-0 text-primary">To</Title>
                        </div>
                        <Calendar />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Button type="primary" onClick={(e) => setAddCityModalVisible(true)}>
                    Add City
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            <Card>
              <Title level={3} className="mb-0 text-primary">Commodity Rates Lists</Title>
              {cities &&
                cities.map((val, key) => {
                  return province === val.province ? (
                    <Fragment key={key}>
                      <CommodityRatesDivs
                        cities={val.cities}
                        Heading={val.division}
                        product={product}
                        province={province}
                      />
                    </Fragment>
                  ) : null;
                })}
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Body;
