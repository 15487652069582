import { Button, Col, Row, Select } from "antd";
import React from "react";
import { poultryCommodities } from "../../shared";

const { Option } = Select;

export default function Filter({
  CategoryTypes,
  selectedValue,
  setFilter,
  selectedCommodity,
  setSelectedCommodity,
}) {
  return (
    <Row
      gutter={[12, 12]}
      style={{
        marginTop: "10px",
      }}
      justify="space-around"
    >
      {CategoryTypes?.map((type, key) => (
        <Col span={3}>
          <Button
            onClick={() => setFilter(type.value)}
            type={type.value === selectedValue ? "text" : "primary"}
          >
            {type.text}
          </Button>
        </Col>
      ))}

      <Col span={3}>
        <Select
          showSearch
          style={{ width: "100%" }}
          value={selectedCommodity}
          placeholder="Select a Commodity"
          optionFilterProp="children"
          onChange={(e) => {
            setSelectedCommodity(e);
          }}
          filterOption={(input, option) =>
            option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        >
          <Option value={""}>Over All</Option>;
          {poultryCommodities.map((commodities) => {
            return (
              <Option key={commodities} value={commodities}>
                {commodities}
              </Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
}
