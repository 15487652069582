import React from "react";
import "./upper-cards.css";
import "./progress.css";
import ProgressBar from "./ProgressBar";
import { Col, Row, Tooltip } from "antd";
import { ReasonOfBlocked } from "../../../../../shared";

const ReasonCard = ({ data, setSelected }) => {
  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={8}>
        {ReasonOfBlocked.map((value) => (
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <div>
              <ProgressBar
                strokeColor={"brown"}
                strokeWidth={12}
                percent={data[value]?.length}
                showInfo={false}
                status="active"
              />
            </div>
            <div
              onClick={() => setSelected(value, data[value])}
            >
              <Tooltip
                title={data[value]?.map((reason) => (
                  <p>{reason.phoneNumber}</p>
                ))}
              >
                <h1 className="title">{data[value]?.length}</h1>
              </Tooltip>
              <p className="body mb-0" style={{ fontSize: "20px" }}>{value}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ReasonCard;