import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBiddings } from '../../../Redux/ActionCreaters/Order';
import { RemoveColonFromPermission } from '../../../shared';
import { MAIN_BACKGROUND_COLOR } from '../../../styleConstants';
import Filter from '../../../utils/filter/Filter';
import CustomPagination from '../../Pagination';
import PostBidDetailsModal from './PostBidDetails';
// import AggOrderModal from './aggOrders/aggOrderModal';

export const bidStatusArray = [
  {
    value: '',
    text: 'All Offers',
  },
  {
    value: 'OFFER_SEND',
    text: 'Pending Offers',
  },

  {
    value: 'OFFER_ACCEPTED',
    text: 'Pending to pay',
  },
  {
    value: 'OFFER_PAID',
    text: 'Paid Offer',
  },
  {
    value: 'OFFER_REJECTED',
    text: 'Rejected Offers',
  },
];

export default function MurgiMandiOffer({}) {
  const [selectedCommodity, setSelectedCommodity] = useState('');
  const { bidding } = useSelector((state) => state.Order);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [bidStatus, setBidStatus] = useState('');

  const [selectedBit, setSelectedBit] = useState(null);

  const {
    token,
    profileDetails,
    _id: userId,
  } = useSelector((state) => state.Authentication.user);

  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getOrders();
  }, [currentPage, isModalVisible, bidStatus, selectedCommodity]);
  const dispatch = useDispatch();
  const getOrders = async () => {
    try {
      let crmUserId = '';
      const PermissionsRomovingColor = RemoveColonFromPermission(
        profileDetails.permissions,
      );
      if (PermissionsRomovingColor.includes('Crm_System')) {
        crmUserId = userId;
      }
      let obj = {
        finalBidForParent: bidStatus,
        parentId: true,
      };
      dispatch(
        getAllBiddings(selectedCommodity, currentPage, token, crmUserId, obj),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <PostBidDetailsModal
        selectedBit={selectedBit}
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CustomPagination
          current={currentPage}
          total={bidding?.totalDocs}
          onPageChange={onPageChange}
        />
      </div>
      <Filter
        CategoryTypes={bidStatusArray}
        selectedValue={bidStatus}
        setFilter={setBidStatus}
        selectedCommodity={selectedCommodity}
        setSelectedCommodity={setSelectedCommodity}
      />

      <Row
        gutter={[32, 32]}
        justify={'space-around'}
        style={{ borderRadius: '15px', marginTop: '20px' }}
      >
        {bidding?.docs?.map((value) => (
          <Col
            onClick={() => {
              setSelectedBit(value);
              setIsModalVisible(true);
            }}
            key={value._id}
            span={22}
            style={{
              backgroundColor:
                value.finalBidForParent === 'OFFER_REJECTED'
                  ? 'red'
                  : value.finalBidForParent === 'OFFER_PAID'
                  ? 'blue'
                  : value.paymentStatusOwner === 'PAID' ||
                    value.paymentStatusClient === 'PAID'
                  ? 'orange'
                  : '#dce600',
              borderRadius: '15px',
              color: MAIN_BACKGROUND_COLOR,
              paddingTop: '5px',
              cursor: 'pointer',
            }}
          >
            <Row
              align="middle"
              gutter={[12, 12]}
              justify={'space-between'}
              style={{ paddingTop: '10px' }}
            >
              <Col span={6}>
                <p style={{ color: 'black' }}>{value?._id}</p>
              </Col>
              <Col span={6}>
                <p style={{ color: 'black' }}>{value?.message}</p>
              </Col>{' '}
              <Col span={6}>
                <p style={{ color: 'black' }}>
                  {value?.postDetails?.postDetails?.type}{' '}
                  {value?.postDetails?.postDetails?.subType} (
                  {value?.postDetails?.city})
                </p>
              </Col>{' '}
              <Col span={4}>
                <p style={{ color: 'black' }}>
                  Price/Offer Price (price on call / {value?.offeredPrice})/-
                </p>
              </Col>
              <Col span={2}>
                <p style={{ color: 'black' }}>{value?.maxTryInDeal}</p>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}
