export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';

export const CITIES_LOADING = 'CITIES_LOADING';
export const CITIES_SUCCESSFULL = 'CITIES_SUCCESSFULL';
export const CITIES_FAILED = 'CITIES_FAILED';
export const CITIES_CHANGED = 'CITIES_CHANGED';

export const COMMODITY_LOADING = 'COMMODITY_LOADING';
export const COMMODITY_SUCCESSFULL = 'COMMODITY_SUCCESSFULL';
export const COMMODITY_ADD_SUCCESSFULL = 'COMMODITY_ADD_SUCCESSFULL';

export const COMMODITY_FAILED = 'COMMODITY_FAILED';

export const GET_TIME_STRING = 'GET_TIME_STRING';
export const CREATE_TIME_STRING = 'CREATE_TIME_STRING';
export const EDIT_TIME_STRING = 'EDIT_TIME_STRING';
export const TIMER_LOADING = 'TIMER_LOADING';

export const FEEDBACK_LOADING = 'FEEDBACK_LOADING';
export const FEEDBACK_SUCCESSFULL = 'FEEDBACK_SUCCESSFULL';
export const FEEDBACK_FAILED = 'FEEDBACK_FAILED';

export const COMMODITY_COUNT_GET = 'COMMODITY_COUNT_GET';
export const COMMODITY_COUNT_LOADING = 'COMMODITY_COUNT_LOADING';
export const COMMODITY_COUNT_LOADING_FALSE = 'COMMODITY_COUNT_LOADING_FALSE';

export const CHANGE_MOBILE_VIEW = 'CHANGE_MOBILE_VIEW';

export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_SUCCESSFULL = 'ORDER_SUCCESSFULL';
export const BIDDINGS_SUCCESSFULL = 'BIDDINGS_SUCCESSFULL';
export const ORDER_COUNT_SUCCESSFULL = 'ORDER_COUNT_SUCCESSFULL';

export const ORDER_FAILED = 'ORDER_FAILED';

export const ORDER_CHANGED = 'ORDER_CHANGED';
