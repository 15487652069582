import React from 'react';
import UserList from './UserList';

import TopFans from './TopFans';
import CommodityTrend from './commodityTrend';
import UserListForCrm from './userListForCrm';

import { ReasonOfBlocked } from '../../shared';
import NonAppUser from './NonAppUser';

export default function Index(props) {
  return (
    <div>
      {(() => {
        if (props.slectedSubkey === 'Top Fans') {
          return <TopFans {...props} />;
        } else if (props.slectedSubkey.startsWith('Crm')) {
          return <UserListForCrm {...props} />;
        } else if (props.slectedSubkey === 'Commodity Trend') {
          return <CommodityTrend {...props} />;
        } else if (ReasonOfBlocked.includes(props.slectedSubkey)) {
          return <NonAppUser {...props} />;
        } else {
          return <UserList {...props} />;
        }
      })()}
    </div>
  );
}
