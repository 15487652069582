import { Row, Col, Typography } from "antd";
import React from "react";
import { KeyboardArrowDownOutlined, Schedule } from "@material-ui/icons";

import moment from "moment";
import 'moment/locale/en-gb';

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import ModalUtil from "../../../../utils/Modal/Modal";
import { styles } from "./style";
export default function SetTimerModal({
  datePicker,
  changeTimeFromModal,
  ...otherProps
}) {
moment.locale("en-gb");

  return (
    <div className="modalC-timer">
      {" "}
      <ModalUtil {...otherProps}>
        <img src="/modalPopUp.png" style={{ width: "100%" }} />
        <Row justify="center">
          <Col style={styles.headerStyle} push={2} pull={2} span={20}>
            <Schedule />
            <Typography.Text style={styles.typeStyle}>
              {" "}
              Set the time
            </Typography.Text>
          </Col>
        </Row>
        <Row justify="center">
          <Col style={styles.timerStyle}>
            <TimePicker
              defaultValue={datePicker.date}
              showHour={true}
              className="timePicker"
              onChange={changeTimeFromModal}
              showMinute={false}
              showSecond={false}
              value={datePicker.date}
              inputIcon={<KeyboardArrowDownOutlined />}
              clearIcon={null}
              use12Hours={true}
              // hideDisabledOptions={true}
              // disabledHours={() => [
              //   12,
              //   13,
              //   14,
              //   15,
              //   16,
              //   17,
              //   18,
              //   19,
              //   20,
              //   21,
              //   22,
              //   23,
              // ]}
            />
            <TimePicker
              defaultValue={datePicker.date}
              showHour={false}
              showMinute={true}
              showSecond={false}
              value={datePicker.date}
              showHour={false}
              inputIcon={<KeyboardArrowDownOutlined />}
              className="timePicker"
              onChange={changeTimeFromModal}
            />
          </Col>
        </Row>
      </ModalUtil>
    </div>
  );
}
