// import OverallStatistics from './overallStatistics/statistics';
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { Button, Card, Col, Row, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import friends from "../../../assets/Dashboard Icons/Group 536.png";
import { getContactWithTask } from "../../../RestServices/authService";
import {
  CommodityGraphData,
  getPostCount,
} from "../../../RestServices/postingService";
import {
  getDailyStat,
  getTopFan,
  getTrafficGraphData,
} from "../../../RestServices/statService";
import { itemProducts } from "../../../shared";
import "./body.css";
import CardDetail from "./cardDetail";
import SocialCard from "./Cards/socialCards.js";
import socialCardData from "./Cards/socialCards.js/data";
import ReasonCard from "./Cards/UpperCards/reasonCard";
import UpperCard1 from "./Cards/UpperCards/UpperCard1";
import UpperCard2 from "./Cards/UpperCards/UpperCard2";
import UpperCard3 from "./Cards/UpperCards/UpperCard3";
import UpperCard4 from "./Cards/UpperCards/UpperCard4";
import UpperCard5 from "./Cards/UpperCards/UpperCard5";
import UpperCard6 from "./Cards/UpperCards/upperCard6";
import CommodityGraph from "./commodityGraph";
import TableComponent from "./table";
import TopFeature from "./TopFeatureDiv/TopFeature";
import { AiOutlineCalendar } from "react-icons/ai";
import { Calendar } from "react-date-range";

moment.locale("en");
const Body = ({ setSelected }) => {
  const { Title } = Typography;
  const [valueDate, onChangeDate] = useState(new Date());

  const [appTraffic, setAppTraffic] = useState({
    type: "bar",
    data: {
      labels: itemProducts,
      datasets: [
        {
          label: `line`,
          data: [],
          type: "line",
          borderWidth: 5,
          borderColor: [
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
          ],
          backgroundColor: [
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
          ],
        },
        {
          label: `Bar`,
          data: [],
          // type: 'line',
          borderWidth: 1,
          borderColor: [
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
          ],
          backgroundColor: [
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
          ],
        },
      ],
    },
    options: {
      cutoutPercentage: 75,
      spanGaps: false,
      legend: {
        display: true,
      },
      maintainAspectRatio: true,
    },
  });

  const [data, setdata] = useState({
    type: "bar",
    data: {
      labels: itemProducts,
      datasets: [
        {
          label: `line`,
          data: [],
          type: "line",
          borderWidth: 5,
          borderColor: [
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
          ],
          backgroundColor: [
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
            "#576CF6",
          ],
        },
        {
          label: `Bar`,
          data: [],
          // type: 'line',
          borderWidth: 1,
          borderColor: [
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
          ],
          backgroundColor: [
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
            "#091565",
          ],
        },
      ],
    },
    options: {
      cutoutPercentage: 75,
      spanGaps: false,
      legend: {
        display: true,
      },
      maintainAspectRatio: true,
    },
  });

  const [open, setOpen] = useState(true);

  const [stat, setstat] = useState(null);

  const [yesterdayStat, setYesterdasStat] = useState(null);

  const { token } = useSelector((state) => state.Authentication.user);
  const { commodityStatColumns } = useSelector((state) => state.CommodityRates);

  const [top5Toggle, setTop5Toggle] = useState(false);

  const onToggleCommodity = () => {
    setTop5Toggle(!top5Toggle);
  };

  const [date, setData] = useState({
    day: new Date().getDate(),
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });

  const [datesBetweenCommodity, setDateBetweenCommodity] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dateSelectionCommodity, setdateSelectionCommodity] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [dropdrownshowCommodity, setdropdrownshowCommodity] = useState(false);

  const handleSelectCommodity = (ranges) => {
    if (ranges.hasOwnProperty("range1")) {
      setdateSelectionCommodity(ranges.range1);
    } else {
      setdateSelectionCommodity(ranges.selection);
    }
  };
  const PickDateCommodity = () => {
    setDateBetweenCommodity({
      startDate: dateSelectionCommodity.startDate,
      endDate: dateSelectionCommodity.endDate,
    });
  };

  const [datesBetweenAppTraffic, setDateBetweenAppTraffic] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dateSelectionAppTraffic, setdateSelectionAppTraffic] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [dropdrownshowAppTraffic, setdropdrownshowAppTraffic] = useState(false);

  const handleSelectAppTraffic = (ranges) => {
    if (ranges.hasOwnProperty("range1")) {
      setdateSelectionAppTraffic(ranges.range1);
    } else {
      setdateSelectionAppTraffic(ranges.selection);
    }
  };
  const PickDateAppTraffic = () => {
    setDateBetweenAppTraffic({
      startDate: dateSelectionAppTraffic.startDate,
      endDate: dateSelectionAppTraffic.endDate,
    });
  };
  const [graphCity, setGraphCity] = useState("");

  const [appTrafficGraphDate, setAppTrafficGraphDate] = useState(new Date());
  const [appTrafficGraphCity, setAppTrafficGraphCity] = useState("");
  function appTrafficGraphDateChange(date, dateString) {
    setAppTrafficGraphDate(new Date(dateString));
  }

  const [TopFan, setTopFan] = useState({});
  useEffect(() => {
    (async () => {
      try {
        const response = await getTopFan({}, token);
        const {
          stat: { docs },
        } = response;
        console.log(docs, "response");
        setTopFan(response?.stat);
      } catch (err) { }
    })();
  }, []);

  const [phoneInCategory, setPhoneInCategory] = useState({
    "IOS User": [],
    "Keypad user": [],
    "Not Interested": [],
  });

  const getSetUp = async () => {
    const { contacts } = await getContactWithTask({
      status: "blocked",
    });
    const phoneInCategory = {
      "IOS User": [],
      "Keypad user": [],
      "Not Interested": [],
    };
    for (let contact of contacts) {
      if (contact && contact?.blockedReason) {
        phoneInCategory[contact.blockedReason]?.push(contact);
      }
    }
    setPhoneInCategory(phoneInCategory);
  };

  useEffect(() => {
    getSetUp();

    (async () => {
      try {
        let limit = 20;
        if (top5Toggle) {
          limit = 5;
        }
        const response = await CommodityGraphData(
          {
            startDate: datesBetweenCommodity.startDate.toDateString(),
            endDate: datesBetweenCommodity.endDate.toDateString(),

            city: graphCity.toUpperCase(),
            limit,
          },
          token,
        );
        console.log(response, "response");
        const {
          stat: { docs },
        } = response;
        console.log(docs, "response");
        let pointGraphData = [];
        const {
          data: { labels },
        } = data;

        const comingLabel = docs.map((item) => item.product);
        if (comingLabel.length !== labels) {
          for (let item of itemProducts) {
            if (!comingLabel.includes(item)) {
              comingLabel.push(item);
            }
          }
        }

        for (let productName of comingLabel) {
          const foundItem = docs?.find((item) => item.product === productName);
          if (foundItem) {
            pointGraphData.push(foundItem.count);
          } else {
            pointGraphData.push(0);
          }
        }
        console.log(pointGraphData);
        if (top5Toggle) {
          pointGraphData = pointGraphData.slice(0, 5);
        }
        const changeData = { ...data };
        changeData.data.datasets[0].data = pointGraphData;
        changeData.data.datasets[1].data = pointGraphData;
        setdata({
          ...changeData,
        });
      } catch (err) { }
    })();
  }, [datesBetweenCommodity, graphCity, top5Toggle]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getTrafficGraphData(
          {
            startDate: datesBetweenAppTraffic.startDate.toDateString(),
            endDate: datesBetweenAppTraffic.endDate.toDateString(),
            city: appTrafficGraphCity?.toLowerCase(),
          },
          token,
        );
        const {
          stat: { docs },
        } = response;

        let pointGraphLabel = docs.map((item) =>
          moment(new Date(item.date + ":00"))
            .add(6, "hours")
            .format("LLL"),
        );

        // pointGraphLabel = Array.from(new Set(pointGraphLabel));
        const pointGraphData = docs.map((item) => item.count);
        console.log(pointGraphData);
        const changeData = { ...appTraffic };
        changeData.data.labels = pointGraphLabel;
        changeData.data.datasets[0].data = pointGraphData;
        changeData.data.datasets[1].data = pointGraphData;
        setAppTraffic({
          ...changeData,
        });
      } catch (err) { }
    })();
  }, [datesBetweenAppTraffic, appTrafficGraphCity]);

  const [postCount, setPostCount] = useState(0);
  const getPostCounting = async () => {
    try {
      const response = await getPostCount({}, token);
      if (response.posts) {
        let totalCount = 0;
        for (let product of response.posts) {
          totalCount += product?.count || 0;
        }
        setPostCount(totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    (async () => {
      getPostCounting();
      try {
        const settedDate = moment(valueDate).date(date.day - 1);
        const selectedDay = await getDailyStat(
          new Date(settedDate).toDateString(),
          token,
        );
        setstat(selectedDay);
      } catch (err) {
        console.log(err);
      }

      try {
        const yesterDate = moment(valueDate).date(date.day - 2);
        const prevoiceDay = await getDailyStat(
          new Date(yesterDate).toDateString(),
          token,
        );
        setYesterdasStat(prevoiceDay);
      } catch (err) { }
    })();
  }, [date]);

  return (
    <div className="main-dashboard">
      <div style={{ marginBottom: "20px" }}>
        <div className="dashboard-header flex-between">
          <div>
            <Title level={3} style={{ fontWeight: "bold", marginBottom: 0 }}>Welcome to Poultry Baba!</Title>
            <Title level={4} type="secondary" style={{ marginTop: 0 }}>Admin Dashboard</Title>
          </div>
          <div style={{ marginRight: "2%", marginTop: "3px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start" }}>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", marginBottom: "5px" }}>
                <AiOutlineCalendar size={20} style={{ color: "#000", marginRight: "5px" }} />
                <div style={{ fontWeight: "500", color: "#000", marginBottom: 0, fontSize: "16px" }}>Please Select Date :</div>
              </div>
              <div style={{ width: "100%" }} >
                <DatePicker onChange={onChangeDate} value={valueDate} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dashboard Cards */}
      <Row style={{ marginBottom: "15px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <div className="flex-between">
              <div>
                <Title level={4} className="mb-0 text-primary">Overall Statistics</Title>
              </div>
              <div>
                <Button className="buttonStyle" size="small">
                  {open ? (
                    <DownOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}
                    />
                  ) : (
                    <UpOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      {
        open ? (
          <div>
            <Row gutter={[12, 12]} style={{ paddingBottom: "12px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card style={{ height: "172px" }}>
                  <UpperCard1
                    setSelected={setSelected}
                    number={stat ? stat.totalUsers : 0}
                    preNumber={yesterdayStat ? yesterdayStat.totalUsers : 0}
                    title="Total Members"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card style={{ height: "172px" }}>
                  <UpperCard2
                    setSelected={setSelected}
                    number1={stat ? stat.activeUsers : 0}
                    preNumber1={yesterdayStat ? yesterdayStat.activeUsers : 0}
                    title1="Active Members(10)"
                    number3={stat ? stat.idolUsers : 0}
                    preNumber3={yesterdayStat ? yesterdayStat.idolUsers : 0}
                    title3="Idol Members(30)"
                    number2={stat ? stat?.sluggishUser : 0}
                    preNumber2={yesterdayStat ? yesterdayStat.idolUsers - 400 : 0}
                    title2="Sluggish Members(10-30)"
                  />
                </Card>
              </Col>
            </Row>

            <Row gutter={[12, 12]} style={{ paddingBottom: "12px" }}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card style={{ height: "172px" }}>
                  <UpperCard3
                    setSelected={console.log}
                    number={postCount}
                    title="Total Posts"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card style={{ height: "172px" }}>
                  <UpperCard5
                    setSelected={console.log}
                    number1={326}
                    title1="Total Req Accept"
                    number2={192}
                    title3="Total Req Reject"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card style={{ height: "172px" }}>
                  <UpperCard6
                    setSelected={console.log}
                    number1={326}
                    title1="Gold Star Members"
                    number2={192}
                    title3="Rising Star Members"
                  />
                </Card>
              </Col>
            </Row>

            <Row gutter={[12, 12]} style={{ paddingBottom: "12px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card style={{ height: "172px" }}>
                  <UpperCard3
                    number={TopFan?.totalDocs}
                    setSelected={() => setSelected("Dashboard/User", "Top Fans")}
                    title="Top Fans"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card style={{ height: "172px" }}>
                  <ReasonCard
                    setSelected={(subCategory, data) =>
                      setSelected("Dashboard/User", subCategory, data)
                    }
                    data={phoneInCategory}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        ) : null
      }
      {/* Dashboard Table */}
      <TableComponent date={date} />

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <TopFeature
              showCity={true}
              showcalender={true}
              PickDate={PickDateAppTraffic}
              handleSelect={handleSelectAppTraffic}
              dateSelection={dateSelectionAppTraffic}
              datesBetween={datesBetweenAppTraffic}
              dropdrownshow={dropdrownshowAppTraffic}
              setdropdrownshow={(e) => {
                if (typeof e === "boolean") setdropdrownshowAppTraffic(e);
              }}
              setdateSelection={() => {
                setdateSelectionAppTraffic({
                  ...dateSelectionAppTraffic,
                  ...datesBetweenAppTraffic,
                });
                // setdropdrownshowAppTraffic(false);
              }}
              city={appTrafficGraphCity}
              date={appTrafficGraphDate}
              onChangeCity={setAppTrafficGraphCity}
              top5Commodity={true}
              onChangeDate={appTrafficGraphDateChange}
              TopButtonText={"Top 10 Cities"}
              text={"App Traffic Analyses"}
            />
          </Card>
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <CommodityGraph data={appTraffic} />
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <TopFeature
              showcalender={true}
              PickDate={PickDateCommodity}
              handleSelect={handleSelectCommodity}
              dateSelection={dateSelectionCommodity}
              datesBetween={datesBetweenCommodity}
              dropdrownshow={dropdrownshowCommodity}
              setdropdrownshow={(e) => {
                if (typeof e === "boolean") setdropdrownshowCommodity(e);
              }}
              setdateSelection={() => {
                setdateSelectionCommodity({
                  ...dateSelectionCommodity,
                  ...datesBetweenCommodity,
                });
              }}
              showCity={true}
              city={graphCity}
              // date={dateSelectionCommodity}
              onToggleCommodity={onToggleCommodity}
              onChangeCity={setGraphCity}
              top5Commodity={false}
              TopButtonText={!top5Toggle ? "Top 5 Commodities" : "All Commodities"}
              // onChangeDate={graphDateChange}
              text={"Commodity Analytics"}
            />
          </Card>
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <CommodityGraph data={data} />
          </Card>
        </Col>
      </Row>

      {/* <Col span={23} offset={1}>
        <TopFeature text={"Traffic Sources"} />
      </Col> */}
      {/* <Col span={23} offset={1}>
        <div
          style={{
            padding: "10px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap ",
          }}
        >
          {socialCardData.map((val) => {
            return (
              <div
                style={{
                  margin: "10px",
                }}
              >
                <SocialCard
                  key={val.id}
                  Img={val.image}
                  numbers={val.numbers}
                  name={val.name}
                />
              </div>
            );
          })}

          <div
            style={{
              margin: "10px",
            }}
          >
            <SocialCard
              setSelected={() =>
                setSelected("Dashboard/User", "Commodity Trend")
              }
              key={4}
              Img={friends}
              // numbers={val.numbers}
              name={"Commodity Trend"}
            />
          </div>
        </div>
      </Col> */}

      <CardDetail />
    </div >
  );
};
export default Body;
