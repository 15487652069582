import {
  FEEDBACK_LOADING,
  FEEDBACK_SUCCESSFULL,
  FEEDBACK_FAILED,
} from "../../actionTypes";

const FeedBack = (
  state = {
    feedBack: null,
    isLoading: false,
    errMess: null,
  },
  action
) => {
  switch (action.type) {
    case FEEDBACK_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
      };
    case FEEDBACK_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.errMess,
      };
    case FEEDBACK_SUCCESSFULL:
      return {
        ...state,
        feedBack: action.feedback,
        isLoading: false,
        errMess: action.errMess,
      };

    default:
      return state;
  }
};
export default FeedBack;
