import {
  feedbackLoading,
  feedbackSuccessfull,
  feedbackFailed,
} from "../Actions/FeedBackAction";
import { message } from "antd";
import { feedBackGet } from "../../RestServices/authService";

export const getFeedbacks =
  (pageNo, feedbackResponsed, token) => async (dipsatch) => {
    dipsatch(feedbackLoading());
    try {
      let { feedbacks } = await feedBackGet(
        { pageNo, feedbackResponsed },
        token
      );
      if (feedbacks.docs) {
        let { docs } = feedbacks;
        docs = docs.map((value, idex) => {
          value.sr = idex + 1;
          return value;
        });
      }
      dipsatch(feedbackSuccessfull(feedbacks));
    } catch (err) {
      dipsatch(feedbackFailed(err.message));
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    }
  };
