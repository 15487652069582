import React from "react";
import shortid from "shortid";
import {
  Modal,
  Button,
  Input,
  DatePicker,
  Menu,
  Dropdown,
  message,
} from "antd";

import { connect } from "react-redux";
import { createRateList } from "../../../RestServices/postingService";
import { Delete } from "@material-ui/icons";

shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@",
);
const styles = {
  modal: {
    backgroundColor: "#de923b",
  },
  submitButton: {
    backgroundColor: "#1f910e",
    width: "80%",
    border: "none",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  inputStyles: {
    width: "150px",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "10px 0px",
    overflow: "auto",
  },
  addRateButtonContainer: { display: "flex", justifyContent: "flex-end" },
};
class BroilerRateModal extends React.Component {
  state = {
    inputFeilds: [],
    cites: ["Lahore", "Karachi", "Faisalabad", "Multan"],
    inputs: [],
    isLoading: false,
  };

  citiesDropdown = (name) => (
    <Menu onClick={this.cityChangeHandler(name)}>
      {this.state.cites.map((city) => (
        <Menu.Item key={city?.toLowerCase()}>{city}</Menu.Item>
      ))}
    </Menu>
  );

  inputChangeHandler = (evt) => {
    const { name, value } = evt.target;

    const id = name.split("-")[0];

    const input = this.state.inputs.find((input) => input.id === id);

    input[name] = value;
    evt.target.setAttribute("value", value);
    const inputs = this.state.inputs.filter((input) => input.id !== id);
    this.setState({ inputs: [input, ...inputs] });
  };

  dateChangeHandler = (name) => (date) => {
    const id = name.split("-")[0];
    const input = this.state.inputs.find((input) => input.id === id);
    if (date) {
      input[name] = date.toISOString();
    } else {
      input[name] = "";
    }
    const inputs = this.state.inputs.filter((input) => input.id !== id);
    this.setState({ inputs: [input, ...inputs] });
  };

  cityChangeHandler =
    (name) =>
    ({ key: value }) => {
      const id = name.split("-")[0];
      const input = this.state.inputs.find((input) => input.id === id);
      input[name] = value;
      const inputs = this.state.inputs.filter((input) => input.id !== id);
      this.setState({ inputs: [input, ...inputs] });
      document.getElementById(`${id}-city-button`).innerText =
        value.toUpperCase();
    };

  addFeilds = () => {
    const key = shortid();
    const newInputs = {
      id: key,
      [`${key}-city`]: "",
      [`${key}-date`]: Date.now(),
      [`${key}-rate`]: 0,
    };
    this.setState({ inputs: [newInputs, ...this.state.inputs] });

    const Feilds = (
      <div key={key} style={styles.inputContainer}>
        <Dropdown
          overlay={this.citiesDropdown(`${key}-city`)}
          placement="bottomLeft"
          arrow
        >
          <Button id={`${key}-city-button`} style={styles.inputStyles}>
            Select City
          </Button>
        </Dropdown>
        <DatePicker
          onChange={this.dateChangeHandler(`${key}-date`)}
          placeholder="Select Date"
        />
        <Input
          name={`${key}-rate`}
          onChange={this.inputChangeHandler}
          style={styles.inputStyles}
          placeholder="Rate"
          type="number"
          min="0"
        />
        <Delete
          name={key}
          onClick={this.removeInput(key)}
          style={{ fontSize: 24 }}
        />
      </div>
    );
    this.setState({ inputFeilds: [Feilds, ...this.state.inputFeilds] });
  };
  removeInput = (key) => () => {
    const newInputFeilds = this.state.inputFeilds.filter(
      (feild) => feild.key !== key,
    );
    const newInputs = this.state.inputs.filter((input) => input.id !== key);
    this.setState({ inputFeilds: newInputFeilds, inputs: newInputs });
  };
  resetInputs = () => this.setState({ inputFeilds: [], inputs: [] });
  submitRates = async () => {
    this.setState({ isLoading: true });
    const rateLists = this.state.inputs.map((input) => {
      return {
        city: input[`${input.id}-city`],
        createdAt: input[`${input.id}-date`],
        rateDetails: {
          type: "BROILER",
          rate: input[`${input.id}-rate`],
        },
      };
    });
    try {
      await createRateList({ rateLists }, this.props.Auth.user.token);
      this.setState({ isLoading: false });
      message.success("Rate List Created");
      this.resetInputs();
      this.props.closeModal();
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  render() {
    const { isModalOpen, closeModal } = this.props;
    const { inputFeilds, isLoading } = this.state;
    return (
      <div>
        <Modal
          maskClosable={false}
          width="90%"
          bodyStyle={styles.modal}
          onCancel={closeModal}
          title="Add Egg Rates"
          footer={null}
          visible={isModalOpen}
          closable={!isLoading}
        >
          <div style={styles.addRateButtonContainer}>
            <Button size="large" onClick={this.addFeilds} type="dashed">
              Add Rate
            </Button>
          </div>
          <div
            style={{ maxHeight: "300px", margin: "10px 0px", overflow: "auto" }}
          >
            {inputFeilds}
          </div>
          <div style={styles.submitButtonContainer}>
            <Button
              size="large"
              onClick={this.submitRates}
              loading={isLoading}
              style={styles.submitButton}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(BroilerRateModal);
