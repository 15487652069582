import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createCommission,
  editCommision,
} from "../../RestServices/postingService";
import { commissionGivenCategory } from "../../shared";

const { Option } = Select;
export default function AddAds({
  isModalVisible,
  handleOk,
  handleCancel,
  item,
}) {
  const { token } = useSelector((state) => state.Authentication.user);
  const [isFormLoading, setFormLoading] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const [category, setCategory] = useState("");

  const [commission, setCommission] = useState("");

  useEffect(() => {
    if (item) {
      setSubCategory(item?.subCategory);
      setCategory(item?.category);
      setCommission(item?.commission ? item?.commission : "");
    }
  }, [item]);

  const submitCommission = async () => {
    try {
      if (!subCategory || !commission || !category) {
        return message.warn("Please enter the all fields");
      }
      setFormLoading(true);
      if (!item) {
        await createCommission(
          {
            subCategory: subCategory.split(" ").join("")?.toLowerCase(),
            category,
            commission,
          },
          token,
        );
      } else {
        await editCommision(
          {
            category,
            commissionId: item?._id,
            subCategory: subCategory.split(" ").join("")?.toLowerCase(),
            commission,
          },
          token,
        );
      }
      setSubCategory("");
      setCategory("");
      setCommission("");
      handleOk();
      handleCancel();
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title="Add Commision"
        visible={isModalVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item label="Select Category" required>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Category"
                  optionFilterProp="children"
                  onChange={setCategory}
                  value={category}
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {Object.keys(commissionGivenCategory).map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Sub Category" required>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Sub Category"
                  optionFilterProp="children"
                  onChange={setSubCategory}
                  value={subCategory}
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {commissionGivenCategory[category]?.value?.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={23}>
              <Form.Item label="Commission in Rs" required>
                <Input
                  size="large"
                  type="number"
                  style={{ width: "100%" }}
                  disabled={isFormLoading}
                  name="commission"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                  placeholder="Enter the commission"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  size="large"
                  loading={isFormLoading}
                  style={{ width: "100%", marginTop: "80px" }}
                  htmlType="submit"
                  type="primary"
                  onClick={submitCommission}
                >
                  {item ? "Update" : "Create"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
