import React from "react";
import { BrowserRouter } from "react-router-dom";
import Main from "./Components/MainComponent";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./Redux/store";
import "./App.less";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { locale } from "moment";
locale("en");

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
// /admin/api/v1/cities/getAllAvailableCities
export default App;
