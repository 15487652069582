import Pet from '../../../../../assets/Dashboard Icons/ENG-PET.png';
import tips from '../../../../../assets/Dashboard Icons/Group 4305.png';
import ForriZarorat from '../../../../../assets/Dashboard Icons/bill2.png';
import mandiRate from '../../../../../assets/Dashboard Icons/Group 4306.png';
import doctorsCorner from '../../../../../assets/Dashboard Icons/Group 4308.png';
import News from '../../../../../assets/Dashboard Icons/Group 4309.png';
import poultryPlaza from '../../../../../assets/Dashboard Icons/poultryPalaza.png';
import poultryBazar from '../../../../../assets/Dashboard Icons/Group 4311.png';
import maeriZameen from '../../../../../assets/Dashboard Icons/Group 4312.png';
import hazirJanab from '../../../../../assets/Dashboard Icons/Group 4313.png';
import meriRozee from '../../../../../assets/Dashboard Icons/Group 4314.png';
import chinaDesk from '../../../../../assets/Dashboard Icons/Group 4315.png';
import grainMarket from '../../../../../assets/Dashboard Icons/Group 4316.png';
import MergiMendi from '../../../../../assets/Dashboard Icons/MurghiMandi.png';

export const PostServiceItem = [
  // {
  //   id: 676,
  //   image: Pet,
  //   heading: 'Pets Point',
  //   total_post: 'Total Post',
  //   total_video: 'Total Video',
  //   total_share: 'Total Share',
  //   total_views: 'Total View',
  //   total_likes: 'Total Likes',
  //   total_visit: 'Total Visit',
  //   avg_view_time: 'Avg View Time',
  //   total_post_value: 236,
  //   total_video_value: 161,
  //   total_share_value: 254,
  //   total_views_value: 365,
  //   total_likes_value: 170,
  //   total_visit_value: 224,
  //   avg_view_time_value: '12:40',
  //   // Height: "47rem"
  // },
  {
    id: 6,
    image: poultryPlaza,
    heading: 'Poultry Market',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_visit: 'Total Visit',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    avg_view_time_value: '12:40',
    // Height: "41rem"
  },
  // {
  //   id: 7,
  //   image: poultryBazar,
  //   heading: 'Poultry Bazar',
  //   total_post: 'Total Post',
  //   total_video: 'Total Video',
  //   total_share: 'Total Share',
  //   total_views: 'Total View',
  //   total_visit: 'Total Visit',
  //   total_likes: 'Total Likes',
  //   avg_view_time: 'Avg View Time',
  //   total_post_value: 236,
  //   total_video_value: 161,
  //   total_share_value: 254,
  //   total_views_value: 365,
  //   total_likes_value: 170,
  //   avg_view_time_value: '12:40',
  //   // Height: "41rem"
  // },

  {
    id: 3,
    image: MergiMendi,
    heading: 'MURGHI MANDI',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    avg_view_time_value: '12:40',
    // Height: "41rem"
  },
  // {
  //   id: 9,
  //   image: grainMarket,
  //   heading: 'Grain Market',
  //   total_post: 'Total Post',
  //   total_video: 'Total Video',
  //   total_share: 'Total Share',
  //   total_views: 'Total View',
  //   total_visit: 'Total Visit',
  //   total_likes: 'Total Likes',
  //   avg_view_time: 'Avg View Time',
  //   total_post_value: 236,
  //   total_video_value: 161,
  //   total_share_value: 254,
  //   total_views_value: 365,
  //   total_likes_value: 170,
  //   avg_view_time_value: '12:40',
  //   // Height: "47rem"
  // },
  {
    id: 33,
    image: ForriZarorat,
    heading: 'FOORI ZRORRAT',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    avg_view_time_value: '12:40',
    // Height: "41rem"
  },
  // {
  //   id: 8,
  //   image: maeriZameen,
  //   heading: 'Meri Zameen',
  //   total_post: 'Total Post',
  //   total_video: 'Total Video',
  //   total_share: 'Total Share',
  //   total_views: 'Total View',
  //   total_likes: 'Total Likes',
  //   total_visit: 'Total Visit',
  //   avg_view_time: 'Avg View Time',
  //   total_post_value: 236,
  //   total_video_value: 161,
  //   total_share_value: 254,
  //   total_views_value: 365,
  //   total_likes_value: 170,
  //   avg_view_time_value: '12:40',
  //   // Height: "41rem"
  // },
];

export const ArticleItem = [
  {
    id: 2,
    image: doctorsCorner,
    heading: 'Article',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    total_visit_value: 224,
    avg_view_time_value: '12:40',
    // Height: "47rem"
  },
];
export const LowerCardData1 = [
  {
    id: 2,
    image: doctorsCorner,
    heading: 'DOCTORS_CORNER',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    total_visit_value: 224,
    avg_view_time_value: '12:40',
    // Height: "47rem"
  },
  {
    id: 3,
    image: mandiRate,
    heading: 'MANDI_RATE',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    total_visit_value: 224,
    avg_view_time_value: '12:40',
    // Height: "47rem"
  },
  //   "DOCTORS_CORNER",
  //   "TIPS",
  //   "MANDI_RATE",
  //   "BEST_FARMER_AWARD",
  //   "NEWS",
  //   "MARKET_VIDEOS",
  {
    id: 4,
    image: tips,
    heading: 'TIPS',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    total_visit_value: 224,
    avg_view_time_value: '12:40',
  },

  {
    id: 8,
    image: News,
    heading: 'NEWS',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    avg_view_time_value: '12:40',
    // Height: "41rem"
  },

  // "

  {
    id: 10,
    image: chinaDesk,
    heading: 'China Desk',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    avg_view_time_value: '12:40',
    // Height: "47rem"
  },
  // {
  //   id: 11,
  //   image: meriRozee,
  //   heading: 'Meri Rozee',
  //   total_post: 'Total Post',
  //   total_video: 'Total Video',
  //   total_share: 'Total Share',
  //   total_views: 'Total View',
  //   total_likes: 'Total Likes',
  //   total_visit: 'Total Visit',
  //   avg_view_time: 'Avg View Time',
  //   total_post_value: 236,
  //   total_video_value: 161,
  //   total_share_value: 254,
  //   total_views_value: 365,
  //   total_likes_value: 170,
  //   total_visit_value: 224,
  //   avg_view_time_value: '12:40',
  //   // Height: "47rem"
  // },
  {
    id: 12,
    image: hazirJanab,
    heading: 'HAZAR JANAB',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    total_visit_value: 224,
    avg_view_time_value: '12:40',
    // Height: "47rem"
  },
  {
    id: 1,
    image: poultryPlaza,
    heading: 'BEST_FARMER_AWARD',
    total_post: 'Total Post',
    total_video: 'Total Video',
    total_share: 'Total Share',
    total_views: 'Total View',
    total_likes: 'Total Likes',
    total_visit: 'Total Visit',
    avg_view_time: 'Avg View Time',
    total_post_value: 236,
    total_video_value: 161,
    total_share_value: 254,
    total_views_value: 365,
    total_likes_value: 170,
    total_visit_value: 224,
    avg_view_time_value: '12:40',
    // Height: "47rem"
  },
];
