import { Button, Table } from "antd";
import React from "react";

const ProductTable = ({ dataSource }) => {
  console.log(dataSource, "data source");
  // {
  //   productName: "",
  //   subHeading: "",
  //   description: "",
  //   buttonText: "",
  //   buttonLink: "",
  //   tagName: "",
  //   isFormLoading: false,
  //   uploadedPercentage: 0,
  //   selectedThemnail: "",
  //   articleImage: "",
  // },
  const columns = [
    {
      title: () => (
        <p
          style={{ fontWeight: "bolder", textAlign: "center", height: "10px" }}
        >
          Image
        </p>
      ),
      dataIndex: "articleImage",
      key: "articleImage",
      align: "center",
      // colSpan: 16
      width: 100,
      render: (pic) => {
        return (
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={pic}
            width="70px"
            alt="production image"
            srcset=""
          />
        );
      },
    },
    {
      title: () => (
        <p
          style={{ fontWeight: "bolder", textAlign: "center", height: "10px" }}
        >
          Product
        </p>
      ),
      dataIndex: "productName",
      key: "productName",
      align: "left",
      width: 120,
      render: (i) => {
        return (
          <div style={{ fontWeight: "bolder", fontSize: "16px" }}>{i}</div>
        );
      },
    },
    {
      title: () => (
        <p style={{ fontWeight: "bolder", height: "10px" }}>Description</p>
      ),
      dataIndex: "description",
      key: "description",
      align: "left",
      width: 200,
    },
    {
      title: () => (
        <p style={{ fontWeight: "bolder", textAlign: "left", height: "10px" }}>
          Price
        </p>
      ),
      dataIndex: "price",
      key: "price",
      align: "center",
      width: 80,
      render: () => {
        return (
          <Button
            // onClick={() => window.onclick(buttonLink, "_blank")}
            style={{
              backgroundColor: "#de923b",
              width: "100%",
              fontSize: "16px",
              height: "70px",
              marginBottom: "25px",
              borderRadius: "0px",
            }}
          >
            Check on <br /> Poultry Baba
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <h3 style={{ fontWeight: "bold" }}>Product Table</h3>
      <Table
        // title={<h3>Product Table</h3>}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        style={{
          borderTop: "8px solid #de923b",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          backgroundColor: "4283586137",
        }}
      />
    </>
  );
};

export default ProductTable;
