import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { message, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllCities } from "../../../Redux/ActionCreaters/citiesRequets";
import { AddCity } from "../../../RestServices/authService";
import "./rates-divs.css";
import Table from "./Table";

class Rates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonOn: false,
      province: "",
      division: "",
      city: "",
      district: "",
    };
  }

  addCitySubmit = async () => {
    console.log(this.state);
    const { city, division, province, district } = this.state;
    if (!city || !division || !province || !district) {
      return message.warn("some of fields are missings");
    }
    this.setState({ addCityModalLoading: true });
    try {
      const payload = {
        cities: [
          { name: city, value: city, division, district: district, province },
        ],
      };
      const { token } = this.props.Authentication;
      const responce = await AddCity(payload, token);
      this.props.getCities(token);
      console.log(responce.data);
      this.handleAddcityModalOnCancle();
    } catch (err) {
      console.log(err);
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    } finally {
      this.setState({ addCityModalLoading: false });
    }
  };

  selectIcon = () => {
    this.setState((event) => ({
      isButtonOn: !event.isButtonOn,
    }));
  };

  render() {
    return (
      <>
        <div
          onClick={this.selectIcon}
          style={{ cursor: "pointer", borderRadius: "8px" }}
          className="rates_div"
        >
          <div className="mid_div">
            <Typography.Title level={4} className="heading_div">
              {this.props.Heading}
            </Typography.Title>
            <img
              src={`image/${this.props.product}.png`}
              alt="pic"
              className="img_rate"
              style={{height:"80px", width:"auto"}}
            />
          </div>
          {/* <a href=" " className="link_rates">
            Edit City
          </a> */}

          <div className="button_div">
            <div style={{ color: "black", paddingRight: "10px", fontSize: "16px", fontWeight: 500 }}>
              {this.props.cities &&
                this.props.cities
                  .map((city) => {
                    if (
                      city.activeForProduct &&
                      city.activeForProduct.indexOf(this.props.product) !== -1
                    ) {
                      return 1;
                    }
                    return 0;
                  })
                  .reduce((a, b) => a + b, 0)}{" "}
              / {this.props.cities && this.props.cities.length}
            </div>

            <IconButton className="icon_Button">
              {this.state.isButtonOn ? (
                <RemoveIcon className="icon" style={{ fontSize: "25" }} />
              ) : (
                <AddIcon className="icon" style={{ fontSize: "25" }} />
              )}
            </IconButton>
          </div>
        </div>
        {this.state.isButtonOn ? (
          <Table {...this.props} cities={this.props.cities} />
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  Authentication: store.Authentication.user,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCities: (token) => {
      dispatch(getAllCities(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
