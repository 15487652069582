import {
  CITIES_LOADING,
  CITIES_SUCCESSFULL,
  CITIES_FAILED,
  CITIES_CHANGED,
} from "../actionTypes";

export const citiesLoading = () => ({
  type: CITIES_LOADING,
});

export const citiesSuccessfull = (cities) => ({
  type: CITIES_SUCCESSFULL,
  cities,
});

export const changeCity = (cities) => ({
  type: CITIES_CHANGED,
  cities,
});

export const citiesFailed = (errMess) => ({
  type: CITIES_FAILED,
  errMess,
});
