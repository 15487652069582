import { UserOutlined } from '@ant-design/icons';
import { Col, DatePicker, Image, message, Row } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCustomers } from '../../../RestServices/authService';
import { getTopFan } from '../../../RestServices/statService';
import { MAIN_BACKGROUND_COLOR } from '../../../styleConstants';
import AddUser from '../../AppUsers/AddUser';
import '../../Crm/crm.css';

export default function PostAndProfile({ profileDetail, monthCommission }) {
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const { token } = useSelector((state) => state.Authentication.user);
  const getUserApiFunc = async (user) => {
    let payload = {
      userId: user.userId,
    };

    try {
      let data = await getCustomers(token, payload);
      setSelectedUser(data.customers.docs[0]);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const onUserClick = (user) => {
    setUserDetailModal(true);
    setSelectedUser(user);
    getUserApiFunc(user);
  };
  return (
    <Col
      lg={20}
      md={24}
      sm={24}
      xs={24}
      style={{
        border: '1px black solid',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: '4px',
        borderRadius: '5px',
      }}
    >
      <AddUser
        isOpen={userDetailModal}
        closeModal={() => setUserDetailModal(false)}
        submitForm={() => setUserDetailModal(false)}
        isFormLoading={false}
        selectedUser={selectedUser}
      />
      <Row
        style={{
          borderBottom: '1px black solid',
          borderRadius: '5px',
        }}
      >
        <Col style={{ padding: '10px', backgroundColor: 'brown' }} span={4}>
          <DatePicker />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {profileDetail?.length}
          </div>
        </Col>
        <Col span={15}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h3>Post and Profile</h3>
          </div>
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: 'black',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingLeft: '5px',
          }}
          span={5}
        >
          <p>
            Total Post :{' '}
            <span className='NumberStyle'>
              {(() => {
                let postQuantity = 0;
                for (let com of monthCommission) {
                  if (
                    com._id === 'Grain Market' ||
                    com._id === 'Poultry Bazar' ||
                    com._id === 'Poultry Market' ||
                    com._id === 'Pets Point' ||
                    com._id === 'FOORI ZRORRAT' ||
                    com._id === 'Meri Zameen' ||
                    com._id === 'Meri Rozi'
                  ) {
                    postQuantity += com.quantity;
                  }
                }

                return postQuantity ? postQuantity : 0;
              })()}
            </span>
          </p>

          <p>
            Commision :{' '}
            <span className='NumberStyle'>
              {(() => {
                let total = 0;
                for (let value of monthCommission) {
                  total += value?.sumQuantity;
                }
                return total.toFixed(2);
              })()}
            </span>
          </p>
        </Col>

        <Col
          style={{
            color: 'black',
            padding: '5px',
          }}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          align='middle'
        >
          <div
            className='round-shape'
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>
              <p>
                Grain Market :{' '}
                <span className='NumberStyle'>
                  {(() => {
                    let postQuantity = 0;
                    for (let com of monthCommission) {
                      if (com._id === 'Grain Market') {
                        postQuantity += com.quantity;
                      }
                    }

                    return postQuantity ? postQuantity : 0;
                  })()}
                </span>
              </p>
            </span>

            <span>
              <p>
                {' '}
                Poultry Bazar :{' '}
                <span className='NumberStyle'>
                  {(() => {
                    let postQuantity = 0;
                    for (let com of monthCommission) {
                      if (com._id === 'Poultry Bazar') {
                        postQuantity += com.quantity;
                      }
                    }

                    return postQuantity ? postQuantity : 0;
                  })()}
                </span>
              </p>
            </span>

            <span>
              <p>
                {' '}
                Poultry Plaza:{' '}
                <span className='NumberStyle'>
                  {(() => {
                    let postQuantity = 0;
                    for (let com of monthCommission) {
                      if (com._id === 'Poultry Market') {
                        postQuantity += com.quantity;
                      }
                    }

                    return postQuantity ? postQuantity : 0;
                  })()}
                </span>
              </p>
            </span>
            <span>
              <p>
                {' '}
                Pet's Store :{' '}
                <span className='NumberStyle'>
                  {(() => {
                    let postQuantity = 0;
                    for (let com of monthCommission) {
                      if (com._id === 'Pets Point') {
                        postQuantity += com.quantity;
                      }
                    }

                    return postQuantity ? postQuantity : 0;
                  })()}
                </span>
              </p>
            </span>

            <span>
              <p>
                {' '}
                Foori Zrorat
                <span className='NumberStyle'>0</span>
              </p>
            </span>

            <span>
              <p>
                {' '}
                Meri Zameen
                <span className='NumberStyle'>0</span>
              </p>
            </span>
            <span>
              <p>
                {' '}
                Meri Rozi
                <span className='NumberStyle'>0</span>
              </p>
            </span>
          </div>
        </Col>
      </Row>

      <Row
        gutter={[12, 12]}
        justify='center'
        align='top'
        style={{ height: '100vh', color: 'black', padding: '20px' }}
      >
        <Col span={24}>
          {profileDetail?.map((value, index) => {
            return (
              <Row
                key={value?.user?.phoneNumber}
                gutter={[12, 12]}
                justify='center'
                align='middle'
                style={{ color: 'black', marginTop: '10px' }}
                className='postDetail'
                onClick={() => onUserClick(value?.user)}
              >
                <Col span={1}>{index + 1}</Col>
                <Col span={5}>{value?.user?.phoneNumber}</Col>
                <Col span={2}>
                  <Avatar
                    icon={
                      value?.user?.profileImage ? (
                        <Image
                          preview={false}
                          src={value?.user?.profileImage}
                          alt='profile'
                        />
                      ) : (
                        <UserOutlined />
                      )
                    }
                  />
                </Col>
                <Col span={3}>
                  <div
                    style={{
                      color: 'green',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <p style={{ padding: 0, margin: 0 }}>Total Post's</p>
                    <p style={{ padding: 0, margin: 0 }}>{value?.TotalCount}</p>
                  </div>
                </Col>
                <Col span={3} className='pap'>
                  <div
                    style={{
                      width: '7vw',
                      height: '7vw',
                      borderRadius: '100%',
                      display: 'flex',
                      background: '#67d700',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        fontSize: '12px',
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      Grain Market
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      {value?.GrainMarket}
                    </p>
                  </div>
                </Col>

                <Col span={3} className='pap'>
                  <div
                    style={{
                      width: '7vw',
                      height: '7vw',
                      borderRadius: '100%',
                      display: 'flex',
                      background: '#67d700',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      background: '#ff7e00',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        fontSize: '12px',
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      Poultry Bazar
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      {value?.PoultryBazar}
                    </p>
                  </div>
                </Col>

                <Col span={3} className='pap'>
                  <div
                    style={{
                      width: '7vw',
                      height: '7vw',
                      borderRadius: '100%',
                      display: 'flex',
                      background: '#67d700',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      background: '#a6a9e0',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        fontSize: '12px',
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      Poultry Plaza
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      {value?.PoultryMarket}
                    </p>
                  </div>
                </Col>
                <Col span={3} className='pap'>
                  <div
                    style={{
                      width: '7vw',
                      height: '7vw',
                      borderRadius: '100%',
                      display: 'flex',
                      background: '#67d700',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      background: '#de8cbd',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        fontSize: '12px',
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      Pet's Store
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      {value?.PetsPoint}
                    </p>
                  </div>
                </Col>
                <Col span={1}></Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
}
