import { ThumbDown, ThumbUp } from "@material-ui/icons";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { Button, Card, Col, Menu, message, Row, Typography } from "antd";
import React from "react";
import { connect } from "react-redux";
// import youTubeMetaData from "youtube-meta-data";
import {
  editVideo,
  getVideos,
  saveVideo,
  videoThumbnailUrl,
} from "../../RestServices/videosService";

import { videosEnum, videosService } from "../../shared";
import { MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import ContantTitle from "../../utils/contantTitle/ContantTitle";
import Calendar from "../../utils/dropDown/Calendar";
import CategoryDropDown from "../../utils/dropDown/CategoryDropDown";
import ProvinceDropDown from "../../utils/dropDown/ProvinceDropDown";
import Pagination from "../Pagination";
import Table from "../Table";
import DeleteVideo from "../UiElements/DeleteVideo";
import VideoPlayer from "../VideoPlayer";
import AddVideo from "./AddVideo";

const videoUrl = `${videosService}/api/v1/customer/playVideo`;
const styles = {
  tableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  addVideoButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addVideoButton: {
    marginRight: "20px",
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: "white",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "5px",
  },
  titleContainer: {
    padding: "10px 20px",
    color: "#fb6222",
  },
};

// LIKE", "CLAP", "ANGRY"
const columns = [
  {
    Header: "Sr.",
    accessor: "serialNumber",
  },
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>Video ID</p>
        <p style={{ margin: 0, padding: 0, color: "black" }}>Date/Time</p>
      </div>
    ),
    accessor: "_id",
  },
  {
    Header: "video",
    accessor: "image",
  },
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>title</p>
        <p style={{ margin: 0, padding: 0, color: "black" }}>discription</p>
      </div>
    ),
    accessor: "title",
  },
  {
    Header: "Engagements",
    accessor: "reactions",
  },
  {
    Header: "Views",
    accessor: "views",
  },
  {
    Header: "Tags",
    accessor: "tags",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

class Body extends React.Component {
  constructor() {
    super();
    this.state = {
      isFormLoading: false,
      isEditFormLoading: false,
      uploadedPercentage: 0,
      videos: [],
      allVideo: [],
      isModalEdit: false,
      isModalOpen: false,
      videoId: "",
      title: "demmy",
      description: "demmy",
      video: null,
      deleteModal: false,
      thumbnail: null,
      totalPages: 0,
      tags: ["dummy"],
      videoUri: "",
      currentPage: 0,
      playingVideo: null,
      isVideoPlayerOpen: false,
      selectedCategory: "DOCTORS_CORNER",
      province: "پنجاب",
    };
  }

  onAddCategoryChange = (selectedCategory) =>
    this.setState({ selectedCategory });

  openVideoPlayer = () => this.setState({ isVideoPlayerOpen: true });
  closeVideoPlayer = () => this.setState({ isVideoPlayerOpen: false });
  openVideoForm = () => this.setState({ isModalOpen: true });
  closeVideoForm = () => this.setState({ isModalOpen: false });
  closeVideoEditFrom = () =>
    this.setState({ isModalEdit: false, title: "", description: "", tags: [] });

  inputChangeHandler = (evt, textNmae) => {
    const { name, value } = evt.target;
    if (name) {
      this.setState({ [name]: value });
    } else {
      this.setState({ [textNmae]: value });
    }
  };

  selectVideo = (video) => this.setState({ video });
  selectThumbnail = (thumbnail) => this.setState({ thumbnail });

  onVideoCategoryChange = (selectedCategory) =>
    this.setState({ selectedCategory }, this.getVideos);

  submitVideo = async () => {
    const { title, description, videoUri, tags } = this.state;
    if (!videoUri) {
      return message.warn("Please fill all the required Fields");
    }
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = videoUri.match(regExp);
    if (!match) {
      return message.error("Please put a valid url of video");
    }
    this.setState({ isFormLoading: true });
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    // formData.append("video", video);
    formData.append("videoUri", videoUri);
    // formData.append("thumbnail", thumbnail);

    for (let i = 0; i < tags.length; i++) {
      formData.append("tags[]", tags[i]);
    }

    formData.append("category", this.state.selectedCategory);
    try {
      await saveVideo(formData, this.props.Auth.user.token, (percentage) =>
        this.setState({ uploadedPercentage: percentage }),
      );
      this.resetForm();
      message.success("Video Successfully Uploaded");
      this.getVideos();
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  deleteModalToggle = () =>
    this.setState({ deleteModal: !this.state.deleteModal });
  videoEditSubmit = async () => {
    this.setState({ isEditFormLoading: true });
    const { title, description, tags, videoUri, videoId } = this.state;

    try {
      await editVideo(
        { title, description, videoUri, tags, _id: videoId },
        this.props.Auth.user.token,
        (percentage) => this.setState({ uploadedPercentage: percentage }),
      );
      this.resetForm();
      message.success("Video Successfully Uploaded");
      this.getVideos();
    } catch (err) {
      this.setState({ isEditFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  resetForm = () =>
    this.setState({
      title: "",
      description: "",
      video: null,
      isFormLoading: false,
      isModalOpen: false,
      allVideo: [],
      isEditFormLoading: false,
      uploadedPercentage: 0,
      isModalEdit: false,
      videoUri: "",
    });

  getVideos = async (pageNo) => {
    try {
      const data = await getVideos(
        { categoryName: this.state.selectedCategory, pageNo },
        this.props.Auth.user.token,
      );

      const videos = data.videos.docs.map((video, index) => ({
        serialNumber: `${data.videos.page - 1}` + index,
        _id: (
          <div>
            <p>{video._id.slice(0, 8)}</p>
            <p>{new Date(video.createdAt).toDateString()} </p>
          </div>
        ),
        title: (
          <div style={{ width: "150px" }}>
            <Typography.Paragraph
              type="warning"
              style={{ textDecoration: "underline", fontSize: "12px" }}
              ellipsis={{
                rows: 1,
                tooltip: video.description,
              }}
            >
              {video.title}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{ fontSize: "10px", fontWeight: "400" }}
              ellipsis={{
                rows: 1,
                tooltip: video.description,
              }}
            >
              {video.description}
            </Typography.Paragraph>
          </div>
        ),
        tags: (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {" "}
            <Typography.Paragraph
              style={{ fontSize: "10px", fontWeight: "400" }}
              ellipsis={{
                rows: 2,
                tooltip: video.tags?.join(", "),
              }}
            >
              {video.tags
                ? video.tags.map((tag, index) => {
                  return (
                    <Typography.Text
                      style={{
                        border: "1px solid gray",
                        padding: "2px",
                        margin: "2px",
                      }}
                    >
                      {tag}
                    </Typography.Text>
                  );
                })
                : null}
            </Typography.Paragraph>
          </div>
        ),
        views: video.views,
        reactions: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <ThumbUp style={{ color: "#de923b" }} />{" "}
              {video.reactions
                .map((react) => {
                  if (react.reaction === "LIKE") {
                    return 1;
                  }
                  return 0;
                })
                .reduce((a, b) => a + b, 0)}
            </div>
            <div>
              <ThumbDown />{" "}
              {video.reactions
                .map((react) => {
                  if (react.reaction === "ANGRY") {
                    return 1;
                  }
                  return 0;
                })
                .reduce((a, b) => a + b, 0)}
            </div>
            <div>
              👏
              {video.reactions
                .map((react) => {
                  if (react.reaction === "CLAP") {
                    return 1;
                  }
                  return 0;
                })
                .reduce((a, b) => a + b, 0)}
            </div>
          </div>
        ),
        image: (
          <img
            style={{ width: "150px" }}
            src={
              video.thumbnailUri.startsWith("https://i.ytimg.com")
                ? video.thumbnailUri
                : videoThumbnailUrl(video._id)
            }
            alt={video.title}
          />
        ),
        viewsTime: "10",
        createdAt: new Date(video.createdAt).toLocaleDateString(),
        action: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {" "}
            <Button
              onClick={(e) => this.onEditClick(e, video)}
              style={{ marginRight: "10px" }}
              type="primary"
            >
              Edit
            </Button>{" "}
            <br />
            <DeleteVideo
              deleteModalFuc={this.deleteModalToggle}
              video={video}
            />
          </div>
        ),
      }));
      this.setState({
        videos,
        allVideo: data.videos,
        totalPages: data.videos.totalDocs,
        currentPage: data.videos.page,
      });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  onEditClick = (e, data) => {
    e.stopPropagation();
    const { tags, title, description, _id, videoUri } = data;
    this.setState({
      videoId: _id,
      tags,
      title,
      description,
      videoUri,
      isModalEdit: true,
    });
  };
  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getVideos(this.state.currentPage);
    });
  };
  videoCategory = (
    <Menu
      selectedKeys={[this.state && this.state.selectedCategory]}
      onClick={({ key }) => this.onVideoCategoryChange(key)}
    >
      <Menu.Item key="DOCTORS_CORNER">DOCTORS_CORNER</Menu.Item>
      <Menu.Item key="TIPS">TIPS</Menu.Item>
    </Menu>
  );
  componentDidMount() {
    this.getVideos();
  }
  rowClickHandler = (video, envt, index) => {
    if (this.state.isModalEdit || this.state.deleteModal) {
      return;
    }
    const vadio = this.state.allVideo.docs[index];
    this.setState({ playingVideo: vadio, isVideoPlayerOpen: true });
  };
  changeProvince = (province) => {
    this.setState({ province });
  };
  handleChangeTags = (tags) => {
    this.setState({ tags });
  };
  render() {
    const {
      videos,
      totalPages,
      currentPage,
      isVideoPlayerOpen,
      video,
      isEditFormLoading,
      playingVideo,
      title,
      description,
      isModalOpen,
      isFormLoading,
      thumbnail,
      selectedCategory,
      videoUri,
      tags,
    } = this.state;
    const { Title } = Typography;
    return (
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ padding: "10px" }}>
              <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <div className="flex-between">
                  <div style={{ width: "100%" }}>
                    <Row gutter={[12, 12]}>
                      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                        <div className="flex-col">
                          <Title level={4} className="mb-0 text-primary">Select Province</Title>
                          <ProvinceDropDown
                            selectProvince={this.changeProvince}
                            selectedProvince={this.state.province}
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                        <div className="flex-col">
                          <Title level={4} className="mb-0 text-primary">Select Category</Title>
                          <CategoryDropDown
                            category={selectedCategory}
                            categoriesOptions={videosEnum}
                            setCategory={this.onVideoCategoryChange}
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <div className="flex-col">
                          <div className="flex-row">
                            <DateRangeOutlinedIcon />
                            <Title level={4} className="mb-0 text-primary">From</Title>
                          </div>
                          <Calendar />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <div className="flex-col">
                          <div className="flex-row">
                            <DateRangeOutlinedIcon />
                            <Title level={4} className="mb-0 text-primary">To</Title>
                          </div>
                          <Calendar />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Button type="primary" onClick={this.openVideoForm}>
                      Upload Video
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ padding: "10px" }}>
              <Card>
                <Table
                  onRowClick={this.rowClickHandler}
                  data={videos}
                  columns={columns}
                />
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="flex-center" style={{ padding: "10px" }}>
              <div>
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={this.onPageChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        {isVideoPlayerOpen && (
          <VideoPlayer
            videoTitle={playingVideo && playingVideo.title}
            closePlayer={this.closeVideoPlayer}
            isPlayerOpen={isVideoPlayerOpen}
            url={
              playingVideo &&
              (playingVideo.videoUri.startsWith("https://www.youtube.com")
                ? playingVideo.videoUri
                : `${videoUrl}?videoId=${playingVideo._id}&customerId=${this.props.Auth.user._id}`)
            }
          />
        )}
        <AddVideo
          videoUri={videoUri}
          title={title}
          category={selectedCategory}
          setCategory={this.onAddCategoryChange}
          categoriesOptions={videosEnum}
          closeModal={this.closeVideoForm}
          description={description}
          inputHandler={this.inputChangeHandler}
          selectVideo={this.selectVideo}
          video={video}
          isOpen={isModalOpen}
          tags={tags}
          isNotShow={false}
          handleChangeTags={this.handleChangeTags}
          isFormLoading={isFormLoading}
          submitVideo={this.submitVideo}
          selectThumbnail={this.selectThumbnail}
          thumbnail={thumbnail}
          uploadedPercentage={this.state.uploadedPercentage}
        />
        <AddVideo
          videoUri={videoUri}
          title={title}
          isNotShow={true}
          category={selectedCategory}
          setCategory={this.onAddCategoryChange}
          categoriesOptions={videosEnum}
          closeModal={this.closeVideoEditFrom}
          description={description}
          inputHandler={this.inputChangeHandler}
          selectVideo={this.selectVideo}
          video={video}
          isOpen={this.state.isModalEdit}
          tags={tags}
          handleChangeTags={this.handleChangeTags}
          isFormLoading={isEditFormLoading}
          submitVideo={this.videoEditSubmit}
          selectThumbnail={this.selectThumbnail}
          thumbnail={thumbnail}
          uploadedPercentage={this.state.uploadedPercentage}
        />
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(Body);
