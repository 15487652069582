import React from 'react';
import { Table, Row, Col, Switch } from 'antd';
import './body.css';
import moment from 'moment';
import { deleteCommission } from '../../RestServices/postingService';
import { useSelector } from 'react-redux';
import { EditOutlined, DeleteForever } from '@material-ui/icons';

export default function TableComponent({
  poster,
  setEditModal,
  edit,
  setEdit,
}) {
  const { token } = useSelector((state) => state.Authentication.user);

  const onChange = async (data) => {
    const { _id: commissionId } = data;
    try {
      await deleteCommission(
        {
          commissionId,
        },
        token
      );
      setEdit(!edit);
    } catch (err) {
      console.log(err);
    }
  };
  const columns = [
    {
      title: (
        <span
          style={{
            textAlign: 'left',
            alignSelf: 'start',
          }}
        >
          <b>Sr #</b>
        </span>
      ),
      dataIndex: 'sr',
      key: 'sr',
      width: '5%',
      align: 'center',
    },
    {
      title: (
        <span>
          <b>
            Commission ID <br />{' '}
            <span style={{ color: 'black' }}> Date/Time</span>{' '}
          </b>
        </span>
      ),
      dataIndex: '_id',
      key: '_id',
      width: '12%',
      align: 'left',
      render: (id, row, index) => {
        return (
          <Row align='middle' justify='start'>
            <Col span={24}>
              <Row align='top' justify='start'>
                {row?._id}
              </Row>
              <Row align='middle' justify='start'>
                <Col span={24}>
                  <b>{moment(row?.createdAt).format('MMMM Do YYYY')}</b>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
    },

    {
      title: (
        <span>
          <b>Category</b>
        </span>
      ),
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      align: 'left',
    },

    {
      title: (
        <span>
          <b>Sub Category</b>
        </span>
      ),
      dataIndex: 'subCategory',
      key: 'subCategory',
      width: '20%',
      align: 'left',
    },
    {
      title: (
        <span>
          <b>Commission</b>
        </span>
      ),
      dataIndex: 'commission',
      key: 'commission',
      width: '10%',
      align: 'left',
    },

    {
      title: (
        <span>
          <b>Action</b>
        </span>
      ),
      dataIndex: 'isActive',
      key: 'isActive',
      width: '20%',
      align: 'center',
      render: (id, row, index) => {
        return (
          <Row align='middle' justify='center'>
            <Col span={24}>
              <Row align='middle' justify='space-around'>
                <Col span={8} onClick={() => setEditModal(true, row)}>
                  <EditOutlined />
                </Col>
                <Col style={{ cursor: 'pointer' }} span={8}>
                  <DeleteForever onClick={(e) => onChange(row)} />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={poster} />;
}
