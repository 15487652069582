import { Button, Card, Col, Image, Menu, message, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
  activeThePost,
  expireThePost,
  getPosts,
} from '../../RestServices/postingService';
import { formateString, postType, videosService } from '../../shared';
import { MAIN_BACKGROUND_COLOR } from '../../styleConstants';
import ContantTitle from '../../utils/contantTitle/ContantTitle';
import CategoryDropDown from '../../utils/dropDown/CategoryDropDown';
import ProvinceDropDown from '../../utils/dropDown/ProvinceDropDown';
import Pagination from '../Pagination';
import Table from '../Table';
import DeleteVideo from '../UiElements/DeleteVideo';

const styles = {
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  addVideoButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addVideoButton: {
    marginRight: '20px',
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: 'white',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
  },
  titleContainer: {
    padding: '10px 20px',
    color: '#fb6222',
  },
};
// address: ""
// city: "Bahawalpur"
// createdAt: "2021-09-07T14:42:27.154Z"
// customerId: "60aa502074389c916a45d1fd"
// description: "test post"
// expireAfter: "2021-09-12T14:42:27.152Z"
// id: "61377a5335a458845bdebee4"
// imagePaths: [,…]
// isActive: false
// isBlock: false
// isFeatured: true
// postDetails: {type: "Poultry Market", subType: "incubators", capacity: 609}
// capacity: 609
// subType: "incubators"
// type: "Poultry Market"
// postLocation: {type: "Point", coordinates: []}
// title: "Place holder"

// LIKE", "CLAP", "ANGRY"
const columns = [
  {
    Header: 'User Picture',
    accessor: 'userPicture',
  },
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>Created By</p>
        <p style={{ margin: 0, padding: 0, color: 'black' }}>Name/Email</p>
      </div>
    ),
    accessor: 'customerDetailPost',
  },
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>Post ID</p>
        <p style={{ margin: 0, padding: 0, color: 'black' }}>Date/Time</p>
      </div>
    ),
    accessor: '_id',
  },

  {
    Header: 'image',
    accessor: 'image',
  },
  {
    Header: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>Title</p>
        <p style={{ margin: 0, padding: 0, color: 'black' }}>discription</p>
      </div>
    ),
    accessor: 'title',
  },
  {
    Header: 'Expire At',
    accessor: 'expireAfter',
  },

  {
    Header: 'Creat At',
    accessor: 'createdAt',
  },
  {
    Header: 'Featured',
    accessor: 'isFeatured',
  },

  {
    Header: 'City',
    accessor: 'city',
  },

  {
    Header: 'Views',
    accessor: 'views',
  },
  {
    Header: 'Visits',
    accessor: 'visits',
  },

  {
    Header: 'Action',
    accessor: 'action',
  },
];

class PostShower extends React.Component {
  constructor() {
    super();
    this.state = {
      allVideo: [],
      posts: [],
      videoId: '',
      totalPages: 0,
      currentPage: 1,
      selectedCategory: '',
      province: '',
    };
  }

  activePost = async (postId) => {
    try {
      await activeThePost({ postId }, this.props.Auth.user.token);
    } catch (err) {
      console.log(err);
    } finally {
      this.getPostsApiFunc(this.state.currentPage);
    }
  };

  makeExpired = async (postId) => {
    try {
      await expireThePost({ postId }, this.props.Auth.user.token);
    } catch (err) {
      console.log(err);
    } finally {
      this.getPostsApiFunc(this.state.currentPage);
    }
  };

  onAddCategoryChange = (selectedCategory) =>
    this.setState({ selectedCategory });

  onVideoCategoryChange = (selectedCategory) =>
    this.setState({ selectedCategory }, this.getPostsApiFunc);

  deleteModalToggle = () =>
    this.setState({ deleteModal: !this.state.deleteModal });

  resetForm = () =>
    this.setState({
      allVideo: [],
    });

  getPostsApiFunc = async (pageNo) => {
    try {
      const data = await getPosts(
        { type: this.state.selectedCategory, pageNo },
        this.props.Auth.user.token
      );

      console.log("DDD",data);
      const posts = data?.posts?.docs?.map((post, index) => ({
        userPicture: (
          <Image
            style={{ width: '150px', height: '150' }}
            src={post?.customerProfile?.profileImage}
          />
        ),

        customerDetailPost: (
          <div>
            <p>{post?.customerProfile?.name}</p>
            <p>
              {' '}
              <a
                style={{ color: 'blue' }}
                href={`tel:${post?.customerProfile?.phoneNumber}`}
              >
                {post?.customerProfile?.phoneNumber}
              </a>{' '}
            </p>
          </div>
        ),

        _id: (
          <div>
            <p>{post._id.slice(0, 8)}</p>
            <p>{new Date(post.createdAt).toDateString()} </p>
          </div>
        ),
        title: (
          <div style={{ width: '150px' }}>
            <Typography.Paragraph
              type='warning'
              style={{ textDecoration: 'underline', fontSize: '12px' }}
              ellipsis={{
                rows: 1,
                tooltip: post.description,
              }}
            >
              {post.title}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{ fontSize: '10px', fontWeight: '400' }}
              ellipsis={{
                rows: 1,
                tooltip: post.description,
              }}
            >
              {post.description}
            </Typography.Paragraph>
          </div>
        ),
        expireAfter: moment(post.expireAfter).format(formateString),
        isFeatured: post.isFeatured ? 'Featured' : 'Not Featured',
        city: post.city,
        views: post.views,
        visits: post.visits,

        image: (
          <Image
            style={{ width: '150px' }}
            src={post.imagePaths ? post.imagePaths[0] : ''}
            alt={post.title}
          />
        ),
        createdAt: moment(post.createdAt).format(formateString),
        action: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <br />
            {post.isActive ? (
              <Button
                type={'ghost'}
                style={{ background: 'red' }}
                onClick={() => this.makeExpired(post._id)}
              >
                Make Inactive
              </Button>
            ) : (
              <Button
                type={'primary'}
                onClick={() => this.activePost(post._id)}
              >
                Make Active
              </Button>
            )}
          </div>
        ),
      }));
      this.setState({
        posts,
        allVideo: data.posts,
        totalPages: data.posts.totalDocs,
        currentPage: data.posts.page,
      });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getPostsApiFunc(this.state.currentPage);
    });
  };
  videoCategory = (
    <Menu
      selectedKeys={[this.state && this.state.selectedCategory]}
      onClick={({ key }) => this.onVideoCategoryChange(key)}
    >
      <Menu.Item key='DOCTORS_CORNER'>DOCTORS_CORNER</Menu.Item>
      <Menu.Item key='TIPS'>TIPS</Menu.Item>
    </Menu>
  );
  componentDidMount() {
    this.getPostsApiFunc();
  }
  rowClickHandler = (video, envt, index) => {
    if (this.state.isModalEdit || this.state.deleteModal) {
      return;
    }
    const vadio = this.state.allVideo.docs[index];
    this.setState({ playingVideo: vadio, isVideoPlayerOpen: true });
  };
  changeProvince = (province) => {
    this.setState({ province });
  };
  handleChangeTags = (tags) => {
    this.setState({ tags });
  };
  render() {
    const {
      posts,
      totalPages,
      currentPage,

      selectedCategory,
    } = this.state;

    const { Title } = Typography;
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <div className="flex-between">
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                      <div className="flex-col">
                        <Title level={2} className="mb-0 text-primary" style={{height: "50px", lineHeight: "50px"}}>All Posts</Title>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div className="flex-col">
                        <Title level={4} className="mb-0 text-primary">Select Category</Title>
                        <CategoryDropDown
                          category={selectedCategory}
                          categoriesOptions={postType}
                          setCategory={this.onVideoCategoryChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px" }}>
            <Table
              onRowClick={this.rowClickHandler}
              data={posts}
              columns={columns}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
            <Pagination
              current={currentPage}
              total={totalPages}
              onPageChange={this.onPageChange}
            />
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(PostShower);
