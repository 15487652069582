import { Button, Col, Modal, Row } from "antd";
import React from "react";
import { MAIN_BACKGROUND_COLOR } from "../../../styleConstants";
import ProductInput from "./productInputState";
import ProductList from "./productList";

const styles = {
  modal: {
    backgroundColor: "white",
  },
  submitButton: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    width: "100%",
    border: "none",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

const ProductTable = ({
  tableModelOpen,
  setTableModelOpen,
  setproductDetail,
  productDetails,
}) => {
  const ResetTable = () => {
    setproductDetail([
      {
        productName: "",
        subHeading: "",
        description: "",
        buttonText: "",
        buttonLink: "",
        tagName: "",
        isFormLoading: false,
        uploadedPercentage: 0,
        selectedThemnail: "",
        articleImage: "",
      },
    ]);
  };

  const OnInsertTable = () => {
    setproductDetail([
      ...productDetails,
      {
        productName: "",
        subHeading: "",
        description: "",
        buttonText: "",
        buttonLink: "",
        tagName: "",
        isFormLoading: false,
        uploadedPercentage: 0,
        selectedThemnail: "",
        articleImage: "",
      },
    ]);
  };

  console.log(productDetails, "product detail");
  return (
    <Modal
      bodyStyle={styles.modal}
      width={800}
      title="Product Table"
      visible={tableModelOpen} // isOpen
      // closable={() => setIsModelOpen(!isModelOpen)}
      confirmLoading={true}
      onCancel={() => setTableModelOpen(!tableModelOpen)}
      footer={null}
      maskClosable={false}
    >
      {productDetails?.map((value, index) => {
        return (
          <ProductInput
            setproductDetail={setproductDetail}
            productDetails={productDetails}
            productDetail={value}
            index={index}
          />
        );
      })}
      <Row gutter={[64, 64]} justify="start" style={{ marginTop: "30px" }}>
        <Col span={2}></Col>
        <Col span={6}>
          <Button
            onClick={OnInsertTable}
            className="headingColor"
            style={{
              backgroundColor: "#de923b",
              // width: '250px',
              height: "50px",
              fontWight: "bold",
              fontSize: "22px",
            }}
          >
            add More Product
          </Button>
        </Col>
        <Col span={2}></Col>
        <Col span={8}>
          <Button
            onClick={ResetTable}
            className="headingColor"
            style={{
              // backgroundColor: '#de923b',
              // width: '250px',
              height: "50px",
              fontWight: "bold",
              fontSize: "22px",
            }}
          >
            Reset Table
          </Button>
        </Col>
      </Row>
      <div style={{ paddingTop: "20px" }}>
        <ProductList dataSource={productDetails} />;
      </div>
    </Modal>
  );
};

export default ProductTable;
