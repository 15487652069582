import React from "react";
import { Bar } from "react-chartjs-2";

const CategoryChart = () => {
  // const data = [{
  //   type: 'white Egg',
  //   sales: 45,
  // },
  // {
  //   type: 'Brwon Egg',
  //   sales: 52,
  // },
  // {
  //   type: 'Layer Chick',
  //   sales: 70,
  // },
  // {
  //   type: 'Brown Chick',
  //   sales: 69,
  // },
  // {
  //   type: 'Broiler',
  //   sales: 53,
  // },
  // {
  //   type: 'layer',
  //   sales: 38,
  // },
  // {
  //   type: 'brown Layer',
  //   sales: 38,
  // },
  // {
  //   type: 'corn',
  //   sales: 38,
  // },
  // ];

  const data = {
    labels: [
      "Faisalabad",
      "Lahore",
      "Karachi",
      "Rawalpindi",
      "Saiwal",
      "Multan",
      "Peshawar",
      "Gujrat",
      "Marre",
      "Islamabad",
    ],
    datasets: [
      {
        label: "label",
        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(2, 0, 36, 0.5)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],

        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
      <Bar
        data={data}
        options={options}
        // width={20}
        // height={200}
        options={{ maintainAspectRatio: false }}
      />
  );
};

export default CategoryChart;
