import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
// import BillIcon from "@material-ui/icons/NotificationImportantTwoTone";
import { BsBell } from "react-icons/bs";
import {
  Avatar,
  Badge,
  Divider,
  Input,
  Menu,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import BillIcon from "@material-ui/icons/NotificationImportantTwoTone";
import SearchIcon from "@material-ui/icons/Search";
import {
  UserOutlined,
} from "@ant-design/icons";
import { AiOutlineCaretDown } from "react-icons/ai";
import {
  ADMINCHANEL,
  FEEDBACKCOME,
  RemoveColonFromPermission,
  UserPermisstion,
} from "../../shared";
import "./inputText.css";
import LogOut from "./LogOut";
import AudioSrc from "./short.mp3";
import "./header.css";
import menuIcon from "../../assets/Dashboard Icons/menuIcon.svg";
import notifyIcon from "../../assets/Dashboard Icons/notify.svg";
import messageIcon from "../../assets/Dashboard Icons/message.svg";
import { Link } from "react-router-dom";
import { getAdminOrSubAdmins } from "../../RestServices/authService";
import SearchPostModal from "./SearchModal/searchPostModal";
import SearchUserModal from "./SearchModal/searchUserModal";

const pusher = window.pusher;
const styles = {
  container: {
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    fontSize: "30px",
    color: "white",
    cursor: "pointer",
    marginLeft: "5px",
  },
  avatarContainer: {
    marginRight: "40px",
  },
  userNameText: {
    marginRight: "10px",
    fontWeight: "500",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "16px",
    color: "#4A0600",
    paddingLeft: "5px",
  },
};
const HeaderContent = ({ onNotification, ...props }) => {
  const { Title } = Typography;
  const [noficicationCount, setnoficicationCount] = useState(0);
  const { permissions } = useSelector(
    (state) => state.Authentication.user.profileDetails,
  );

  const { token } = useSelector((state) => state.Authentication.user);

  useEffect(() => {
    const PermissionsRomovingColor = RemoveColonFromPermission(permissions);
    try {
      var channel = pusher.subscribe(ADMINCHANEL);
      channel.bind(FEEDBACKCOME, function (data) {
        if (
          PermissionsRomovingColor.includes(
            UserPermisstion[0].value.split(":")[0],
          ) || PermissionsRomovingColor.includes("FeedBack")
            ? true
            : false
        ) {
          onNotification(data.message.unSeenCount);
          if (data.message.unSeenCount) {
            new Audio(AudioSrc).play();
          }
          setnoficicationCount(data.message.unSeenCount);
        }
      });
    } catch (err) { }
  }, []);

  const [searchUserModal, setSearchUserModal] = useState(false);

  const showModalSearchUser = () => {
    setSearchUserModal(true);
  };

  const handleOkSearchUser = () => {
    setSearchUserModal(false);
  };

  const handleCancelSearchUser = () => {
    setSearchUserModal(false);
  };

  const [postModal, setPostModal] = useState(false);

  const showModalPost = () => {
    setPostModal(true);
  };

  const handleOkPost = () => {
    setPostModal(false);
  };

  const handleCancelPost = () => {
    setPostModal(false);
  };

  const [crmUsers, setCrmUsers] = useState([]);

  useEffect(() => {
    (async () => {
      let crmData = await getAdminOrSubAdmins(token);
      const CRMcustomers = crmData.customers.map((customer) => ({
        name: customer.name,
        _id: customer._id,
        email: customer.email,
        profileDetails: customer.profileDetails,
      }));
      setCrmUsers(CRMcustomers);
    })();
  }, [token]);

  return (
    <>
      <SearchUserModal
        handleOk={handleOkSearchUser}
        isModalOpen={searchUserModal}
        handleCancel={handleCancelSearchUser}
      />

      <SearchPostModal
        handleOk={handleOkPost}
        isModalOpen={postModal}
        handleCancel={handleCancelPost}
      />
      <div className="main__header">
        <div className="flex-row">
          <div className="left__header" style={{ marginRight: "20px", marginLeft: "10px" }}>
            <span>
              {props.collapsed ? (
                <span onClick={props.toggleSider} style={{ cursor: "pointer" }}>
                  <img src={menuIcon} alt="drawer icon" width={36} height={36} />
                </span>
              ) : (
                <span onClick={props.toggleSider} style={{ cursor: "pointer" }}>
                  <img src={menuIcon} alt="drawer icon" width={36} height={36} />
                </span>
              )}

            </span>
          </div>
          <div className="flex-row search_boxes">
            <div style={{ marginRight: "10px" }}>
              <Input
                prefix={<SearchIcon />}
                style={{ width: '200px' }}
                type="text"
                onClick={showModalSearchUser}
                placeholder="Search User"
                name={props.name}
                onChange={props.onChange}
              />
            </div>
            <div>
              <Input
                prefix={<SearchIcon />}
                style={{ width: '200px' }}
                onClick={showModalPost}
                type="text"
                placeholder="Search Post"
                name={props.name}
                onChange={props.onChange}
              />
            </div>
          </div>
        </div>
        <div className="right__header">
          {/* <Badge count={noficicationCount} className="header__icon header__icon__msg">
            <div>
              <img src={messageIcon} alt="notify icon" width={36} height={36} />
            </div>
          </Badge> */}
          <Divider type="vertical" />

          <div className="user__profile">
            <div className="user__profile__box">
              <LogOut />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  Authentication: store.Authentication,
});

export default connect(mapStateToProps)(HeaderContent);
