import { Button, Col, DatePicker, Input, message, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  checkUserExitAgainstNumber,
  findCustomerWithPhoneNumbers,
} from '../../../RestServices/authService';
import { phoneStatuses, ReasonOfBlocked } from '../../../shared';
import { MAIN_BACKGROUND_COLOR } from '../../../styleConstants';
import { Menu, Dropdown } from 'antd';

export default function LinkSended({ phoneList, onUpdateContactStatus }) {
  const { token } = useSelector((state) => state.Authentication.user);

  const [otherPhoneNumber, setOtherPhoneNumber] = useState('');

  const [visibleDropDown, setVisibleDropDown] = useState(false);

  const [phoneNumberDropDown, setPhoneNumberDropDown] = useState(false);

  const updateUser = async (payload, blocked, cond) => {
    try {
      let phoneList = {};
      let payloadNumber;
      if (!cond) {
        payloadNumber = {
          phoneNumbers: [payload.phoneNumber.trim()],
        };

        try {
          const { customerList } = await findCustomerWithPhoneNumbers(
            token,
            payloadNumber
          );
          phoneList = customerList;

          if (phoneList.customerList.length) {
            onUpdateContactStatus({
              _id: payload._id,
              taskId: payload.taskId,
              status: phoneStatuses.APP_DOWNLOADED,
              blocked: otherPhoneNumber.trim(),
              phone: payload.phoneNumber.trim(),
            });
            setOtherPhoneNumber('');
          } else {
            return message.error('User not found');
          }
        } catch (err) {
          if (err.response) {
            return message.error(err.response.data.message);
          }
          return message.error(err.message);
        }
      } else {
        if (otherPhoneNumber.length < 8) {
          return message.error('Please enter full number');
        }

        payloadNumber = {
          phoneNumber: otherPhoneNumber.trim(),
        };
        try {
          const { exit } = await checkUserExitAgainstNumber(
            token,
            payloadNumber
          );
          if (!exit) {
            onUpdateContactStatus({
              _id: payload._id,
              taskId: payload.taskId,
              status: phoneStatuses.APP_DOWNLOADED,
              blocked: otherPhoneNumber.trim(),
              phone: otherPhoneNumber.trim(),
            });
            setOtherPhoneNumber('');
          }
        } catch (err) {
          if (err.response) {
            return message.error(err.response.data.message);
          }
          return message.error(err.message);
        }
      }
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const rejectUser = async (payload, blocked) => {
    try {
      await onUpdateContactStatus({
        _id: payload._id,
        taskId: payload.taskId,
        status: phoneStatuses.blocked,
        blocked,
      });

      setVisibleDropDown(false);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const subManu = (payload) => {
    return (
      <Menu>
        <Menu.Item>
          <p>Phone number</p>
          <Input
            name='phoneNumber'
            value={otherPhoneNumber}
            onChange={(e) => setOtherPhoneNumber(e.target.value)}
          />
        </Menu.Item>
        <Menu.Item>
          <Button
            onClick={() => {
              updateUser(payload, otherPhoneNumber, true);
            }}
            type={'primary'}
            style={{ width: '100%' }}
          >
            Submit
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const menu = (payload) => {
    return (
      <Menu>
        <Menu.Item style={{ margin: '10px' }}>
          <Button
            onClick={() => {
              updateUser(payload);
            }}
            type={'primary'}
            style={{ width: '100%' }}
          >
            OK
          </Button>
        </Menu.Item>
        {ReasonOfBlocked?.map((value) => (
          <Menu.Item style={{ margin: '10px' }} key={value}>
            <Button
              onClick={() => {
                rejectUser(payload, value);
              }}
              type={'primary'}
              style={{ width: '100%' }}
            >
              {value}
            </Button>
          </Menu.Item>
        ))}
        <Menu.Item>
          <Dropdown
            visible={phoneNumberDropDown}
            overlay={() => subManu(payload)}
            placement='topLeft'
            onVisibleChange={setPhoneNumberDropDown}
          >
            <Button type={'primary'} style={{ width: '100%' }}>
              Other Phone number
            </Button>
          </Dropdown>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Col
      lg={8}
      md={16}
      sm={20}
      xs={20}
      style={{
        border: '1px black solid',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: '4px',
        borderRadius: '5px',
      }}
    >
      <Row
        style={{
          borderBottom: '1px black solid',
          borderRadius: '5px',
        }}
      >
        <Col span={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h3>Link Sended</h3>
          </div>
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          span={6}
        >
          Total: {phoneList?.length}
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
          }}
          span={6}
        >
          <Button
            onClick={() => {
              // eslint-disable-next-line array-callback-return
              phoneList?.map((value, index) => {
                updateUser(value);
              });
            }}
          >
            Check All
          </Button>
        </Col>
      </Row>

      <Row
        gutter={[12, 12]}
        justify='center'
        align='top'
        style={{ height: '100vh', color: 'black', padding: '20px' }}
      >
        <Col span={24}>
          {phoneList?.map((value, index) => {
            return (
              <Row
                key={value.phoneNumber}
                gutter={[12, 12]}
                justify='center'
                align='middle'
                style={{ color: 'black', marginTop: '10px' }}
              >
                <Col span={2}>{index + 1}</Col>
                <Col span={9}>{value.phoneNumber}</Col>
                <Col span={7}>
                  {value.createdAt && new Date(value.createdAt).toDateString()}
                </Col>
                <Col span={6}>
                  <Dropdown
                    trigger={['click']}
                    overlay={() => menu(value)}
                    placement='bottomRight'
                  >
                    <Button
                      type={'primary'}
                      style={{ borderRadius: '50px', width: '100%' }}
                    >
                      Details
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
}
