import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import React from "react";
export default function ListProsAndCons({
  proCon,
  setProsCons,
  index,
  prosCons,
}) {
  return (
    <div>
      <Row gutter={[16, 16]} justify="center">
        <Col span={11}>
          <Card
            title={
              <h4
                style={{
                  fontSize: "17px",
                  color: "green",
                  fontWeight: "bolder",
                  textAlign: "center",
                }}
              >
                Pros
              </h4>
            }
            style={{
              marginBottom: "30px",
              width: "100%",
              textAlign: "center",
              fontWeight: "bolder",
              marginTop: "30px",
              borderTop: "15px solid green",
            }}
          >
            <Button
              style={{
                backgroundColor: "#de923b",
                width: "80%",
                fontSize: "20px",
                height: "50px",
                marginBottom: "25px",
              }}
              onClick={() => {
                const items = prosCons[index];
                items.pros = [];
                setProsCons([...prosCons]);
              }}
            >
              Reset Table
            </Button>

            {proCon?.pros &&
              proCon?.pros?.map((el) => {
                return (
                  <Row key={el} justify="start" align="top">
                    <Col span={2}></Col>
                    <Col span={4}>
                      <CheckCircleOutlined
                        style={{
                          color: "green",
                          fontSize: "30px",
                        }}
                      />
                    </Col>
                    <Col
                      span={18}
                      style={{
                        textAlign: "start",
                        paddingTop: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <p>{el}</p>
                    </Col>
                  </Row>
                );
              })}
          </Card>
        </Col>
        <Col span={11}>
          <Card
            title={
              <h4
                style={{
                  fontSize: "17px",
                  color: "red",
                  fontWeight: "bolder",
                  textAlign: "center",
                }}
              >
                Cons
              </h4>
            }
            style={{
              width: "100%",
              marginBottom: "30px",
              textAlign: "center",
              fontWeight: "bolder",
              marginTop: "30px",
              borderTop: "15px solid red",
            }}
          >
            <Button
              style={{
                backgroundColor: "#de923b",
                width: "80%",
                fontSize: "20px",
                height: "50px",
                marginBottom: "25px",
              }}
              onClick={() => {
                // console.log(value);
                const items = prosCons[index];
                items.cons = [];
                setProsCons([...prosCons]);
              }}
            >
              Reset Table
            </Button>
            {proCon.cons &&
              proCon.cons.map((con) => {
                return (
                  <Row key={con} justify="start" align="top">
                    <Col span={2}></Col>
                    <Col span={4}>
                      <CloseCircleOutlined
                        style={{
                          color: "red",
                          fontSize: "30px",
                        }}
                      />
                    </Col>
                    <Col
                      span={18}
                      style={{
                        textAlign: "start",
                        paddingTop: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <p>{con}</p>
                    </Col>
                  </Row>
                );
              })}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
