import React from "react";
import { Card, Row, Col } from "antd";

const SocialCard = ({ setSelected, key, Img, numbers, name }) => {
  return (
    <Row align="middle" justify="center">
      <Card
        style={{
          width: "180px",
          height: "280px",
        }}
        onClick={setSelected && setSelected}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          {/* <div className="image-div"> */}
          <img
            src={Img}
            height="100px"
            // width="230px"
            alt="pic"
            // className="img"
          />
          {/* </div> */}
        </Col>
        <Col
          span={24}
          style={{
            top: 35,
            textAlign: "center",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            {numbers}
          </h1>
          <span
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "16px",
              // top: -70,
              // backgroundColor: 'red',
              paddingBottom: "50px",
            }}
          >
            {name}
          </span>
        </Col>
      </Card>
    </Row>
  );
};

export default SocialCard;
