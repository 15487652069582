import {
  FEEDBACK_LOADING,
  FEEDBACK_SUCCESSFULL,
  FEEDBACK_FAILED,
} from "../actionTypes";

export const feedbackLoading = () => ({
  type: FEEDBACK_LOADING,
});

export const feedbackSuccessfull = (feedback) => ({
  type: FEEDBACK_SUCCESSFULL,
  feedback,
});

export const feedbackFailed = (errMess) => ({
  type: FEEDBACK_FAILED,
  errMess,
});
