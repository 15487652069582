import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Signin from './Signin';
import MobileComponent from './responsiveCheck';

class Main extends Component {

  render() {
    return (
      <div>
        <MobileComponent />
        <Switch>
          <PublicRoute restricted={true} component={Signin} path="/signin" exact />
          <PrivateRoute component={Dashboard} path="/" exact />
        </Switch>
      </div>
    );
  }
}

export default Main;
