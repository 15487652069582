import ScheduleIcon from "@material-ui/icons/Schedule";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Button, Form, Input, message, Row, Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCityToactive,
  updateCityToInactive,
} from "../../../Redux/ActionCreaters/citiesRequets";
import {
  createCommodityRateTimeString,
  createCommuditiy,
  deleteItemRate,
  updateCommodity,
  updateCommodityRateTimeString,
} from "../../../Redux/ActionCreaters/CommodityRequets";
import ModalUtil from "../../../utils/Modal/Modal";
import "./rates-divs.css";
// import RatesTable from "./RatesTable";
import "moment/locale/en-gb";
import SetTimerModal from "./SetTimerModal/SetTimerModal";
import { formateString, formateStringTimeDate } from "../../../shared";
import { Fragment } from "react";

const format = "h:mm a";
const Table = ({ cities, province, Heading: division, product }) => {
  const [timerModalVisible, setTimerModalVisible] = useState(false);
  const [actionModal, setActionModal] = useState(false);

  const [documentIdForTime, setDocumentIdForTime] = useState("");
  const [marketRate, setMarketRate] = useState(null);
  const [announcedRate, setAnnouncedRate] = useState(null);
  const [actualPosition, setActualPosition] = useState(null);
  const [ModalTite, setTite] = useState("");
  const [passwordForAction, setPasswordForAction] = useState("");

  const [announcedRateTime, setAnnouncedRateTime] = useState({
    hours: "08",
    minutes: "35",
    timeString: "",
  });
  const [marketRateTime, setMarketRateTime] = useState({
    hours: "08",
    minutes: "33",
    timeString: "",
  });
  const [actualPositionTime, setActualPositionTime] = useState({
    hours: "08",
    minutes: "30",
    timeString: "",
  });

  const [updateTimeBody, setUpdateTimeBody] = useState({});
  const [updateDocumentId, setUpdateDocumentId] = useState(null);
  const [itemForAction, setItemForAction] = useState({});

  const [rateModalVisible, setRateModalVisible] = useState(false);

  const [timerModalLoading, setTimerModalLoading] = useState(false);
  const [rateModalLoading, setRateModalLoading] = useState(false);
  const [city, setcity] = useState(null);
  const [cityId, setcityId] = useState(null);
  const dispatch = useDispatch();
  const changeTimeFromModal = (value) => {
    // console.log(value?.time(format));
    const timeValue = value && value.format(format);
    if (!timeValue) {
      return;
    }
    const hours = new Date(value).getHours();
    const minutes = new Date(value).getMinutes();
    const timeString = timeValue;
    setUpdateTimeBody({
      ...updateTimeBody,
      date: value,
      hours,
      minutes,
      timeString,
    });

    console.log(value && value.format(format));
  };
  const showTimerModal = (perameter) => {
    moment.locale("en-gb");
    if (perameter === "announcedRate") {
      const date = moment()
        .hour(announcedRateTime.hours)
        .minute(announcedRateTime.minutes);
      // alert(date);
      setUpdateTimeBody({ perameter, date, ...announcedRateTime });
    } else if (perameter === "marketRate") {
      const date = moment()
        .hour(marketRateTime.hours)
        .minute(marketRateTime.minutes);

      setUpdateTimeBody({ perameter, date, ...marketRateTime });
    } else {
      const date = moment()
        .hour(actualPositionTime.hours)
        .minute(actualPositionTime.minutes);

      setUpdateTimeBody({ perameter, date, ...actualPositionTime });
    }
    setTimerModalVisible(true);
  };
  const { commodityRate, isLoading, dateItems, timerLoading } = useSelector(
    (state) => state.CommodityRates,
  );
  const { cities: allCities } = useSelector((state) => state.Cities);
  useEffect(() => {
    if (dateItems.hasOwnProperty(division)) {
      const data = dateItems[division];
      setDocumentIdForTime(data._id);
      if (data.hasOwnProperty("announcedRate")) {
        setAnnouncedRateTime({ ...data.announcedRate });
      }
      if (data.hasOwnProperty("marketRate")) {
        setMarketRateTime({ ...data.marketRate });
      }
      if (data.hasOwnProperty("actualPosition")) {
        setActualPositionTime({ ...data.actualPosition });
      }
    } else {
      setAnnouncedRateTime({ hours: "08", minutes: "35", timeString: "" });
      setMarketRateTime({ hours: "08", minutes: "35", timeString: "" });
      setActualPositionTime({ hours: "08", minutes: "35", timeString: "" });
      setDocumentIdForTime("");
    }
  }, [timerLoading]);
  const showRateModal = (item) => {
    setcity(item.name);
    setcityId(item._id);

    setRateModalVisible(true);
  };
  const showUpdateModal = (item, rates) => {
    setcity(item.name);
    setcityId(item._id);
    setMarketRate(rates.marketRate);
    setActualPosition(rates.actualPosition);
    setAnnouncedRate(rates.announcedRate);
    setUpdateDocumentId(rates._id);
    setshowHoursTime(rates.showHoursTime);
    setRateModalVisible(true);
  };
  const handleTimerModalOnOk = () => {
    let payload = {
      userId: _id,
      division,
      province,
      product,
      [updateTimeBody.perameter]: { ...updateTimeBody },
    };
    if (!documentIdForTime) {
      dispatch(
        createCommodityRateTimeString(
          payload,
          token,
          setTimerModalLoading,
          setTimerModalVisible,
          dateItems,
        ),
      );
    } else {
      dispatch(
        updateCommodityRateTimeString(
          documentIdForTime,
          payload,
          token,
          setTimerModalLoading,
          setTimerModalVisible,
          dateItems,
        ),
      );
    }
  };
  const [showHoursTime, setshowHoursTime] = useState(15);

  const { _id, token } = useSelector((state) => state.Authentication.user);

  const handleRateModalOnOk = () => {
    let validate = true;
    if (!announcedRate && !marketRate && !actualPosition && !setshowHoursTime) {
      return message.warning("Please enter atleast one value");
    }

    let payload = {
      userId: _id,
      division,
      product,
      city,
      province,
      announcedRate,
      marketRate,
      showHoursTime,
      actualPosition,
      cityId,
    };
    if (!updateDocumentId) {
      dispatch(
        createCommuditiy(
          payload,
          token,
          setRateModalLoading,
          handleRateModalOnCancle,
          commodityRate,
        ),
      );
    } else {
      dispatch(
        updateCommodity(
          updateDocumentId,
          payload,
          token,
          setRateModalLoading,
          handleRateModalOnCancle,
          commodityRate,
        ),
      );
    }
  };

  const handleTimerModalOnCancle = () => {
    setTimerModalVisible(false);
    setUpdateTimeBody({});
  };

  const handleRateModalOnCancle = () => {
    setAnnouncedRate(null);
    setMarketRate(null);
    setActualPosition(null);
    setUpdateDocumentId(null);
    setRateModalVisible(false);
  };
  const actionModalOpen = (item, title, action) => {
    setItemForAction({ item, action });
    setTite(title);
    setActionModal(true);
  };
  const onCloseActionModal = () => {
    setPasswordForAction("");
    setActionModal(false);
  };

  // deleteItemRate
  // dispatch()
  const onDeleteRate = () => {
    if (passwordForAction === "1345") {
      if (itemForAction.action === "deleteRate") {
        dispatch(deleteItemRate(itemForAction.item._id, token, commodityRate));
      } else if (itemForAction.action === "inactiveCity") {
        dispatch(
          updateCityToInactive(
            itemForAction.item._id,
            { product },
            token,
            allCities,
            division,
          ),
        );
      } else if (itemForAction.action === "activeCity") {
        dispatch(
          updateCityToactive(
            itemForAction.item._id,
            { product },
            token,
            allCities,
            division,
          ),
        );
      }
      setActionModal(false);
      setPasswordForAction("");
    } else {
      message.error("Pass key is wrong");
    }
  };

  return (
    <>
      <div className="table_div">
        <SetTimerModal
          timerModalVisible={timerModalVisible}
          timerModalLoading={timerModalLoading}
          title={"Admin set the timer"}
          showTimerModal={showTimerModal}
          handleTimerModalOnOk={handleTimerModalOnOk}
          handleTimerModalOnCancle={handleTimerModalOnCancle}
          changeTimeFromModal={changeTimeFromModal}
          datePicker={updateTimeBody}
        />

        <ModalUtil
          timerModalVisible={actionModal}
          timerModalLoading={false}
          title={"set Rate"}
          showTimerModal={setActionModal}
          handleTimerModalOnOk={onDeleteRate}
          handleTimerModalOnCancle={() => onCloseActionModal()}
        >
          <Row style={{ margin: "20px" }}>
            <Form
              style={{ padding: "20px" }}
              colon={false}
              layout="vertical"
              onSubmit={onDeleteRate}
            >
              <h3>{ModalTite}</h3>
              <Form.Item label="Pass key" required>
                <Input
                  size="large"
                  type="password"
                  name="passwordForAction"
                  onChange={(e) => setPasswordForAction(e.target.value)}
                  value={passwordForAction}
                  placeholder="Enter Password for action"
                />
              </Form.Item>
            </Form>
          </Row>
        </ModalUtil>

        <ModalUtil
          timerModalVisible={rateModalVisible}
          timerModalLoading={rateModalLoading}
          title={"set Rate"}
          showTimerModal={showRateModal}
          handleTimerModalOnOk={handleRateModalOnOk}
          handleTimerModalOnCancle={handleRateModalOnCancle}
        >
          <Row style={{ margin: "20px" }} justify="center">
            <Form
              style={{ padding: "20px", width: "100%" }}
              colon={false}
              layout="vertical"
              onSubmit={handleRateModalOnOk}
            >
              <Form.Item label="Announced Rate" required>
                <Input
                  size="large"
                  type="text"
                  name="announcedRate"
                  onChange={(e) => setAnnouncedRate(e.target.value)}
                  value={announcedRate}
                  placeholder="Enter accounced rate"
                />
              </Form.Item>
              <Form.Item label="Mandi Guru Rate" required>
                <Input
                  size="large"
                  type="number"
                  name="marketRate"
                  onChange={(e) => setMarketRate(e.target.value)}
                  value={marketRate}
                  placeholder="Enter market rate"
                />
              </Form.Item>
              <Form.Item label="Accual Position" required>
                <Input
                  size="large"
                  type="number"
                  name="marketRate"
                  onChange={(e) => setActualPosition(e.target.value)}
                  value={actualPosition}
                  placeholder="Enter market rate"
                />
              </Form.Item>
              {!updateDocumentId && (
                <Form.Item label="Display Time in hours" required>
                  <Input
                    size="large"
                    type="text"
                    name="showHoursTime"
                    onChange={(e) => setshowHoursTime(e.target.value)}
                    value={showHoursTime}
                    placeholder="Enter show Hours Time of rate"
                  />
                </Form.Item>
              )}
            </Form>
          </Row>
        </ModalUtil>

        {isLoading ? (
          <Row>
            <Skeleton active paragraph={{ rows: 4 }} />
          </Row>
        ) : (
          <table className="table_rate">
            <thead>
              <tr>
                <th style={{ width: "30px", fontSize: "10px" }}>
                  Toggle activation
                </th>
                <th>City Name</th>
                <th>
                  Announced Rate
                  <br />
                  <ScheduleIcon className="schedule-icon" />{" "}
                  <span
                    onClick={() => showTimerModal("announcedRate")}
                    className="set-timmer"
                  >
                    {announcedRateTime.timeString
                      ? announcedRateTime.timeString
                      : "Set Timer"}
                  </span>{" "}
                </th>

                <th>
                  Actual Position
                  <br />
                  <ScheduleIcon className="schedule-icon" />{" "}
                  <span
                    onClick={() => showTimerModal("actualPosition")}
                    className="set-timmer"
                  >
                    {actualPositionTime.timeString
                      ? actualPositionTime.timeString
                      : "Set Timer"}
                  </span>{" "}
                </th>
                <th>Engagement</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            {cities.length &&
              cities.map((val) => {
                if (
                  val.activeForProduct &&
                  val.activeForProduct.indexOf(product) !== -1
                ) {
                  return (
                    <Fragment key={val._id}>
                      <tbody>
                        <tr className={
                          val.activeForProduct &&
                            val.activeForProduct.indexOf(product) !== -1
                            ? "data-row active"
                            : "data-row disable"
                        }>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px" }}>
                            {val.activeForProduct &&
                              val.activeForProduct.indexOf(product) !== -1 ? (
                              <Button
                                type={"primary"}
                                onClick={() =>
                                  actionModalOpen(
                                    val,
                                    "Please enter the pass key to inactive the city.",
                                    "inactiveCity",
                                  )
                                }
                              >
                                Inactive
                              </Button>
                            ) : (
                              <Button
                                type={"primary"}
                                onClick={() =>
                                  actionModalOpen(
                                    val,
                                    "Please enter the pass key to active the city.",
                                    "activeCity",
                                  )
                                }
                              >
                                active
                              </Button>
                            )}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {val.name?.toLowerCase()}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {commodityRate &&
                              commodityRate.hasOwnProperty(val._id)
                              ? commodityRate[val._id].announcedRate
                                ? commodityRate[val._id].announcedRate
                                : "N/A"
                              : "N/A"}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {commodityRate &&
                              commodityRate.hasOwnProperty(val._id)
                              ? commodityRate[val._id].actualPosition
                                ? commodityRate[val._id].actualPosition
                                : "N/A"
                              : "N/A"}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {" "}
                            <ThumbUpIcon className="thumb-up" />{" "}
                            {val.engagement && val.engagement.likes
                              ? val.engagement.likes
                              : 0}{" "}
                            <ThumbDownIcon className="thumb-down" />{" "}
                            {val.engagement && val.engagement.dislikes
                              ? val.engagement.dislikes
                              : 0}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {moment(val.createdAt).format(
                              formateStringTimeDate,
                            )}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {moment(val.updatedAt).format(
                              formateStringTimeDate,
                            )}
                          </td>
                          <td style={{ padding: "5px", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>
                            {" "}
                            {!commodityRate.hasOwnProperty(val._id) ? (
                              <Button
                                disabled={
                                  val.activeForProduct &&
                                    val.activeForProduct.indexOf(product) !== -1
                                    ? false
                                    : true
                                }
                                style={{ width: "100px" }}
                                onClick={() => showRateModal(val)}
                                type="primary"
                              >
                                Add
                              </Button>
                            ) : (
                              <Button
                                style={{ marginRight: "10px" }}
                                disabled={
                                  val.activeForProduct &&
                                    val.activeForProduct.indexOf(product) !== -1
                                    ? false
                                    : true
                                }
                                onClick={() =>
                                  showUpdateModal(val, commodityRate[val._id])
                                }
                                type="primary"
                              >
                                Update
                              </Button>
                            )}
                            {commodityRate.hasOwnProperty(val._id) && (
                              <Button
                                onClick={() =>
                                  actionModalOpen(
                                    commodityRate[val._id],
                                    "Please enter the passkey to remove",
                                    "deleteRate",
                                  )
                                }
                                type="primary"
                              >
                                Remove
                              </Button>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Fragment>
                  );
                }
              })}
          </table>
        )}
      </div>
    </>
  );
};

export default Table;
