import { Button, Card, Col, Layout, Row, Table, Typography, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  createAdminOrSubAdmin,
  editAdminUser,
  getAdminOrSubAdmins,
} from "../../RestServices/authService";
import { MAIN_BACKGROUND_COLOR, fontColor } from "../../styleConstants";
import "../Dashboard/styles.css";
import AddUserModal from "./AddUser";
// import Table from "../Table";
import DeleteUser from "./DeleteUser";

import { PermissionEnum } from "../../shared";
import ContantTitle from "../../utils/contantTitle/ContantTitle";
import ActiveOrDisableUser from "./ActiveOrDisableUser";

const styles = {
  layout: {
    minHeight: "100vh",
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: "bold",
  },
  content: {
    color: fontColor,
    backgroundColor: "white",
    margin: "20px",
  },
  logo: {
    height: "200px",
    width: "190px",
  },
  menuToggler: {
    color: fontColor,
    fontSize: "30px",
    marginLeft: "5px",
    marginTop: "15px",
  },

  tableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  addUserButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addUserButton: {
    marginRight: "20px",
    backgroundColor: "#1f910e",
    color: "white",
  },
};
const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Role",
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
class Users extends React.Component {
  state = {
    collapsed: false,
    input: null,
    isFormLoading: false,
    customers: [],
    isModalOpen: false,
    name: "",
    email: "",
    editUserId: "",
    password: "",
    permissions: [],
    type: "ADMIN",
    city: "",
    phoneNumber: "",
  };

  onChangePermission = (permissions) => {
    this.setState({ permissions });
  };
  toggleSider = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  inputEvent = (e) => {
    this.setState({ input: e.target.value });
    console.log(e.target.value);
  };
  inputHandler = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };
  openAddUserModal = () => this.setState({ isModalOpen: true });
  closeAddUserModal = () => this.setState({ isModalOpen: false });

  resetForm = () =>
    this.setState({
      editUserId: "",
      name: "",
      email: "",
      password: "",
      city: "",
      phoneNumber: "",
      permissions: [],
    });
  submitForm = async (evt) => {
    evt.preventDefault();
    const {
      name,
      email,
      password,
      phoneNumber,
      editUserId,
      city,
      type,
      permissions,
    } = this.state;
    if (
      !name ||
      !email ||
      !(password || editUserId) ||
      !phoneNumber ||
      !city ||
      !type
    ) {
      return message.error("Please fill all value ");
    }
    // let permissions = [];
    // if (type === "ADMIN") {
    if (!permissions.length) {
      return message.error("Please check the some permission");
    }
    // else {
    // permissions = [...permisstions];
    // }
    // } else {
    // permissions = ["AllResources:*"];
    // }
    console.log(this.state, permissions);
    this.setState({ isFormLoading: true });
    try {
      if (editUserId) {
        const data = await editAdminUser(
          {
            userId: editUserId,
            name,
            email,
            phoneNumber,
            city,
            type,
            permissions,
          },
          this.props.Auth.user.token,
        );
        this.getAdminOrSubAdmins();
        message.success("User " + data.customer.name + " updated!");
        this.resetForm();
        return;
      }
      const data = await createAdminOrSubAdmin(
        { ...this.state, permissions },
        this.props.Auth.user.token,
      );

      const customer = {
        name: data.customer.name,
        email: data.customer.email,
        city: data.customer.city,
        updatedAt: new Date().toISOString(),
        type: data.customer.profileDetails.type,
        status: data.customer.status,
        phoneNumber: data.customer.phoneNumber,
        action: <DeleteUser customer={data.customer} />,
      };
      this.setState({
        isFormLoading: false,
        isModalOpen: false,
        customers: [customer, ...this.state.customers],
      });
      message.success("User " + data.customer.name + " Created!");
      this.resetForm();
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  getAdminOrSubAdmins = async () => {
    try {
      let data = await getAdminOrSubAdmins(this.props.Auth.user.token);
      // console.log(data, "data");
      const customers = data.customers.map((customer) => ({
        name: customer.name,
        id: customer._id,
        _id: customer._id,
        email: customer.email,
        type: customer.profileDetails.type,
        status: customer.status,
        city: customer.city,
        updatedAt: customer.updatedAt,
        phoneNumber: customer.phoneNumber,
        permisstion: customer.profileDetails.permissions?.join(", "),
        action: <DeleteUser customer={customer} />,
        disableActive: <ActiveOrDisableUser customer={customer} />,
      }));
      this.setState({ customers });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  componentDidMount() {
    this.getAdminOrSubAdmins();
  }

  tableColumns = [
    {
      key: "name",
      title: "User Name",
      dataIndex: "name",
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "phoneNumber",
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },

    {
      key: "type",
      title: "Rule",
      dataIndex: "type",
    },
    {
      title: "Permisstion",
      key: "permisstion",
      dataIndex: "permisstion",
      render: (text, record) => (
        <span type={"primary"}>{record?.permisstion}</span>
      ),
    },
    {
      key: "status",
      title: "Status",
      // title: "Category",
      dataIndex: "status",
    },
    {
      key: "updatedAt",
      title: "Last Seen",
      dataIndex: "updatedAt",
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      // render: (text, record) => (
      //   <Button type={"primary"}>
      //     {" "}
      //     Remove
      //     {/* {this.props.getdataKey !== "Finished" && ( */}
      //     {/* <a onClick={this.onRowClickHandler(record)}> Edit </a> */}
      //     {/* )} */}
      //   </Button>
      // ),
    },
    {
      title: "Disable/Active",
      key: "disableActive",
      dataIndex: "disableActive",
    },

    {
      title: "Update",
      key: "Update",
      render: (text, record) => {
        const { permissions: userPermission } =
          this.props.Auth.user.profileDetails;

        return (
          <Button
            onClick={() => {
              //           email: customer.email,
              // type: customer.profileDetails.type,
              // status: customer.status,
              // updatedAt: customer.updatedAt,
              // phoneNumber: customer.phoneNumber,
              // permisstion: customer.profileDetails.permissions?.join(", "),
              debugger;
              this.setState({
                isModalOpen: true,
                editUserId: record.id,
                email: record?.email,
                name: record?.name,
                permissions: record?.permisstion,
                password: undefined,
                city: record.city,
                phoneNumber: record.phoneNumber,
                type: record.type,
              });
            }}
            disabled={
              !(
                userPermission.includes(PermissionEnum["AllResources:*"]) ||
                userPermission.includes(PermissionEnum["Users:*"]) ||
                userPermission.includes(PermissionEnum["Users:add"])
              )
            }
            type={"primary"}
          >
            Update
            {/* {this.props.getdataKey !== "Finished" && ( */}
            {/* <a onClick={this.onRowClickHandler(record)}> Edit </a> */}
            {/* )} */}
          </Button>
        );
      },
    },
  ];

  // ;
  render() {
    const { Sider, Header } = Layout;
    const {
      isModalOpen,
      customers,
      name,
      editUserId,
      email,
      password,
      type,
      city,
      phoneNumber,
      permissions,
      isFormLoading,
    } = this.state;
    console.log(permissions, "permissions");

    const { permissions: userPermission } = this.props.Auth.user.profileDetails;

    const { Title } = Typography;
    return (
      <div>
        <Row>
          {(userPermission.includes(PermissionEnum["AllResources:*"]) ||
            userPermission.includes(PermissionEnum["Users:*"]) ||
            userPermission.includes(PermissionEnum["Users:add"])) && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ padding: "10px" }}>
                  <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <div className="flex-between">
                      <div style={{ width: "100%" }}>
                        <Row gutter={[12, 12]}>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                            <div className="flex-col">
                              <Title level={4} className="mb-0 text-primary">Users</Title>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Button type="primary" onClick={this.openAddUserModal}>
                          Add User
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
            )}

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ padding: "10px" }}>
              <Table
                style={{
                  overflow: "auto",
                  background: "#fff"
                }}
                dataSource={customers}
                pagination={false}
                columns={this.tableColumns}
                rowKey={(record) => record._id}
              />
            </div>
          </Col>
        </Row>
        <AddUserModal
          isOpen={isModalOpen}
          name={name}
          email={email}
          editUserId={editUserId}
          permissions={permissions}
          onChangePermission={this.onChangePermission}
          password={password}
          city={city}
          phoneNumber={phoneNumber}
          type={type}
          submitForm={this.submitForm}
          isFormLoading={isFormLoading}
          closeModal={this.closeAddUserModal}
          inputHandler={this.inputHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(Users);
