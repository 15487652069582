import { LeftSquareTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Image, message, Row, Table } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
  createAdminOrSubAdmin,
  findCustomerWithPhoneNumbers,
  getUserCrmUser,
} from '../../RestServices/authService';
import { formateString, PermissionEnum } from '../../shared';
import { fontColor, MAIN_BACKGROUND_COLOR } from '../../styleConstants';
import Calender from '../../utils/CalenderDropDown/Calender';
import ContantTitle from '../../utils/contantTitle/ContantTitle';
import '../Dashboard/styles.css';
import Pagination from '../Pagination';
import AddUserModal from './AddUser';

//
// import Table from "../Table";

const styles = {
  layout: {
    minHeight: '100vh',
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: 'bold',
  },
  content: {
    color: fontColor,
    backgroundColor: 'white',
    margin: '20px',
  },
  logo: {
    height: '200px',
    width: '190px',
  },
  menuToggler: {
    color: fontColor,
    fontSize: '30px',
    marginLeft: '5px',
    marginTop: '15px',
  },

  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  addUserButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addUserButton: {
    marginRight: '20px',
    backgroundColor: '#1f910e',
    color: 'white',
  },
};
const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email', // accessor is the "key" in the data
  },
  {
    Header: 'Role',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status', // accessor is the "key" in the data
  },
];
class UsersListForCrm extends React.Component {
  state = {
    collapsed: false,
    input: null,
    isFormLoading: false,
    customers: [],
    totalPages: 1,
    selectedRowKeys: [],
    currentPage: 1,
    metaData: {},
    isModalOpen: false,
    name: '',
    data: {},
    email: '',
    password: '',
    permissions: [],
    type: 'ADMIN',
    city: '',
    selectedUser: {},
    rawCustomer: [],
    phoneNumber: '',
    dropdrownshow: false,
    dateSelection: {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    },

    datesBetween: {
      startDate: undefined,
      endDate: undefined,
    },
    isModalVisible: false,
    phoneNumberText: '',
    getPhoneListLoading: false,
    ListUser: null,
    screenSwitch: false,
  };

  onChangePermission = (permissions) => {
    this.setState({ permissions });
  };
  toggleSider = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  inputEvent = (e) => {
    this.setState({ input: e.target.value });
    console.log(e.target.value);
  };
  inputHandler = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };
  openUserShowDetailModal = () => this.setState({ isModalOpen: true });
  closeUserShowDetailModal = () => this.setState({ isModalOpen: false });

  resetForm = () =>
    this.setState({
      name: '',
      email: '',
      password: '',
      city: '',
      phoneNumber: '',
      permissions: [],
    });
  submitForm = async (evt) => {
    evt.preventDefault();
    const { name, email, password, phoneNumber, city, type, permissions } =
      this.state;
    if (!name || !email || !password || !phoneNumber || !city || !type) {
      return message.error('Please fill all value ');
    }
    if (!permissions.length) {
      return message.error('Please check the some permission');
    }

    console.log(this.state, permissions);
    this.setState({ isFormLoading: true });
    try {
      const data = await createAdminOrSubAdmin(
        { ...this.state, permissions },
        this.props.Auth.user.token,
      );

      const customer = {
        name: data.customer.name,
        email: data.customer.email,
        key: data.customer._id,
        updatedAt: new Date().toISOString(),
        type: data.customer.profileDetails.type,
        status: data.customer.status,
        phoneNumber: data.customer.phoneNumber,
      };
      this.setState({
        isFormLoading: false,
        isModalOpen: false,
        customers: [customer, ...this.state.customers],
      });
      message.success('User ' + data.customer.name + ' Created!');
      this.resetForm();
    } catch (err) {
      this.setState({ isFormLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  getUserApiFunc = async () => {
    try {
      let data = await getUserCrmUser(
        {
          page: this.state.currentPage,
          userId: this.props.Auth.user._id,
          type: this.props.slectedSubkey,
          startDate: this.state.datesBetween.startDate
            ? moment(this.state.datesBetween.startDate).toDate()
            : '',
          endDate: this.state.datesBetween.endDate
            ? moment(this.state.datesBetween.endDate).toDate()
            : '',
        },
        this.props.Auth.user.token,
      );
      console.log(data, 'data abc');
      const customers = data.userDailyStat.users.docs.map((customer) => ({
        name: customer.name,
        type: customer.profileDetails.type,
        status: customer.status,
        key: customer._id,
        updatedAt: moment(customer.lastProfileFetched).format(formateString),
        createdAt: moment(customer.createdAt).format(formateString),
        phoneNumber: customer.phoneNumber,
        profileImage: customer.profileImage,
        city: customer.city,
      }));
      this.setState({
        customers,
        currentPage: data.userDailyStat.users.page,
        totalPages: data.userDailyStat.users.totalDocs,
        rawCustomer: data.userDailyStat.users.docs,
        data: data.userDailyStat.users,
      });
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };
  componentDidMount() {
    this.getUserApiFunc();
  }

  tableColumns = [
    {
      title: 'Sr.',
      width: 20,
      dataIndex: 'index',
      fixed: 'left',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Image',
      width: 20,
      dataIndex: 'profileImage',
      fixed: 'left',
      align: 'center',
      render: (text, record, index) => (
        <Avatar src={<Image style={{ width: '80px' }} src={text} />} />
      ),
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },

    {
      key: 'phoneNumber',
      title: 'Phone',
      dataIndex: 'phoneNumber',
      render: (text, record, index) => (
        <a style={{ color: 'blue' }} href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      key: 'city',
      title: 'City',
      dataIndex: 'city',
    },

    {
      key: 'type',
      title: 'Rule',
      dataIndex: 'type',
    },

    {
      key: 'status',
      title: 'Status',
      // title: "Category",
      dataIndex: 'status',
    },
    {
      key: 'createdAt',
      title: 'date of Creation',
      dataIndex: 'createdAt',
    },

    {
      key: 'updatedAt',
      title: 'Last Seen',
      dataIndex: 'updatedAt',
    },
  ];

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getUserApiFunc();
    });
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, screenSwitch: false });
  };

  handleSelect = (ranges) => {
    console.log(ranges);
    if (ranges.hasOwnProperty('range1')) {
      this.setState({ dateSelection: { ...ranges.range1 } });
    } else {
      this.setState({ dateSelection: { ...ranges.selection } });
    }
  };
  PickDate = () => {
    this.setState(
      {
        dropdrownshow: false,
        datesBetween: {
          startDate: this.state.dateSelection.startDate,
          endDate: this.state.dateSelection.endDate,
        },
      },
      () => this.getUserApiFunc(),
    );
  };

  rowSelection = {
    onChange: (selectedRowKeys) => {
      const [id] = selectedRowKeys;
      const selectedUser = this.state.rawCustomer.find(
        (user) => user.id === id,
      );
      console.log(selectedUser);
      this.setState({ selectedRowKeys, selectedUser });
      this.openUserShowDetailModal();
    },
  };

  onChange = ({ target: { value } }) => {
    this.setState({ phoneNumberText: value });
  };
  fetchUserData = async () => {
    try {
      const { phoneNumberText } = this.state;
      let payload = phoneNumberText.split('\n');

      payload = payload.filter(function (el) {
        return el != '';
      });

      this.setState({ getPhoneListLoading: true });
      const response = await findCustomerWithPhoneNumbers(
        this.props.Auth.user.token,
        { phoneNumbers: payload },
      );
      this.setState({
        listUser: response.customerList,
        screenSwitch: true,
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ getPhoneListLoading: false });
    }
  };
  render() {
    const {
      customers,

      totalPages,
      currentPage,

      isFormLoading,
    } = this.state;
    console.log(customers);

    const { permissions: userPermission } = this.props.Auth.user.profileDetails;

    return (
      <div style={styles.content}>
        <Modal
          title="Filter Phone Number"
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          width="70%"
          height="80vh"
          onCancel={this.handleCancel}
        >
          <Row justify="center" align="middle">
            {!this.state.screenSwitch ? (
              <Col span={12}>
                <p>Enter phone numbers</p>
                <TextArea
                  style={{ width: '100%' }}
                  value={this.state.phoneNumberText}
                  onChange={this.onChange}
                  placeholder="Controlled autosize"
                  autoSize={{ minRows: 3, maxRows: 15 }}
                />
                <Button
                  loading={this.state.getPhoneListLoading}
                  onClick={this.fetchUserData}
                  style={{ marginTop: '20px' }}
                  type={'primary'}
                >
                  Get Result
                </Button>
              </Col>
            ) : (
              <Col span={16}>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <p>Users Number</p>
                    <TextArea
                      style={{ width: '100%' }}
                      value={this.state.listUser?.customerList
                        ?.map((value) => value.phoneNumber)
                        ?.join('\n')}
                      // onChange={this.onChange}
                      placeholder="User"
                      autoSize={{ minRows: 3, maxRows: 15 }}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Non Users Number</p>
                    <TextArea
                      style={{ width: '100%' }}
                      value={this.state.listUser?.notPresents?.join('\n')}
                      // onChange={this.onChange}
                      placeholder="Non User"
                      autoSize={{ minRows: 3, maxRows: 15 }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Modal>
        <Row>
          {' '}
          <Col span={24}>
            <div>
              <LeftSquareTwoTone
                onClick={() => {
                  if (this.props.slectedSubkey.startsWith('Crm')) {
                    this.props.setSelected('Crm_System');
                  } else {
                    this.props.setSelected('Dashboard');
                  }
                }}
                style={{
                  fontSize: '24px',
                  color: 'red',
                  margin: '20px',
                  cursor: 'pointer',
                }}
              />
            </div>
          </Col>
          <Col span={8}>
            <Breadcrumb style={{ margin: '16px 16px', cursor: 'pointer' }}>
              <Breadcrumb.Item>Admin Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.slectedSubkey}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={8}>
            <Button onClick={this.showModal}>Filter Phone Number</Button>
          </Col>
          <Col span={8}>
            <Calender
              handleSelect={this.handleSelect}
              PickDate={this.PickDate}
              dateSelection={this.state.dateSelection}
              datesBetween={this.state.datesBetween}
              dropdrownshow={this.state.dropdrownshow}
              setdropdrownshow={(value) =>
                this.setState({ dropdrownshow: value })
              }
              setdateSelection={(pros) =>
                this.setState({ ...pros, dropdrownshow: false })
              }
            />
          </Col>
        </Row>
        {(userPermission.includes(PermissionEnum['AllResources:*']) ||
          userPermission.includes(PermissionEnum['Users:*']) ||
          userPermission.includes(PermissionEnum['Users:add'])) && (
          <ContantTitle
            heading={
              this.props.slectedSubkey +
              ' Users (' +
              this.state.data?.totalDocs +
              ')'
            }
            // buttonText={"Add User"}
            openForm={console.log}
          />
        )}
        {/* <div style={styles.tableContainer}> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={currentPage}
            total={totalPages}
            onPageChange={this.onPageChange}
          />
        </div>
        <Table
          dataSource={customers}
          pagination={false}
          columns={this.tableColumns}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: this.state.selectedRowKeys,
            ...this.rowSelection,
          }}
          // onSelect={this.onS/elect}
          // rowKey={(record) => record._id}
        />

        <AddUserModal
          selectedUser={this.state.selectedUser}
          submitForm={this.closeUserShowDetailModal}
          isFormLoading={isFormLoading}
          isOpen={this.state.isModalOpen}
          closeModal={this.closeUserShowDetailModal}
          inputHandler={this.inputHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(UsersListForCrm);
