import { InboxOutlined } from '@material-ui/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Row,
  Tabs,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Dragger from 'antd/lib/upload/Dragger';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AddTaskPhoneNumber,
  findCustomerOlderThanThreeDay,
  getContactWithTask,
  getTaskUserIdAndMonth,
  getUserCrmDailyStat,
  updatePhoneStatusCrm,
} from '../../RestServices/authService';
import { getOrderCountWRTStatus } from '../../RestServices/ecommerenceService';
import {
  getCommissionForDownandRev,
  getCommissionsAmount,
} from '../../RestServices/postingService';
import { getDailyTaskContactsReport } from '../../RestServices/statService';
import { uploadThumnail } from '../../RestServices/videosService';
import { phoneStatuses } from '../../shared';
import MurgiMandiOffer from '../Dashboard/DashboardBody/MurghiMundiOffer';
import MurghiMundiState from '../Dashboard/DashboardBody/MurghiMundiStat';
import './crm.css';
import PhoneNumberFilter from './PhoneNumberFilter';
import RejectUSer from './rejectUser/rejectUSer';
import AddNumber from './Stages/addNumber';
import BlockedNumber from './Stages/BlockedNumber';
import CallNumber from './Stages/CallNumber';
import Download from './Stages/Download';
import PostAndProfile from './Stages/postAndProfile';
import Reviews from './Stages/reviews';
import LinkSended from './Stages/sendLink';
import UserStat from './UserStat';

export default function CrmComponent({ ...props }) {
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(false);
  const showModal = () => {
    setPhoneNumberFilter(true);
  };

  const [addPhoneNumberLoading, setAddPhoneNumberLoading] = useState(false);

  const [reviewPictureModel, setReviewPicture] = useState(false);

  const [phoneInCategory, setPhoneInCategory] = useState({
    INITIAL: [],
    APP_DOWNLOADED: [],
    MESSAGE_SENT: [],
    CALLED_NUMBER: [],
    blocked: [],
    REVIEWED: [],
  });

  const [phoneNumber, setPhoneNumber] = useState('');

  const [selectedDate, setSelectedDate] = useState(
    moment().format('MMMM YYYY'),
  );

  const [modelAddNumVisible, setModelAddNumVisible] = useState(false);

  const [taskDetail, setTaskDetail] = useState(undefined);

  const [userAndProfile, setUserAndProfile] = useState([]);
  const [monthCommission, setMonthlyCommission] = useState([]);

  const { token, _id: userId } = useSelector(
    (state) => state.Authentication.user,
  );

  const getSetUp = async () => {
    try {
      const { contacts } = await getContactWithTask({
        userId,
      });
      const phoneInCategory = {
        INITIAL: [],
        APP_DOWNLOADED: [],
        MESSAGE_SENT: [],
        CALLED_NUMBER: [],
        blocked: [],
        REVIEWED: [],
      };
      for (let contact of contacts) {
        phoneInCategory[contact.status].push(contact);
      }
      setPhoneInCategory(phoneInCategory);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await getTaskUserIdAndMonth({
        month: selectedDate,
        userId,
      });

      if (response.tasks.length) {
        setTaskDetail(response.tasks[0]);
        getDailyTaskContacts(response?.tasks[0]?._id);
      }
      getCommission();
    } catch (err) {
      console.log(err);
    }
  };

  const getDailyTaskContacts = async (taskId) => {
    try {
      const data = await getDailyTaskContactsReport({
        taskId,
        date: moment().subtract(1, 'day').format('MMMM DD YYYY'),
      });
      setUserAndProfile(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getCommission = async (taskId) => {
    try {
      const data = await getCommissionsAmount(
        {
          referenceOf: userId,
          month: selectedDate,
        },
        token,
      );
      setMonthlyCommission(data.commission);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSetUp();

    getCommission();
  }, [selectedDate]);

  const handleOk = () => {
    setPhoneNumberFilter(false);
  };

  const onChangeDatePicker = (date, dateString) => {
    setSelectedDate(date?.format('MMMM YYYY'));
  };

  const handleCancel = () => {
    setPhoneNumberFilter(false);
  };

  const { TabPane } = Tabs;

  const menu = (
    <Menu>
      <Menu.Item key="1">
        {/* <Row gutter={[12, 12]}>
          <Col span={16}>Assigned Numbers</Col>
          <Col span={3}></Col>
          <Col span={5}>12</Col>
        </Row> */}
      </Menu.Item>
      <Menu.Item key="2">
        {' '}
        <Row gutter={[12, 12]}>
          <Col span={16}>Assigned Download</Col>
          <Col span={3}></Col>
          <Col span={5}>{taskDetail ? taskDetail.downloads : 'Not assign'}</Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="2">
        {' '}
        <Row gutter={[12, 12]}>
          <Col span={16}>Assigned Review</Col>
          <Col span={3}></Col>
          <Col span={5}>{taskDetail ? taskDetail.reviews : 'Not assign'}</Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="3">
        <Row gutter={[12, 12]}>
          <Col span={16}>Assigned Post's</Col>
          <Col span={3}></Col>
          <Col span={5}>{taskDetail ? taskDetail.posts : 'Not assign'}</Col>
        </Row>
      </Menu.Item>
    </Menu>
  );
  // INITIAL

  const onUpdateContactStatus = async ({
    _id,
    taskId,
    status,
    blocked,
    phone,
  }) => {
    try {
      await updatePhoneStatusCrm(
        {
          contactId: _id,
          taskId,
          status,
          blockedReason: blocked,
          blocked: true,
        },
        token,
      );
      if (
        [phoneStatuses.APP_DOWNLOADED, phoneStatuses.REVIEWED].includes(status)
      )
        try {
          const dataPayload = {
            category:
              phoneStatuses.APP_DOWNLOADED === status ? 'Download' : 'Review',
            subCategory:
              phoneStatuses.APP_DOWNLOADED === status ? 'download' : 'review',
            createdBy: phone,
            taskId: taskId,
            referenceOf: userId,
          };
          await getCommissionForDownandRev(dataPayload, token);
        } catch (err) {
          console.log(err);
        }
      getSetUp();
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const [userDetails, setUserDetail] = useState({
    activeUser: 0,
    totalUser: 0,
    idolUser: 0,
    sluggishUser: 0,
  });
  useEffect(() => {
    getSetUpUserDailyStat();
    getOrderCount();
  }, []);

  const [totalOrder, setTotalOrder] = useState(0);

  const add = (accumulator, a) => {
    return accumulator + a;
  };
  const getOrderCount = async () => {
    try {
      const { data } = await getOrderCountWRTStatus(token, userId);
      const values = Object.values(data);
      if (values.length) {
        setTotalOrder(values.reduce(add, 0));
      }
    } catch (err) {
      console.log(err, 'er');
    }
  };
  const getSetUpUserDailyStat = async () => {
    const { userDailyStat } = await getUserCrmDailyStat(
      {
        userId: userId,
      },
      token,
    );

    setUserDetail({ ...userDailyStat });
  };

  const onCreatePhone = async () => {
    try {
      if (phoneNumber.trim().length !== 11) {
        return message.error('Please add complete number start from 03');
      }
      if (!taskDetail) {
        return message.error('As you are not assigned any task yet');
      }
      if (!phoneNumber.startsWith('03')) {
        return message.error('Phone number must start from 03');
      }
      const payloadNumber = {
        phoneNumber: '+92' + phoneNumber.trim().slice(1),
      };

      const { customer } = await findCustomerOlderThanThreeDay(
        token,
        payloadNumber,
      );

      if (customer) {
        return message.error('This number is already in use');
      }

      setAddPhoneNumberLoading(true);

      const response = await AddTaskPhoneNumber(
        {
          userId,
          taskId: taskDetail._id,
          phoneNumber: phoneNumber.trim(),
          status: 'INITIAL',
        },
        token,
      );

      // this.getAdminOrSubAdminsInCom();
      getSetUp();
      setPhoneNumber('');
      setModelAddNumVisible(false);
      console.log(response);
    } catch (err) {
      console.log(err);
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setAddPhoneNumberLoading(false);
    }
  };

  const [openReviewData, setOpenReviewData] = useState(undefined);
  const openReviewScreen = (data) => {
    setOpenReviewData({
      ...data,
    });
    setReviewPicture(true);
  };

  const [isFormLoading, setFormLoading] = useState(false);
  const [uploadedPercentage, setUploadedPercentage] = useState(0);
  const [bannerLinks, setBannerLinks] = useState([]);
  const [images, setImages] = useState([]);

  const uploadThumbnail = async (thumbnail) => {
    setFormLoading(true);

    setUploadedPercentage(0);
    const formData = new FormData();
    formData.append('thumbnail', thumbnail);
    try {
      const response = await uploadThumnail(formData, token, (percentage) =>
        setUploadedPercentage(percentage),
      );
      setBannerLinks([...bannerLinks, response.thumnailUrl]);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  const thumbnailProps = {
    onRemove: (image) => {
      let imag = images.filter((value) => value.name !== image.name);
      setImages(imag);
    },

    beforeUpload: (image) => {
      uploadThumbnail(image);
      setImages([...images, image]);
      return false;
    },
    fileList: images ? images : [],
    accept: 'image/*',
  };

  const submitReview = async () => {
    try {
      if (!bannerLinks.length) {
        return;
      }
      setReviewPicture(false);
      await updatePhoneStatusCrm(
        {
          ...openReviewData,
          contactId: openReviewData._id,
          status: 'REVIEWED',
          reviewPictures: bannerLinks,
        },
        token,
      );
      try {
        const dataPayload = {
          category: 'Review',
          subCategory: 'review',
          createdBy: openReviewData.phoneNumber,
          taskId: openReviewData.taskId,
          referenceOf: userId,
        };
        await getCommissionForDownandRev(dataPayload, token);
      } catch (err) {
        console.log(err);
      }
      setBannerLinks([]);
      setImages([]);
      getSetUp();
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <Modal
        visible={modelAddNumVisible}
        title="Add Phone Number"
        onCancel={() => setModelAddNumVisible(false)}
        footer={null}
      >
        <Form layout={'vertical'} name="control-hooks">
          <Form.Item
            required
            label="Mobile Number"
            rules={[{ required: true }]}
          >
            <Input
              value={phoneNumber}
              type="string"
              placeholder="0315000000"
              onChange={(e) => setPhoneNumber(e.target.value)}
              name={'phoneNumber'}
            />
          </Form.Item>

          <Row justify="end">
            <Col>
              <Button
                loading={addPhoneNumberLoading}
                onClick={onCreatePhone}
                type={'primary'}
              >
                Add Phone Number
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={reviewPictureModel}
        title="Add review picture"
        onCancel={() => setReviewPicture(false)}
        footer={null}
      >
        <Form layout={'vertical'} name="control-hooks">
          <Form.Item
            required
            label="Mobile Number"
            rules={[{ required: true }]}
          >
            <Col span={24}>
              {/* <Input style={{ marginTop: '10px' }} placeholder="PKR 500" /> */}
              <Dragger {...thumbnailProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-hint">Upload Image/Video/GIF</p>
                <p className="ant-upload-hint">.JPG/.PNG/MP3/MP4</p>
              </Dragger>
            </Col>
          </Form.Item>

          <Row justify="end">
            <Col>
              <Button onClick={submitReview} type={'primary'}>
                Submit Review Picture
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Row align="middle">
        <Col xl={3} lg={24} md={24} sm={24} xs={24}>
          <PhoneNumberFilter
            phoneNumberFilter={phoneNumberFilter}
            showModal={showModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          <Dropdown trigger={['click']} overlay={menu}>
            <Button
              onClick={(e) => e.preventDefault()}
              type="primary"
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '0px 10px 0px 5px',
                height: '34px',
                marginBottom: '15px',
              }}
            >
              Assign Targets
            </Button>
          </Dropdown>
        </Col>
        <Col lg={15} md={24} sm={24} xs={24}>
          <Row justify={'space-around'}>
            <Col lg={4} md={4} sm={8} xs={12}>
              <div className="d-flex flex-column">
                <h3 className="t">Download Score</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  {(() => {
                    const quantity = monthCommission.find(
                      (com) => com._id === 'Download',
                    );
                    return quantity ? quantity.quantity : 0;
                  })()}
                </Button>
                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === 'Download',
                  );
                  return (
                    <span className="p">
                      {' '}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(2) : 0}{' '}
                    </span>
                  );
                })()}
              </div>
            </Col>
            <Col lg={4} md={4} sm={8} xs={12}>
              <div className="d-flex flex-column">
                <h3 className="t">Post Score</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  {(() => {
                    let postQuantity = 0;
                    for (let com of monthCommission) {
                      if (
                        com._id === 'Grain Market' ||
                        com._id === 'Poultry Bazar' ||
                        com._id === 'Poultry Market' ||
                        com._id === 'Pets Point' ||
                        com._id === 'FOORI ZRORRAT' ||
                        com._id === 'Meri Zameen' ||
                        com._id === 'Meri Rozi'
                      ) {
                        postQuantity += com.quantity;
                      }
                    }

                    return postQuantity ? postQuantity : 0;
                  })()}
                </Button>
                {(() => {
                  let amount = 0;
                  for (let com of monthCommission) {
                    if (
                      com._id === 'Grain Market' ||
                      com._id === 'Poultry Bazar' ||
                      com._id === 'Poultry Market' ||
                      com._id === 'Pets Point' ||
                      com._id === 'FOORI ZRORRAT' ||
                      com._id === 'Meri Zameen' ||
                      com._id === 'Meri Rozi'
                    ) {
                      amount += com.sumQuantity;
                    }
                  }

                  return (
                    <span className="p">
                      {' '}
                      RS.
                      {amount ? amount.toFixed(2) : 0}{' '}
                    </span>
                  );
                })()}
                {/* <span className='p'>Rs. 2000</span> */}
              </div>
            </Col>
            <Col lg={4} md={4} sm={8} xs={12}>
              <div className="d-flex flex-column">
                <h3 className="t">Review Score</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  {(() => {
                    const quantity = monthCommission.find(
                      (com) => com._id === 'Review',
                    );
                    return quantity ? quantity.quantity : 0;
                  })()}
                </Button>
                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === 'Review',
                  );
                  return (
                    <span className="p">
                      {' '}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(2) : 0}{' '}
                    </span>
                  );
                })()}
                {/* <span className='p'>Rs. 2000</span> */}
              </div>
            </Col>

            <Col lg={4} md={4} sm={8} xs={12}>
              <div className="d-flex flex-column">
                <h3 className="t">Murgi Mandi</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  {(() => {
                    const quantity = monthCommission.find(
                      (com) => com._id === 'MURGHI MANDI',
                    );
                    return quantity ? quantity.quantity : 0;
                  })()}
                </Button>
                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === 'MURGHI MANDI',
                  );
                  return (
                    <span className="p">
                      {' '}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(2) : 0}{' '}
                    </span>
                  );
                })()}

                {/* <span className='p'>Rs. 2000</span> */}
              </div>
            </Col>

            <Col lg={4} md={4} sm={8} xs={12}>
              <div className="d-flex flex-column">
                <h3 className="t">E commerce</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  N/A
                </Button>
                {(() => {
                  return <span className="p">Rs 0</span>;
                })()}
                {/* <span className='p'>Rs. 2000</span> */}
              </div>
            </Col>

            <Col lg={4} md={4} sm={8} xs={12}>
              <div className="d-flex flex-column">
                <h3 className="t">Fori Zarorat</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  {(() => {
                    const quantity = monthCommission.find(
                      (com) => com._id === 'FOORI ZRORRAT',
                    );
                    return quantity ? quantity.quantity : 0;
                  })()}
                </Button>
                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === 'FOORI ZRORRAT',
                  );
                  return (
                    <span className="p">
                      {' '}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(2) : 0}{' '}
                    </span>
                  );
                })()}
                {/* <span className='p'>Rs. 2000</span> */}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={24} md={24} sm={24} xs={24}>
          <Row>
            <Col
              lg={12}
              sm={12}
              xs={12}
              style={{ paddingLeft: '10px' }}
              className="total"
            >
              <div className="d-flex flex-column">
                <h3>Total Earnings</h3>
                <Button
                  style={{ backgroundColor: 'lightpink', marginRight: '5px' }}
                >
                  Rs:
                  {(() => {
                    let total = 0;
                    for (let value of monthCommission) {
                      total += value?.sumQuantity;
                    }
                    return total.toFixed(2);
                  })()}
                </Button>
              </div>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              <div className="d-flex flex-column">
                <DatePicker
                  value={moment(selectedDate)}
                  onChange={onChangeDatePicker}
                  picker="month"
                  style={{ marginBottom: '5px' }}
                />
                <Button onClick={showModal} type={'primary'}>
                  Phone # Filter{' '}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="tab-group">
            <Tabs defaultActiveKey="3" centered>
              <TabPane tab="My Orders" key="1">
                <Row>
                  <Col
                    lg={{ span: 8, offset: 4 }}
                    md={{ span: 8, offset: 4 }}
                    sm={24}
                    xs={24}
                  >
                    <div
                      className="order-box ml-lg7"
                      style={{
                        background: 'linear-gradient(45deg, #FC9C39, #FBB438)',
                      }}
                    >
                      <div className="order-text">Trading Orders</div>
                      <div className="order-count">987546</div>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={24} xs={24}>
                    <a
                      target="blank"
                      href="https://e-commence-admin-penal-live.web.app/"
                    >
                      <div
                        className="order-box ml-lg7"
                        style={{
                          background:
                            'linear-gradient(45deg, #8DD38F, #8BF78F)',
                        }}
                      >
                        <div className="order-text">Ecommerce Orders</div>
                        <div className="order-count">{totalOrder}</div>
                      </div>
                    </a>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="My Dashboard" key="2">
                <Row
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflowX: 'auto',
                  }}
                >
                  <AddNumber
                    onUpdateContactStatus={onUpdateContactStatus}
                    phoneList={phoneInCategory.INITIAL}
                    showModal={() => setModelAddNumVisible(true)}
                  />
                  <CallNumber
                    onUpdateContactStatus={onUpdateContactStatus}
                    phoneList={phoneInCategory.CALLED_NUMBER}
                  />
                  <LinkSended
                    onUpdateContactStatus={onUpdateContactStatus}
                    phoneList={phoneInCategory.MESSAGE_SENT}
                  />
                  <Download
                    onUpdateContactStatus={openReviewScreen}
                    phoneList={phoneInCategory.APP_DOWNLOADED}
                  />
                  <BlockedNumber
                    onUpdateContactStatus={console.log}
                    phoneList={phoneInCategory.blocked}
                  />

                  <PostAndProfile
                    monthCommission={monthCommission}
                    profileDetail={userAndProfile}
                  />
                  <Reviews
                    onUpdateContactStatus={console.log}
                    phoneList={phoneInCategory.REVIEWED}
                  />
                </Row>
              </TabPane>
              <TabPane tab="Overall Statistics" key="3">
                <UserStat {...props} userDetails={userDetails} />
                <Row>
                  <Col lg={6} md={12} sm={12} xs={24}>
                    <div
                      className="order-box"
                      style={{
                        background: 'linear-gradient(45deg, #FC9C39, #FBB438)',
                      }}
                    >
                      <div className="order-text">Total Posts</div>
                      <div className="order-count">
                        {
                          <span className="NumberStyle">
                            {(() => {
                              let total = 0;
                              if (userAndProfile.length) {
                                for (let value of userAndProfile) {
                                  total += value?.TotalCount;
                                }
                                return total;
                              } else {
                                return 0;
                              }
                            })()}
                          </span>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col lg={9} md={12} sm={14} xs={24}>
                    <div className="order-box order-box2">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <div
                          onClick={() => {
                            props.setSelected(
                              'Dashboard/User',
                              'Crm Golden User',
                            );
                          }}
                          style={{
                            flexDirection: 'column',
                            flexGrow: 1,
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            marginRight: '5px',
                            background:
                              'linear-gradient(45deg, #FC9C39, #FBB438)',
                          }}
                        >
                          <div className="order-text2 order-text-lg">
                            Golden <br /> Members
                          </div>
                          <div className="order-count2">
                            {userDetails.activeUser + 20}
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            props.setSelected(
                              'Dashboard/User',
                              'Crm Rising User',
                            );
                          }}
                          style={{
                            flexDirection: 'column',
                            flexGrow: 1,
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            background:
                              'linear-gradient(0deg, #FC9C39, #FBB438)',
                          }}
                        >
                          <div className="order-text2  order-text-lg">
                            Rising <br />
                            Members
                          </div>

                          <div className="order-count2">
                            {userDetails.activeUser > 25
                              ? userDetails.activeUser - 25
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={9} md={24} sm={24} xs={24}>
                    <div
                      className='order-box ml-sm-0'
                      style={{
                        marginLeft: '20px',
                        background: 'linear-gradient(45deg, #FFAAD5, #F64AEE)',
                      }}
                    >
                      <div className='order-text'>Total Fans</div>
                      <div className='order-count'>
                        {userDetails.activeUser}
                      </div>
                    </div>
                  </Col>

                  */}

                  <RejectUSer />
                </Row>
                {/* <Row>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <div style={{overflowX: 'scroll'}}>
                          <Table1/>
                        </div>
                      </Col>
                    </Row> */}
              </TabPane>
              <TabPane tab="Murghi mandi Stat" key="4">
                <MurghiMundiState userId={userId} />
              </TabPane>

              <TabPane tab="Orders" key="5">
                <MurgiMandiOffer userId={userId} />
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}
