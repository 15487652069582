import { Button, Col, Row } from 'antd';
import React from 'react';
import { phoneStatuses } from '../../../shared';
import { MAIN_BACKGROUND_COLOR } from '../../../styleConstants';

export default function AddNumber({
  showModal,
  phoneList,
  onUpdateContactStatus,
}) {
  return (
    <Col
      lg={8}
      md={16}
      sm={20}
      xs={20}
      style={{
        border: '1px black solid',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: '4px',
        borderRadius: '5px',
      }}
    >
      <Row
        style={{
          borderBottom: '1px black solid',
          borderRadius: '5px',
        }}
      >
        <Col style={{ padding: '10px' }} span={12}>
          {/* <Button
            type={"primary"}
            style={{ borderRadius: "50px", marginRight: "10px" }}
          >
            Add Category
          </Button> */}
          <Button
            onClick={showModal}
            type={'primary'}
            style={{ borderRadius: '50px' }}
          >
            Add Num
          </Button>
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          span={6}
        >
          Total: {phoneList?.length}
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
          }}
          span={6}
        >
          <Button
            onClick={() => {
              // eslint-disable-next-line array-callback-return
              phoneList?.map((value, index) => {
                onUpdateContactStatus({
                  _id: value._id,
                  taskId: value.taskId,
                  status: phoneStatuses.CALLED_NUMBER,
                });
              });
            }}
          >
            Check All
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ padding: '10px' }} span={24}>
          {/* <Button
            type={"primary"}
            style={{ borderRadius: "50px", marginRight: "10px" }}
          >
            FaceBook {"  "} 50
          </Button>
          <Button type={"primary"} style={{ borderRadius: "50px" }}>
            WhatsApp {"  "} 100
          </Button> */}
        </Col>
      </Row>
      <Row
        gutter={[12, 12]}
        justify='center'
        align='top'
        style={{ height: '100vh', color: 'black', padding: '20px' }}
      >
        <Col span={24}>
          {phoneList?.map((value, index) => {
            return (
              <Row
                key={value.phoneNumber}
                gutter={[12, 12]}
                justify='center'
                align='middle'
                style={{ color: 'black', marginTop: '10px' }}
              >
                <Col span={2}>{index + 1}</Col>
                <Col span={9}>{value.phoneNumber}</Col>
                <Col span={7}>
                  {value.createdAt && new Date(value.createdAt).toDateString()}
                </Col>

                <Col span={6}>
                  <Button
                    onClick={() =>
                      onUpdateContactStatus({
                        _id: value._id,
                        taskId: value.taskId,
                        status: phoneStatuses.CALLED_NUMBER,
                      })
                    }
                    type={'primary'}
                    style={{ borderRadius: '50px', width: '100%' }}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
}
