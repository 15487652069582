import React from "react";
import { Tabs } from "antd";
import EggRate from "./EggRates";
import ChickRate from "./ChickRates";
import BroilerRate from "./BroilerRates";
class Body extends React.Component {
  render() {
    const { TabPane } = Tabs;
    return (
      <div>
        <Tabs size="large" animated={false} defaultActiveKey="egg_rates">
          <TabPane tab="Egg Rates" key="egg_rates">
            <EggRate />
          </TabPane>
          <TabPane tab="Chick Rates" key="chick_rates">
            <ChickRate />
          </TabPane>
          <TabPane tab="Broiler Rates" key="broiler_rates">
            <BroilerRate />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Body;
