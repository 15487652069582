import SearchIcon from "@material-ui/icons/Search";
import {
  Avatar,
  Button,
  Col,
  Image,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import {
  changeCompApi,
  findCustomerWithPhone,
} from "../../../RestServices/authService";
import {
  formateString,
  PermissionEnum,
  RemoveColonFromPermission,
  UserPermisstion,
} from "../../../shared";

const SearchUserModal = ({ handleOk, crmUsers, isModalOpen, handleCancel }) => {
  const {
    token,
    profileDetails: { permissions },
  } = useSelector((state) => state.Authentication.user);

  const crmUsersRef = useRef(crmUsers);

  useEffect(() => {
    crmUsersRef.current = crmUsers;
  }, [crmUsers]);

  const content = (phoneNumber) => {
    return (
      <div style={{ hight: "400px", overflow: "scroll" }}>
        {crmUsersRef?.current?.map(({ _id, name, profileDetails }) => {
          return profileDetails.permissions.includes(
            PermissionEnum["Crm_System:*"],
          ) ? (
            <p
              onClick={async () => {
                try {
                  await changeCompApi(token, {
                    toUserId: _id,
                    phoneNumber,
                  });
                  message.success("Successfully Done");
                } catch (err) {
                  message.error(err.response.data.message);
                }
              }}
              style={{ cursor: "pointer" }}
              key={_id}
            >
              {name}
            </p>
          ) : (
            <></>
          );
        })}
      </div>
    );
  };

  const tableColumns = [
    {
      title: "Sr.",
      width: 20,
      dataIndex: "index",
      fixed: "left",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Image",
      width: 20,
      dataIndex: "profileImage",
      fixed: "left",
      align: "center",
      render: (text, record, index) => (
        <Avatar src={<Image style={{ width: "80px" }} src={text} />} />
      ),
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },

    {
      key: "phoneNumber",
      title: "Phone",
      dataIndex: "phoneNumber",
      render: (text, record, index) => (
        <a style={{ color: "blue" }} href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      key: "city",
      title: "City",
      dataIndex: "city",
    },

    {
      key: "type",
      title: "Rule",
      dataIndex: "type",
    },

    {
      key: "status",
      title: "Status",
      // title: "Category",
      dataIndex: "status",
    },
    {
      key: "createdAt",
      title: "date of Creation",
      dataIndex: "createdAt",
    },

    {
      key: "updatedAt",
      title: "Last Seen",
      dataIndex: "updatedAt",
    },
    {
      key: "Action",
      title: "Change his comp.",
      render: (text, record, index) => {
        const PermissionsRomovingColor = RemoveColonFromPermission(permissions);
        return (
          <div>
            {PermissionsRomovingColor.includes(
              UserPermisstion[0].value.split(":")[0],
            ) || PermissionsRomovingColor.includes("Dashboard") ? (
              <Popover
                content={() => content(record.phoneNumber)}
                title="Select Champion"
                trigger="click"
              >
                <Button style={{ marginTop: "10px" }} type={"primary"}>
                  Change Champion
                </Button>
              </Popover>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const [searchPhoneNumber, setSearchingPhoneNumber] = useState("");
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getUserApiFunc();
  }, [searchPhoneNumber]);

  const getUserApiFunc = async () => {
    try {
      let data = await findCustomerWithPhone(token, {
        phoneNumber: searchPhoneNumber,
      });
      if (!data.customerList?.customerList) {
        setCustomers([]);
      }
      const customers = data?.customerList?.customerList?.map((customer) => ({
        name: customer.name,
        type: customer.profileDetails.type,
        status: customer.status,
        key: customer._id,
        updatedAt: moment(customer.lastProfileFetched).format(formateString),
        createdAt: moment(customer.createdAt).format(formateString),

        phoneNumber: customer.phoneNumber,
        profileImage: customer.profileImage,
        city: customer.city,
      }));
      setCustomers(customers);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const [tableColumnsState] = useState(tableColumns);

  return (
    <>
      <Modal
        title={"Search User"}
        width="80%"
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel(false)}
      >
        <Row>
          <Col span={24}>
            <Input
              prefix={<SearchIcon />}
              style={{ width: "200px"}}
              // className="search"
              type="number"
              value={searchPhoneNumber}
              onChange={(e) => setSearchingPhoneNumber(e.target.value)}
              placeholder="Search with user ID"
            />
          </Col>
          <Col
            span={24}
            style={{
              overflowX: "scroll",
            }}
          >
            <Table
              dataSource={customers}
              pagination={false}
              columns={tableColumnsState}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SearchUserModal;
