import React from "react";
import { List, message, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { getListOfMandiGurus } from "../../RestServices/authService";
import { connect } from "react-redux";
import "./styles.css";
import RenderMandiGuru from "./RenderMandiGuru";

class Body extends React.Component {
  state = {
    mandiGurus: [],
    totalMandiGurus: 0,
    loading: false,
    hasMore: true,
    currentPage: 1,
  };

  handleInfiniteOnLoad = async () => {
    try {
      this.setState({
        loading: true,
      });
      let { mandiGurus, totalMandiGurus, currentPage } = this.state;
      if (mandiGurus.length >= totalMandiGurus) {
        message.info("All MandiGurus Loaded");
        this.setState({
          hasMore: false,
          loading: false,
        });
        return;
      }

      const data = await getListOfMandiGurus(
        "PENDING",
        currentPage + 1,
        this.props.Auth.user.token
      );
      mandiGurus = mandiGurus.concat(data.mandigurus.docs);
      this.setState({
        mandiGurus: mandiGurus,
        loading: false,
        currentPage: currentPage + 1,
      });
    } catch (err) {
      this.setState({ loading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const data = await getListOfMandiGurus(
        "PENDING",
        1,
        this.props.Auth.user.token
      );
      this.setState({
        mandiGurus: data.mandigurus.docs,
        totalMandiGurus: data.mandigurus.totalDocs,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  }

  render() {
    return (
      <div className="mandigurus-infinite-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <List
            dataSource={this.state.mandiGurus}
            renderItem={(item) => (
              <RenderMandiGuru token={this.props.Auth.user.token} item={item} />
            )}
          >
            {this.state.loading && this.state.hasMore && (
              <div className="mandigurus-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(Body);
