import React from "react";
import "./upper-cards.css";
import "./progress.css";
import ProgressBar from "./ProgressBar";
import { calCulatePercentage } from "../../../../../shared";
import { Col, Row } from "antd";

const UpperCard2 = ({ setSelected, ...props }) => {
  return (
    <Row gutter={8}>
      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
        <div>
          <ProgressBar
            strokeColor={"#4a0600"}
            strokeWidth={12}
            percent={calCulatePercentage(props.number1, props.preNumber1)}
            showInfo={false}
            status="active"
          />
        </div>
        <div
          onClick={() => setSelected("Dashboard/User", "Active")}
        >
          <h1 className="title">{props.number1}</h1>
          <p className="body mb-0" style={{ fontSize: "18px" }}>{props.title1}</p>
        </div>
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
        <div>
          <ProgressBar
            strokeColor={"#4a0600"}
            strokeWidth={12}
            percent={calCulatePercentage(props.number1, props.preNumber1)}
            showInfo={false}
            status="active"
          />
        </div>
        <div
          onClick={() => setSelected("Dashboard/User", "Idol")}
        >
          <h1 className="title">{props.number2}</h1>
          <p className="body mb-0" style={{ fontSize: "18px" }}>{props.title2}</p>
        </div>
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
        <div>
          <ProgressBar
            strokeColor={"#4a0600"}
            strokeWidth={12}
            percent={calCulatePercentage(props.number1, props.preNumber1)}
            showInfo={false}
            status="active"
          />
        </div>
        <div
          onClick={() => setSelected("Dashboard/User", "Sluggish")}
        >
          <h1 className="title">{props.number3}</h1>
          <p className="body mb-0" style={{ fontSize: "18px" }}>{props.title3}</p>
        </div>
      </Col>
    </Row>
  );
};

export default UpperCard2;
