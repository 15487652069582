// import jwt from "jsonwebtoken";
// eslint-disable-next-line
export const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const searchDateString = "ddd MMM DD YYYY";
export const RemoveColonFromPermission = (permissions) => {
  return permissions.map((premission) => premission.split(":")[0]);
};

export const calCulatePercentage = (number, preNumber) => {
  let percentage = (((number - preNumber) / preNumber) * 100).toFixed(2);

  if (isNaN(percentage)) {
    return 0;
  }
  if (!isFinite(percentage)) {
    return 100;
  }
  return percentage;
};

export const dateFormat = "DD-MM-YYYY";
export const poultryCommodities = [
  "whiteegg",
  "brownegg",
  "whitelayer",
  "broiler",
];

export const ADMINCHANEL = "allAdmin";

export const FEEDBACKCOME = "feedBackCome";

export const itemProducts = [
  "WHITE_EGG",
  "BROWN_EGG",
  "LAYER_CHICK",
  "BROWN_CHICK",
  "BROILER_CHICK",
  "BROILER",
  "LAYER",
  "BROWN_LAYER",
  "CORN",
  "SOYABEAN",
];

export const videosEnum = [
  "DOCTORS_CORNER",
  "TIPS",
  "MANDI_RATE",
  "BEST_FARMER_AWARD",
  "NEWS",
  "MARKET_VIDEOS",
  "HOW_TO_USE_APP",
  "HOME",
  "RATE",
];

export const PermissionEnum = {
  "AllResources:*": "AllResources:*",
  "Dashboard:*": "Dashboard:*",
  "Users:*": "Users:*",
  "Users:add": "Users:add",
  "Users:delete": "Users:delete",
  "Users:get": "Users:get",
  "CommodityRates:*": "CommodityRates:*",
  "MandiRates:*": "MandiRates:*",
  "Videos:*": "Videos:*",
  "News_Articles:*": "News_Articles:*",
  "Reports:*": "Reports:*",
  "Ads:*": "Ads:*",
  "FeedBack:*": "FeedBack:*",
  "Crm_System:*": "Crm_System:*",
  "AssignTarget:*": "AssignTarget:*",
  "e-commerce:*": "e-commerce:*",
  "Posts:*": "Posts:*",
  "CommissionRates:*": "CommissionRates:*",
};

export const TopPermission = [
  {
    value: PermissionEnum["AllResources:*"],
    label: "AllResources:AllAccess",
    disabled: true,
  },
  { value: PermissionEnum["Dashboard:*"], label: "Dashboard:AllAccess" },
];

export const UserPermisstion = [
  {
    value: PermissionEnum["AllResources:*"],
    label: "AllResources:AllAccess",
    disabled: true,
  },
  { value: PermissionEnum["Dashboard:*"], label: "Dashboard:AllAccess" },
  {
    value: PermissionEnum["Users:*"],
    label: "Users:AllAccess",
    disabled: true,
  },
  {
    value: PermissionEnum["Users:add"],
    label: "Users:addUser",
    disabled: true,
  },
  {
    value: PermissionEnum["Users:delete"],
    label: "Users:deleteUser",
    disabled: true,
  },
  { value: PermissionEnum["Users:get"], label: "Users:getUser" },
  {
    value: PermissionEnum["CommodityRates:*"],
    label: "Commodity Rates:AllAccess",
  },
  { value: PermissionEnum["MandiRates:*"], label: "Mandi Rates:AllAccess" },
  { value: PermissionEnum["Videos:*"], label: "Videos:AllAccess" },
  {
    value: PermissionEnum["News_Articles:*"],
    label: "News Articles:AllAccess",
  },
  {
    value: PermissionEnum["Crm_System:*"],
    label: "CRM System:AllAccess",
  },
  {
    value: PermissionEnum["AssignTarget:*"],
    label: "Assign Target CRM:AllAccess",
  },
  { value: PermissionEnum["Reports:*"], label: "Reports:AllAccess" },
  { value: PermissionEnum["Ads:*"], label: "Ads:AllAccess" },
  { value: PermissionEnum["FeedBack:*"], label: "Feedback:AllAccess" },
  { value: PermissionEnum["Posts:*"], label: "Posts:AllAccess" },
  {
    value: PermissionEnum["CommissionRates:*"],
    label: "Commission Rates:AllAccess",
  },

  { value: PermissionEnum["e-commerce:*"], label: "e-commerce:AllAccess" },
];

export const phoneStatuses = {
  INITIAL: "INITIAL",
  APP_DOWNLOADED: "APP_DOWNLOADED",
  MESSAGE_SENT: "MESSAGE_SENT",
  CALLED_NUMBER: "CALLED_NUMBER",
  blocked: "blocked",
  REVIEWED: "REVIEWED",
};

export const ReasonOfBlocked = ["IOS User", "Keypad user", "Not Interested"];
export const posterCategories = [
  "Poultry Bazar",
  "Poultry Market",
  "Real Estate",
  "Grain Market",
  "Poultry Careers Request",
  "Poultry Careers Job",
  "PETS_POINT",
];
export const posterFor = ["WEB", "MOBILE"];

export const NewsEnum = [
  "NEWS",
  "EVENT",
  "ARTICLE",
  "URDU_ARTICLE",
  "HOME",
  "RATE",
  "DOCTORS_CORNER",
  "TIPS",
  "MANDI_RATE",
  "MARKET_VIDEOS",
];

export const formateString = "MMM DD YYYY, h:mm:ss a";
export const formateStringTimeDate = "MMM DD, h:mm";
// export const authService = "http://localhost:4001";
// export const postingService = 'http://localhost:4000';
// export const videosService = "http://localhost:4002";
// export const baseUrlOfCommerce = 'http://localhost:8000/';

// export const baseUrlOfCommerce = "https://api-ecommerce.poultrybaba.com/";
// export const authService = "https://api-auth.poultrybaba.com";
// export const postingService = "https://api-posts.poultrybaba.com";
// export const videosService = "https://api-videos.poultrybaba.com";

// export const baseUrlOfCommerce =
//   "https://api.poultrybaba.com/ecommerce-service/";
// export const authService = "https://api.poultrybaba.com/auth-service";
// export const postingService = "https://prod-posts.poultrybaba.com";
// export const videosService = "https://api.poultrybaba.com/videos-service";

export const baseUrlOfCommerce = "https://prod-ecommerce.poultrybaba.com";
export const authService = "https://prod-auth.poultrybaba.com";
export const postingService = "https://prod-posts.poultrybaba.com";
export const videosService = "https://prod-videos.poultrybaba.com";

export const statService = "http://128.199.138.137:5000";

export const postType = [
  "Poultry Bazar",
  "Poultry Market",
  "Real Estate",
  "Grain Market",
  "Poultry Careers Request",
  "Poultry Careers Job",
  "Pets Point",
];

export const discriminators = {
  "Pets Point": {
    type: "Pets Point",
    value: [
      "LOVE_BIRDS",
      "BUDGIES",
      "COCKATIEL",
      "RING_NECK_PARROT",
      "RAW_PARROT",
      "GREY_PARROT",
      "MACAW",
      "LORRY_PARROT",
      "SUN_CONURE",
      "AMAZON_PARROT",
      "MONK_PARAKEET",
      "ROSELLAS",
      "PIGEONS",
      "FANCY_HENS",
      "ASIL_CHICKEN",
      "PHEASANT",
      "PEA_COCK",
      "AUSTRALORP",
      "CATS",
      "DOGS",
      "OTHER_ANIMALS",
      "OTHER_BIRDS",
    ],
  },
  "Poultry Bazar": {
    type: "Poultry Bazar",
    value: [
      "White Egg",
      "Brown Egg",
      "Broiler Chick",
      "Brown Layer Chick",
      "White Layer Chick",
      "Broiler Flock",
      "Brown Layer Hen",
      "White Layer Hen",
      "Poultry Feed",
      "Feed Bags",
      "Egg Trays",
      "Manure",
      "Rise Husk",
    ],
  },
  "Poultry Market": {
    type: "Poultry Market",
    value: [
      "Medicine",
      "Vaccine",
      "Egg Laying Cage",
      "Drinking System",
      "Feeding System",
      "Evaporative Cooling System",
      "Winter Ventilation System",
      "Services Pressure Washing",
      "Detergents",
      "Disinfectants",
      "Insecticides",
      "Anti Fungal",
      "Fumigation Services",
      "Feed Additives",
      "Anti biotic growth",
      "Probiotics",
      "Amino Acids",
      "Vitamins",
      "Mineral",
      "Enzymes",
      "Vaccination Tools",
      "Automatic Injector Machine",
      "Manual Injectors Syringes",
      "Winch System",
      "Curtains",
      "Incubators",
    ],
  },
  // REAL_ESTATE: "Real Estate",
  "Grain Market": {
    type: "Grain Market",
    value: [
      "Corn",
      "Rice Broken",
      "Wheat",
      "Millet",
      "Sorghun",
      "Soyabean Meal",
      "Canola Meal",
      "Sunflower",
      "Rap Seed meal",
      "Gawar meal",
      "Palm kernel oil meal",
      "Fish meal",
      "Poultry by product meal",
      "Gluten 30",
      "Gluten 60",
      "DCP",
      "DCP Born meal",
      "Lysine",
      "Methionine",
      "Thyronine",
    ],
  },
  // POULTRY_CAREERS_REQUEST: "Poultry Careers Request",
  // POULTRY_CAREERS_JOB: "Poultry Careers Job",
};

export const commissionGivenCategory = {
  ...discriminators,
  Download: {
    type: "Download",
    value: ["download"],
  },
  Review: {
    type: "Review",
    value: ["review"],
  },
  "Pets Point": {
    type: "Pets Point",
    value: ["Pets Point"],
  },
  "Poultry Bazar": {
    type: "Poultry Bazar",
    value: ["Poultry Bazar"],
  },
  "Poultry Market": {
    type: "Poultry Market",
    value: ["Poultry Plaza"],
  },
  "MURGHI MANDI": {
    type: "MURGHI MANDI",
    value: ["MURGHI MANDI", ""],
  },
  // REAL_ESTATE: "Real Estate",
  "Grain Market": {
    type: "Grain Market",
    value: ["Gain Market"],
  },
};

//
// export const postingService = 'http://localhost:4000';
// export const authService = window.url;
// export const videosService = window.videoUrl;

// "http://localhost:4001";
// export const videosService = "http://localhost:4002"

// export const verifyToken = () => {
//   const token = localStorage.getItem("token");
//   if (!token) return null;
//   const decodedToken = jwt.decode(token);
//   if (decodedToken.exp > Date.now() / 1000) return decodedToken;
//   return null;
// };

// "codepipeline:*",
// "cloudformation:DescribeStacks",
// "cloudformation:ListChangeSets",
// "cloudtrail:DescribeTrails",
// "codebuild:BatchGetProjects",
// "codebuild:CreateProject",
// "codebuild:ListCuratedEnvironmentImages",
// "codebuild:ListProjects",
// "codecommit:ListBranches",
// "codecommit:GetReferences",
// "codecommit:ListRepositories",
// "codedeploy:BatchGetDeploymentGroups",
// "codedeploy:ListApplications",
// "codedeploy:ListDeploymentGroups",
// "ec2:DescribeSecurityGroups",
// "ec2:DescribeSubnets",
// "ec2:DescribeVpcs",
// "ecr:DescribeRepositories",
// "ecr:ListImages",
// "ecs:ListClusters",
// "ecs:ListServices",
// "elasticbeanstalk:DescribeApplications",
// "elasticbeanstalk:DescribeEnvironments",
// "iam:ListRoles",
// "iam:GetRole",
