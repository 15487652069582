import React from "react";
import "./progress.css";
import ProgressBar from "./ProgressBar";
import "./upper-cards.css";

const UpperCard3 = (props) => {
  return (
    <>
      <div onClick={props.setSelected}>
        <div style={{ position: "relative", padding: "15px" }}>
          <ProgressBar
            strokeColor={"#43dc80"}
            strokeWidth={12}
            percent={90}
            showInfo={false}
            status="active"
          />
          <div>
            <h1 className="title">{props.number}</h1>
            <p className="body mb-0">{props.title}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpperCard3;
