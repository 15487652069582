import { Card, Col, Divider, Image, Row, Skeleton, Typography } from 'antd';
import React from 'react';
import './lower-card.css';
// import DummyPic from "./Images/dummyPic.jpg";

const LowerCardForVidios = ({ data, ...props }) => {
  const dataOne = data
    ? data?.find((value) => value.title === props.Heading)
    : null;
  const { Title } = Typography;
  return (
    <div>
      <div className="flex-center">
        <Image src={props.Img} style={{ width: "100%", height: "100px" }} alt='pic' />
      </div>
      <Title level={3} className="mb-0 text-primary" style={{ textAlign: "center", marginTop: "5px" }}>
        {props.Heading.split('_').join(' ')}
      </Title>
      <Divider />
      <div>
        {!props?.loading ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={[12, 12]}>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalPost}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne ? dataOne.count : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalView}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne ? (dataOne.views ? dataOne.views : 'N/A') : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalVisit}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{dataOne && dataOne.visits ? dataOne.visits : 'N/A'}</Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.TotalLikes}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{(() => {
                  let variable = 0;

                  if (dataOne && dataOne.ids.length)
                    for (let video of dataOne.ids) {
                      for (let reactin of video.reactions) {
                        if (reactin.reaction === 'LIKE') {
                          variable += 1;
                        }
                      }
                    }
                  return variable === 0 ? 'N/A' : variable;
                })()}
                </Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>TotalDislike</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{(() => {
                  let variable = 0;

                  if (dataOne && dataOne.ids.length)
                    for (let video of dataOne.ids) {
                      for (let reactin of video.reactions) {
                        if (reactin.reaction === 'ANGRY') {
                          variable += 1;
                        }
                      }
                    }
                  return variable === 0 ? 'N/A' : variable;
                })()}
                </Title>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{props.AvgViewTime}</Title>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Title level={4} className="mb-0 text-primary" style={{ textAlign: "center" }}>{'N/A'}</Title>
              </Col>
            </Row>
          </Col>
        ) : <Skeleton line={10} active />}
      </div>
    </div>
  );
};

export default LowerCardForVidios;

// <div className="main-lower-div">
//   <div className="Main_Lower">
//     <div className="dropdown-div">
//       <CityDropDown />
//       <CategoryDropDown />
//     </div>
//     <div className="image-div">
//       <img src={props.Img} alt="pic" className="img" />
//     </div>
// <h1 className="lower-heading">{props.Heading}</h1>
// <hr className="hr" />
// <div className="Total" style={{ marginTop: "10%" }}>
//   <h1 className="text-color">{props.TotalPost}</h1>
//   <h1 className="text-color">{props.TotalPostValue}</h1>
// </div>
// <div className="Total">
//   <h1 className="text-color">{props.TotalVideos}</h1>
//   <h1 className="text-color">{props.TotalVideosValue}</h1>
// </div>
// <div className="Total">
//   <h1 className="text-color">{props.TotalShare}</h1>
//   <h1 className="text-color">{props.TotalShareValue}</h1>
// </div>
// <div className="Total">
//   <h1 className="text-color">{props.TotalView}</h1>
//   <h1 className="text-color">{props.TotalViewValue}</h1>
// </div>
// <div className="Total">
//   <h1 className="text-color">{props.TotalLikes}</h1>
//   <h1 className="text-color">{props.TotalLikesValue}</h1>
// </div>
// <div className="Total">
//   <h1 className="text-color">{props.TotalVisit}</h1>
//   <h1 className="text-color">{props.TotalVisitValue}</h1>
// </div>
// <div className="Total">
//   <h1 style={{ marginLeft: "6%" }} className="text-color">
//     {props.AvgViewTime}
//   </h1>
//   <h1 style={{ marginRight: "2%" }} className="text-color">
//     {props.AvgViewTimeValue}
//   </h1>
// </div>
//   </div>
// </div>
