import { Col, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getContactWithTask } from '../../../RestServices/authService';

export default function RejectUSer() {
  const { token, _id: userId } = useSelector(
    (state) => state.Authentication.user
  );
  const [phoneInCategory, setPhoneInCategory] = useState({
    'IOS User': [],
    'Keypad user': [],
    'Not Interested': [],
  });
  useEffect(() => {
    getSetUp();
  }, []);
  const getSetUp = async () => {
    const { contacts } = await getContactWithTask(
      {
        status: 'blocked',
        userId: userId,
      },
      token
    );
    const phoneInCategory = {
      'IOS User': [],
      'Keypad user': [],
      'Not Interested': [],
    };
    for (let contact of contacts) {
      if (contact && contact?.blockedReason) {
        phoneInCategory[contact.blockedReason]?.push(contact);
      }
    }
    setPhoneInCategory(phoneInCategory);
  };
  return (
    <Col lg={9} md={24} sm={24} xs={24}>
      <div
        className='order-box order-box2'
        style={{ marginLeft: '10px', height: '130px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Tooltip
            title={phoneInCategory['IOS User']?.map((reason) => (
              <p>{reason.phoneNumber}</p>
            ))}
          >
            <div
              style={{
                flexDirection: 'column',
                flexGrow: 1,
                paddingTop: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',

                paddingBottom: '12px',
                marginRight: '5px',
                background: 'linear-gradient(45deg, #FFB548, #FFA113)',
              }}
            >
              <div className='order-text3'>Ios Users</div>
              <div className='order-count3'>
                {phoneInCategory['IOS User'].length}
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={phoneInCategory['Keypad user']?.map((reason) => (
              <p>{reason.phoneNumber}</p>
            ))}
            className='u'
          >
            <div
              style={{
                flexDirection: 'column',
                flexGrow: 1,
                paddingTop: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',

                paddingBottom: '12px',
                marginRight: '5px',
                background: 'linear-gradient(45deg, #FFB548, #FFDEB1)',
              }}
            >
              <div className='order-text3'>Keypad</div>
              <div className='order-count3'>
                {phoneInCategory['Keypad user'].length}
              </div>
            </div>
          </Tooltip>
          <Tooltip
            className='u'
            title={phoneInCategory['Not Interested']?.map((reason) => (
              <p>{reason.phoneNumber}</p>
            ))}
          >
            <div
              style={{
                flexDirection: 'column',
                flexGrow: 1,
                paddingTop: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',

                paddingBottom: '12px',
                background: 'linear-gradient(45deg, #FFB548, #FFDEB1)',
              }}
            >
              <div className='order-text3'>Not Interested</div>
              <div className='order-count3'>
                {phoneInCategory['Not Interested'].length}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </Col>
  );
}
