// import React from "react";
// import { Button, message } from "antd";
// import { deleteVideoById } from "../../../RestServices/videosService";
// import { connect } from "react-redux";
// class DeleteVideo extends React.Component {
//   state = { isDeleteLoading: false, isDeleted: false };
//   deleteUser = async (evt) => {
//     evt.stopPropagation();
//     this.setState({ isDeleteLoading: true });
//     try {
//       await deleteVideoById(
//         {
//           videoId: this.props.video._id,
//           thumbnailUri: this.props.video.thumbnailUri,
//           videoUri: this.props.video.videoUri,
//         },
//         this.props.Auth.user.token
//       );
//       message.success("Video " + this.props.video.title + " Deleted!");
//       this.setState({ isDeleteLoading: false, isDeleted: true });
//     } catch (err) {
//       this.setState({ isDeleteLoading: false });
//       if (err.response) {
//         return message.error(err.response.data.message);
//       }
//       message.error(err.message);
//     }
//   };
//   render() {
//     const { isDeleted, isDeleteLoading } = this.state;
//     if (isDeleted) return "User Deleted!";
//     return (
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <Button
//           loading={isDeleteLoading}
//           disabled={isDeleteLoading}
//           onClick={this.deleteUser}
//         >
//           Delete
//         </Button>
//       </div>
//     );
//   }
// }
// const mapStateToProps = (store) => ({
//   Auth: store.Authentication,
// });
// export default connect(mapStateToProps)(DeleteVideo);

import React from "react";
import { Button, message, Row, Col, Form, Input } from "antd";
import { connect } from "react-redux";
import {
  deleteArticle,
  deleteVideoById,
} from "../../../RestServices/videosService";
import ModalUtil from "../../../utils/Modal/Modal";

class DeleteVideo extends React.Component {
  state = {
    isDeleteLoading: false,
    isDeleted: false,
    actionModal: false,
    passwordForAction: null,
  };

  deleteUser = async (evt) => {
    evt.stopPropagation();
    if (this.state.passwordForAction !== "1345") {
      return message.error("Pass key is wrong. Rocorded");
    }
    this.setState({ isDeleteLoading: true });

    try {
      if (this.props.articles) {
        await deleteArticle(
          {
            articleId: this.props.video._id,
          },
          this.props.Auth.user.token
        );
      } else {
        await deleteVideoById(
          {
            videoId: this.props.video._id,
            thumbnailUri: this.props.video.thumbnailUri,
            videoUri: this.props.video.videoUri,
          },
          this.props.Auth.user.token
        );
      }
      message.success(this.props.video.title + " Deleted!");
      this.setState({
        isDeleteLoading: false,
        isDeleted: true,
        actionModal: false,
        passwordForAction: null,
      });
    } catch (err) {
      this.setState({ isDeleteLoading: false });
      if (err.response) {
        return message.error(err.response.data.message);
      }
      message.error(err.message);
    }
  };
  onCloseActionModal = (e) => {
    e.stopPropagation();
    this.props.deleteModalFuc && this.props.deleteModalFuc();

    this.setState({
      actionModal: false,
      passwordForAction: null,
    });
  };
  openModal = (e) => {
    e.stopPropagation();
    this.props.deleteModalFuc && this.props.deleteModalFuc();
    this.setState({ actionModal: true });
  };

  render() {
    const { isDeleted, isDeleteLoading } = this.state;
    if (isDeleted) return "Deleted!";
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ModalUtil
          timerModalVisible={this.state.actionModal}
          timerModalLoading={isDeleteLoading}
          title={"set Rate"}
          showTimerModal={this.setActionModal}
          handleTimerModalOnOk={this.deleteUser}
          handleTimerModalOnCancle={(e) => this.onCloseActionModal(e)}
        >
          <Row style={{ margin: "20px" }} justify="center">
            <Form
              style={{ padding: "20px", width: "100%" }}
              colon={false}
              layout="vertical"
              onSubmit={this.deleteUser}
            >
              <h3>Enter the pass key to perform action</h3>
              <Form.Item label="Pass key" required>
                <Input
                  size="large"
                  type="password"
                  name="passwordForAction"
                  onChange={(e) =>
                    this.setState({ passwordForAction: e.target.value })
                  }
                  value={this.state.passwordForAction}
                  placeholder="Enter Password for action"
                />
              </Form.Item>
            </Form>
          </Row>
        </ModalUtil>

        <Button
          type={"primary"}
          loading={isDeleteLoading}
          disabled={isDeleteLoading}
          onClick={this.openModal}
        >
          Delete
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(DeleteVideo);
