import { Progress } from "antd";
import React from "react";

const ProgressBar = ({ strokeColor, percent }) => {
  // let perc = (props.value / props.max) * 100;
  // perc = perc.toFixed(0);
  return (
    <div
      style={{
        position: "relative",
        top: "-10px",
        width: "100%",
      }}
    >
      <Progress
        strokeColor={strokeColor}
        percent={percent}
        showInfo={false}
        status="active"
      />
      <div
        style={{
          color: "black",
          fontSize: "12px",
          fontWeight: 500,
          position: "absolute",
          top: "25px",
          marginLeft: `${percent - 0}%`,
        }}
      >
        {percent} %
      </div>
    </div>
  );
};

export default ProgressBar;
