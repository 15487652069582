import { message as AntAlert } from 'antd';
import axios from 'axios';
import { baseUrlOfCommerce } from '../shared';

export const getOrderCountWRTStatus = async (token, crmUserId) => {
  let uri = `api/v1/orders/getOrderCountWRTStatus`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      crmUserId,
    },
    headers: {
      Authorization: 'bearer ' + token,
      'api-key': 'poultrybaba@1234Sumandri',
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error('Api error');
  }
};

export const ErrorCatcher = (response) => {
  const { success, message } = response;
  if (success) {
    return response;
  }
  AntAlert.error(Object.values(message).join('. '));
  return false;
};
