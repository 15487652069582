import {
  CloseCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { CloseOutlined } from "@material-ui/icons";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { MAIN_BACKGROUND_COLOR } from "../../../styleConstants";
import "../style.css";

const styles = {
  modal: {
    backgroundColor: "white",
  },
  submitButton: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    width: "100%",
    border: "none",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

const CreateProsAndCons = ({
  isModelOpen,
  setIsModelOpen,
  setProsCons,
  editData,
  proCon,
  prosCons,
  index,
}) => {
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);

  const [consform] = Form.useForm();
  const [prosform] = Form.useForm();

  // useEffect(() => {
  //   if (editData.hasOwnProperty("_id")) {
  //     if (editData?.prosCons) {
  //       const prosCons = editData.prosCons;
  //       if (prosCons.pros.length) {
  //         let prosField = prosCons.pros.map((value) => {
  //           return { pros: value };
  //         });
  //         setPros(prosField);
  //       }
  //       if (prosCons.cons.length) {
  //         let cons = prosCons.cons.map((value) => {
  //           return { cons: value };
  //         });
  //         setCons(cons);
  //       }
  //     }
  //   }
  // }, [editData]);
  const deleteItem = (index) => {
    let prosCon = [...prosCons];
    prosCon.splice(index, 1);
    setProsCons([...prosCon]);
  };

  const addItemPros = (index) => {
    let prosCon = prosCons[index];
    if (!prosCon?.pros) {
      prosCon.pros = [];
    } else {
      prosCon.pros = [...prosCon.pros, ""];
    }
    setProsCons([...prosCons]);
  };

  const removeItemPros = (Removeindex) => {
    let prosCon = [...prosCons];
    prosCon[index].pros.splice(Removeindex, 1);
    setProsCons([...prosCons]);
  };

  const addItemCon = (index) => {
    let prosCon = prosCons[index];
    if (!prosCon?.cons) {
      prosCon.cons = [""];
    } else {
      prosCon.cons = [...prosCon.cons, ""];
    }
    setProsCons([...prosCons]);
  };

  const removeItemCons = (indexIme) => {
    let prosCon = prosCons[index];
    prosCon.cons.splice(indexIme, 1);
    setProsCons([...prosCons]);
  };
  const ChangePros = (changeIndex, valueu) => {
    let prosCon = prosCons[index];
    prosCon.pros[changeIndex] = valueu;
    setProsCons([...prosCons]);
  };

  const ChangeCons = (changeIndex, valueu) => {
    let prosCon = prosCons[index];
    prosCon.cons[changeIndex] = valueu;
    setProsCons([...prosCons]);
  };
  return (
    <Row gutter={[16, 16]} justify="center" align="top">
      {prosCons.length !== 1 && (
        <CloseOutlined
          onClick={() => deleteItem(index)}
          style={{
            position: "relative",
            zIndex: 300,
            left: "85%",
            top: "30px",
          }}
        />
      )}
      <Col span={11}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            color: "black",
          }}
        >
          Pros
        </p>
        {prosCons[index].pros?.map((value, index) => {
          return (
            <Row style={{}} justify="start" align="middle">
              <Col span={19}>
                <Form.Item
                  rules={[{ required: true, message: "Missing Pros" }]}
                  style={{ width: "100%" }}
                >
                  <Input
                    value={value}
                    onChange={(e) => ChangePros(index, e.target.value)}
                    placeholder="Write Here"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={3}>
                <CloseCircleOutlined
                  style={{
                    color: "red",
                    fontSize: "30px",
                  }}
                  onClick={() => removeItemPros(index)}
                />
              </Col>
            </Row>
          );
        })}

        <Form.Item>
          <Button
            type="primary"
            onClick={() => addItemPros(index)}
            style={{
              color: "black",
              width: "140px",
              fontSize: "20px",
              height: "40px",
              fontWeight: "bolder",
            }}
            icon={<PlusOutlined />}
          >
            Add
          </Button>
        </Form.Item>
      </Col>
      <Col span={11}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            color: "black",
          }}
        >
          Cons
        </p>
        {prosCons[index].cons?.map((value, index) => {
          return (
            <Row justify="start" align="middle">
              <Col span={19}>
                <Form.Item
                  // {...restField}
                  // name={[name, "cons"]}
                  // fieldKey={[fieldKey, "cons"]}
                  rules={[{ required: true, message: "Missing Pros" }]}
                  style={{ width: "100%" }}
                >
                  <Input
                    value={value}
                    onChange={(e) => ChangeCons(index, e.target.value)}
                    placeholder="Write Here"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={3}>
                <CloseCircleOutlined
                  style={{
                    color: "red",
                    fontSize: "30px",
                  }}
                  onClick={() => removeItemCons(index)}
                />
              </Col>
            </Row>
          );
        })}
        <Form.Item>
          <Button
            type="primary"
            // onClick={() => add()}
            style={{
              color: "black",
              width: "140px",
              fontSize: "20px",
              height: "40px",
              fontWeight: "bolder",
            }}
            onClick={() => addItemCon(index)}
            icon={<PlusOutlined />}
          >
            Add
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CreateProsAndCons;
