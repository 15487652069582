import {
  FieldTimeOutlined,
  LoadingOutlined,
  BellFilled,
} from '@ant-design/icons';
import {
  AddOutlined,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ArrowRightOutlined,
} from '@material-ui/icons';
import {
  message as messageAlert,
  Button,
  Col,
  Collapse,
  DatePicker,
  Input,
  Modal,
  Row,
  Spin,
  Switch,
  Typography,
  notification,
  Card,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createNotificationAdminAlert,
  getNotificationsAdminAlert,
  updateNotificationAdminAlert,
} from '../../RestServices/authService';
import { MAIN_BACKGROUND_COLOR } from '../../styleConstants';
import './body.css';

const SchedulerNotification = ({ item }) => {
  const { TextArea } = Input;
  const [message, setMessage] = useState('');
  const [runDaily, setRunDaily] = useState(false);

  const [title, setTitle] = useState('');
  const { token } = useSelector((state) => state.Authentication.user);
  const [loading, setLoading] = useState(false);
  const [addNotification, setAddNotification] = useState(false);
  const [sentTime, setSentTime] = useState(moment());
  const [notificationId, setEditNotificationId] = useState();
  const [todayMoment, setTodayMoment] = useState(moment());

  const setAddNotificationModelClose = (check) => {
    setAddNotification(check);
    setEditNotificationId('');
    setRunDaily(false);
    setMessage('');
    setTitle('');
  };

  const { Panel } = Collapse;

  const [notifications, setNotifications] = useState([]);
  const onDateChange = (value) => {
    setSentTime(value);
  };
  const onTimeChange = (value) => {
    setSentTime(value);
  };

  const getNotifications = async () => {
    try {
      const { notifications } = await getNotificationsAdminAlert(
        { page: 1, runDaily: true, today: new Date(todayMoment) },
        token
      );
      setNotifications(notifications);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [addNotification, todayMoment]);

  const onOk = (value) => {
    setSentTime(value);
  };

  const editFormClick = (notification) => {
    setMessage(notification.message);
    setTitle(notification.title);
    setAddNotification(true);
    setRunDaily(notification?.runDaily);
    setSentTime(notification.sentTime);
    setEditNotificationId(notification._id);
  };

  const switchCheck = async (check, notification) => {
    const payload = {
      ...notification,
      notificationId: notification._id,
      active: check,
    };
    await updateNotificationAdminAlert(payload, token, setLoading, console.log);
    getNotifications();
  };

  const handleSubmit = async () => {
    try {
      if (!message || !title || !sentTime) {
        return messageAlert.error('Please fill all form');
      }
      let data = {
        message,
        title,
        sentTime,
        runDaily,
        notificationId,
      };
      if (!notificationId) {
        await createNotificationAdminAlert(
          data,
          token,
          setLoading,
          setAddNotificationModelClose
        );
      } else {
        await updateNotificationAdminAlert(
          data,
          token,
          setLoading,
          setAddNotificationModelClose
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
  );

  const { Title } = Typography;
  return (
    <div style={{ padding: "10px" }}>
      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col xs={{
          span: 24,
          offset: 0,
        }}
        sm={{
          span: 24,
          offset: 0,
        }}
        md={{
          span: 12,
          offset: 6,
        }}
        lg={{
          span: 12,
          offset: 6,
        }}
        xl={{
          span: 12,
          offset: 6,
        }}>
          <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div style={{ padding: "5px" }}>
              <div className="flex-between" style={{ alignItems: "flex-start" }}>
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Title level={1} className="mb-0">Today's schedule</Title>
                      <Title level={1} className="mb-0">
                        <span style={{ color: 'rgb(251, 180, 56)' }}>
                          {moment(todayMoment).format('MMM Do YYYY')}
                        </span>
                        <ArrowBackOutlined
                          onClick={() =>
                            setTodayMoment(moment(todayMoment).subtract('day', 1))
                          }
                          style={{ cursor: "pointer", marginLeft: '20px', marginRight: '20px' }}
                        />
                        <ArrowForwardOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setTodayMoment(moment(todayMoment).add('day', 1))
                          }
                        />
                      </Title>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '5px',
                      marginRight: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      cursor: 'pointer',
                      background: MAIN_BACKGROUND_COLOR,
                    }}
                    onClick={() => setAddNotification(!addNotification)}
                  >
                    <AddOutlined
                      style={{ width: '40px', height: '30px', color: 'black' }}
                      fill={'black'}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <div className='outer'>
        <Collapse>
          {notifications.map((notification) => {
            return (
              <Panel
                key={notification._id}
                showArrow={false}
                header={
                  <span>
                    <BellFilled fill={'white'} /> {notification.title}
                  </span>
                }
                className='site-collapse-custom-panel'
                extra={moment(notification.sentTime).format('LT')}
              >
                <div className='panel-body'>
                  <div className='panel-wrapper'>
                    <div className='panel-icon'>
                      <FieldTimeOutlined />
                    </div>
                    <div className='panel-title'>
                      {notification.message}
                      <div className='panel-list-items'>
                        <li>
                          Push at{' '}
                          {moment(notification.sentTime).format('LT')}
                        </li>
                      </div>
                    </div>
                  </div>
                  <div className='switch'>
                    <Switch
                      checked={notification.active}
                      onChange={(value) =>
                        switchCheck(value, notification)
                      }
                    />
                    <div
                      onClick={() => editFormClick(notification)}
                      className='edit-btn'
                      style={{ cursor: 'pointer' }}
                    >
                      EDIT
                    </div>
                  </div>
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </div>
      <Modal
        title='Add Notification'
        visible={addNotification} // isOpen
        // closable={() => setIsModelOpen(!isModelOpen)}
        confirmLoading={true}
        onCancel={() => setAddNotificationModelClose(!addNotification)}
        footer={null}
        maskClosable={false}
      >
        <div>
          <div>
            <Card>
              <div className="flex-between" style={{ alignItems: "flex-start" }}>
                <div style={{ width: "100%" }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Title level={2} className="mb-0 text-primary">Notification <Title level={2} className="mb-0" type="success">History</Title> </Title>
                      <Title level={2} className="mb-0 text-primary" style={{ marginTop: 0 }}>Create and Update <Title level={2} className="mb-0" type="success">Scheduler Notification</Title> </Title>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Button
                    span={2}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '5px',
                      marginRight: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      cursor: 'pointer',
                      background: MAIN_BACKGROUND_COLOR,
                    }}
                    onClick={() => setAddNotificationModelClose(!addNotification)}
                  >
                    <ArrowRightOutlined
                      style={{ width: '40px', height: '30px', color: 'black' }}
                      fill={'black'}
                    />
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div>
                  <Card>
                    <div style={{ padding: "10px" }}>
                      <div className="flex-col" style={{ marginBottom: "10px" }}>
                        <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Title</Title>
                        <Input
                          value={title}
                          style={{ width: '100%' }}
                          placeholder='Title'
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="flex-col" style={{ marginBottom: "10px" }}>
                        <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Message</Title>
                        <TextArea
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          rows={4}
                          placeholder='Enter the message'
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className="flex-col" style={{ marginBottom: "10px" }}>
                        <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Date</Title>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='YYYY-MM-DD'
                          onChange={onDateChange}
                          onOk={onOk}
                          value={moment(sentTime)}
                        />
                      </div>
                      <div className="flex-col" style={{ marginBottom: "10px" }}>
                        <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Time</Title>
                        <DatePicker
                          picker='time'
                          allowClear={true}
                          format='HH:mm'
                          style={{ width: '100%' }}
                          onChange={onTimeChange}
                          value={moment(sentTime)}
                          placeholder='Best time pick'
                          onOk={onOk}
                        />
                      </div>
                      <div className="flex-col" style={{ marginBottom: "10px" }}>
                        <Title level={4} className="text-primary" style={{ fontSize: "16px", fontWeight: 500 }}>Run Daily</Title>
                        <Switch
                          checked={runDaily}
                          onChange={(value) => setRunDaily(value)}
                        />
                      </div>

                      <Button
                        style={{
                          width: '100%',
                          marginTop: '20px',
                          height: '50px',
                        }}
                        type='primary'
                        onClick={() => handleSubmit()}
                      >
                        {loading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <Title
                            level={4}
                            style={{ marginBottom: 0, color: 'white' }}
                          >
                            Add Scheduler Notification
                          </Title>
                        )}
                      </Button>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SchedulerNotification;
