import { combineReducers } from "redux";
import Cities from '../Reducers/Cities/Cities';
import Authentication from '../Reducers/Signin';
import logger from 'redux-logger';
import CommodityRates from '../Reducers/CommodityRates/CommodityRates';
import FeedBack from '../Reducers/FeedBack/FeedBack';
import Order from '../Reducers/order/order';
import Setting from '../Reducers/setting/setting';

export const rootReducer = combineReducers({
    Authentication,
    Cities,
    CommodityRates,
    FeedBack,
    Setting,
    Order,
})