import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Form, Col, Row, Card } from "antd";
import {
  CloseCircleOutlined,
  PlusOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { MAIN_BACKGROUND_COLOR } from "../../../styleConstants";
import "../style.css";
import CreateProsAndCons from "./createProsCons";
import ListProsAndCons from "./listProsAndCons";

const styles = {
  modal: {
    backgroundColor: "white",
  },
  submitButton: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    width: "100%",
    border: "none",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

const ProsCons = ({
  isModelOpen,
  setIsModelOpen,
  setProsCons,
  editData,
  prosCons,
}) => {
  const newProsCons = () => {
    setProsCons([...prosCons, { pros: [], cons: [] }]);
  };
  return (
    <Modal
      bodyStyle={styles.modal}
      width={800}
      visible={isModelOpen}
      confirmLoading={true}
      onCancel={() => setIsModelOpen(!isModelOpen)}
      footer={null}
      maskClosable={false}
    >
      <div>
        {prosCons?.map((value, index) => {
          return (
            <CreateProsAndCons
              isModelOpen={isModelOpen}
              setIsModelOpen={setIsModelOpen}
              setProsCons={setProsCons}
              editData={editData}
              prosCons={prosCons}
              proCon={value}
              index={index}
            />
          );
        })}
        <Row justify="center">
          <Button
            type="primary"
            htmlType="submit"
            style={{
              color: "black",
              fontSize: "20px",
              marginTop: "23px",
              height: "40px",
              fontWeight: "bolder",
            }}
            onClick={newProsCons}
          >
            Create New Pros and Cons
          </Button>
        </Row>
        {prosCons?.map((value, index) => {
          return (
            <ListProsAndCons
              isModelOpen={isModelOpen}
              setIsModelOpen={setIsModelOpen}
              setProsCons={setProsCons}
              editData={editData}
              prosCons={prosCons}
              proCon={value}
              index={index}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default ProsCons;
