import { Col, Row } from 'antd';
import React from 'react';

export default function UserStat({
  setSelected,
  slectedSubkey,
  selectedKey,
  userDetails,
}) {
  return (
    <Row>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          onClick={() => {
            setSelected('Dashboard/User', 'Crm All User');
          }}
          className='order-box'
          style={{
            cursor: 'pointer',
            background: 'linear-gradient(45deg, #FC9C39, #FBB438)',
          }}
        >
          <div className='order-text'>Total </div>
          <div className='order-count'>{userDetails.totalUser}</div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className='order-box'
          style={{
            cursor: 'pointer',
            background: 'linear-gradient(45deg, #8DD38F, #8BF78F)',
          }}
          onClick={() => {
            setSelected('Dashboard/User', 'Crm Active User');
          }}
        >
          <div className='order-text'>Active </div>

          <div className='order-count'>{userDetails.activeUser}</div>
          <div className='order-count'>
            {userDetails.totalUser
              ? (
                  (userDetails.activeUser / userDetails.totalUser) *
                  100
                ).toFixed(2)
              : 0}{' '}
            %
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className='order-box'
          style={{
            cursor: 'pointer',
            background: 'linear-gradient(45deg, #F9EB54, #FEC512)',
          }}
          onClick={() => {
            setSelected('Dashboard/User', 'Crm Sluggish');
          }}
        >
          <div className='order-text'>Sluggish </div>
          <div className='order-count'>{userDetails.sluggishUser}</div>
          <div className='order-count'>
            {userDetails.totalUser
              ? (
                  (userDetails.sluggishUser / userDetails.totalUser) *
                  100
                ).toFixed(2)
              : 0}{' '}
            %
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className='order-box'
          style={{
            cursor: 'pointer',
            background: 'linear-gradient(45deg, #EB5252, #FA1515)',
          }}
          onClick={() => {
            setSelected('Dashboard/User', 'Crm Idol');
          }}
        >
          <div className='order-text'>Idol </div>
          <div className='order-count'>{userDetails.idolUser}</div>
          <div className='order-count'>
            {userDetails.totalUser
              ? ((userDetails.idolUser / userDetails.totalUser) * 100).toFixed(
                  2
                )
              : 0}{' '}
            %
          </div>
        </div>
      </Col>
    </Row>
  );
}
