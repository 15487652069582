import React, { Component } from "react";
import "./calendar.css";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = { year: "Year", month: "Month", day: "Day" };
  }

  render() {
    return (
      <div className="flex-row custom-calendar">
        <DayPicker
          defaultValue={this.state.day}
          endYearGiven
          required={true}
          value={this.state.day}
          onChange={(day) => {
            this.setState({ day });
            console.log(day);
          }}
          id={"day"}
          name={"day"}
          classes={"calendar_Day"}
          optionClasses={"select_list"}
        />
        <MonthPicker
          defaultValue={this.state.month}
          short
          caps
          endYearGiven
          year={this.state.year}
          required={true}
          value={this.state.month}
          onChange={(month) => {
            this.setState({ month });
            console.log(month);
          }}
          id={"month"}
          name={"month"}
          classes={"calendar_Month"}
          optionClasses={"select_list"}
        />

        <YearPicker
          defaultValue={this.state.year}
          reverse
          required={true}
          value={this.state.year}
          onChange={(year) => {
            this.setState({ year });
            console.log(year);
          }}
          id={"year"}
          name={"year"}
          classes={"calendar_Year"}
          optionClasses={"select_list"}
        />
      </div>
    );
  }
}

export default Calendar;
