import {
  COMMODITY_LOADING,
  COMMODITY_SUCCESSFULL,
  COMMODITY_FAILED,
  COMMODITY_ADD_SUCCESSFULL,
  GET_TIME_STRING,
  CREATE_TIME_STRING,
  EDIT_TIME_STRING,
  TIMER_LOADING,
  COMMODITY_COUNT_GET,
  COMMODITY_COUNT_LOADING,
  COMMODITY_COUNT_LOADING_FALSE,
} from "../actionTypes";

export const commodityLoading = () => ({
  type: COMMODITY_LOADING,
});

export const commoditySuccessfull = (commodities) => ({
  type: COMMODITY_SUCCESSFULL,
  commodities,
});
export const commodityAddOneSuccessfull = (commodity) => ({
  type: COMMODITY_ADD_SUCCESSFULL,
  commodity,
});
export const commodityFailed = (errMess) => ({
  type: COMMODITY_FAILED,
  errMess,
});

export const getTimeString = (payload) => ({
  type: GET_TIME_STRING,
  payload,
});
export const createTimeString = (payload) => ({
  type: CREATE_TIME_STRING,
  payload,
});
export const timerLoading = (payload) => ({
  type: TIMER_LOADING,
  payload,
});

export const getCommoditiesCount = (payload) => ({
  type: COMMODITY_COUNT_GET,
  payload,
});

export const getCommoditiesCountLoading = (payload) => ({
  type: COMMODITY_COUNT_LOADING,
  payload,
});

export const getCommoditiesCountLoadingFalse = (payload) => ({
  type: COMMODITY_COUNT_LOADING_FALSE,
  payload,
});
