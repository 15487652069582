import {
  ORDER_LOADING,
  ORDER_SUCCESSFULL,
  ORDER_FAILED,
  ORDER_CHANGED,
  ORDER_COUNT_SUCCESSFULL,
  BIDDINGS_SUCCESSFULL,
} from '../../actionTypes';

const Order = (
  state = {
    isLoading: false,
    errMess: null,
    orders: null,
    city: null,
    bidding: null,
    orderStat: {
      PENDING: 0,
      IN_PROGRESS: 0,
      CANCELED: 0,
      REJECTED: 0,
      DELIVERED: 0,
    },
  },
  action,
) => {
  switch (action.type) {
    case ORDER_LOADING:
      return { ...state, isLoading: true, errMess: null };
    case ORDER_FAILED:
      return {
        ...state,
        orders: {},
        bidding: null,
        isLoading: false,
        errMess: null,
      };
    case ORDER_COUNT_SUCCESSFULL:
      return {
        ...state,
        orderStat: {
          PENDING: 0,
          IN_PROGRESS: 0,
          CANCELED: 0,
          REJECTED: 0,
          DELIVERED: 0,
          ...action.ordersCount,
        },
        isLoading: false,
        errMess: null,
      };
    case ORDER_CHANGED: {
      const {
        orders: { docs },
      } = state;

      let order = docs?.find((order) => order?._id === action?.order?._id);
      order.orderStatus = action.order.orderStatus;
      return {
        ...state,
        orders: { ...state.orders, docs },
        isLoading: false,
        errMess: null,
      };
    }
    case ORDER_SUCCESSFULL:
      return {
        ...state,
        orders: action.orders,
        isLoading: false,
        errMess: null,
      };

    case BIDDINGS_SUCCESSFULL:
      return {
        ...state,
        bidding: action.payload,
        isLoading: false,
        errMess: null,
      };
    default:
      return state;
  }
};
export default Order;
