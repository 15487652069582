import { Card, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getPostCount } from '../../../RestServices/postingService';
import LowerCard from './Cards/LowerCards/LowerCard';
import {
  ArticleItem,
  LowerCardData1,
  PostServiceItem,
} from './Cards/LowerCards/LowerCardData1';
import TopFeature from './TopFeatureDiv/TopFeature';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getVideosCount,
  getArticleCount,
} from '../../../RestServices/videosService';

import LowerCardForVidios from './Cards/LowerCards/LowerCardForVideo';
import ArticlesShow from './Cards/LowerCards/ArticlesShow';
export default function CardDetail() {
  const [postData, setpostData] = useState([]);
  const [videos, setvideos] = useState([]);

  const [articles, setArticles] = useState([]);

  const [articlesCountLoading, setArticlesCountLoading] = useState(false);
  const [videosCountLoading, setVideosCountLoading] = useState(false);
  const [postCountLoading, setPostCountLoading] = useState(false);

  const [dateSelection, setdateSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const { token } = useSelector((state) => state.Authentication.user);

  const [city, setCity] = useState('');

  const [datesBetween, setDateBetween] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  const [dropdrownshow, setdropdrownshow] = useState(false);

  const handleSelect = (ranges) => {
    console.log(ranges);
    if (ranges.hasOwnProperty('range1')) {
      setdateSelection(ranges.range1);
    } else {
      setdateSelection(ranges.selection);
    }
  };
  const PickDate = () => {
    setdropdrownshow(false);
    setDateBetween({
      startDate: dateSelection.startDate,
      endDate: dateSelection.endDate,
    });
  };

  const onChangeCity = (value) => {
    console.log(`selected ${value}`);
    setCity(value);
  };

  useEffect(() => {
    getPostCounting();
    getVideosC();
    getArticleC();
  }, [datesBetween, city]);

  const getPostCounting = async () => {
    try {
      setPostCountLoading(true);
      const startDate = datesBetween.startDate
        ? moment(datesBetween.startDate).toDate()
        : datesBetween.startDate;
      const enddate = datesBetween.startDate
        ? moment(datesBetween.endDate).toDate()
        : datesBetween.startDate;
      const response = await getPostCount(
        {
          startDate: startDate,
          endDate: enddate,
          city,
        },
        token
      );
      console.log(response.posts);
      setpostData(response.posts);
    } catch (err) {
      console.log(err);
    } finally {
      setPostCountLoading(false);
    }
  };

  const getVideosC = async () => {
    try {
      setVideosCountLoading(true);
      const startDate = datesBetween.startDate
        ? moment(datesBetween.startDate).toDate()
        : datesBetween.startDate;
      const enddate = datesBetween.startDate
        ? moment(datesBetween.endDate).toDate()
        : datesBetween.startDate;
      const response = await getVideosCount(
        {
          startDate: startDate,
          endDate: enddate,
          city,
        },
        token
      );
      setvideos(response.video);
    } catch (err) {
      console.log(err);
    } finally {
      setVideosCountLoading(false);
    }
  };

  const getArticleC = async () => {
    try {
      setArticlesCountLoading(true);
      const startDate = datesBetween.startDate
        ? moment(datesBetween.startDate).toDate()
        : datesBetween.startDate;
      const enddate = datesBetween.startDate
        ? moment(datesBetween.endDate).toDate()
        : datesBetween.startDate;
      const response = await getArticleCount(
        {
          startDate: startDate,
          endDate: enddate,
          city,
        },
        token
      );
      setArticles(response?.article);
    } catch (err) {
      console.log(err);
    } finally {
      setArticlesCountLoading(false);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <TopFeature
              showCity={true}
              data={[...postData, ...videos]}
              text={'Top Features'}
              showcalender={true}
              handleSelect={handleSelect}
              PickDate={PickDate}
              dateSelection={dateSelection}
              datesBetween={datesBetween}
              dropdrownshow={dropdrownshow}
              setdropdrownshow={(flag) => {
                if (typeof flag === 'boolean') setdropdrownshow(flag);
              }}
              setdateSelection={() => {
                setdateSelection({ ...dateSelection, ...datesBetween });
              }}
              onChangeCity={onChangeCity}
              city={city}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[12, 12]} style={{ marginTop: "20px" }}>
        {PostServiceItem.map((val) => (
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card>
              <div>
                <LowerCard
                  data={postData}
                  key={val.id}
                  loading={postCountLoading}
                  Heading={val.heading}
                  Img={val.image}
                  TotalPost={val.total_post}
                  TotalPostValue={val.total_post_value}
                  TotalVideos={val.total_video}
                  TotalVideosValue={val.total_video_value}
                  TotalShare={val.total_share}
                  TotalShareValue={val.total_share_value}
                  TotalView={val.total_views}
                  TotalViewValue={val.total_views_value}
                  TotalLikes={val.total_likes}
                  TotalLikesValue={val.total_likes_value}
                  TotalVisit={val.total_visit}
                  TotalVisitValue={val.total_visit_value}
                  AvgViewTime={val.avg_view_time}
                  AvgViewTimeValue={val.avg_view_time_value}
                />
              </div>
            </Card>
          </Col>
        ))}
        {ArticleItem.map((val) => (
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card>
              <ArticlesShow
                key={val.id}
                loading={articlesCountLoading}
                data={articles}
                Heading={val.heading}
                Img={val.image}
                TotalPost={val.total_post}
                TotalPostValue={val.total_post_value}
                TotalVideos={val.total_video}
                TotalVideosValue={val.total_video_value}
                TotalShare={val.total_share}
                TotalShareValue={val.total_share_value}
                TotalView={val.total_views}
                TotalViewValue={val.total_views_value}
                TotalLikes={val.total_likes}
                TotalLikesValue={val.total_likes_value}
                TotalVisit={val.total_visit}
                TotalVisitValue={val.total_visit_value}
                AvgViewTime={val.avg_view_time}
                AvgViewTimeValue={val.avg_view_time_value}
              />
            </Card>
          </Col>
        ))}

        {LowerCardData1.map((val) => (
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Card>
              <LowerCardForVidios
                key={val.id}
                loading={videosCountLoading}
                data={videos}
                Heading={val.heading}
                Img={val.image}
                TotalPost={val.total_post}
                TotalPostValue={val.total_post_value}
                TotalVideos={val.total_video}
                TotalVideosValue={val.total_video_value}
                TotalShare={val.total_share}
                TotalShareValue={val.total_share_value}
                TotalView={val.total_views}
                TotalViewValue={val.total_views_value}
                TotalLikes={val.total_likes}
                TotalLikesValue={val.total_likes_value}
                TotalVisit={val.total_visit}
                TotalVisitValue={val.total_visit_value}
                AvgViewTime={val.avg_view_time}
                AvgViewTimeValue={val.avg_view_time_value}
              />
            </Card>
          </Col>
        ))}

      </Row>
    </div>
  );
}
